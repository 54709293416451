import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Progress: ComponentStyleConfig = {
  baseStyle: {
    track: {
      w: "100%",
      bg: "#98E2DB"
    },
    filledTrack: {
      bg: "primary.500"
    }
  },
  sizes: {
    md: {
      track: { h: "10px" },
    },
  },
};

export default Progress;
