import {
  Preferences,
  UpdatePasswordData,
  User,
  UserFormRequest,
  VerifyEmailProps,
  VerifyIsTaken,
} from "interfaces/User";
import api from "services/api";

export async function getUsersRequest(name?: string) {
  const { data } = await api.get<User[]>("/users", {
    params: {
      query: name,
    },
  });
  return data;
}

export async function getUser(username: string) {
  const { data } = await api.get<User>(`/users/${username}`);
  return data;
}

export async function verifyEmailRequest(email: string) {
  const { data } = await api.get<VerifyEmailProps>(`/auth/email/${email}`);
  return data;
}

export async function verifyUsernameRequest(username: string) {
  const { data } = await api.get<VerifyIsTaken>(`/auth/username/${username}`);
  return data;
}

export async function confirmEmailRequest(code: string) {
  const { data } = await api.get("/email/confirmation", {
    params: {
      confirmationToken: code,
    },
  });
  return data;
}

export async function resendConfirmEmailRequest() {
  const { data } = await api.post("/email/resend-confirmation");
  return data;
}

export async function updateUser(user: UserFormRequest) {
  const { data } = await api.patch<User>("/me", user);

  return data;
}

export async function updateUserImage(formData: FormData) {
  const { data } = await api.post<{ url: string }>("/me/avatar", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return data;
}

export async function updatePassword({
  currentPassword,
  password,
  passwordConfirmation,
}: UpdatePasswordData) {
  const { data } = await api.put("/password", {
    currentPassword,
    password,
    passwordConfirmation,
  });

  return data;
}

export async function updatePreferencesRequest(params: {
  allowDataCollect: boolean;
  notificationOpts: Preferences;
}) {
  const { data } = await api.put("/me/preferences", params);
  return data;
}

export async function sendDeleteAccountCode() {
  const { data } = await api.post("/me/delete-account");

  return data;
}

export async function deleteAccount(token: string) {
  const { data } = await api.delete("/me", {
    data: {
      token,
    },
  });

  return data;
}

export async function referFriend(email: string) {
  const { data } = await api.post("/users/invitations", { email });

  return data;
}
