import {
  AnswerType,
  AppealOrganizer,
  Appeals,
  CorrectionDataToRequest,
  CreateAppeals,
  CreateEventType,
  EventListFilters,
  EventStatusLookupEnum,
  EventType,
  FinishedAppeal,
  getSubmissionsToPeerCorrection,
  MultipleChoiceQuestion,
  MyEventListType,
  MyEventsToCalendar,
  OpenQuestion,
  RankingType,
  RegectAppeal,
  ReportQuestion,
  SeeQuestionAnswer,
  Submission,
  SubmissionCorrected,
  SubmitQuizData,
} from "interfaces/event";
import {
  AnswerProps,
  SubmissionsProps,
  SubmitCorrection,
} from "interfaces/EventOLD";
import { User } from "interfaces/User";
import { DashboardProps } from "pages/EventManagement/props";
import api from "./api";

export async function getEventsRequest({
  page,
  size,
  time,
  name,
  startDate,
  type,
}: EventListFilters) {
  const { data } = await api.get("/events", {
    params: {
      page,
      size,
      time,
      name,
      startDate,
      type,
    },
  });

  return data;
}

export async function getEventRequest(id: string) {
  const { data } = await api.get<EventType>(`/events/${id}`);
  return data;
}

export async function getEventQuestionsRequest(id: string) {
  const { data } = await api.get<Array<OpenQuestion | MultipleChoiceQuestion>>(
    `/events/${id}/questions`
  );
  return data;
}

export async function getEventQuestionsRestrictedRequest(id: string) {
  const { data } = await api.get<Array<OpenQuestion | MultipleChoiceQuestion>>(
    `/events/${id}/questions/restricted`
  );
  return data;
}

export async function createEventRequest(event: CreateEventType | EventType) {
  const { data } = await api.post<EventType>("/events", event);
  return data;
}

export async function editEventRequest(event: CreateEventType) {
  const { data } = await api.put<EventType>(`/events/${event.id}`, {
    ...event,
    id: undefined,
  });
  return data;
}

export async function uploadEventImageRequest(id: string, image: File) {
  const formData = new FormData();

  formData.append("image", image);

  const { data } = await api.post<{ url: string }>(
    `/events/${id}/image`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return data;
}

export async function getEventLookupRequest(
  id: string
): Promise<EventStatusLookupEnum> {
  const { data } = await api.get<{ status: EventStatusLookupEnum }>(
    `/events/${id}/lookup`
  );

  return data.status;
}

export async function eventSubscribeRequest(idOrCode: string) {
  const { data } = await api.put(`/events/${idOrCode}/join`);
  return data;
}

export async function getPendingCorrectionsCountRequest(eventId: string) {
  const { data } = await api.get<{ count: number }>("me/corrections/count", {
    params: {
      eventId,
    },
  });

  return data.count;
}

export async function eventUnsubscribeRequest(id: string) {
  const { data } = await api.put(`events/${id}/unsubscribe`);
  return data;
}

export async function getSubmissionsToModeratorOrOwnerRequest(id: string) {
  const { data } = await api.get<Submission[]>(`/events/${id}/submissions`);
  return data;
}

export async function getSubmissionsToPeerCorrectionRequest(eventId: string) {
  const { data } = await api.get<getSubmissionsToPeerCorrection[]>(
    "/me/corrections",
    {
      params: {
        eventId,
      },
    }
  );
  return data;
}

export async function getSubmissionRequest(id: string) {
  const { data } = await api.get<Submission>(`/submissions/${id}`);
  return data;
}

export async function correctPeerCorrectionRequest(
  id: string,
  corrections: CorrectionDataToRequest[]
) {
  const { data } = await api.post(`/corrections/${id}`, {
    corrections,
  });
  return data;
}

export async function correctWithModeratorsOrOwnerRequest(
  id: string,
  corrections: CorrectionDataToRequest[],
  submissionCorrectionId: string
) {
  const { data } = await api.post(`/submissions/${id}/corrections`, {
    corrections,
    submissionCorrectionId,
  });
  return data;
}

export async function finishEvent(id: string) {
  const { data } = await api.patch(`/events/${id}/finish`);
  return data;
}

export async function uploadQuestionImage(formData: FormData) {
  const { data } = await api.post("/questions/attachments", formData);
  return data;
}

export async function getEvent(id: string) {
  const { data } = await api.get(`/events/${id}`);
  return data;
}

export async function getDashBoardEvent(eventId: string) {
  const res = await api.get(`/events/${eventId}/dashboard
  `);
  return res.data;
}

export async function getDashBoardEventRequest(eventId: string) {
  const res = await api.get<DashboardProps>(`/events/${eventId}/dashboard
  `);
  return res.data;
}

export async function getParticipantsEvent(eventId: string) {
  const res = await api.get(`/events/${eventId}/participants`);
  return res.data;
}

export async function getParticipantsEventRequest(eventId: string) {
  const res = await api.get<User[]>(`/events/${eventId}/participants`);
  return res.data;
}

export async function getCorrections(id: string) {
  const { data } = await api.get("/me/corrections", {
    params: {
      eventId: id,
    },
  });
  return data;
}

export async function getCorrection(id: string) {
  const { data } = await api.get(`/corrections/${id}`);
  return data;
}

export async function getSubmissions(id: string) {
  const { data } = await api.get(`/events/${id}/submissions`);
  return data;
}

export async function getSubmission(id: string) {
  const { data } = await api.get(`/submissions/${id}`);
  return data;
}

export async function submitPeerCorrection(
  id: string,
  correction: SubmitCorrection[]
) {
  const { data } = await api.post(`/corrections/${id}`, {
    corrections: correction,
  });
  return data;
}

export async function submitCorrection(
  id: string,
  correction: SubmitCorrection[]
) {
  const { data } = await api.post(`/submissions/${id}/corrections`, {
    corrections: correction,
  });
  return data;
}

export async function getEventsList() {
  const res = await api.get("/events?type=organizer");
  return res.data;
}

export async function eventSubscribe(eventIdOrCode: string) {
  const res = await api.put(`/events/${eventIdOrCode}/join`);
  return res.data;
}

export async function SubmissionsAnswer(
  eventId: string,
  answer: SubmissionsProps
) {
  const res = await api.post(`/events/${eventId}/submissions`, answer);
  return res.data;
}

export async function createSubmissionsAnswerRequest(
  eventId: string,
  submitQuizData: SubmitQuizData
) {
  const res = await api.post(`/events/${eventId}/submissions`, submitQuizData);
  return res.data;
}

export async function UpdateSubmissionsAnswer(
  submissionId: string,
  answers: AnswerProps[],
  isDraft: boolean
) {
  const res = await api.put(`/submissions/${submissionId}`, {
    answers,
    isDraft,
  });
  return res.data;
}

export async function UpdateSubmissionsAnswerRequest(
  submissionId: string,
  submitQuizData: SubmitQuizData
) {
  const res = await api.put(`/submissions/${submissionId}`, {
    ...submitQuizData,
  });
  return res.data;
}

export async function getQuestionsEvent(eventId: string) {
  const res = await api.get(`/events/${eventId}/questions`);
  return res.data;
}

export async function unsubscribeEvent(eventId: string) {
  const { data } = await api.put(`events/${eventId}/unsubscribe`);
  return data;
}

export async function getPendingAnswersCount(eventId: string) {
  const { data } = await api.get("me/corrections/count", {
    params: {
      eventId,
    },
  });

  return data;
}
export async function getRankingEvent(eventId: string) {
  const res = await api.get(`/events/${eventId}/ranking
  `);
  return res.data;
}
export async function getRankingEventRequest(eventId: string) {
  const res = await api.get<RankingType[]>(`/events/${eventId}/ranking
  `);
  return res.data;
}
export async function getSubmissionEvent(eventId: string) {
  const res = await api.get(`/events/${eventId}/submissions
  `);
  return res.data;
}
export async function getSubmissionEventRequest(eventId: string) {
  const res = await api.get<AnswerType[]>(`/events/${eventId}/submissions
  `);
  return res.data;
}

export async function getAnswersSubmissions(submissionsId: string) {
  const res = await api.get(`/submissions/${submissionsId}`);
  return res.data;
}

export async function getMyEventsToCalendar(
  startDate: string,
  endDate: string
) {
  const { data } = await api.get<MyEventsToCalendar[]>("/me/events/calendar", {
    params: {
      startDate,
      endDate,
    },
  });
  return data;
}

export async function getMyEvents({
  name,
  page,
  size,
  startDate,
  time,
  type,
}: EventListFilters) {
  const { data } = await api.get<MyEventListType>("/me/events", {
    params: {
      name,
      type,
      page,
      size,
      time,
      startDate,
    },
  });
  return data;
}

export async function getAppealsEvent(eventId: string, page?: number) {
  const res = await api.get(`/appeals/event/${eventId}`, {
    params: { page, size: 9 },
  });
  return res.data;
}

export async function getAppealsEventRequest(eventId: string, pages?: number) {
  const res = await api.get<AppealOrganizer[]>(`/appeals/event/${eventId}`, {
    params: { pages, size: 9 },
  });
  return res.data;
}

export async function finishedAppeals(
  appealId: string,
  params: FinishedAppeal
) {
  const res = await api.post(`/appeals/${appealId}/finish`, params);
  return res.data;
}

export async function finishedAppealsRequest(
  appealId: string,
  params: FinishedAppeal
) {
  const res = await api.post(`/appeals/${appealId}/finish`, params);
  return res.data;
}

export async function rejectAppeals(appealId: string, params: RegectAppeal) {
  const res = await api.post(`/appeals/${appealId}/reject`, params);
  return res.data;
}

export async function rejectAppealsRequest(
  appealId: string,
  params: RegectAppeal
) {
  const res = await api.post(`/appeals/${appealId}/reject`, params);
  return res.data;
}

export async function nullifyQuestion(
  questionId: string,
  eventId: string,
  appealId: string
) {
  const res = await api.patch(`/questions/${questionId}/nullify`, {
    eventId,
    appealId,
  });
  return res.data;
}

export async function createAppealsAnswerRequest(params: CreateAppeals) {
  const res = await api.post("/appeals", params);
  return res.data;
}

export async function getAnswerEvent(eventId: string) {
  const res = await api.get(`/me/event/${eventId}/submission`);
  return res.data;
}

export async function getAnswerEventRequest(eventId: string) {
  const res = await api.get<Submission>(`/me/event/${eventId}/submission`);
  return res.data;
}

export async function getDetailsSubmission(submissionId: string) {
  const res = await api.get(`/submissions/${submissionId}/corrections `);
  return res.data;
}

export async function getDetailsSubmissionRequest(submissionId: string) {
  const res = await api.get<SubmissionCorrected[]>(
    `/submissions/${submissionId}/corrections `
  );
  return res.data;
}

export async function getDetailsAnswer(answerId: string) {
  const res = await api.get(`/submissions/answer/${answerId}`);
  return res.data;
}

export async function getDetailsAnswerRequest(answerId: string) {
  const res = await api.get<SeeQuestionAnswer>(
    `/submissions/answer/${answerId}`
  );
  return res.data;
}

export async function getDetailsAppeals(appealsId: string) {
  const res = await api.get(`/appeals/${appealsId}`);
  return res.data;
}

export async function getDetailsAppealsRequest(appealsId: string) {
  const res = await api.get<Appeals>(`/appeals/${appealsId}`);
  return res.data;
}

export async function lookup(id: string) {
  const res = await api.get(`/events/${id}/lookup`);
  return res.data;
}

export async function reportQuestion({
  eventId,
  justification,
  questionId,
}: ReportQuestion) {
  const res = await api.post("/appeals/question", {
    eventId,
    questionId,
    justification,
  });
  return res.data;
}
