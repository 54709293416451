import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "contexts/auth/useAuth";
import { gapi } from "gapi-script";
import { Credentials } from "interfaces/User";
import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { FiEye, FiEyeOff, FiMail } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { signInSchema } from "./schema";

export function Form() {
  const { useSignIn, useAuthWithGoogle } = useAuth();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const { mutate: signIn, isLoading } = useSignIn();
  const { mutate: authWithGoogle, isLoading: isAuthWithGoogleLoading } =
    useAuthWithGoogle();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credentials>({
    resolver: yupResolver(signInSchema),
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChangePasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSignIn = (credentials: Credentials) => {
    signIn(credentials);
  };

  return (
    <Flex direction="column" gap={8} w="100%" px={8} pb={[8, 0]}>
      <Flex
        as="form"
        onSubmit={handleSubmit(handleSignIn)}
        direction="column"
        justify="space-between"
        gap={8}
        w="100%"
        h="100%"
      >
        <Flex direction="column" gap={2}>
          <Flex direction="column" gap={4}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement
                  children={<FiMail size={20} />}
                  color={errors.email ? "error" : "primary.500"}
                />
                <Input
                  placeholder="ex.:alexandre@arbo.com"
                  {...register("email")}
                />
              </InputGroup>

              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password}>
              <FormLabel>Senha</FormLabel>
              <InputGroup>
                <InputLeftElement
                  children={<MdLockOutline size={20} />}
                  color={errors.password ? "error" : "primary.500"}
                />
                <Input
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="••••••••"
                  {...register("password")}
                />
                <InputRightElement
                  children={
                    <IconButton
                      variant="icon"
                      aria-label="toggle password visibilty"
                      icon={isPasswordVisible ? <FiEye /> : <FiEyeOff />}
                      fontSize={20}
                      onClick={handleChangePasswordVisible}
                    />
                  }
                  color={errors.password ? "error" : "gray.200"}
                />
              </InputGroup>

              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <Link
            as={RouterLink}
            to="/recuperar-senha"
            alignSelf="flex-end"
            fontSize="sm"
          >
            Esqueci minha senha
          </Link>
        </Flex>

        <Button size="lg" type="submit" isLoading={isLoading}>
          Entrar
        </Button>
      </Flex>

      <Flex direction="column" align="center" gap={4}>
        <Flex gap={1} align="baseline">
          <Text fontSize="sm" fontWeight={700} color="gray.300">
            Não possui uma conta?
          </Text>
          <Link as={RouterLink} to="/criar-conta" fontSize="sm">
            Criar conta →
          </Link>
        </Flex>

        <Text fontSize="sm" fontWeight={700} color="gray.300">
          - ou -
        </Text>

        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
          onSuccess={authWithGoogle}
          render={(props) => (
            <Button
              {...props}
              variant="outline"
              size="lg"
              boxShadow="none"
              justifyContent={
                isAuthWithGoogleLoading ? "center" : "space-between"
              }
              leftIcon={<FcGoogle size={24} />}
              rightIcon={<FcGoogle size={24} opacity={0} />}
              isFullWidth
            >
              Entre pelo Google
            </Button>
          )}
        />
      </Flex>
    </Flex>
  );
}

