import { Flex, Heading, Text } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export function RecoverPassword() {
  return (
    <Flex direction="column" h="100%">
      <Flex direction="column" align="center" mt={4} mb={10}>
        <Text fontSize="xs" color="gray.300">
          Seja bem-vindo(a),
        </Text>
        <Heading fontSize="2xl" color="primary.600">
          Recuperar senha
        </Heading>
      </Flex>

      <Outlet />
    </Flex>
  );
}
