import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { Modal } from "components/Modal";
import { useCreateAppeals } from "hooks/event";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ReportModalProps } from "./props";

export function ReportAnswerModal({
  isOpen,
  onClose,
  title,
  numberQuestion,
  user,
  answerId,
  questionId,
  submissionId,
}: ReportModalProps) {
  type ReportForm = {
    value: string;
  };
  const defaultValues: ReportForm = { value: "" };
  const {
    mutate: createAppeals,
    isLoading,
    isSuccess,
  } = useCreateAppeals(submissionId, answerId);
  const { handleSubmit, register } = useForm<ReportForm>({
    defaultValues,
  });

  function onSubmit({ value }: ReportForm) {
    createAppeals({
      answerId,
      justification: value,
      questionId,
      submissionId,
    });
  }

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <Flex as="form" direction="column" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody
          gap={4}
          display="flex"
          flexDirection="column"
          pt={8}
          px={8}
          pb={0}
        >
          <Text fontSize="sm">Questão {numberQuestion}</Text>
          <Box dangerouslySetInnerHTML={{ __html: title }} color="gray.400" />
          <Divider />
          <FormControl isRequired>
            <FormLabel>
              Escreva um feedback para o proprietário do evento, justificando o
              problema.
            </FormLabel>
            <Textarea
              {...register("value")}
              placeholder="ex.: Recusamos sua solicitação, pois..."
              resize="none"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter
          gap={[4, 10]}
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          w={["full", "fit"]}
        >
          <ParticipantCard user={user} />

          <Flex justifyContent="space-between" gap={4} w={["full", "auto"]}>
            <Button variant="link" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              colorScheme="secondary"
              px="1.188rem"
            >
              Reportar
            </Button>
          </Flex>
        </ModalFooter>
      </Flex>
    </Modal>
  );
}
