import { useAuth } from "contexts/auth/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export function PublicRoutes() {
  const location = useLocation();

  const { user } = useAuth();

  if (user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
}
