import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { EmptyContent } from "components/EmptyContent";
import { Paper } from "components/Paper";
import { useGroup } from "contexts/group/useGroup";
import { useGroups } from "hooks/group";
import { useEffect } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdClose, MdPeople } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { GroupList } from "../GroupList";
import { CreateGroupModal } from "./CreateGroupModal";

export function LeftSide() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register, reset, name } = useGroup();
  const { data: groups, isLoading } = useGroups(name);

  useEffect(() => {
    if (name !== "" && !id) {
      navigate("/grupos");
    }
  }, [name]);

  return (
    <Paper flex={1} px={8} py={6} gap={4} maxW="30%" position="relative">
      <Flex as="form">
        <FormControl>
          <FormLabel>Pesquisar nome do grupo</FormLabel>
          <InputGroup mt={2}>
            <Input
              placeholder="ex.: Grupo avançado"
              {...register("name")}
              autoComplete="off"
            />
            <InputRightElement
              children={
                name && (
                  <IconButton
                    aria-label=""
                    variant="link"
                    icon={<MdClose />}
                    onClick={() => reset()}
                  />
                )
              }
            />
          </InputGroup>
        </FormControl>
      </Flex>

      {isLoading ? (
        <Flex flex={1} align="center" justify="center" position="relative">
          <Spinner />
        </Flex>
      ) : (
        <Flex flex={1} h="90%" overflow="auto">
          {groups?.length === 0 ? (
            <EmptyContent message="Nenhum grupo existente." icon={MdPeople} />
          ) : (
            <GroupList name={name} />
          )}

          <IconButton
            aria-label="Adicionar grupo"
            size="lg"
            icon={<BsPlusLg />}
            position="absolute"
            right={5}
            bottom={5}
            w={55}
            height={55}
            onClick={onOpen}
          />
        </Flex>
      )}

      <CreateGroupModal isOpen={isOpen} onClose={onClose} />
    </Paper>
  );
}
