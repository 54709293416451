import { Flex, Image, Link, Text } from "@chakra-ui/react";
import recoveryPassword from "assets/recovery-password.png";
import { Link as RouterLink } from "react-router-dom";

export function CheckMail() {
  return (
    <Flex
      direction="column"
      align="center"
      gap={8}
      w="100%"
      h="100%"
      px={8}
      pb={[8, 0]}
    >
      <Text maxW={280} fontSize="sm" color="gray.300" textAlign="center">
        Enviamos um link para criação de nova senha. Por favor, Confira a caixa
        de entrada do seu e-mail.
      </Text>

      <Flex direction="column" align="center" justify="space-between" h="100%">
        <Image src={recoveryPassword} />

        <Link as={RouterLink} to="/login">
          Acesse sua conta
        </Link>
      </Flex>
    </Flex>
  );
}
