import {
  Divider,
  Flex,
  Heading,
  Skeleton,
  SkeletonCircle,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { useEvent, useSubmission } from "hooks/event";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { formatDate } from "utils/format";

export function Correction() {
  const { id, submissionId } = useParams();
  const { search } = useLocation();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { data: event, isLoading: isEventLoading } = useEvent(id);
  const { data: submission, isLoading: isSubmissionLoading } =
    useSubmission(submissionId);

  const params = new URLSearchParams(search);
  const participantIndex = params.get("participant");
  const submissionCorrectionId = params.get("submissionCorrectionId") as string;

  return (
    <>
      <Header
        title={isLargerThan1080 ? event?.name : "Correção do evento"}
        goback={
          submissionCorrectionId
            ? `/meus-eventos/${id}/gerenciador/ranking`
            : submissionId
            ? `/meus-eventos/${id}/corrigir`
            : undefined
        }
      />

      <Paper
        p={8}
        direction="column"
        justify="space-between"
        overflow="auto"
        gap={isLargerThan1080 ? 0 : 5}
      >
        <Flex
          direction={isLargerThan1080 ? "row" : "column"}
          justify="space-between"
          gap={4}
          w="100%"
        >
          <Flex direction="column" gap={2}>
            {!isLargerThan1080 && !submissionId && (
              <Heading color="gray.400" fontSize="1.15rem" fontWeight={700}>
                {event?.name}
              </Heading>
            )}

            <Heading color="gray.400" fontSize="1.15rem" fontWeight={700}>
              Etapa de correção
            </Heading>

            {isEventLoading ? (
              <Skeleton h={4} w={36} />
            ) : (
              <Text>Data limite {formatDate(event?.correctionPeriodEnd)}</Text>
            )}
          </Flex>

          <Flex>
            {isSubmissionLoading ? (
              <Flex align="center" gap={4}>
                <SkeletonCircle size="12" />
                <Skeleton h={4} w={20} />
              </Flex>
            ) : (
              <>
                {submission && !event?.allowPeerCorrection && (
                  <ParticipantCard user={submission?.user} />
                )}

                {participantIndex && (
                  <ParticipantCard label={`Participante ${participantIndex}`} />
                )}
              </>
            )}
          </Flex>
        </Flex>

        {(isLargerThan1080 || !submissionId) && <Divider my={4} />}

        <Flex flex={1}>
          <Outlet />
        </Flex>
      </Paper>
    </>
  );
}
