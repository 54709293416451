import {
  Avatar,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  IconButton,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { format, isToday, isYesterday, lightFormat, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNotifications } from "hooks/notifications";
import { DisclosureProps } from "interfaces/common";
import { NotificationGroup } from "interfaces/notification";
import { useEffect, useState } from "react";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdNotifications,
} from "react-icons/md";
import { groupBy } from "utils/groupBy";
import { NotificationItem } from "./NotificationItem";

export function Notifications({ isOpen, onClose }: DisclosureProps) {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const { user } = useAuth();

  const {
    data: notificationsRequest,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    isRefetching,
  } = useNotifications({
    enabled: !!user,
  });

  const notificationsList = notificationsRequest?.pages
    .map(({ data }) => data)
    .map((notification) => notification)
    .flat();

  const notificationsPerDay = notificationsList?.map((notification) => ({
    ...notification,
    day: lightFormat(new Date(notification.createdAt), "yyyy-MM-dd"),
  }));

  const [notificationsGroups, setNotificationsGroups] = useState<
    NotificationGroup[]
  >([]);

  function getDate(date: string) {
    if (isToday(parseISO(date))) return "Hoje";

    if (isYesterday(parseISO(date))) return "Ontem";

    return format(new Date(date), "cccccc dd, MMMM", { locale: ptBR });
  }

  useEffect(() => {
    if (notificationsPerDay) {
      setNotificationsGroups(
        groupBy(notificationsPerDay, "day").map((group) => ({
          date: group[0],
          notifications: group[1],
        })) as NotificationGroup[]
      );
    }
  }, [isFetchingNextPage, isSuccess, isRefetching]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan600 ? "sm" : "full"}
    >
      <DrawerOverlay />

      <DrawerContent px={isLargerThan600 ? 6 : 0} bg="background">
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={6}
          mx="auto"
          w="100%"
        >
          {!isLargerThan600 && (
            <IconButton
              variant="link"
              aria-label="navigate back"
              icon={<MdKeyboardArrowLeft />}
              onClick={onClose}
            />
          )}

          <Heading
            fontSize={["lg", "xl", "2xl"]}
            color={isLargerThan600 ? "black" : "primary.500"}
            fontWeight={isLargerThan600 ? 800 : 700}
          >
            Notificações
          </Heading>

          {!isLargerThan600 ? (
            <Avatar
              boxSize={8}
              icon={<MdNotifications />}
              bg="primary.500"
              color="background"
              onClick={onClose}
            />
          ) : (
            <DrawerCloseButton
              position="relative"
              top={0}
              right={0}
              size="lg"
            />
          )}
        </DrawerHeader>

        <DrawerBody
          display="flex"
          flexDirection="column"
          alignItems={isLoading ? "center" : "stretch"}
          justifyContent={isLoading ? "center" : "flex-start"}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <Flex direction="column" gap={6}>
              {notificationsGroups.map(({ date, notifications }) => (
                <Flex key={date} direction="column" gap={4}>
                  <Flex align="center" gap={2}>
                    <Text
                      fontWeight={600}
                      textTransform="capitalize"
                      color="gray.400"
                      whiteSpace="nowrap"
                    >
                      {getDate(date)}
                    </Text>

                    {isLargerThan600 && <Divider />}
                  </Flex>

                  <Flex direction="column" gap={4}>
                    {notifications.map((notification) => (
                      <NotificationItem
                        key={notification.id}
                        onClose={onClose}
                        {...notification}
                      />
                    ))}
                  </Flex>

                  {!isLargerThan600 && <Divider />}
                </Flex>
              ))}
            </Flex>
          )}

          <Flex alignItems="center" justifyContent="center" pt={4}>
            {hasNextPage ? (
              <Button
                variant="unstyled"
                display="flex"
                flexDirection="column"
                fontSize="xs"
                onClick={() => fetchNextPage()}
                isLoading={isFetchingNextPage}
              >
                Ver mais
                <Icon as={MdKeyboardArrowDown} color="primary.500" />
              </Button>
            ) : (
              <Text>Não há mais notificações.</Text>
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
