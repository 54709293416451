import { Flex, IconButton, Image, Text } from "@chakra-ui/react";
import avatar from "assets/participant.svg";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { makeFullName } from "utils/makeFullName";
import { ParticipantCardProps } from "./props";

export function ParticipantCard({
  user,
  icon,
  isAnonymous = Boolean(!user),
  label,
  isLink = false,
  showEmail,
  onRemove,
  userId,
  showBorder,
  ...rest
}: ParticipantCardProps) {
  return (
    <Flex
      justify="space-between"
      align="center"
      gap={3}
      w="100%"
      border={showBorder ? "1px solid" : 0}
      borderColor="gray.200"
      borderRadius={10}
      transition="all .2s"
      _hover={showBorder ? { bg: "backgroundGray" } : {}}
      {...rest}
    >
      <Flex
        p={showBorder ? 4 : 0}
        flex={1}
        gap={3}
        align="center"
        as={isLink ? Link : undefined}
        to={`/${user?.username}`}
        cursor="pointer"
      >
        <Image
          boxSize={12}
          borderRadius="full"
          objectFit="cover"
          src={user?.image ?? avatar}
          alt={`foto de perfil de ${user?.firstName ?? { label }}`}
          flexShrink={0}
        />

        <Flex direction="column" w="83%">
          <Text fontSize="0.813rem" color="black">
            {isAnonymous
              ? label
              : makeFullName(user?.firstName, user?.lastName)}
          </Text>

          {showEmail && (
            <Text fontSize="0.875rem" color="gray.400">
              {user?.email}
            </Text>
          )}
        </Flex>
      </Flex>

      {onRemove && (
        <IconButton
          variant="link"
          colorScheme="secondary"
          p={showBorder ? 4 : 0}
          onClick={onRemove}
          aria-label=""
          icon={<MdClose size={24} />}
        />
      )}
    </Flex>
  );
}
