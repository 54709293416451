import {
  Button,
  Flex,
  Icon,
  Link,
  Spinner,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { EmptyContent } from "components/EmptyContent";
import {
  useEvent,
  useSubmissionsToModeratorOrOwner,
  useSubmissionsToPeerCorrection,
} from "hooks/event";
import { OpenAnswer } from "interfaces/event";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdPeople, MdTimer } from "react-icons/md";
import { Link as RouterLink, useParams } from "react-router-dom";

export function SubmissionsList() {
  const { id: eventId } = useParams();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { data: event } = useEvent(eventId);

  const {
    data: submissionsToModeratorOrOwner,
    isLoading: isSubmissionsToModeratorOrOwnerLoading,
  } = useSubmissionsToModeratorOrOwner(event ?? {});

  const {
    data: submissionsToPeerCorrection,
    isLoading: isSubmissionsToPeerCorrectionLoading,
  } = useSubmissionsToPeerCorrection(event ?? {});

  const submissions =
    submissionsToModeratorOrOwner ??
    submissionsToPeerCorrection?.map(({ submission }) => submission);

  if (
    isSubmissionsToModeratorOrOwnerLoading ||
    isSubmissionsToPeerCorrectionLoading
  ) {
    return (
      <Flex direction="column" align="center" justify="center" w="100%">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      {submissions?.length !== 0 ? (
        <Flex
          direction="column"
          gap={isLargerThan1080 ? 4 : 8}
          w="100%"
          h="100%"
        >
          {submissions?.map(
            ({ _id, user: participant, isCorrected, answers }, index) => (
              <Flex key={`${_id}-${index.toString()}`}>
                {!event?.allowPeerCorrection ? (
                  <ParticipantCard user={participant} />
                ) : (
                  <ParticipantCard label={`Participante ${index + 1}`} />
                )}

                {isCorrected ||
                  !answers?.some(
                    (answer) => (answer as OpenAnswer).openAnswer
                  ) ? (
                  <Tooltip label="Correção efetuada" placement="top">
                    <Flex>
                      <Icon
                        as={BsCheckCircleFill}
                        w="1.25rem"
                        h="1.25rem"
                        color="primary.500"
                      />
                    </Flex>
                  </Tooltip>
                ) : (
                  answers?.some(
                    (answer) => (answer as OpenAnswer).openAnswer
                  ) && (
                    <Button
                      as={RouterLink}
                      to={{
                        pathname: _id,
                        search:
                          submissionsToPeerCorrection &&
                          submissionsToPeerCorrection[index]._id &&
                          `correctionId=${submissionsToPeerCorrection &&
                          submissionsToPeerCorrection[index]._id
                          }${event?.allowPeerCorrection
                            ? `&participant=${index + 1}`
                            : ""
                          }`,
                      }}
                      variant={isLargerThan1080 ? "link" : "solid"}
                      px={8}
                    >
                      Corrigir
                    </Button>
                  )
                )}
              </Flex>
            )
          )}
        </Flex>
      ) : (
        <EmptyContent
          message={
            event?.distributionOccurred
              ? "Nenhuma submissão disponível."
              : "As submissões serão listadas em breve. Aguarde ou volte mais tarde."
          }
          icon={event?.distributionOccurred ? MdPeople : MdTimer}
        />
      )}
      <Link
        as={RouterLink}
        to={`/meus-eventos/${eventId}`}
        alignSelf="flex-start"
      >
        Voltar
      </Link>
    </Flex>
  );
}
