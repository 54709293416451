import * as React from "react";

const SvgLogo = (props) => (
  <svg width={392} height={70} viewBox="0 0 566.244 137.583" {...props}>
    <path
      d="m1663.168-701.992-43.985 23.23-.018 50.731 42.19 25.055 41.836-25.713.015-.329v-35.088l-14.54 9.855v17.055h-.001l-27.441 16.898-27.228-16.17.013-33.353 28.735-15.177.438.26v.002l13.124 7.795-33.884 20.672h0l18.837 21.392v-16.164l42.093-27.084z"
      style={{
        opacity: 1,
        fill: "#462066",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "#462066",
        strokeWidth: 3.70416665,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 16.89999962,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "stroke markers fill",
      }}
      transform="matrix(1.3394 0 0 1.3394 -2166.225 942.727)"
    />
    <text
      x={1722.464}
      y={-625.532}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: "52.69772339px",
        lineHeight: 1.25,
        fontFamily: "Ubuntu",
        InkscapeFontSpecification: "Ubuntu",
        letterSpacing: 0,
        wordSpacing: 0,
        fill: "#462066",
        fillOpacity: 1,
        stroke: "#462066",
        strokeWidth: 3.74904776,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "markers fill stroke",
      }}
      transform="matrix(1.3394 0 0 1.3394 -2166.225 938.796)"
    >
      <tspan
        x={1722.464}
        y={-625.532}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "67.02777863px",
          fontFamily: "Ubuntu",
          InkscapeFontSpecification: "Ubuntu",
          fill: "#462066",
          fillOpacity: 1,
          stroke: "#462066",
          strokeWidth: 3.74904776,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "markers fill stroke",
        }}
      >
        Eduka
      </tspan>
    </text>
    <text
      x={1916.409}
      y={-625.532}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: "52.69772339px",
        lineHeight: 1.25,
        fontFamily: "Ubuntu",
        InkscapeFontSpecification: "Ubuntu",
        letterSpacing: 0,
        wordSpacing: 0,
        fill: "#05aca2",
        fillOpacity: 1,
        stroke: "#05aca2",
        strokeWidth: 3.74904776,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        paintOrder: "markers fill stroke",
      }}
      transform="matrix(1.3394 0 0 1.3394 -2166.225 938.796)"
    >
      <tspan
        x={1916.409}
        y={-625.532}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "67.02777863px",
          fontFamily: "Ubuntu",
          InkscapeFontSpecification: "Ubuntu",
          fill: "#05aca2",
          fillOpacity: 1,
          stroke: "#05aca2",
          strokeWidth: 3.74904776,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "markers fill stroke",
        }}
      >
        Hub
      </tspan>
    </text>
  </svg>
);
export default SvgLogo;
