import { ListRequestOptionsType, ListResponseOptionsType } from "./common";
import { User } from "./User";

export type CreateEventSteps = "SETUP" | "Q&A" | "PUBLISH";

export type QuestionType = "OPEN" | "MULTIPLE_CHOICE";

export type EventStatus =
  | "NOT_JOINED"
  | "JOINED"
  | "ANSWERED"
  | "CORRECTED_ANSWERS";

export enum EventStatusLookupEnum {
  JOINED = "JOINED",
  NOT_JOINED = "NOT_JOINED",
  ANSWERED = "ANSWERED",
  CORRECTED_ANSWERS = "CORRECTED_ANSWERS",
}
export type EventUserType = "participant" | "organizer" | "explore" | "all";

export type EventStatusTime = "all" | "future" | "in_progress" | "finished";

export type Attachment = {
  url: string;
  file: File;
};

export type Question = {
  id: string;
  type: QuestionType;
  title: string;
  order: number;
  files: Attachment[];
  isNullified?: boolean;
  remove?: boolean;
};

export type Choice = {
  text: string;
  id: string;
  isCorrect: boolean;
};

export type OpenQuestion = {
  type: "OPEN";
  answerPattern: string;
  criteria: string;
} & Question;

export type MultipleChoiceQuestion = {
  type: "MULTIPLE_CHOICE";
  choices: Choice[];
} & Question;

export type Category = {
  id: string;
  name: string;
};

export interface ModeratorEmail {
  email: string;
}

export type EventType = {
  id: string;
  code: string;
  name: string;
  distributionOccurred: boolean;
  description?: string;
  isPublic: boolean;
  isDraft: boolean;
  categories?: Category[];
  weightOfQuestions?: number | string | null;
  responsePeriodStart: string;
  responsePeriodEnd: string;
  correctionPeriodStart: string;
  correctionPeriodEnd: string;
  appealPeriodStart: string;
  appealPeriodEnd: string;
  allowPeerCorrection: boolean;
  useAnswersInGroup: boolean;
  groupMembersCount?: number | null;
  allowModerators: boolean;
  moderators: User[];
  participants: string[];
  participantsCount: number;
  linkedGroup?: string | null;
  questions: Array<OpenQuestion | MultipleChoiceQuestion>;
  image?: string;
  owner: User;
  createdAt: string;
  isFinished: boolean;
};

export type MyEventListType = {
  data: EventType[];
  meta: ListResponseOptionsType;
};
export type EventListType = {
  events: Event[];
} & ListResponseOptionsType;

export type EventListFilters = {
  type?: EventUserType;
  time?: EventStatusTime;
  startDate?: Date | null;
} & Pick<Partial<EventType>, "name"> &
  ListRequestOptionsType;

export type CreateEventType = Omit<
  EventType,
  | "_id"
  | "code"
  | "categories"
  | "appealPeriodStart"
  | "appealPeriodEnd"
  | "moderators"
  | "participants"
  | "image"
  | "createdAt"
  | "linkedGroup"
> & {
  currentDate: Date;
  categories?: string[];
  moderators?: ModeratorEmail[] | string[];
  image?: File | string;
  linkedGroupId?: string | null;
};

export type Answer = {
  id: string;
  question: string | MultipleChoiceQuestion | OpenQuestion;
};

export type OpenAnswer = {
  openAnswer: string;
} & Answer;

export type MultipleChoiceAnswer = {
  multipleChoiceAnswer: string;
} & Answer;

export type Submission = {
  _id: string;
  event: string;
  grade: number | null;
  isCorrected: boolean;
  isDraft: boolean;
  user: User;
  answers: Array<MultipleChoiceAnswer | OpenAnswer>;
};

export type Correction = {
  answer: MultipleChoiceAnswer | OpenAnswer;
  feedback: string;
  grade: number | null;
  _id?: string;
};

export type getSubmissionsToPeerCorrection = {
  corrections: Correction[];
  corrector: string;
  grade: number | null;
  submission: Submission;
  _id: string;
};

export type AnswerAppeals = {
  _id: string;
  question: Question;
  openAnswer: string;
  multipleChoiceAnswer: string;
  corrections: Correction[];
};
export interface Appeals {
  event: string;
  feedback: string;
  justification: string;
  question: Question;
  answer: AnswerAppeals;
  _id: string;
  type: "QUESTION_APPEAL" | "CORRECTION_APPEAL";
}

export type Appeal = {
  question: string | Question;
  justification: string;
  feedback: string;
  status?: string;
  grade?: number;
};
export interface SubmissionCorrected {
  appeal: Appeal;
  openAnswer: string;
  question: Question;
  _id: string;
}

export type SubmitQuizData = {
  isDraft: boolean;
  answers: Array<MultipleChoiceAnswer | OpenAnswer>;
};

export type SeeQuestionAnswer = {
  question: MultipleChoiceQuestion | OpenQuestion;
  corrections: Correction[];
  multipleChoiceAnswer: string;
  submission: Submission;
  openAnswer: string;
  appeal: Appeal;
  _id: string;
  user: User;
};

export type CreateAppeals = {
  submissionId: string;
  answerId: string;
  questionId: string;
  justification: string;
};

export type ReportQuestion = {
  eventId: string;
} & Pick<CreateAppeals, "questionId" | "justification">;

export type CorrectionDataToRequest = {
  answerId: string;
  grade: number | string;
} & Omit<Correction, "answer" | "grade">;

export type CorrectionForm = {
  corrections: CorrectionDataToRequest[];
};

export type AnswerType = {
  user: User;
  _id: string;
};
export type RankingType = {
  user: User;
  grade: number;
  isCorrected: boolean;
  corrections: [
    {
      _id: string;
      grade: number;
      corrector: User;
    }
  ];
  _id: string;
};

export interface AppealOrganizer extends Appeal {
  answer: string;
  user: User;
  event: string;
  _id: string;
}

export type AnswerProps = {
  multipleChoiceAnswer?: string;
  openAnswer?: string;
  questionId: string;
};

export type FinishedAppeal = {
  eventId: string;
  submissionId: string;
  feedback: string;
  grade: number;
};
export type RegectAppeal = {
  eventId: string;
  feedback?: string;
};

export type MyEventsToCalendar = {
  date: string;
  count: number;
};
