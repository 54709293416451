import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { Editor } from "components/Editor";
import { Modal } from "components/Modal";
import { useToast } from "contexts/layout/toast/useToast";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MdInfo } from "react-icons/md";
import { OpenQuestionSettingsModalProps } from "./props";

export function OpenQuestionSettingsModal({
  isOpen,
  index,
  onClose,
  question,
}: OpenQuestionSettingsModalProps) {
  const { toast } = useToast();

  const {
    control,
    formState: { errors },
    setError,
    watch,
    clearErrors,
  } = useFormContext();

  const criteria = watch(`questions.${index}.criteria`);
  const answerPattern = watch(`questions.${index}.answerPattern`);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (showErrors) {
      if (question.criteria === "<p></p>\n") {
        setError(`questions.${index}.criteria`, {
          message: "O barema/critério para a questão é obrigatório.",
        });
      } else {
        clearErrors(`questions.${index}.criteria`);
      }

      if (question.answerPattern === "<p></p>\n") {
        setError(`questions.${index}.answerPattern`, {
          message: "O padrão de resposta é obrigatório.",
        });
      } else {
        clearErrors(`questions.${index}.answerPattern`);
      }
    }
  }, [criteria, answerPattern, showErrors]);

  const handleValidate = async () => {
    setShowErrors(true);

    if (
      question.criteria !== "<p></p>\n" &&
      question.answerPattern !== "<p></p>\n"
    ) {
      toast(
        "Padrão de resposta e critérios adicionados com sucesso.",
        "success"
      );
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalHeader>
        <Flex direction="column" gap={2}>
          <Text fontSize="sm" fontWeight={400}>
            Questão {index + 1}
          </Text>
          Padrão de Resposta
        </Flex>
      </ModalHeader>

      <ModalCloseButton />

      <ModalBody textAlign="center" fontSize="sm">
        <Flex direction="column" gap={4}>
          <FormControl
            isInvalid={
              !!(errors.questions && errors.questions[index]?.answerPattern)
            }
          >
            <FormLabel
              color="gray.400"
              display="flex"
              alignItems="center"
              gap={1}
            >
              Padrão de resposta
              <Icon as={MdInfo} w="12px" h="12px" color="gray.200" />
            </FormLabel>
            <Controller
              control={control}
              name={`questions.${index}.answerPattern`}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Editor
                  defaultValue={question?.answerPattern}
                  error={error}
                  onChange={onChange}
                  placeholder="Padrão de resposta"
                />
              )}
            />
            <FormErrorMessage>
              {errors.questions &&
                errors.questions[index]?.answerPattern &&
                errors.questions[index]?.answerPattern?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!(errors.questions && errors.questions[index]?.criteria)
            }
          >
            <FormLabel
              color="gray.400"
              display="flex"
              alignItems="center"
              gap={1}
            >
              Barema e critérios para questão
              <Icon as={MdInfo} w="12px" h="12px" color="gray.200" />
            </FormLabel>

            <Controller
              control={control}
              name={`questions.${index}.criteria`}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Editor
                  defaultValue={question?.criteria}
                  error={error}
                  onChange={onChange}
                  placeholder="Barema e critérios para questão"
                />
              )}
            />
            <FormErrorMessage>
              {errors.questions &&
                errors.questions[index]?.criteria &&
                errors.questions[index]?.criteria?.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleValidate}>Definir padrão de resposta</Button>
      </ModalFooter>
    </Modal>
  );
}
