import { Flex, Icon, Image, Text } from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { FiUser } from "react-icons/fi";
import { MdNotifications } from "react-icons/md";
import { LuPanelBottomClose } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { NavItem } from "./NavItem";

export default function Nav() {
  const { user } = useAuth();
  const { pathname } = useLocation();

  return (
    <Flex position="relative" direction="column" gap={6} flex={1}>
      <NavItem path="/configuracoes/perfil">
        <Flex direction="column" alignItems="center">
          {user?.image ? (
            <Image
              src={user?.image}
              objectPosition="top"
              objectFit="cover"
              borderRadius="full"
              boxSize={34}
            />
          ) : (
            <Flex
              bg="gray.200"
              align="center"
              justify="center"
              borderRadius="50%"
              w={34}
              h={34}
            >
              <Icon as={FiUser} w={4} h={4} color="gray.500" />
            </Flex>
          )}
          <Text fontWeight={700}>Meu perfil</Text>
        </Flex>
      </NavItem>

      {/* <NavItem path="/configuracoes/planos">
        <Flex direction="column" alignItems="center" gap={1}>
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bg={
              pathname.includes("/configuracoes/planos")
                ? "#46C7BB"
                : "primary.100"
            }
            w={34}
            h={34}
          >
            <Icon
              as={LuPanelBottomClose}
              color={
                pathname.includes("/configuracoes/planos")
                  ? "white"
                  : "primary.500"
              }
            />
          </Flex>
          <Text fontWeight={700}>Planos</Text>
        </Flex>
      </NavItem> */}

      <NavItem path="/configuracoes/preferencias">
        <Flex direction="column" alignItems="center" gap={1}>
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bg={
              pathname.includes("/configuracoes/preferencias")
                ? "#46C7BB"
                : "primary.100"
            }
            w={34}
            h={34}
          >
            <Icon
              as={MdNotifications}
              color={
                pathname.includes("/configuracoes/preferencias")
                  ? "white"
                  : "primary.500"
              }
            />
          </Flex>
          <Text fontWeight={700}>Preferências</Text>
        </Flex>
      </NavItem>
    </Flex>
  );
}
