import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Switch: ComponentStyleConfig = {
  baseStyle: {
    track: {
      bg: "gray.200",
      borderRadius: 20,
      w: 41,
      h: 18,
      p: 0,
      display: "flex",
      alignItems: "center",
      transition: "all .2s",
      _focus: {
        boxShadow: "none",
      },
      _hover: {
        boxShadow: "none",
      },
      _checked: {
        boxShadow: "none",
        bg: "#C4E3E0",
      }
    },
    thumb: {
      bg: "#FFFFFF",
      boxShadow: "0px 1.5px 3px 0px #00000040",
      w: "24px",
      h: "24px",
      transition: "all .2s",
      _checked: {
        transform: "translateX(18px)",
        bg: "primary.500"
      }
    }
  }
};

export default Switch;