import { Flex, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { NavItemProps } from "./props";

export function NavItem({ children, path }: NavItemProps) {
  const { pathname } = useLocation();

  const isSelected = pathname.includes(path);

  const arrowSelected = isSelected ? {
    content: "''",
    width: 0,
    height: 0,
    position: "absolute",
    right: "-12px",
    borderTop: "40px solid transparent",
    borderBottom: "40px solid transparent",
    borderLeft: "15px solid",
    borderLeftColor: "primary.500",
  } : {};

  return (
    <Link to={path} style={{ position: "relative" }}>
      <Flex
        alignItems="center"
        justifyContent="center"
        bg={isSelected ? "primary.500" : "background"}
        color={isSelected ? "background" : "primary.500"}
        borderRadius={20}
        p={6}
        h={110}
        w="100%"
        sx={{
          _after: arrowSelected
        }}
      >
        {children}
      </Flex>
    </Link>
  );
}
