import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "contexts/auth/useAuth";
import { IconProvider } from "contexts/layout/IconProvider";
import { ToastProvider } from "contexts/layout/toast/useToast";
import ReactDOM from "react-dom";
import { Chatwoot, LiveChatLoaderProvider } from "react-live-chat-loader";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "react-widgets/styles.css";
import { Routes } from "routes";
import { theme } from "styles/theme";

const queryClient = new QueryClient();

ReactDOM.render(
  <ChakraProvider resetCSS theme={theme}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <IconProvider>
          <ToastProvider>
            <AuthProvider>
              <Routes />

              {/* <LiveChatLoaderProvider providerKey={`${process.env.REACT_APP_CHATWOOT_TOKEN}`} provider="chatwoot">
                <Chatwoot />
              </LiveChatLoaderProvider> */}
              
            </AuthProvider>
          </ToastProvider>
        </IconProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
