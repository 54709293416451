import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { useToast } from "contexts/layout/toast/useToast";
import { useEffect } from "react";
import { MdContentCopy } from "react-icons/md";
import { ShareLinkType } from "./props";

export function ShareLink({ title, link }: ShareLinkType) {
  const { hasCopied, onCopy } = useClipboard(link);
  const { toast } = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast("Copiado para área de transferência", "success");
    }
  }, [hasCopied]);

  return (
    <Flex direction="column" gap={2} w="100%">
      <Text fontWeight={700} fontSize="lg" color="gray.400">
        {title}
      </Text>
      <InputGroup>
        <Input
          value={link}
          readOnly
          pr={12}
          sx={{
            "&:not(:placeholder-shown)": {
              borderColor: "gray.200",
              color: "gray.400",
            },
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />
        <Tooltip
          label={hasCopied ? "Copiado" : "Copiar para área de transferência"}
          closeDelay={1000}
        >
          <InputRightElement
            children={<MdContentCopy />}
            color="primary.500"
            cursor="pointer"
            onClick={onCopy}
          />
        </Tooltip>
      </InputGroup>
    </Flex>
  );
}
