import * as yup from "yup";

export const updatePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("A senha é obrigatória.")
    .min(8, "Sua senha deve ter pelo menos 8 caracteres."),
  password: yup
    .string()
    .required("A nova senha é obrigatória.")
    .min(8, "Sua senha deve ter pelo menos 8 caracteres.")
    .notOneOf(
      [yup.ref("currentPassword")],
      "A nova senha deve ser diferente da senha atual."
    ),
  passwordConfirmation: yup
    .string()
    .required("A confirmação da nova senha é obrigatória.")
    .oneOf([yup.ref("password")], "As senhas não estão iguais."),
});
