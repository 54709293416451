import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Skeleton,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { Paper } from "components/Paper";
import { ShareLink } from "components/ShareLink";
import { useAuth } from "contexts/auth/useAuth";
import { useEvent as useEventContext } from "contexts/event/useEvent";
import { isFuture } from "date-fns";
import {
  useEvent,
  useEventLookup,
  useEventQuestionsRestricted,
  useEventSubscribe,
  usePendingCorrectionsCount,
} from "hooks/event";
import { EventStatusLookupEnum, EventType } from "interfaces/event";
import { User } from "interfaces/User";
import { MdInfo } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { isObjectEmpty } from "utils/isObjectEmpty";
import { isWithinThePeriod } from "utils/isWithinThePeriod";
import { Banner } from "./Banner";
import { OwnerInfo } from "./OwnerInfo";
import { CoverButtonProps } from "./props";
import { ResponseRecommendationsModal } from "./ResponseRecommendationsModal";
import { Stages } from "./Stages";
import { UnsubscribeModal } from "./UnsubscribeModal";

export function EventInfo() {
  const { id } = useParams();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const {
    isOpen: isUnsubscribeModalOpen,
    onOpen: onUnsubscribeModalOpen,
    onClose: onUnsubscribeModalClose,
  } = useDisclosure();

  const {
    isOpen: isResponseRecomendationsModalOpen,
    onOpen: onResponseRecomendationsModalOpen,
    onClose: onResponseRecomendationsModalClose,
  } = useDisclosure();

  const { user } = useAuth();

  const { event: eventFromContext } = useEventContext();

  const { data: eventFromRequest, isLoading: isEventLoading } = useEvent(id);
  const { data: questionsFromRequest } = useEventQuestionsRestricted(
    id as string,
    {
      enabled: !!user,
    }
  );
  const { data: lookup } = useEventLookup(id, {
    enabled: !!user,
  });

  const event =
    id && !eventFromContext
      ? {
          ...eventFromRequest,
          categories: eventFromRequest?.categories?.map(({ name }) => name),
          questions: questionsFromRequest?.map((question) => ({
            ...question,
            remove: false,
          })),
        }
      : eventFromContext;

  const { data: count, isLoading: isPendingCorrectionsCountLoading } =
    usePendingCorrectionsCount(
      {
        ...(event as EventType),
        id,
      },
      {
        enabled: !!user,
      }
    );

  const { mutate: subscribe, isLoading: isSubscribeLoading } =
    useEventSubscribe(id as string);

  const loggedUserIsTheOwner = event?.owner?.id === user?.id;
  const loggedUserIsAModerator = (event?.moderators as User[])?.some(
    (moderator) => moderator.id === user?.id
  );
  const showSeeAnswersButton =
    isLargerThan1080 &&
    (lookup === EventStatusLookupEnum.ANSWERED ||
      lookup === EventStatusLookupEnum.CORRECTED_ANSWERS) &&
    (event as EventType)?.appealPeriodStart &&
    isFuture(new Date((event as EventType)?.appealPeriodStart));

  function getMobileButtonProps(): CoverButtonProps {
    if (!user) {
      return {
        text: "Fazer login",
        isLink: true,
        props: {
          to: "/login",
        },
      };
    }

    if (!event?.isFinished) {
      if (
        lookup === EventStatusLookupEnum.JOINED &&
        isWithinThePeriod(event?.responsePeriodStart, event?.responsePeriodEnd)
      ) {
        return {
          text: "Quero responder",
          props: {
            onClick: onResponseRecomendationsModalOpen,
          },
        };
      }

      if (
        lookup === EventStatusLookupEnum.NOT_JOINED &&
        event?.responsePeriodEnd &&
        isFuture(new Date(event.responsePeriodEnd)) &&
        !loggedUserIsTheOwner &&
        !loggedUserIsAModerator
      ) {
        return {
          text: "Participar",
          props: {
            onClick: () => subscribe(id as string),
            isLoading: isSubscribeLoading,
          },
        };
      }

      if (
        lookup === EventStatusLookupEnum.ANSWERED ||
        lookup === EventStatusLookupEnum.CORRECTED_ANSWERS
      ) {
        return {
          text: "Ver minhas respostas ",
          isLink: true,
          props: {
            to: "quiz",
            isLoading: isSubscribeLoading,
          },
        };
      }
    }

    return {};
  }

  return (
    <>
      <Paper overflow="auto">
        <Banner />

        <Flex
          direction={isLargerThan1080 ? "row" : "column"}
          flex={1}
          px={isLargerThan1080 ? 12 : 8}
          py={isLargerThan1080 ? 8 : 6}
          gap={isLargerThan1080 ? 12 : 6}
        >
          <Tabs w="100%" flex={isLargerThan1080 ? 3 : "initial"}>
            <TabList>
              <Tab>Visão Geral</Tab>
            </TabList>

            <TabPanels>
              <TabPanel
                display="flex"
                flexDirection="column"
                gap={isLargerThan1080 ? 8 : 4}
                pt={isLargerThan1080 ? 8 : 4}
              >
                <Flex
                  direction="column"
                  gap={2}
                  display={
                    isEventLoading || event?.description ? "flex" : "none"
                  }
                >
                  <Skeleton isLoaded={!isEventLoading}>
                    {event?.description && (
                      <Heading fontSize="lg" color="gray.400">
                        Descrição do evento
                      </Heading>
                    )}
                  </Skeleton>
                  <SkeletonText isLoaded={!isEventLoading} noOfLines={4}>
                    {event?.description && (
                      <Text color="gray.300">{event?.description}</Text>
                    )}
                  </SkeletonText>
                </Flex>

                <Flex direction="column" gap={4}>
                  <Skeleton
                    display="flex"
                    alignItems="center"
                    gap={2}
                    isLoaded={!isEventLoading}
                  >
                    <Heading fontSize="lg" color="gray.400">
                      Evento com correção em pares{" "}
                      {event?.allowPeerCorrection ? "ativada" : "desativada"}.
                    </Heading>

                    {isLargerThan1080 && (
                      <Tooltip
                        hasArrow
                        label="A correção em pares consiste em uma atividade na qual cada participante pode corrigir o evento de outro participante."
                        placement="top"
                      >
                        <Flex>
                          <Icon as={MdInfo} color="gray.200" w={3} h={3} />
                        </Flex>
                      </Tooltip>
                    )}
                  </Skeleton>
                </Flex>

                <Skeleton isLoaded={!isEventLoading}>
                  <OwnerInfo loggedUserIsTheOwner={loggedUserIsTheOwner} />
                </Skeleton>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Flex direction="column" gap={7} pt={isLargerThan1080 ? 8 : 0}>
            <Flex direction="column" gap={3}>
              {showSeeAnswersButton && (
                <Button variant="link" as={Link} to="quiz" mb={8}>
                  Ver minha resposta
                </Button>
              )}
              <Stages />

              {!isLargerThan1080 && <Divider my={4} />}

              {(!isLargerThan1080 || loggedUserIsTheOwner) &&
                !event.isDraft &&
                !event?.isFinished && (
                  <ShareLink
                    title="Compartilhe o evento"
                    link={`${process.env.REACT_APP_BASE_URL}/meus-eventos/${event?.id}`}
                  />
                )}

              {!isLargerThan1080 && !isObjectEmpty(getMobileButtonProps()) && (
                <>
                  <Button
                    mt={8}
                    size="lg"
                    isFullWidth
                    as={getMobileButtonProps().isLink ? Link : undefined}
                    to={getMobileButtonProps().props?.to ?? "#"}
                    {...getMobileButtonProps().props}
                  >
                    {getMobileButtonProps()?.text}
                  </Button>

                  {isWithinThePeriod(
                    event?.correctionPeriodStart,
                    event?.correctionPeriodEnd
                  ) &&
                    count !== 0 &&
                    !isPendingCorrectionsCountLoading && (
                      <Skeleton isLoaded={!isEventLoading}>
                        <Button
                          isFullWidth
                          size="lg"
                          variant="outline"
                          as={Link}
                          to="corrigir"
                        >
                          Corrigir de outrem
                        </Button>
                      </Skeleton>
                    )}
                </>
              )}
            </Flex>

            {!isEventLoading && lookup === EventStatusLookupEnum.JOINED && (
              <Button
                isFullWidth
                colorScheme="secondary"
                variant="outlineSecondary"
                size="lg"
                boxShadow="none"
                onClick={onUnsubscribeModalOpen}
              >
                Cancelar participação
              </Button>
            )}
          </Flex>
        </Flex>
      </Paper>

      <UnsubscribeModal
        isOpen={isUnsubscribeModalOpen}
        onClose={onUnsubscribeModalClose}
      />

      <ResponseRecommendationsModal
        isOpen={isResponseRecomendationsModalOpen}
        onClose={onResponseRecomendationsModalClose}
        responsePeriodEnd={eventFromRequest?.responsePeriodEnd}
      />
    </>
  );
}
