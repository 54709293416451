import { useToast } from "contexts/layout/toast/useToast";
import { ResetPasswordProps } from "interfaces/User";
import { useMutation } from "react-query";
import { resetPassword, sendRecoveryPasswordEmail } from "services/auth";

export function useSendRecoveryPasswordEmail() {
  const { toast } = useToast();

  return useMutation((email: string) => sendRecoveryPasswordEmail(email), {
    onSuccess: () => {
      toast("E-mail de verificação enviado com sucesso.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao enviar o e-mail de recuperação.", "error");
    },
  });
}

export function useResetPassword() {
  const { toast } = useToast();

  return useMutation(
    ({ token, password, passwordConfirmation }: ResetPasswordProps) =>
      resetPassword({ token, password, passwordConfirmation }),
    {
      onSuccess: () => {
        toast("Senha redefinida com sucesso.", "success");
      },
      onError: () => {
        toast("Ocorreu um erro ao redefinir a senha.", "error");
      },
    }
  );
}
