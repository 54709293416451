import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Avatar: ComponentStyleConfig = {
  baseStyle: {
    container: {
      bg: "gray.200",
      color: "gray.400",
    },
  },
};
