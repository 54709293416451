import { Avatar, Flex, Text } from "@chakra-ui/react";
import { useEvent } from "contexts/event/useEvent";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { StepProps } from "./props";

export function Step({ title, to, index, tag }: StepProps) {
  const resolved = useResolvedPath(to); // verify url pathname 
  const match = useMatch({ path: resolved.pathname, end: true });

  const { steps } = useEvent();

  const isStepAvailable = Boolean(match || steps.some((step) => step === tag));

  const getStepCurrentStyle = () => {
    if (match) {
      return ({
        container: {
          bg: "primary.500",
          color: "background",
        },
        content: {
          bg: "background",
          color: "primary.500"
        }
      });
    }

    if (steps.some((step) => step === tag)) {
      return ({
        container: {
          bg: "#DDEDEC",
          color: "primary.500",
        },
        content: {
          bg: "rgba(24, 185, 170, 0.25)",
          color: "primary.500"
        }
      });
    }

    return ({
      container: {
        bg: "gray.100",
        color: "gray.300",
      },
      content: {
        bg: "gray.300",
        color: "background"
      }
    });
  };

  return (
    <Flex
      as={NavLink}
      to={to}
      direction="column"
      align="center"
      gap={2}
      w={165}
      borderRadius={20}
      p={3}
      {...getStepCurrentStyle().container}
      style={!isStepAvailable ? { pointerEvents: "none" } : {}}
    >
      <Avatar
        name={index}
        fontWeight={700}
        boxSize="34px"
        {...getStepCurrentStyle().content}
      />

      <Text
        w={110}
        textAlign="center"
        fontWeight={700}
        lineHeight="22px"
      >
        {title}
      </Text>
    </Flex>
  );
}
