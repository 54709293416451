import { Input, useDisclosure } from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { format, formatISO, getSeconds, sub } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { formatDate } from "utils/format";
import { DatePickerType } from "./props";
import * as Styled from "./styles";

const DatePicker = ({
  name,
  enableTime = false,
  dropUp = false,
  isModal = false,
  placeholder,
  ...rest
}: DatePickerType) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const date: string = watch(name);

  const onDateChange = (
    date: Date | null | undefined,
    onChange: (...event: any[]) => void
  ) => {
    onChange(
      date ? formatISO(sub(date, { seconds: getSeconds(date) })) : undefined
    );
  };

  const className = useMemo(() => {
    if (errors[name]) return "picker-error";

    if (date) return "picker-filled";

    return "";
  }, [date, errors[name]]);

  if (isModal) {
    return (
      <>
        <Input
          onClick={onOpen}
          value={
            date
              ? format(new Date(date), "dd/MM/yyyy", { locale: ptBR })
              : placeholder
          }
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, ...field } }) => (
              <Styled.DatePicker
                {...rest}
                {...field}
                messages={{ moveToday: "Hoje", dateButton: "Selecionar data" }}
                value={value ? new Date(value) : null}
                onChange={(date) => onDateChange(date, onChange)}
                className={className}
                placeholder={placeholder}
                dropUp={dropUp}
              />
            )}
          />
        </Modal>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...field } }) => (
        <Styled.DatePicker
          {...rest}
          {...field}
          includeTime={enableTime}
          messages={{ moveToday: "Hoje", dateButton: "Selecionar data" }}
          value={value ? new Date(value) : null}
          onChange={(date) => onDateChange(date, onChange)}
          className={className}
          onClick={onOpen}
          placeholder={placeholder}
          dropUp={dropUp}
        />
      )}
    />
  );
};

export { DatePicker };
