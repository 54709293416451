import axios from "axios";
import getToken from "./getToken";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default api;

export async function registerUser(data: any) {
  const res = await api.post("/users", data);
  return res.status;
}

export async function searchUsers(query?: string) {
  const res = await api.get("/users", {
    params: { query },
  });
  return res.data;
}

export async function verifyEmail(email: string) {
  const res = await api.get(`/auth/email/${email}`);
  return res.data.isTaken;
}

export async function changePassword(values: any) {
  const res = await api.put("/password", values);
  const { data, status } = res;
  return { data, status };
}

export async function updatePreferences(values: any) {
  const res = await api.put("/me/preferences", values);
  const { data, status } = res;
  return { data, status };
}

export async function sendDeleteCode() {
  const res = await api.post("/me/delete-account");
  const { data, status } = res;
  return { data, status };
}

export async function deleteAccount(token: string) {
  const res = await api.delete("/me", {
    data: { token },
  });
  const { data, status } = res;
  return { data, status };
}

export async function getGroups(name?: string) {
  const res = await api.get("/groups", {
    params: { name },
  });
  const { status, data } = res;
  return { status, data };
}

export async function createGroup(data: any) {
  const res = await api.post("/groups", data);
  return res.status;
}

export async function getDetailsEvent() {
  const res = await api.get("/events/6272b4b39af13ec908aa7344");
  return res.data;
}

export async function getEvents(
  type: "organizer" | "participant",
  time: "future" | "in_progress" | "finished"
) {
  const res = await api.get("/events", {
    params: { type, time },
  });
  const { data } = res;
  return { data: data.data, meta: data.meta };
}
