import { Flex, List, ListItem, Text } from "@chakra-ui/react";
import { Paper } from "components/Paper";
import { QuestionType } from "interfaces/EventOLD";
import { getChoiceLetter } from "utils/getChoiceLetter";
import { SeeAnswerProps } from "./props";

export function SeeAnswer({
  numberQuestion,
  question,
  multipleChoiceAnswer,
  openAnswer,
}: SeeAnswerProps) {
  return (
    <Paper gap={8}>
      <Flex direction="column">
        <Text fontSize="0.875rem" color="gray.500">
          Questão {numberQuestion + 1}
        </Text>
        <Text
          dangerouslySetInnerHTML={{ __html: question?.title }}
          color="gray.500"
        />
      </Flex>
      <Flex direction="column">
        {question?.type && question.type === QuestionType.OPEN ? (
          <>
            <Text fontSize="0.875rem" color="gray.500">
              Padrão de resposta
            </Text>
            <Text
              dangerouslySetInnerHTML={{ __html: question?.criteria }}
              color="gray.500"
            />
          </>
        ) : (
          <>
            <Text fontSize="0.875rem" color="gray.500">
              Alternativas da questão
            </Text>
            <List color="gray.500">
              {question?.choices &&
                question.choices.map((choice) => (
                  <ListItem>
                    {getChoiceLetter(question?.choices.indexOf(choice))}
                    {") "}
                    {choice?.text}
                  </ListItem>
                ))}
            </List>
          </>
        )}
      </Flex>
      <Flex direction="column">
        <Text fontSize="0.875rem" color="gray.500">
          Resposta do aluno
        </Text>

        {question?.type && question?.type === QuestionType.OPEN ? (
          <Text color="gray.500">{openAnswer}</Text>
        ) : (
          <Text color="gray.500">
            {getChoiceLetter(
              question?.choices.indexOf(
                question?.choices.find((el) => el.id === multipleChoiceAnswer)!
              )
            )}

            {") "}
            {question?.choices &&
              question.choices.find((el) => el.id === multipleChoiceAnswer)
                ?.text}
          </Text>
        )}
      </Flex>
    </Paper>
  );
}
