import { useToast } from "contexts/layout/toast/useToast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteExploreSlideRequest,
  getExploreSlidesRequest,
  uploadExploreSlideRequest,
} from "services/explore";

export function useExploreSlides() {
  return useQuery("explore", () => getExploreSlidesRequest(), {
    staleTime: 1000 * 60, // 1 minuto
  });
}

export function useExploreUploadSlides() {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  return useMutation((file: File) => uploadExploreSlideRequest(file), {
    onSuccess: () => {
      queryClient.invalidateQueries("explore");
      toast("Slide adicionado com sucesso.", "success");
    },
  });
}

export function useExploreDeleteSlides() {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  return useMutation((slideId: string) => deleteExploreSlideRequest(slideId), {
    onSuccess: () => {
      queryClient.invalidateQueries("explore");
      toast("Slide deletado com sucesso.", "success");
    },
  });
}
