import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Spinner,
  Switch,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { useToast } from "contexts/layout/toast/useToast";
import {
  useExploreDeleteSlides,
  useExploreSlides,
  useExploreUploadSlides
} from "hooks/explore";
import { useUpdatePreference } from "hooks/user";
import { PreferenceForm } from "interfaces/User";
import { ChangeEvent, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { BiShieldQuarter } from "react-icons/bi";
import { MdClose, MdImage } from "react-icons/md";
import { DeleteAccountModal } from "./DeleteAccountModal";
import { UpdatePasswordModal } from "./UpdatePasswordModal";

export function Preferences() {
  const { user } = useAuth();
  const { toast } = useToast();

  const {
    isOpen: deleteModal,
    onClose: closeDelete,
    onOpen: openDelete,
  } = useDisclosure();

  const {
    isOpen: isUpdatePasswordModalOpen,
    onClose: onUpdatePasswordModalClose,
    onOpen: onUpdatePasswordModalOpen,
  } = useDisclosure();

  const { data: slides, isLoading } = useExploreSlides();
  const { mutate: uploadExploreSlideRequest } = useExploreUploadSlides();
  const { mutate: deleteExploreRequest } = useExploreDeleteSlides();
  const {
    mutate: updatePreference,
    isSuccess: isUpdatePreferenceSucess,
    isLoading: isLoadingUpadatePreferece,
  } = useUpdatePreference();

  const defaultValues: Partial<PreferenceForm> = {
    allowDataCollect: user?.allowDataCollect,
    alsoEmail: user?.notificationOpts.alsoEmail,
    eachEventStep: user?.notificationOpts.eachEventStep,
    newEvents: user?.notificationOpts.newEvents,
  };

  const {
    watch,
    register,
    formState: { dirtyFields },
  } = useForm<PreferenceForm>({
    defaultValues,
  });

  useMemo(() => {
    updatePreference({
      allowDataCollect: watch("allowDataCollect"),
      notificationOpts: {
        alsoEmail: watch("alsoEmail"),
        newEvents: watch("newEvents"),
        eachEventStep: watch("eachEventStep"),
      },
    });
    if (isUpdatePreferenceSucess) {
      toast("Preferencia atualizada com sucesso!", "success");
    }
  }, [
    dirtyFields.allowDataCollect,
    dirtyFields.newEvents,
    dirtyFields.eachEventStep,
    dirtyFields.alsoEmail,
  ]);

  const handleUploadExploreSlide = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;

      if (!fileList || !fileList.length) return;
      uploadExploreSlideRequest(fileList[0]);
    },
    []
  );

  return (
    <>
      <Flex direction="column">
        <Flex as="form" direction="column">
          <VStack alignItems="start" marginBottom={5}>
            <Heading fontSize="2xl">Preferências</Heading>
            <Text fontSize="sm" color="gray.500" marginTop="0 !important">Modifique suas preferências na plataforma</Text>
          </VStack>
          <Box display="flex" flexDirection="column" gap={1} mb={2}>
            <Text fontWeight="bold" color="gray.400" mb={1}>
              Notificações
            </Text>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="black">Notifique-me sobre novos eventos</Text>
              <Switch
                isDisabled={isLoadingUpadatePreferece}
                colorScheme="primary"
                isChecked={watch("newEvents")}
                {...register("newEvents")}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="black">Notifique-me em cada etapa do evento</Text>
              <Switch
                colorScheme="primary"
                isChecked={watch("eachEventStep")}
                isDisabled={isLoadingUpadatePreferece}
                {...register("eachEventStep")}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="black">Notifique-me também no email</Text>
              <Switch
                colorScheme="primary"
                isChecked={watch("alsoEmail")}
                isDisabled={isLoadingUpadatePreferece}
                {...register("alsoEmail")}
              />
            </Box>
            <Divider mt={2} />
          </Box>

          <Box mb={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Flex bgColor="gray.100" borderRadius="100%" mr={3} p={4}>
                <Icon
                  boxSize="1.3em"
                  as={BiShieldQuarter}
                  color="primary.500"
                />
              </Flex>
              <Text color="gray.400" fontSize="sm">
                Nós respeitamos muito sua privacidade, sempre tratando seus
                dados de acordo com os nossos
                <Text as="a" color="primary.500" fontSize="sm">
                  {" "}
                  Termos de Uso{" "}
                </Text>
                e
                <Text as="a" color="primary.500" fontSize="sm">
                  {" "}
                  Política de Privacidade{" "}
                </Text>
              </Text>
            </Box>
            <Divider mt={2} />
          </Box>

          <Box mb={2}>
            <Text fontWeight="bold" color="gray.400" mb={3}>
              Privacidade
            </Text>
            <Text fontWeight="thin" color="gray.300" fontSize="sm">
              LGPD
            </Text>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="black">
                Permitir coleta de dados através dos cookies do navegador
              </Text>
              <Switch
                colorScheme="primary"
                isChecked={watch("allowDataCollect")}
                isDisabled={isLoadingUpadatePreferece}
                {...register("allowDataCollect")}
              />
            </Box>

            <Divider mt={2} />
          </Box>
        </Flex>
        {user?.roles.includes("admin") && (
          <Flex direction="column" mb={2} gap={4}>
            <Text fontWeight="bold" color="gray.400">
              Slider Explorar
            </Text>

            <Flex gap={4} overflow="auto" pb={2}>
              {slides?.map((slide) => (
                <Flex
                  key={slide._id}
                  direction="column"
                  position="relative"
                  _hover={{
                    ".backdrop": {
                      display: "flex",
                    },
                  }}
                >
                  <Box
                    bg="rgba(29, 29,29,.5)"
                    position="absolute"
                    w="100%"
                    h="100%"
                    borderRadius={20}
                    className="backdrop"
                    display="none"
                  />

                  <IconButton
                    aria-label="Excluir imagem"
                    icon={<MdClose />}
                    variant="link"
                    position="absolute"
                    top={2}
                    right={0}
                    color="background"
                    zIndex={3}
                    onClick={() => deleteExploreRequest(slide._id)}
                  />

                  <Image src={slide.url} h={95} w={150} borderRadius={20} />
                </Flex>
              ))}

              {isLoading && <Spinner />}

              {slides && slides.length < 4 && (
                <Flex direction="column" align="center" gap={2} w={150}>
                  <Input
                    type="file"
                    id="test"
                    accept=".jpg,.jpeg,.png"
                    onChange={(event) => handleUploadExploreSlide(event)}
                    hidden
                  />

                  <Flex
                    as="label"
                    htmlFor="test"
                    bg="#18B9AA1A"
                    border="2px dashed"
                    borderColor="primary.500"
                    borderRadius={20}
                    w="100%"
                    h={95}
                    direction="column"
                    align="center"
                    justify="center"
                    cursor="pointer"
                  >
                    <Icon as={MdImage} w="30px" h="30px" color="primary.500" />
                    <Text fontWeight={700} color="gray.300" fontSize="xs">
                      Adicionar imagem
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>

            <Divider mt={2} />
          </Flex>
        )}

        <Box mb={2}>
          <Text fontWeight="bold" color="gray.400" mb={3}>
            Segurança da conta
          </Text>
          <Button onClick={onUpdatePasswordModalOpen}>Alterar senha</Button>
        </Box>

        <Box mt={4} mb={2}>
          <Button
            bgColor="white"
            color="error"
            _hover={{ backgroundColor: "gray.100" }}
            _active={{ backgroundColor: "gray.100" }}
            onClick={openDelete}
          >
            Excluir conta
          </Button>
        </Box>
      </Flex>

      <UpdatePasswordModal
        isOpen={isUpdatePasswordModalOpen}
        onClose={onUpdatePasswordModalClose}
      />

      <DeleteAccountModal isOpen={deleteModal} onClose={closeDelete} />
    </>
  );
}
