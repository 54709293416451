import {
  Flex,
  Heading,
  IconButton,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { Helmet } from "react-helmet";
import {
  MdArrowBack,
  MdKeyboardArrowLeft,
  MdMenu,
  MdNotifications,
  MdOutlineFilterAlt
} from "react-icons/md";
import { Link } from "react-router-dom";
import { MobileSidebarMenu } from "../MobileSidebarMenu";
import { Notifications } from "../Notifications";
import { HeaderRightSide } from "./HeaderRightSide";
import { HeaderProps } from "./props";

export function Header({
  title,
  hasFilter = false,
  onFilterOpen,
  goback,
}: HeaderProps) {
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { user } = useAuth();

  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const {
    isOpen: isNotificationsOpen,
    onOpen: onNotificationsOpen,
    onClose: onNotificationsClose,
  } = useDisclosure();

  return (
    <>
      <Helmet title={`${title} | Eduka Hub`} />

      <Flex
        justify={user ? "space-between" : "flex-start"}
        align="center"
        bg={isLargerThan1080 ? "transparent" : "background"}
        borderBottom={isLargerThan1080 ? "none" : "1px solid"}
        borderColor="gray.100"
        w={isLargerThan1080 ? "100%" : "90%"}
        py={isLargerThan1080 ? 4 : 6}
        mx="auto"
      >
        {!isLargerThan1080 &&
          user &&
          (goback ? (
            <IconButton
              aria-label="toggle sidebar menu"
              icon={<MdKeyboardArrowLeft />}
              variant="link"
              as={Link}
              to={goback}
            />
          ) : (
            <IconButton
              aria-label="toggle sidebar menu"
              icon={<MdMenu />}
              variant="link"
              onClick={onSidebarOpen}
            />
          ))}

        <Flex align="center">
          {goback && isLargerThan1080 && (
            <IconButton
              aria-label="go back"
              as={Link}
              to={goback}
              icon={<MdArrowBack />}
              variant="unstyled"
              display="flex"
              alignItems="center"
            />
          )}

          <Heading
            fontSize={["lg", "xl", "2xl"]}
            color={isLargerThan1080 ? "black" : "primary.500"}
          >
            {title}
          </Heading>
        </Flex>

        {user &&
          (isLargerThan1080 ? (
            <HeaderRightSide onNotificationsOpen={onNotificationsOpen} />
          ) : hasFilter ? (
            <IconButton
              aria-label="toggle filter options"
              icon={<MdOutlineFilterAlt />}
              variant="link"
              onClick={onFilterOpen}
            />
          ) : (
            <IconButton
              aria-label="toggle notifications sidebar"
              icon={<MdNotifications />}
              variant="link"
              onClick={onNotificationsOpen}
            />
          ))}

        {hasFilter && !isLargerThan1080 ? null : (
          <Notifications
            isOpen={isNotificationsOpen}
            onClose={onNotificationsClose}
          />
        )}

        <MobileSidebarMenu isOpen={isSidebarOpen} onClose={onSidebarClose} />
      </Flex>
    </>
  );
}
