import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useToast } from "contexts/layout/toast/useToast";
import { useVerifyUserEmail } from "hooks/user";
import { CreateGroup } from "interfaces/group";
import { User } from "interfaces/User";
import { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { FiMail } from "react-icons/fi";

export function InviteByEmailModal({ isOpen, onClose }: ModalProps) {
  const { toast } = useToast();

  const { watch, setValue } = useFormContext<CreateGroup>();
  const participants = watch("participants");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<Pick<User, "email">>({
    defaultValues: {
      email: "",
    },
  });

  const {
    mutate: verifyUserEmail,
    data,
    isLoading,
    isSuccess,
  } = useVerifyUserEmail();

  useEffect(() => {
    if (isSuccess) {
      if (!data?.isTaken) {
        toast(
          "Usuário não encontrado. Verifique o e-mail e tente novamente.",
          "error"
        );
      }
    }
  }, [data, isSuccess]);

  const handleClose = () => {
    reset();
    onClose();
  };

  function handelSendGroupInvite({ email }: Pick<User, "email">) {
    verifyUserEmail(email, {
      onSuccess: ({ user }) => {
        if (user) {
          setValue("participants", [...new Set([...participants, user])]);
          toast("Participante adicionado com sucesso.", "success");
          handleClose();
        }
      },
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="sm">
      <ModalHeader maxW={300}>
        Coloque o e-mail que deseja adicionar!
      </ModalHeader>

      <ModalCloseButton />

      <Flex
        as="form"
        onSubmit={handleSubmit(handelSendGroupInvite)}
        direction="column"
      >
        <ModalBody>
          <FormControl variant="floating" id="email" isInvalid={!!errors.email}>
            <InputGroup>
              <InputLeftElement
                children={<FiMail />}
                color={errors.email ? "error" : "primary.500"}
              />
              <Input placeholder=" " {...register("email")} />
              <FormLabel color={errors.email ? "error" : "primary.500"}>
                E-mail
              </FormLabel>
            </InputGroup>

            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            isFullWidth
            isDisabled={!isDirty}
            isLoading={isLoading}
          >
            Indicar
          </Button>
        </ModalFooter>
      </Flex>
    </Modal>
  );
}
