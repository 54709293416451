import { AxiosError } from "axios";
import { useToast } from "contexts/layout/toast/useToast";
import { QueryOptions } from "interfaces/common";
import {
  CorrectionDataToRequest,
  CreateAppeals,
  CreateEventType,
  EventListFilters,
  EventType,
  FinishedAppeal,
  RegectAppeal,
  ReportQuestion,
  SubmitQuizData,
} from "interfaces/event";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  correctPeerCorrectionRequest,
  correctWithModeratorsOrOwnerRequest,
  createAppealsAnswerRequest,
  createEventRequest,
  createSubmissionsAnswerRequest,
  editEventRequest,
  eventSubscribeRequest,
  eventUnsubscribeRequest,
  finishedAppealsRequest,
  finishEvent,
  getAnswerEventRequest,
  getAppealsEventRequest,
  getDashBoardEventRequest,
  getDetailsAnswerRequest,
  getDetailsAppealsRequest,
  getDetailsSubmissionRequest,
  getEventLookupRequest,
  getEventQuestionsRequest,
  getEventQuestionsRestrictedRequest,
  getEventRequest,
  getEventsRequest,
  getMyEvents,
  getMyEventsToCalendar,
  getParticipantsEventRequest,
  getPendingCorrectionsCountRequest,
  getRankingEventRequest,
  getSubmissionEventRequest,
  getSubmissionRequest,
  getSubmissionsToModeratorOrOwnerRequest,
  getSubmissionsToPeerCorrectionRequest,
  nullifyQuestion,
  rejectAppealsRequest,
  reportQuestion,
  UpdateSubmissionsAnswerRequest,
  uploadEventImageRequest,
} from "services/event";

export function useEvents(eventListRequestFilters: EventListFilters) {
  const { toast } = useToast();

  return useQuery(
    ["events", eventListRequestFilters],
    () => getEventsRequest(eventListRequestFilters),
    {
      staleTime: 1000 * 60, // 1 minute
      onError: () => {
        toast("Ocorreu um erro ao carregar os eventos.", "error");
      },
    }
  );
}
export function useMyEvents(eventListRequestFilters: EventListFilters) {
  const { toast } = useToast();

  return useQuery(
    ["my-events", eventListRequestFilters],
    () => getMyEvents(eventListRequestFilters),
    {
      staleTime: 1000 * 60, // 1 minute
      onError: () => {
        toast("Ocorreu um erro ao carregar os eventos.", "error");
      },
    }
  );
}

export function useEvent(id?: string) {
  const { toast } = useToast();

  return useQuery(["event", id], () => getEventRequest(id as string), {
    enabled: Boolean(id),
    staleTime: 1000 * 60, // 1 minute
    retry: false,
    onError: () => {
      toast("Ocorreu um erro ao carregar o evento.", "error");
    },
  });
}

export function useMyEventsToCalendar(startDate: string, endDate: string) {
  const { toast } = useToast();

  return useQuery(
    ["events-to-calendar", { startDate, endDate }],
    () => getMyEventsToCalendar(startDate, endDate),
    {
      staleTime: 1000 * 60, // 1 minute
      onError: () => {
        toast("Ocorreu um erro ao carregar os eventos.", "error");
      },
    }
  );
}

export function useDashBoardEvent(id?: string) {
  return useQuery(
    ["events-dashboard", id],
    () => getDashBoardEventRequest(id as string),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 60, // 1 minute
    }
  );
}

export function useAppealsEvent(id?: string, page?: number) {
  return useQuery(
    ["appeals", id],
    () => getAppealsEventRequest(id as string, page),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 60, // 1 minute
    }
  );
}

export function useQuestionsAnswerEvent(id?: string) {
  return useQuery(
    ["questions-answer", id],
    () => getAnswerEventRequest(id as string),
    {
      enabled: Boolean(id),
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function useSubmissionRequest(submissionId?: string) {
  return useQuery(
    ["submission", submissionId],
    () => getDetailsSubmissionRequest(submissionId as string),
    {
      enabled: Boolean(submissionId),
      staleTime: 1000 * 30, // 30 seconds
    }
  );
}

export function useDetailsAnswerRequest(answerId?: string) {
  return useQuery(
    ["answer-corrected", answerId],
    () => getDetailsAnswerRequest(answerId as string),
    {
      enabled: Boolean(answerId),
      staleTime: 1000 * 30, // 30 seconds
    }
  );
}

export function useCreateAppeals(submissionId: string, answerId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (appeals: CreateAppeals) => createAppealsAnswerRequest(appeals),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["submission", submissionId]);
        queryClient.invalidateQueries(["answer-corrected", answerId]);

        toast("Chamado enviado com sucesso", "success");
      },
      onError: (error) => {
        const err = error as AxiosError;
        toast(`${err.response?.data.message}`, "error");
      },
    }
  );
}

export function useRejectAppeals(appealId: string, eventId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (appeals: RegectAppeal) => rejectAppealsRequest(appealId, appeals),
    {
      onSuccess: ({ submissionId }) => {
        queryClient.invalidateQueries("appeals-details-event");
        queryClient.invalidateQueries(["appeals", eventId]);
        queryClient.invalidateQueries(["submission", submissionId]);

        toast("Chamado rejeitado com sucesso", "success");
      },
      onError: (error) => {
        const err = error as AxiosError;
        toast(`${err.response?.data.message}`, "error");
      },
    }
  );
}
export function useAcceptAppeals(appealId: string, eventId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (appeals: FinishedAppeal) => finishedAppealsRequest(appealId, appeals),
    {
      onSuccess: ({ submissionId }) => {
        queryClient.invalidateQueries("appeals-details-event");
        queryClient.invalidateQueries(["appeals", eventId]);
        queryClient.invalidateQueries(["submission", submissionId]);

        toast("Chamado aceito com sucesso", "success");
      },
      onError: (error) => {
        const err = error as AxiosError;
        toast(`${err.response?.data.message}`, "error");
      },
    }
  );
}

export function useEventQuestions(id?: string, options?: QueryOptions) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useQuery(
    ["event-questions", id],
    () => getEventQuestionsRequest(id as string),
    {
      enabled: Boolean(id) && options?.enabled,
      staleTime: 1000 * 30, // 30 seconds
      onSuccess: () => {
        queryClient.invalidateQueries("event-lookup");
      },
      onError: () => {
        toast("Ocorreu um erro ao carregar as questões do evento.", "error");
      },
    }
  );
}

export function useEventQuestionsRestricted(
  id?: string,
  options?: QueryOptions
) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useQuery(
    ["event-questions-restricted", id],
    () => getEventQuestionsRestrictedRequest(id as string),
    {
      enabled: Boolean(id) && options?.enabled,
      staleTime: 1000 * 30, // 30 seconds
      onSuccess: () => {
        queryClient.invalidateQueries("event-lookup");
      },
      onError: () => {
        toast("Ocorreu um erro ao carregar as questões do evento.", "error");
      },
    }
  );
}

export function useParticipantsEvent(id?: string) {
  return useQuery(
    ["participants-event", id],
    () => getParticipantsEventRequest(id as string),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 30, // 30 seconds
    }
  );
}

export function useAnswerEvent(id?: string) {
  return useQuery(
    ["answer-event", id],
    () => getSubmissionEventRequest(id as string),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 30, // 30 seconds
    }
  );
}

export function useRankingEvent(id?: string) {
  return useQuery(
    ["ranking-event", id],
    () => getRankingEventRequest(id as string),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 30, // 30 seconds
    }
  );
}

export function useAppealDetailsEvent(id?: string) {
  return useQuery(
    ["appeals-details-event", id],
    () => getDetailsAppealsRequest(id as string),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 30, // 30 seconds
    }
  );
}

export function useCreateEvent() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (event: EventType | CreateEventType) => createEventRequest(event),
    {
      onSuccess: (event: EventType) => {
        queryClient.invalidateQueries("events");
        queryClient.invalidateQueries("events-to-calendar");
        queryClient.invalidateQueries("events-dashboard");
        queryClient.invalidateQueries("me");

        toast(
          event.isDraft
            ? "Evento salvo como rascunho."
            : "Evento criado como sucesso.",
          "success"
        );
      },
      onError: () => {
        toast("Ocorreu um erro ao criar o evento.", "error");
      },
    }
  );
}

export function useEditEvent() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((event: CreateEventType) => editEventRequest(event), {
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries("events");
      queryClient.invalidateQueries(["event", id]);
      queryClient.invalidateQueries("event-questions");
      queryClient.invalidateQueries("event-questions-restricted");
      queryClient.invalidateQueries("events-to-calendar");
      queryClient.invalidateQueries("events-dashboard");
      queryClient.invalidateQueries("me");

      toast("Evento atualizado com sucesso.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao salvar o evento.", "error");
    },
  });
}

export function useUploadEventImage(id?: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (image: File) => uploadEventImageRequest(id as string, image),
    {
      onSuccess: ({ url }) => {
        const previousEvent = queryClient.getQueryData<EventType>([
          "event",
          id,
        ]);

        if (previousEvent) {
          queryClient.setQueryData(["event", id], {
            ...previousEvent,
            image: url,
          });
        }
      },
      onError: () => {
        toast("Ocorreu um erro ao atualizar a imagem do evento.", "error");
      },
    }
  );
}

export function useEventLookup(id?: string, options?: QueryOptions) {
  const { toast } = useToast();

  return useQuery(
    ["event-lookup", id],
    () => getEventLookupRequest(id as string),
    {
      enabled: Boolean(id) && options?.enabled,
      staleTime: 1000 * 30, // 30 seconds
      onError: () => {
        toast("Ocorreu um erro ao buscar o status do participante.", "error");
      },
    }
  );
}

export function useEventSubscribe(id: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((id: string) => eventSubscribeRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("event-lookup");
      queryClient.invalidateQueries(["event", id]);
      queryClient.invalidateQueries("me");

      toast("Você agora participa deste evento.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao se inscrever no evento.", "error");
    },
  });
}

export function useCreateSubmissionAnswers(id?: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (submitQuizData: SubmitQuizData) =>
      createSubmissionsAnswerRequest(id as string, submitQuizData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("event-lookup");
        queryClient.invalidateQueries(["event", id]);
        queryClient.invalidateQueries("me");

        toast("Questionário salvo com sucesso.", "success");
      },
      onError: () => {
        toast("Ocorreu um erro ao se inscrever no evento.", "error");
      },
    }
  );
}

export function useUpdateSubmissionAnswers(id?: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (submitQuizData: SubmitQuizData) =>
      UpdateSubmissionsAnswerRequest(id as string, submitQuizData),
    {
      onSuccess: ({ event }) => {
        queryClient.invalidateQueries(["questions-answer", event.id]);
        queryClient.invalidateQueries("event-lookup");
        queryClient.invalidateQueries(["event", id]);
        queryClient.invalidateQueries("me");

        toast("Questionário salvo com sucesso.", "success");
      },
    }
  );
}

export function usePendingCorrectionsCount(
  { id, isDraft }: Partial<EventType>,
  options?: QueryOptions
) {
  const { toast } = useToast();

  return useQuery(
    ["pending-corrections", id],
    () => getPendingCorrectionsCountRequest(id as string),
    {
      enabled: Boolean(!isDraft) && options?.enabled,
      staleTime: 1000 * 30, // 30 seconds
      onError: () => {
        toast(
          "Ocorreu um erro ao carregar a quantidade de correções disponíveis.",
          "error"
        );
      },
    }
  );
}

export function useEventUnsubscribe() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((id: string) => eventUnsubscribeRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("event");
      queryClient.invalidateQueries("event-lookup");
      toast("Participação cancelada com suceso.", "success");
    },
    onError: ({ response }) => {
      if (response.status === 400) {
        toast(
          "Você não pode cancelar sua partipação neste evento, pois já foram submetidas respostas.",
          "error"
        );
      } else {
        toast("Ocorreu um erro ao cancelar participação do evento.", "error");
      }
    },
  });
}

export function useSubmissionsToModeratorOrOwner({
  id,
  allowPeerCorrection,
}: Partial<EventType>) {
  const { toast } = useToast();

  return useQuery(
    ["event-submissions", id],
    () => getSubmissionsToModeratorOrOwnerRequest(id as string),
    {
      enabled: Boolean(id && !allowPeerCorrection),
      staleTime: 1000 * 30, // 30 seconds
      onError: () => {
        toast(
          "Ocorreu um erro ao carregar as respostas dos partipantes.",
          "error"
        );
      },
    }
  );
}

export function useSubmissionsToPeerCorrection({
  id,
  allowPeerCorrection,
}: Partial<EventType>) {
  const { toast } = useToast();

  return useQuery(
    ["event-submissions-with-peer-correction", id],
    () => getSubmissionsToPeerCorrectionRequest(id as string),
    {
      enabled: Boolean(id && allowPeerCorrection),
      staleTime: 1000 * 30, // 30 seconds
      onError: () => {
        toast(
          "Ocorreu um erro ao carregar as respostas dos partipantes.",
          "error"
        );
      },
    }
  );
}

export function useSubmission(id?: string) {
  const { toast } = useToast();

  return useQuery(
    ["submission", id],
    () => getSubmissionRequest(id as string),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 30, // 30 seconds
      onError: () => {
        toast(
          "Ocorreu um erro ao carregar a resposta do participante.",
          "error"
        );
      },
    }
  );
}

export function useCorrectPeerCorrection(
  submissionId?: string,
  eventId?: string
) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (corrections: CorrectionDataToRequest[]) =>
      correctPeerCorrectionRequest(submissionId as string, corrections),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "event-submissions-with-peer-correction",
          eventId,
        ]);
        toast("Correção efetuada com sucesso!", "success");
      },
      onError: () => {
        toast("Ocorreu um erro ao efetuar a correção.", "error");
      },
    }
  );
}

export function useCorrectWithModeratorsOrOwnerRequest(
  submissionId?: string,
  eventId?: string,
  submissionCorrectionId?: string
) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (corrections: CorrectionDataToRequest[]) =>
      correctWithModeratorsOrOwnerRequest(
        submissionId as string,
        corrections,
        submissionCorrectionId as string
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["event-submissions", eventId]);
        queryClient.invalidateQueries(["ranking-event", eventId]);
        toast("Correção efetuada com sucesso!", "success");
      },
      onError: () => {
        toast("Ocorreu um erro ao efetuar a correção.", "error");
      },
    }
  );
}

export function useFinishEvent(id: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(() => finishEvent(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["event", id]);
      toast("Evento finalizado com sucesso!", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao finalizar o evento.", "error");
    },
  });
}

export function useNullifyQuestion(eventId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    ({ questionId, appealId }: { questionId: string; appealId: string }) =>
      nullifyQuestion(questionId, eventId, appealId),
    {
      onSuccess: () => {
        toast("Questão anulada com sucesso!", "success");
        queryClient.invalidateQueries(["appeals", eventId]);
      },
      onError: () => {
        toast("Ocorreu um erro ao anular a questão.", "error");
      },
    }
  );
}

export function useReportQuestion() {
  const { toast } = useToast();

  return useMutation(
    ({ eventId, justification, questionId }: ReportQuestion) =>
      reportQuestion({ eventId, justification, questionId }),
    {
      onSuccess: () => {
        toast("Questão reportada com sucesso!", "success");
      },
      onError: () => {
        toast("Ocorreu um erro ao reportar a questão.", "error");
      },
    }
  );
}
