import { Flex, Input } from "@chakra-ui/react";
import { Tag } from "components/Tag";
import { KeyboardEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { TagSelectorProps } from "./props";

export const TagSelector = ({ name, ...rest }: TagSelectorProps) => {
  const { watch, setValue } = useFormContext();

  const [input, setInput] = useState("");

  const tags: string[] = watch(name);

  const setTags = () => {
    if (input !== "" && !tags?.some((tag) => tag === input)) {
      setValue(name, [...tags, input]);
    }

    setInput("");
  };

  const handleKeyWord = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.currentTarget.onblur) {
      event.preventDefault();

      setTags();
    }
  };

  const handleRemoveCategory = (tagToRemove: string) => {
    setValue(
      name,
      tags.filter((tag) => tag !== tagToRemove)
    );
  };

  return (
    <Flex direction="column" gap={2}>
      <Input
        {...rest}
        onKeyPress={handleKeyWord}
        onBlur={setTags}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />

      <Flex gap={1} flexWrap="wrap">
        {tags?.map((tag) => (
          <Tag
            key={tag}
            name={tag}
            bg="primary.500"
            fontSize=".625rem"
            onClose={() => handleRemoveCategory(tag)}
          />
        ))}
      </Flex>
    </Flex>
  );
};
