import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Accordion: ComponentStyleConfig = {
  baseStyle: {
    
    button: {
      color:"black",
      _focus: {
        boxShadow: "0px",
      },
      _hover: {
        bg: "blackAlpha.50",
      },
    },
    container:{
      _last: {
        borderBottomWidth: "0px",
      },
    },
    icon:{
      color: "primary.500"
    }
  },
};

export default Accordion;
