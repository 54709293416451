import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  getDay,
  isEqual,
  isSameDay,
  isToday,
  parse,
  parseISO,
  startOfToday,
  startOfWeek,
} from "date-fns";

import { ptBR } from "date-fns/locale";
import { useMyEventsToCalendar } from "hooks/event";
import { useState } from "react";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

const daysOfWeek = [
  {
    day: "S",
    dayMobile: "Seg",
    index: 1,
  },
  {
    day: "T",
    dayMobile: "Ter",
    index: 2,
  },
  {
    day: "Q",
    dayMobile: "Qua",
    index: 3,
  },
  {
    day: "Q",
    dayMobile: "Qui",
    index: 4,
  },
  {
    day: "S",
    dayMobile: "Sex",
    index: 5,
  },
  {
    day: "S",
    dayMobile: "Sáb",
    index: 6,
  },
  {
    day: "D",
    dayMobile: "Dom",
    index: 7,
  },
];

export function Calendar() {
  const navigate = useNavigate();

  const today = startOfToday();
  const [selectedDay, setSelectedDay] = useState(today);
  const [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  const firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
  const lastDayCurrentMonth = endOfMonth(firstDayCurrentMonth);

  const [showDetails, setShowDetails] = useState(true);
  const isDesktop = useBreakpointValue({ lg: "none" });

  const { data: events, isLoading } = useMyEventsToCalendar(
    format(firstDayCurrentMonth, "yyyy-MM-dd"),
    format(lastDayCurrentMonth, "yyyy-MM-dd")
  );

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: lastDayCurrentMonth,
  });

  const week = eachDayOfInterval({
    start: startOfWeek(today, { weekStartsOn: 1 }),
    end: endOfWeek(today, { weekStartsOn: 1 }),
  });

  const previousMonth = () => {
    const firstDayPrevMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayPrevMonth, "MMM-yyyy"));
  };

  const nextMonth = () => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  };

  const selectDay = (day: Date) => {
    setSelectedDay(day);
    navigate({
      pathname: "/meus-eventos",
      search: `date=${formatISO(day)}`,
    });
  };

  const styleDayBackground = (day: Date) => {
    if (isEqual(day, selectedDay)) {
      return {
        bgColor: "#FFDDDF",
        _hover: {
          bgColor: "#f3cdd0",
        },
      };
    }
    if (isEqual(today, day)) {
      return {
        bgColor: "#ddfaff",
        _hover: {
          bgColor: "#ccedf3",
        },
      };
    }
    return {
      bgColor: "inherit",
      _hover: {
        bgColor: "#FFDDDF",
      },
    };
  };

  return (
    <Flex direction="column" gap={4} w="100%">
      <Flex
        w="100%"
        h="100%"
        direction="column"
        gap={5}
        bg="background"
        border={showDetails ? "1px solid" : "none"}
        borderColor="gray.100"
        borderRadius={20}
        p={showDetails ? 6 : 0}
      >
        {showDetails && (
          <Flex justify="space-between" align="center">
            <Skeleton isLoaded={!isLoading}>
              <Heading
                color="primary.500"
                fontSize="1.125rem"
                fontWeight={700}
                textTransform="capitalize"
              >
                {format(firstDayCurrentMonth, "MMMM yyyy", { locale: ptBR })}
              </Heading>
            </Skeleton>

            <Flex gap={1}>
              <IconButton
                aria-label="Mês anterior"
                icon={<MdKeyboardArrowLeft size={24} />}
                variant="icon"
                height="fit-content"
                minW="fit-content"
                color="primary.500"
                onClick={previousMonth}
              />
              <IconButton
                aria-label="Próximo mês"
                icon={<MdKeyboardArrowRight size={24} />}
                variant="icon"
                height="fit-content"
                minW="fit-content"
                color="primary.500"
                onClick={nextMonth}
              />
            </Flex>
          </Flex>
        )}

        <Flex w="100%" direction="column" gap={1}>
          <Skeleton isLoaded={!isLoading}>
            <Grid templateColumns="repeat(7, 1fr)" gap={2}>
              {daysOfWeek.map(({ day, dayMobile, index }) => (
                <GridItem key={index} textAlign="center">
                  <Text
                    fontWeight={showDetails ? 800 : 400}
                    color={showDetails ? "primary.600" : "gray.400"}
                    fontSize="sm"
                    textAlign="center"
                  >
                    {showDetails ? day : dayMobile}
                  </Text>
                </GridItem>
              ))}
            </Grid>
          </Skeleton>

          <Skeleton isLoaded={!isLoading}>
            <Grid templateColumns="repeat(7, 1fr)" gap={2}>
              {showDetails
                ? days.map((day, index) => (
                    <GridItem
                      key={day.toString()}
                      gridColumnStart={index === 0 ? getDay(day) : 0}
                      textAlign="center"
                    >
                      <Button
                        color="#666666"
                        variant="unstyled"
                        minW={{ base: "30px", lg: "36px" }}
                        height={{ base: "30px", lg: "36px" }}
                        borderRadius="full"
                        fontWeight={isToday(day) ? 700 : 400}
                        {...styleDayBackground(day)}
                        onClick={() => selectDay(day)}
                        position="relative"
                      >
                        <time dateTime={format(day, "yyyy-MM-dd")}>
                          {format(day, "d")}
                        </time>

                        {events?.some((event) =>
                          isSameDay(parseISO(event.date), day)
                        ) && (
                          <Flex
                            position="absolute"
                            bottom={{ base: 0.5, lg: 1 }}
                            left="50%"
                            transform="translateX(-50%)"
                          >
                            <Tooltip
                              label={`Há ${
                                events.find((event) =>
                                  isSameDay(parseISO(event.date), day)
                                )?.count
                              } evento(s) para este dia.`}
                            >
                              <Box
                                w="6px"
                                h="6px"
                                bg="primary.500"
                                borderRadius="full"
                              />
                            </Tooltip>
                          </Flex>
                        )}
                      </Button>
                    </GridItem>
                  ))
                : week.map((day) => (
                    <Flex
                      boxShadow="2px 2px 4px 0px #0000001A"
                      bg={isEqual(today, day) ? "primary.500" : "background"}
                      borderRadius={5}
                      align="center"
                      justify="center"
                      onClick={() => selectDay(day)}
                    >
                      <Text
                        fontWeight={700}
                        color={
                          isEqual(today, day) ? "background" : "primary.500"
                        }
                        fontSize="2xl"
                      >
                        {format(day, "d")}
                      </Text>

                      {events?.some(({ date }) =>
                        isSameDay(parseISO(date), day)
                      ) && (
                        <Box
                          w="6px"
                          h="6px"
                          bg="primary.500"
                          borderRadius="full"
                        />
                      )}
                    </Flex>
                  ))}
            </Grid>
          </Skeleton>
        </Flex>
      </Flex>

      {!isDesktop && (
        <Flex justify="center">
          <Button
            variant="link"
            display="flex"
            flexDirection="column"
            alignItems="center"
            color="black"
            fontWeight={400}
            onClick={toggleShowDetails}
          >
            <Icon
              as={MdKeyboardArrowDown}
              transition="all .2s"
              transform={showDetails ? "rotate(-180deg)" : "rotate(0)"}
              color="primary.500"
            />
            <Text>{showDetails ? "Ver menos" : "Ver detalhes"} </Text>
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
