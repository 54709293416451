import {
  Button, ButtonGroup, Flex, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter, ModalOverlay, Progress
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useAuth } from "contexts/auth/useAuth";
import { useToast } from "contexts/layout/toast/useToast";
import { useCreateGroup } from "hooks/group";
import { useUsers } from "hooks/user";
import { CreateGroup } from "interfaces/group";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PaymentMethodForm, PlanSelectionForm } from "../Forms";
// import { createGroupSchema } from "./InviteByEmailModal/schema";

const defaultValues: CreateGroup = {
  name: "",
  participants: [],
  period: "",
};

export function PlanSelectionModal({ isOpen, onClose }: ModalProps) {


  // const {
  //   isOpen: isInviteModalOpen,
  //   onOpen: onInviteModalOpen,
  //   onClose: onInviteModalClose,
  // } = useDisclosure();

  const methods = useForm<CreateGroup>({
    defaultValues,
    // resolver: yupResolver(createGroupSchema),
  });



  const { user } = useAuth();
  const { data: users } = useUsers();
  const { toast } = useToast();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);

  const { mutate: createGroup, isLoading, isSuccess } = useCreateGroup();
  const [search, setSearch] = useState("");


  const handleClose = () => {
    // reset();
    onClose();
  };

  const handleCreateGroup = (group: CreateGroup) => {
    createGroup({
      name: group.name,
      period: group.period,
      userIds: group.participants.map(({ id }) => id),
    });

    handleClose();
  };

  useEffect(() => {
    if (isSuccess) {
      setSearch("");
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalCloseButton />
        {/* <FormProvider {...methods}> */}
        <FormProvider {...methods}>
          <Flex
            as="form"
            direction="column"
          // onSubmit={handleSubmit(handleCreateGroup)}
          >
            <ModalBody display="flex" flexDirection="column" gap={3} p={8}>

              {/* <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"> */}
              <Progress hasStripe value={progress} mt="2%" isAnimated />

              {/* fluxo com metodo cadastrado */}
              {step === 1 ? <PaymentMethodForm /> : <PlanSelectionForm />}

              {/* fluxo sem metodo cadastrado */}
              {/* {step === 1 ? <PaymentMethodForm /> : step === 2 ? <Form2 /> : <PlanSelectionForm />} */}

              {/* </Box> */}

            </ModalBody>

            <ModalFooter justifyContent="space-between">

              <ButtonGroup mt="5%" w="100%">
                <Flex w="100%" justifyContent="space-between">
                  <Flex>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                        setProgress(progress - 33.33);
                      }}
                      isDisabled={step === 1}
                      colorScheme="teal"
                      variant="solid"
                      w="7rem"
                      mr="5%">
                      Voltar
                    </Button>
                    <Button
                      w="7rem"
                      isDisabled={step === 2}
                      onClick={() => {
                        setStep(step + 1);
                        if (step === 2) {
                          setProgress(100);
                        } else {
                          setProgress(progress + 33.33);
                        }
                      }}
                      colorScheme="teal"
                      variant="outline">
                      Próximo
                    </Button>
                  </Flex>
                  {step === 2 ? (
                    <Button
                      w="7rem"
                      colorScheme="red"
                      variant="solid"
                    // onClick={() => {
                    //   toast({
                    //     title: 'Account created.',
                    //     description: "We've created your account for you.",
                    //     status: 'success',
                    //     duration: 3000,
                    //     isClosable: true,
                    //   })
                    // }}
                    />
                  ) : null}
                </Flex>
              </ButtonGroup>


              {/* <Button variant="link" onClick={handleClose} px={8}>
              Cancelar
            </Button>

            <Button
              isLoading={isLoading}
              type="submit"
              onClick={handleClose}
            // isDisabled
            >
              Gerar licença
            </Button> */}

            </ModalFooter>

          </Flex>

        </FormProvider>
    </Modal>
  );
}
