import { endOfMinute, isWithinInterval } from "date-fns";

export function isWithinThePeriod(start?: string, end?: string) {
  if (!start || !end) {
    return undefined;
  }

  return isWithinInterval(new Date(), {
    start: new Date(start),
    end: endOfMinute(new Date(end)),
  });
}
