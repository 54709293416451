import { Request } from "./request";
import { User } from "./User";

export enum EventStatus {
  JOINED = "JOINED",
  NOT_JOINED = "NOT_JOINED",
  ANSWERED = "ANSWERED",
  CORRECTED_ANSWERS = "CORRECTED_ANSWERS",
}

export enum EventUserLevel {
  ORGANIZER = "organizer",
  PARTICIPANT = "participant",
}

export enum EventTimeStatus {
  FUTURE = "future",
  IN_PROGRESS = "in_progress",
  FINISHED = "finished",
}

export enum TranslatedEventTime {
  FUTURE = "Futuros",
  IN_PROGRESS = "Em andamento",
  FINISHED = "Finalizados",
}

export enum QuestionType {
  OPEN = "OPEN",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}

export interface ModeratorEmail {
  email: string;
}

export interface Choice {
  index: string;
  text: string;
  id: string;
  isCorrect: boolean;
}

export interface Question {
  type: QuestionType;
  title: string;
  id: string;
  order?: number;
}

export interface QuestionOpen extends Question {
  type: QuestionType.OPEN;
  answerPattern: string;
  criteria: string;
}

export interface QuestionMultiple extends Question {
  type: QuestionType.MULTIPLE_CHOICE;
  choices: Choice[];
}

export interface AnswerProps {
  questionId: string;
}

export function isMultipleChoices(
  question: QuestionMultiple | QuestionOpen
): question is QuestionMultiple {
  return "choices" in question;
}

export interface OpenAnswerProps extends AnswerProps {
  openAnswer?: string;
}

export interface SubmissionsProps {
  answers: Array<OpenAnswerProps | OpenAnswerProps>;
  isDraft: boolean;
}

export interface ChoicesAnswerProps extends AnswerProps {
  multipleChoiceAnswer?: number;
}

export interface Category {
  id: string;
  name: string;
}

export interface Event {
  id: string;
  name: string;
  description: string;
  isPublic: boolean;
  isDraft: boolean;
  categories: Category[];
  weightOfQuestions: number | null;
  responsePeriodStart: string | null;
  responsePeriodEnd: string | null;
  correctionPeriodStart: string | null;
  correctionPeriodEnd: string | null;
  appealPeriodStart: string | null;
  appealPeriodEnd: string | null;
  allowPeerCorrection: boolean;
  useAnswersInGroup: boolean;
  groupMembersCount: number | null;
  allowModerators: boolean;
  moderators: ModeratorEmail[];
  participants: string[];
  participantsCount: number;
  linkedGroup: string | null;
  questions: Array<QuestionOpen | QuestionMultiple>;
  image?: any;
  owner: User;
  createdAt: string;
  code: string;
}

export type EventsListResponse = {
  list: Event[];
} & Request;

export type CreateEvent = Omit<
  Event,
  "owner" | "id" | "code" | "appealPeriodStart" | "appealPeriodEnd"
> & {
  linkedGroupId: string | null;
};

export type EventSetupType = Omit<CreateEvent, "questions">;

export type EventQuiz = Pick<CreateEvent, "questions">;

export type CreateEventSteps = "SETUP" | "QUIZ" | "POST";

export type Appeal = {
  question: string;
  justification: string;
  feedback: string;
};

export type Answer = {
  _id: string;
  question: Question;
  openAnswer: string;
  multipleChoiceAnswer: string;
};

export type Correction = {
  answer: Answer;
  feedback: string;
  grade: number;
};

export type SubmitCorrection = Omit<Correction, "answer"> & {
  answerId: string;
};

export type Submission = {
  _id: string;
  answers: Answer[];
  event: string;
  grade: number;
  isCorrected: boolean;
  isDraft: boolean;
  user?: User;
};
export interface SubmissionCorrected {
  appeal: Appeal;
  openAnswer: string;
  question: Question;
  _id: string;
}

export type CorrectionData = {
  _id: string;
  appeals: Appeal[];
  corrections: Correction[];
  corrector: string;
  grade: number;
  isCorrected: boolean;
  submission: Submission;
  user?: User;
};

export interface AppealOrganizer extends Appeal {
  answer: string;
  event: string;
  _id: string;
}
