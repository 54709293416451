import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "contexts/auth/useAuth";
import { useToast } from "contexts/layout/toast/useToast";
import { useVerifyUserEmail } from "hooks/user";
import { Credentials } from "interfaces/User";
import { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { FiMail } from "react-icons/fi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { verifyEmailSchema } from "./schema";

export function VerifyEmail() {
  const { useAuthWithGoogle } = useAuth();
  const { mutate: authWithGoogle, isLoading: isAuthWithGoogleLoading } =
    useAuthWithGoogle();

  const {
    mutate: verifyUserEmail,
    data,
    isLoading,
    isSuccess,
  } = useVerifyUserEmail();

  const { toast } = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<Pick<Credentials, "email">>({
    resolver: yupResolver(verifyEmailSchema),
  });

  const email = watch("email");

  const handleVerifyEmail = ({ email }: Pick<Credentials, "email">) => {
    verifyUserEmail(email);
  };

  useEffect(() => {
    if (isSuccess) {
      if (data.isTaken) {
        toast("O email informado já está em uso.", "error");
      } else {
        navigate({
          pathname: "/criar-conta/perfil",
          search: `email=${email}`,
        });
      }
    }
  }, [isSuccess]);

  return (
    <Flex direction="column" gap={8} w="100%" h="100%" px={8} pb={[8, 0]}>
      <Flex
        as="form"
        onSubmit={handleSubmit(handleVerifyEmail)}
        direction="column"
        gap={4}
        w="100%"
        h="100%"
      >
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <InputGroup>
            <InputLeftElement
              children={<FiMail size={20} />}
              color={errors.email ? "error" : "primary.500"}
            />
            <Input
              placeholder="ex.:alexandre@arbo.com"
              {...register("email")}
            />
          </InputGroup>

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          size="lg"
          isLoading={isLoading}
          isDisabled={!isDirty}
        >
          Verificar email
        </Button>
      </Flex>

      <Flex direction="column" align="center" gap={4}>
        <Flex gap={1} align="baseline">
          <Text fontSize="sm" fontWeight={700} color="gray.300">
            Já possui uma conta?
          </Text>
          <Link as={RouterLink} to="/login" fontSize="sm">
            Fazer login →
          </Link>
        </Flex>

        <Text fontSize="sm" fontWeight={700} color="gray.300">
          - ou -
        </Text>

        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
          onSuccess={authWithGoogle}
          render={(props) => (
            <Button
              {...props}
              isFullWidth
              isLoading={isAuthWithGoogleLoading}
              variant="outline"
              size="lg"
              boxShadow="none"
              justifyContent={
                isAuthWithGoogleLoading ? "center" : "space-between"
              }
              leftIcon={<FcGoogle size={24} />}
              rightIcon={<FcGoogle size={24} opacity={0} />}
            >
              Entre pelo Google
            </Button>
          )}
        />
      </Flex>
    </Flex>
  );
}
