import {
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import welcome from "assets/welcome.png";
import { Calendar } from "components/Calendar";
import { EmptyContent } from "components/EmptyContent";
import { EventCard } from "components/Events/EventCard";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { MURAL_WELCOME_MESSAGE } from "constants/texts";
import { useAuth } from "contexts/auth/useAuth";
import { useMyEvents } from "hooks/event";
import { MdOutlineToday } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { EventSubscribeModal } from "./EventSubscribeModal";

export function Mural() {
  const { user } = useAuth();
  const [isLargerThan1250] = useMediaQuery("(min-width: 1250px)");
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: eventsRequest, isLoading } = useMyEvents({
    type: "all",
    size: 3,
  });

  return (
    <>
      <Header title="Mural" />

      <Flex
        direction="column"
        gap={8}
        h="100%"
        p={isLargerThan1080 ? 0 : 8}
        w="100%"
        overflow="auto"
      >
        <Flex gap={9} direction={isLargerThan1250 ? "row" : "column"}>
          {isLargerThan1080 && (
            <Flex direction="column" gap={8} flex={1}>
              <>
                <Flex gap={8}>
                  <Button
                    size="lg"
                    as={RouterLink}
                    to="/meus-eventos/editor"
                    px={16}
                  >
                    Criar evento
                  </Button>

                  <Button size="lg" variant="link" onClick={onOpen}>
                    Participar de um evento
                  </Button>
                </Flex>

                <Flex bg="background" borderRadius={20} flex={1}>
                  <Flex
                    bg="linear-gradient(90deg, rgba(24, 185, 170, 0.21) 0%, rgba(251, 251, 251, 0) 66.64%)"
                    w="100%"
                    justify="space-between"
                    align="center"
                    gap={8}
                    p={8}
                    borderRadius="inherit"
                  >
                    <Flex direction="column" gap={4}>
                      <Heading color="primary.600" fontSize="2xl">
                        Olá,{" "}
                        {`${user?.firstName
                          .charAt(0)
                          .toUpperCase()}${user?.firstName.slice(1)}`}
                        !
                      </Heading>

                      <Text color="gray.300" fontSize="sm">
                        {MURAL_WELCOME_MESSAGE}{" "}
                        <Link
                          href="mailto:contato@edukahub.com.br"
                          fontSize="sm"
                        >
                          contato@edukahub.com.br
                        </Link>
                      </Text>
                    </Flex>

                    <Image src={welcome} pt={4} h={130} />
                  </Flex>
                </Flex>
              </>
            </Flex>
          )}

          <Flex direction="column" gap={4}>
            {!isLargerThan1080 && (
              <Text fontSize="xs" color="gray.400">
                Meus compromissos
              </Text>
            )}

            <Calendar />
          </Flex>
        </Flex>

        <Paper gap={isLargerThan1080 ? 6 : 4} p={isLargerThan1080 ? 8 : 0}>
          <Flex w="100%" justify="space-between" align="center">
            <Heading
              fontSize={isLargerThan1080 ? "lg" : "xs"}
              color="gray.400"
              fontWeight={isLargerThan1080 ? 800 : 400}
            >
              Meus eventos
            </Heading>

            <Link as={RouterLink} to="/meus-eventos">
              Ver todos
            </Link>
          </Flex>

          <Flex align="center" justify="center">
            {isLoading ? (
              <Spinner />
            ) : (
              <Grid
                templateColumns={
                  eventsRequest?.data.length === 0
                    ? "1fr"
                    : "repeat(auto-fill, minmax(315px, 1fr))"
                }
                gap={6}
                justifyItems="center"
                alignItems={
                  eventsRequest?.data.length === 0 ? "center" : "flex-start"
                }
                h="100%"
                w="100%"
                pb={isLargerThan1080 ? 0 : 8}
              >
                {eventsRequest?.data.length !== 0 ? (
                  eventsRequest?.data?.map((event: any) => (
                    <EventCard key={event.id} {...event} />
                  ))
                ) : (
                  <EmptyContent
                    message="Não há eventos disponíveis ainda."
                    icon={MdOutlineToday}
                  />
                )}
              </Grid>
            )}
          </Flex>
        </Paper>
      </Flex>

      <EventSubscribeModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
