import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  Switch,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { DatePicker } from "components/DatePicker";
import { Paper } from "components/Paper";
import { SelectComponent } from "components/Select";
import { TagSelector } from "components/TagSelector";
import { UploadImageField } from "components/UploadImageField";
import {
  defaultModeratorEmailField,
  weightOfQuestionsOptions,
} from "constants/event";
import { useEvent } from "contexts/event/useEvent";
import { useGroups } from "hooks/group";
import { CreateEventType, EventType } from "interfaces/event";
import { useState } from "react";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import {
  MdAddCircleOutline,
  MdClose,
  MdInfo,
  MdOutlinePin,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

export function EventSettings() {
  const navigate = useNavigate();

  const { setEvent, setSteps, eventSettingsForm } = useEvent();

  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = eventSettingsForm;

  const {
    fields: moderatorEmails,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "moderators",
  });

  const appendModeratorEmailField = () => {
    append(defaultModeratorEmailField);
  };

  const useAnswersInGroup = watch("useAnswersInGroup");
  const linkedGroupId = watch("linkedGroupId");
  const allowPeerCorrection = watch("allowPeerCorrection");
  const allowModerators = watch("allowModerators");
  const [associateEventWithAGroup, setAssociateEventWithAGroup] = useState(
    !!linkedGroupId
  );

  const { data: groups } = useGroups();

  const groupsOptions = groups?.map(({ name, _id }) => ({
    value: _id,
    label: name,
  }));

  const onAssociateEventWithAGroupChange = () => {
    setAssociateEventWithAGroup(!associateEventWithAGroup);

    if (associateEventWithAGroup) {
      setValue("linkedGroupId", undefined);
    }
  };

  const renderModeratorsEmailsFields = () =>
    moderatorEmails.map((field, index) => (
      <FormControl key={field.id} isDisabled={!allowModerators}>
        <Flex justify="space-between" w="43%">
          <FormLabel>E-mail do moderador {index !== 0 && index + 1}</FormLabel>

          {moderatorEmails.length !== 1 && (
            <IconButton
              aria-label="Remover moderador"
              icon={<MdClose />}
              color="primary.500"
              variant="icon"
              onClick={() => remove(index)}
              height="20px"
              mb={1}
            />
          )}
        </Flex>

        <Flex align="center" gap={1}>
          <Input
            placeholder="ex.: fulanodetal@gmail.com"
            {...register(`moderators.${index}.email`)}
            w="42%"
          />
          <IconButton
            aria-label="Adicionar moderador"
            icon={<MdAddCircleOutline />}
            variant="link"
            onClick={appendModeratorEmailField}
            isDisabled={!allowModerators}
          />
        </Flex>
      </FormControl>
    ));

  const onSaveEventSetup = (event: EventType | CreateEventType) => {
    setEvent(event);
    setSteps((step) => [...step, "Q&A"]);
    navigate(
      event.id
        ? `/meus-eventos/${event.id}/editor/perguntas-e-respostas`
        : "/meus-eventos/editor/perguntas-e-respostas"
    );
  };

  return (
    <FormProvider {...eventSettingsForm}>
      <Paper
        as="form"
        onSubmit={handleSubmit(onSaveEventSetup)}
        py={8}
        px={10}
        gap={6}
        overflow="auto"
      >
        <FormControl
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            as="label"
            htmlFor="isPublic"
            color="gray.400"
            display="flex"
            alignItems="center"
            gap={1}
          >
            Evento Público
            <Icon as={MdInfo} w="12px" h="12px" color="gray.200" />
          </Text>

          <Switch id="isPublic" {...register("isPublic")} />
        </FormControl>

        <Divider />

        <Flex direction="column" gap={8}>
          <Heading color="gray.300" fontSize="md">
            Informações do Evento
          </Heading>

          <Flex gap={12}>
            <Flex w="45%" direction="column" gap={4}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel>Nome do evento</FormLabel>
                <Input
                  {...register("name")}
                  placeholder="Ex.: Programação avançada"
                />
                <FormErrorMessage>
                  {errors.name && errors.name?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel>Categorias do evento</FormLabel>
                <TagSelector name="categories" placeholder="Ex.: Python" />
              </FormControl>

              <FormControl isInvalid={!!errors.weightOfQuestions}>
                <FormLabel>Peso das questões abertas</FormLabel>
                <Controller
                  control={control}
                  name="weightOfQuestions"
                  render={({ field: { ref, value, ...props } }) => (
                    <SelectComponent
                      isClearable
                      options={weightOfQuestionsOptions}
                      inputRef={ref}
                      placeholder="Peso das questões abertas"
                      icon={<MdOutlinePin />}
                      value={value ? String(value) : ""}
                      {...props}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.weightOfQuestions &&
                    errors.weightOfQuestions?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex w="55%" direction="column" gap={4}>
              <FormControl>
                <FormLabel
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  Capa do evento
                  <Text as="span" fontWeight={400} fontSize={10}>
                    Até 3MB
                  </Text>
                </FormLabel>

                <UploadImageField name="image" h={200} />
              </FormControl>

              <FormControl isInvalid={!!errors.description}>
                <FormLabel>Descrição do evento</FormLabel>
                <Textarea
                  {...register("description")}
                  placeholder="Evento com foco em..."
                  resize="none"
                />
                <FormErrorMessage>
                  {errors.description && errors.description?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </Flex>
        </Flex>

        <Divider />

        <Flex direction="column" gap={8} w="90%">
          <Heading color="gray.300" fontSize="md">
            Período de Resposta do Evento
          </Heading>

          <Flex gap={12}>
            <FormControl isInvalid={!!errors.responsePeriodStart}>
              <FormLabel>Data de início</FormLabel>
              <DatePicker
                enableTime
                name="responsePeriodStart"
                placeholder="18/05/2022"
              />
              <FormErrorMessage>
                {errors.responsePeriodStart &&
                  errors.responsePeriodStart?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.responsePeriodEnd}>
              <FormLabel>Data de fim</FormLabel>
              <DatePicker
                enableTime
                name="responsePeriodEnd"
                placeholder="25/05/2022"
              />
              <FormErrorMessage>
                {errors.responsePeriodEnd && errors.responsePeriodEnd?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </Flex>

        <Divider />

        <Flex direction="column" gap={8}>
          <Heading color="gray.300" fontSize="md">
            Período de Correção do Evento
          </Heading>

          <Flex gap={12} w="90%">
            <FormControl isInvalid={!!errors.correctionPeriodStart}>
              <FormLabel>Data de início</FormLabel>
              <DatePicker
                enableTime
                name="correctionPeriodStart"
                placeholder="26/05/2022"
              />
              <FormErrorMessage>
                {errors.correctionPeriodStart &&
                  errors.correctionPeriodStart?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.correctionPeriodEnd}>
              <FormLabel>Data de fim</FormLabel>
              <DatePicker
                enableTime
                name="correctionPeriodEnd"
                placeholder="31/05/2022"
              />
              <FormErrorMessage>
                {errors.correctionPeriodEnd &&
                  errors.correctionPeriodEnd?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </Flex>

        <Divider />

        <Flex direction="column" gap={8} opacity={0.5}>
          <Flex direction="column" gap={5}>
            <Heading color="gray.300" fontSize="md">
              Dinâmica de resposta
            </Heading>

            <FormControl
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              isDisabled
            >
              <Text
                as="label"
                htmlFor="useAnswersInGroup"
                color="gray.400"
                display="flex"
                alignItems="center"
                gap={1}
              >
                Esse evento usará dinâmica de resposta em grupos
                <Icon as={MdInfo} w="12px" h="12px" color="gray.200" />
              </Text>

              <Switch
                id="useAnswersInGroup"
                {...register("useAnswersInGroup")}
              />
            </FormControl>
          </Flex>

          <Flex gap={12} w="100%">
            <FormControl w="42%" isDisabled={!useAnswersInGroup}>
              <FormLabel>Cada grupo terá quantos participantes?</FormLabel>
              <Input placeholder="5" {...register("groupMembersCount")} />
            </FormControl>
          </Flex>
        </Flex>

        <Divider />

        <Flex direction="column" gap={8} opacity={allowModerators ? 0.5 : 1}>
          <Flex direction="column" gap={5}>
            <Heading color="gray.300" fontSize="md">
              Correção em Pares
            </Heading>

            <FormControl
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              isDisabled={allowModerators}
              opacity={allowModerators ? 0.5 : 1}
            >
              <Text
                as="label"
                htmlFor="allowPeerCorrection"
                color="gray.400"
                display="flex"
                alignItems="center"
                gap={1}
              >
                Permitir correção em pares
                <Icon as={MdInfo} w="12px" h="12px" color="gray.200" />
              </Text>

              <Tooltip
                label={
                  allowModerators &&
                  "Um evento não pode ter correção em pares e aceitar moderadores ao mesmo tempo."
                }
                placement="left"
              >
                <Flex>
                  <Switch
                    id="allowPeerCorrection"
                    {...register("allowPeerCorrection")}
                  />
                </Flex>
              </Tooltip>
            </FormControl>
          </Flex>
        </Flex>

        <Divider />

        <Flex
          direction="column"
          gap={8}
          opacity={allowPeerCorrection ? 0.5 : 1}
        >
          <Flex direction="column" gap={5}>
            <Heading color="gray.300" fontSize="md">
              Moderadores do Evento
            </Heading>

            <FormControl
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              isDisabled={allowPeerCorrection}
            >
              <Text
                as="label"
                htmlFor="allowModerators"
                color="gray.400"
                display="flex"
                alignItems="center"
                gap={1}
              >
                Os moderadores poderão participar desse evento também
                <Icon as={MdInfo} w="12px" h="12px" color="gray.200" />
              </Text>

              <Tooltip
                label={
                  allowPeerCorrection &&
                  "Um evento não pode ter correção em pares e aceitar moderadores ao mesmo tempo."
                }
                placement="left"
              >
                <Flex>
                  <Switch
                    id="allowModerators"
                    {...register("allowModerators")}
                  />
                </Flex>
              </Tooltip>
            </FormControl>
          </Flex>

          <Flex direction="column" gap={4} w="100%">
            {renderModeratorsEmailsFields()}
          </Flex>
        </Flex>

        <Divider />

        <Flex direction="column" gap={8} opacity={0.5}>
          <Flex direction="column" gap={5}>
            <Heading color="gray.300" fontSize="md">
              Associar evento a um grupo
            </Heading>

            <FormControl
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              isDisabled
            >
              <Text
                as="label"
                htmlFor="associateEventWithAGroup"
                color="gray.400"
                display="flex"
                alignItems="center"
                gap={1}
              >
                Esse evento será associado a um grupo
                <Icon as={MdInfo} w="12px" h="12px" color="gray.200" />
              </Text>

              <Switch
                id="associateEventWithAGroup"
                onChange={onAssociateEventWithAGroupChange}
              />
            </FormControl>
          </Flex>

          <Flex gap={12} w="100%">
            <FormControl
              w="42%"
              // isDisabled={!associateEventWithAGroup}
              isDisabled
            >
              <FormLabel>Selecione o grupo</FormLabel>
              <Controller
                control={control}
                name="linkedGroupId"
                render={({ field: { ref, value, ...props } }) => (
                  <SelectComponent
                    options={groupsOptions ?? []}
                    placeholder="Selecionar grupo"
                    isDisabled={!associateEventWithAGroup}
                    inputRef={ref}
                    value={value ?? ""}
                    {...props}
                  />
                )}
              />
            </FormControl>
          </Flex>
        </Flex>

        <Flex justify="end">
          <Button size="lg" type="submit" w={214}>
            Avançar
          </Button>
        </Flex>
      </Paper>
    </FormProvider>
  );
}
