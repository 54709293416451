import { endOfMinute, startOfMinute } from "date-fns";
import * as yup from "yup";

export const eventSetupSchema = yup.object().shape({
  currentDate: yup.date(),
  isPublic: yup.boolean().required("O tipo de evento é obrigatório."),
  name: yup.string().required("O nome do evento é obrigatório."),
  categories: yup.array().of(yup.string()),
  weightOfQuestions: yup.string().nullable(),
  description: yup.string().nullable(),
  responsePeriodStart: yup
    .date()
    .required("A data inicial do período de resposta é obrigatória.")
    .when("currentDate", (_, yup) => {
      const currentDate = new Date();

      return (
        currentDate &&
        yup.min(
          startOfMinute(currentDate),
          "A data inicial do período de resposta não pode ser retroativa."
        )
      );
    }),
  responsePeriodEnd: yup
    .date()
    .required("A data final do período de resposta é obrigatória.")
    .when(
      "responsePeriodStart",
      (responsePeriodStart, yup) =>
        responsePeriodStart &&
        yup.min(
          endOfMinute(responsePeriodStart),
          "A data final do período de resposta não pode ser menor que a data inicial."
        )
    ),
  correctionPeriodStart: yup
    .date()
    .required("A data inicial do período de correção é obrigatória.")
    .when(
      "responsePeriodEnd",
      (responsePeriodEnd, yup) =>
        responsePeriodEnd &&
        yup.min(
          endOfMinute(responsePeriodEnd),
          "A data inicial do período de correção não pode ser menor que a data final de resposta."
        )
    ),
  correctionPeriodEnd: yup
    .date()
    .required("A data final do período de correção é obrigatória.")
    .when(
      "correctionPeriodStart",
      (correctionPeriodStart, yup) =>
        correctionPeriodStart &&
        yup.min(
          endOfMinute(correctionPeriodStart),
          "A data final do período de correção não pode ser menor que a data inicial."
        )
    ),
  allowPeerCorrection: yup
    .boolean()
    .required("A permissão para correção em pares é obrigatória."),
  useAnswersInGroup: yup
    .boolean()
    .required("A permissão para dinâmica de resposta em grupos é obrigatória."),
  groupMembersCount: yup
    .string()
    .nullable()
    .when("useAnswersInGroup", {
      is: (useAnswersInGroup: boolean) => useAnswersInGroup,
      then: yup
        .string()
        .required("O número de participantes por grupos é obrigatório."),
    }),
  allowModerators: yup.boolean(),
  moderatorEmails: yup.array().of(
    yup.object().shape({
      email: yup.string().email("E-mail inválido."),
    })
  ),
});
