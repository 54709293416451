import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Table: ComponentStyleConfig = {
  baseStyle: {
    th: {
      textTransform: "capitalize",
      fontSize: "sm",
      color: "black",
      fontWeight: 700,
    },
    td: {
      fontSize: "sm",
      color: "black",
      fontWeight: 400,
    },
    thead: {
      gap: 4,
      borderBottom: " 1px solid #A0AAA9",
    },
  },
  sizes: {
    md: {
      th: { py: 2, px: 0 },
      td: { py: 2, px: 1 },
    },
  },
  variants: {
    simple: {
      th: {
        color: "black",
      }
    }
  },
  defaultProps: {},
};

export default Table;
