import {
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { DatePicker } from "components/DatePicker";
import { Modal } from "components/Modal";
import { eventTimeTypeList } from "constants/event";
import { format, startOfDay } from "date-fns";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EventFilterProps } from "./props";

export function EventFilterModal({
  isOpen,
  onClose,
  enableTime,
  selectedItem,
  setSelectedItem,
  selectedDate,
  setSelectedDate,
}: EventFilterProps) {
  const methods = useForm<{ selectedItem: string; date?: Date | null }>({
    defaultValues: {
      selectedItem,
      date: undefined,
    },
  });

  const item = methods.watch("selectedItem");
  const date = methods.watch("date", undefined);

  useEffect(() => {
    methods.setValue("date", selectedDate);
  }, [selectedDate]);

  const applyFilters = () => {
    setSelectedItem(item);
    if (date) {
      setSelectedDate(startOfDay(new Date(date)));
    }
    onClose();
  };

  return (
    <Modal
      isBottom
      isOpen={isOpen}
      onClose={onClose}
      isCentered={false}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
    >
      <ModalHeader maxW={300} pt={2}>
        <Text fontSize="sm" fontWeight={400}>
          Filtro
        </Text>
      </ModalHeader>

      <ModalCloseButton />

      <FormProvider {...methods}>
        <Flex
          as="form"
          direction="column"
          onSubmit={methods.handleSubmit(applyFilters)}
        >
          <ModalBody as={Flex} flexDir="column" gap={5}>
            {!date && (
              <Flex direction="column" gap={3}>
                <Text color="gray.400">Status</Text>

                <Flex gap={2}>
                  {eventTimeTypeList.map(({ value, label }) => {
                    const isSelected = item === value;

                    return (
                      <Flex
                        key={value}
                        onClick={() => {
                          methods.setValue("selectedItem", value as string);
                        }}
                        border="1px solid"
                        borderColor="primary.500"
                        bg={isSelected ? "primary.500" : "transparent"}
                        color={isSelected ? "background" : "primary.500"}
                        borderRadius={20}
                        px={3}
                        py={2}
                        fontSize="xs"
                      >
                        {label}
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
            )}

            {enableTime && (
              <Flex direction="column" gap={3}>
                <Text>Data</Text>

                <DatePicker
                  enableTime={false}
                  dropUp
                  name="date"
                  placeholder={format(new Date(), "dd/MM/yyyy")}
                />
              </Flex>
            )}
          </ModalBody>

          <ModalFooter justifyContent="center" flexDirection="column" gap={4}>
            <Button isFullWidth type="submit">
              Aplicar
            </Button>

            <Button
              isFullWidth
              variant="link"
              onClick={() => {
                methods.reset();
                setSelectedDate(null);
              }}
            >
              Limpar filtros
            </Button>
          </ModalFooter>
        </Flex>
      </FormProvider>
    </Modal>
  );
}
