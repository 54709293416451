import type { ComponentStyleConfig } from "@chakra-ui/theme";

const CloseButton: ComponentStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: "none",
    },
  }
};

export default CloseButton;