import {
  Box,
  Button,
  Flex,
  Image,
  List,
  ListItem,
  Spinner,
  Text,
  useDisclosure,
  Avatar,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { useAuth } from "contexts/auth/useAuth";
import { useDetailsAnswerRequest, useEvent } from "hooks/event";
import { MultipleChoiceQuestion } from "interfaces/event";
import { QuestionType } from "interfaces/EventOLD";
import { FiUser } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { arredondar } from "utils/formatNote";
import { getChoiceLetter } from "utils/getChoiceLetter";
import { isWithinThePeriod } from "utils/isWithinThePeriod";
import profileOpacity from "../../../assets/profile-opacity.svg";
import { ReportAnswerModal } from "./ReportModal";

export function SeeAnswer() {
  const { id, answerId } = useParams();
  const { user } = useAuth();
  const { data: detailsEvent } = useEvent(id);
  const { data: question, isSuccess: isSuccessQuestion } =
    useDetailsAnswerRequest(answerId);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Header
        title={`${detailsEvent?.name ? detailsEvent?.name : ""}`}
        goback={`/meus-eventos/${id}/minhas-respostas`}
      />

      <Paper
        p="2rem"
        gap="2.813rem"
        overflow="auto"
        flex={1}
        justify="space-between"
      >
        {!isSuccessQuestion ? (
          <Flex w="100%" h="100vh" justify="center" align="center">
            <Spinner />
          </Flex>
        ) : (
          <>
            <Box>
              <Flex
                justifyContent="space-between"
                direction={["column", "row"]}
              >
                <Flex direction="column">
                  <Text fontWeight="700" fontSize="1.5rem" color="black">
                    Resposta corrigida
                  </Text>
                  <Text fontWeight="700" fontSize="1.125rem" color="gray.400">
                    Etapa Atual:{" "}
                    {detailsEvent?.appealPeriodStart &&
                      detailsEvent?.appealPeriodEnd &&
                      isWithinThePeriod(
                        detailsEvent?.appealPeriodStart,
                        detailsEvent?.appealPeriodEnd
                      )
                      ? "Recurso"
                      : "Recurso finalizado"}
                  </Text>
                </Flex>
                <Flex alignItems="center" gap={4} mt={[6, 0]}>
                  <Avatar
                    src={user?.image ?? undefined}
                    icon={!user?.image ? <FiUser /> : undefined}
                    bg="gray.200"
                    boxSize={16}
                  />

                  <Text>
                    {user?.firstName} {user?.lastName}
                  </Text>
                </Flex>
              </Flex>

              <Flex direction="column" w="100%">
                <Box mt={10}>
                  <Text fontSize="sm" color="#73757A">
                    Questão {question.question.order}
                  </Text>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: question?.question?.title,
                    }}
                    mt={3.5}
                    color="#7A8483"
                    fontWeight="700"
                  />
                </Box>

                <Box mt={8}>
                  {question?.question?.type &&
                    question.question.type === QuestionType.OPEN ? (
                    <>
                      <Text fontSize="sm" color="#73757A">
                        Padrão de resposta
                      </Text>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: question?.question.answerPattern,
                        }}
                        color="#A7A7A7"
                      />
                    </>
                  ) : (
                    <>
                      <Text fontSize="sm" color="#73757A">
                        Alternativas da questão
                      </Text>
                      <List color="#A7A7A7">
                        {question?.question &&
                          (
                            question.question as MultipleChoiceQuestion
                          ).choices.map((choice) => (
                            <ListItem>
                              {getChoiceLetter(
                                (
                                  question.question as MultipleChoiceQuestion
                                ).choices.indexOf(choice)
                              )}
                              {") "}
                              {choice.text}
                            </ListItem>
                          ))}
                      </List>
                    </>
                  )}
                </Box>

                <Box mt={8}>
                  <Text fontSize="sm" color="#73757A">
                    Minha resposta
                  </Text>
                  {question?.question?.type &&
                    question.question.type === QuestionType.OPEN ? (
                    <Text color="#A7A7A7">{question.openAnswer}</Text>
                  ) : (
                    <Text color="#A7A7A7">
                      {getChoiceLetter(
                        (
                          question?.question as MultipleChoiceQuestion
                        ).choices.indexOf(
                          (
                            question?.question as MultipleChoiceQuestion
                          ).choices.find(
                            (el) => el.id === question?.multipleChoiceAnswer
                          )!
                        )
                      )}

                      {") "}
                      {question?.question &&
                        (
                          question?.question as MultipleChoiceQuestion
                        ).choices.find(
                          (el) => el.id === question?.multipleChoiceAnswer
                        )?.text}
                    </Text>
                  )}
                </Box>

                {/* <Flex
                  justifyContent="space-between"
                  width={{ base: "100%", lg: "50%" }}
                >
                  <Flex direction="column" gap="0.75rem">
                    <Text fontSize="0.875rem">
                      Nota dos Corretores ({question?.corrections?.length})
                    </Text>
                    <Flex gap="1rem">
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        gap="0.75rem"
                      >
                        {question?.corrections?.map((correction) => (
                          <>
                            <Image src={profileOpacity} />
                            <Text fontSize="0.875rem">{correction?.grade}</Text>
                          </>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex direction="column" gap="0.75rem">
                    <Text fontSize="0.875rem">Nota final</Text>
                    <Text
                      pt="10px"
                      color="primary.500"
                      fontWeight="700"
                      fontSize="0.875rem"
                    >
                      {question?.corrections &&
                        question.corrections
                          .map((correction) => correction.grade ?? 0)
                          .reduce(
                            (total, currentElement) => total + currentElement,
                            0
                          ) / question.corrections.length}
                    </Text>
                  </Flex>
                </Flex> */}

                <Box mt={8} w="fit-content">
                  <Tabs>
                    <TabList>
                      {question?.corrections?.map((_, index) => (
                        <Tab
                          _selected={{
                            bg: "#EFF7FA",
                            color: "#287C74",
                            borderColor: "#287c74",
                            fontWeight: 700,
                          }}
                          color="#73757A"
                          fontWeight={400}
                        >
                          Corretor {index + 1}
                        </Tab>
                      ))}

                      {question?.appeal && question.appeal?.status !== "OPEN" && (
                        <Tab
                          _selected={{
                            bg: "#EFF7FA",
                            color: "#287C74",
                            borderColor: "#287c74",
                            fontWeight: 700,
                          }}
                          color="#73757A"
                          fontWeight={400}
                        >
                          Recurso
                        </Tab>
                      )}
                    </TabList>

                    <TabPanels>
                      {question?.corrections?.map((correction) => (
                        <TabPanel>
                          <Flex gap={5}>
                            <Box>
                              <Text color="#73757A" fontSize="sm">
                                Nota do corretor
                              </Text>
                              <Flex mt={3} gap={3} align="center">
                                <Image src={profileOpacity} />
                                <Text color="#A7A7A7">{correction.grade}</Text>
                              </Flex>
                            </Box>

                            <Box>
                              <Text color="#73757A" fontSize="sm">
                                Nota final
                              </Text>

                              <Flex align="center" mt={4}>
                                <Text
                                  color="#18B9AA"
                                  fontWeight={700}
                                  fontSize="lg"
                                >
                                  {question?.appeal && question.appeal?.status === "FINISHED" ? (
                                    question.appeal?.grade
                                  ) : (
                                    /* Sum all corrections grade to calc the total grade */
                                    question?.corrections &&
                                    arredondar(
                                      question.corrections
                                        .map(
                                          (correction) => correction.grade ?? 0
                                        )
                                        .reduce(
                                          (total, currentElement) =>
                                            total + currentElement,
                                          0
                                        ) / question.corrections.length
                                    )
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          </Flex>

                          {correction?.feedback && (
                            <Box mt={8}>
                              <Text fontSize="sm" color="#73757A">
                                Feedback do corretor
                              </Text>
                              <Text color="#A7A7A7">
                                {correction?.feedback}
                              </Text>
                            </Box>
                          )}
                        </TabPanel>
                      ))}

                      {question?.appeal && question?.appeal.status !== "OPEN" && (
                        <TabPanel>
                          <Flex gap={5}>
                            <Box>
                              <Text color="#73757A" fontSize="sm">
                                Nota do recurso
                              </Text>
                              <Flex mt={3} gap={3} align="center">
                                <Image src={profileOpacity} />
                                <Text color="#A7A7A7">
                                  {question.appeal?.grade ?? "-"}
                                </Text>
                              </Flex>
                            </Box>

                            <Box>
                              <Text color="#73757A" fontSize="sm">
                                Nota final
                              </Text>

                              <Flex align="center" mt={4}>
                                <Text
                                  color="#18B9AA"
                                  fontWeight={700}
                                  fontSize="lg"
                                >
                                  {question.appeal?.grade ??
                                    (question?.corrections &&
                                      arredondar(
                                        question.corrections
                                          .map(
                                            (correction) => correction.grade ?? 0
                                          )
                                          .reduce(
                                            (total, currentElement) =>
                                              total + currentElement,
                                            0
                                          ) / question.corrections.length)
                                    )}
                                </Text>
                              </Flex>
                            </Box>
                          </Flex>

                          <Box mt={8}>
                            <Text fontSize="sm" color="#73757A">
                              Feedback do recurso
                            </Text>
                            <Text color="#A7A7A7">
                              {question?.appeal?.feedback}
                            </Text>
                          </Box>
                        </TabPanel>
                      )}
                    </TabPanels>
                  </Tabs>
                </Box>
              </Flex>
            </Box>

            <Flex justifyContent="flex-end" gap="1.313rem">
              {question?.appeal || detailsEvent?.isFinished ? (
                <Button
                  colorScheme="primary"
                  as={Link}
                  px="4.18rem"
                  to={`/meus-eventos/${id}/minhas-respostas`}
                >
                  Voltar
                </Button>
              ) : (
                <Button colorScheme="secondary" onClick={onOpen}>
                  Abrir recurso
                </Button>
              )}
            </Flex>

            {user && answerId && (
              <ReportAnswerModal
                answerId={answerId}
                questionId={question.question?.id}
                submissionId={question.submission?._id}
                user={user}
                isOpen={isOpen}
                numberQuestion={question?.question?.order}
                onClose={onClose}
                title={question?.question?.title}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
}
