import { format, formatISO, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { ChangeEvent } from "react";

export function formatDate(date?: string | Date | null) {
  if (date) {
    return format(
      date instanceof Date ? date : parseISO(date),
      "dd/MM/yyyy 'às' HH:mm",
      { locale: ptBR }
    );
  }

  return date;
}

export function formatHour(
  date?: string | Date | null,
  options?: {
    fullDate?: boolean;
    mask?: string;
  }
) {
  if (date) {
    return format(date instanceof Date ? date : parseISO(date), "HH:mm", {
      locale: ptBR,
    });
  }

  return date;
}

export function showDatePickerValue(
  date: string | Date | undefined | null
): Date | undefined | null {
  if (typeof date === "string") {
    return parseISO(date);
  }

  return date;
}

export function eventParseISO(date: ChangeEvent<HTMLInputElement>) {
  if (date instanceof Date) {
    return formatISO(date as Date);
  }

  return null;
}

export function formatNamesList(list: string[]) {
  // @ts-ignore
  const formatedList = new Intl.ListFormat("pt-br", {
    style: "long",
    type: "conjunction",
  }).format(list);

  if (formatedList === "") {
    return null;
  }

  return formatedList;
}

export function formatDecimal(number: number) {
  // @ts-ignore
  const formatedNumber = new Intl.NumberFormat("pt-br", {
    style: "decimal",
  }).format(number);

  if (!number) {
    return null;
  }

  return formatedNumber;
}
