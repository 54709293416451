import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FooterMobileProps } from "./props";

export function FooterMobile({
  numberQuestions,
  currentQuestion,
  onChangePrevious,
  onChangeNext,
  disabled,
}: FooterMobileProps) {
  return (
    <Flex justifyContent="space-between" mx={4} alignItems="center">
      <Button
        variant="outline"
        width="3.438rem"
        height="3.5rem"
        borderRadius="55px"
        opacity={currentQuestion === 1 ? 0 : 1}
        onClick={onChangePrevious}
      >
        <Icon as={FiChevronLeft} bgColor="secondary" color="secondary" />
      </Button>
      <Text>
        {currentQuestion}/{numberQuestions}
      </Text>
      <Button
        disabled={disabled}
        px="25px"
        onClick={onChangeNext}
        width="3.438rem"
        height="3.5rem"
        borderRadius="55px"
      >
        <Icon as={FiChevronRight} bgColor="secondary" color="secondary" />
      </Button>
    </Flex>
  );
}
