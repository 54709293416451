import { Modal as ChakraModal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { ModalComponent } from "./props";

export function Modal({ isOpen, onClose, children, isBottom = false, ...rest }: ModalComponent) {
  return (
    <ChakraModal
      isCentered
      autoFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        style={isBottom ? {
          alignSelf: "end",
          margin: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        } : {}}
      >
        {children}
      </ModalContent>
    </ChakraModal>
  );
}