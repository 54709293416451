import { Flex, Spinner } from "@chakra-ui/react";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { useAuth } from "contexts/auth/useAuth";
import { GroupProvider } from "contexts/group/useGroup";
import { useGroup } from "hooks/group";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserList } from "./UserList";

export function Users() {
  const { id } = useParams();
  const { data: group, isLoading } = useGroup(id);
  const navigate = useNavigate();
  const { user } = useAuth();


  useEffect(() => {

    if (!user?.roles.includes("admin")) {
      navigate("/");
    }

  }, [user]);

  return (
    <GroupProvider>
      <Header title="Usuários" />

      <Flex gap={4} overflow="auto" h="100%">
        {/* <LeftSide /> */}

        {isLoading ? (
          <Paper flex={3} p={8} align="center" justify="center">
            <Spinner />
          </Paper>
        ) : (
          <Paper flex={3} p={8} overflow="auto">
            <UserList />
            {/* {group ? (
              <UserList />
            ) : (
              <EmptyContent
                message="Nenhum usuario encontrado."
                icon={MdOutlineManageAccounts}
              />
            )
            } */}
          </Paper>
        )}
      </Flex>
    </GroupProvider>
  );
}
