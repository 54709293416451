import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { MobileFeaturesModal } from "components/Layout/Dashboard/MobileFeaturesModal";
import { useToast } from "contexts/layout/toast/useToast";
import {
  CreateUser,
  Credentials,
  User,
  UserFormRequest,
} from "interfaces/User";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { authWithGoogle, getMe, signIn, signUp } from "services/auth";
import { updateUser, updateUserImage } from "services/user";
import { AuthContextData, AuthProviderProps } from "./props";

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const {
    isOpen: isMobileFeatureModalOpen,
    onOpen: onMobileFeatureModalOpen,
    onClose: onMobileFeatureModalClose,
  } = useDisclosure();

  const [user, setUser] = useState<User | null>(null);

  const storagedToken = localStorage.getItem("@arbo:accessToken");
  const storagedShowMobileFeatures = localStorage.getItem(
    "@arbo:showMobileFeatures"
  );

  useEffect(() => {
    if (!isLargerThan1080 && storagedShowMobileFeatures === "true") {
      onMobileFeatureModalOpen();
    } else {
      localStorage.setItem("@arbo:showMobileFeatures", "false");
    }
  }, [isLargerThan1080, storagedShowMobileFeatures]);

  function useMe() {
    return useQuery(["me"], getMe, {
      enabled: Boolean(storagedToken),
      staleTime: 1000 * 30, // 30 seconds
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (user) => {
        setUser(user);
      },
    });
  }

  const { isLoading } = useMe();

  function useAuthWithGoogle() {
    return useMutation(
      (response: GoogleLoginResponse | GoogleLoginResponseOffline) =>
        authWithGoogle((response as GoogleLoginResponse).tokenId),
      {
        onSuccess: (data) => {
          setUser(data.user);
          localStorage.setItem("@arbo:accessToken", data.accessToken);
          localStorage.setItem("@arbo:showMobileFeatures", "true");
          queryClient.invalidateQueries();
        },
        onError: () => {
          toast("Ocorreu um erro ao entrar com o Google.", "error");
        },
      }
    );
  }

  function useSignIn() {
    return useMutation(
      ({ email, password }: Credentials) => signIn({ email, password }),
      {
        onSuccess: (data) => {
          setUser(data.user);
          localStorage.setItem("@arbo:accessToken", data.accessToken);
          localStorage.setItem("@arbo:showMobileFeatures", "true");
          queryClient.invalidateQueries();
        },
        onError: () => {
          toast("Email ou senha inválidos.", "error");
        },
      }
    );
  }

  function useSignUp() {
    return useMutation((user: CreateUser) => signUp(user), {
      onSuccess: (data) => {
        localStorage.setItem("@arbo:accessToken", data.accessToken);
        toast("Conta criada com sucesso.", "success");
        queryClient.invalidateQueries();
      },
      onError: () => {
        toast("Ocorreu um erro ao criar conta.", "error");
      },
    });
  }

  function useUpdateUser() {
    return useMutation((user: UserFormRequest) => updateUser(user), {
      onSuccess: (user) => {
        toast("Perfil atualizado com sucesso.", "success");
        setUser(user);
        navigate(`/${user.username}`);
      },
      onError: () => {
        toast("Ocorreu um erro ao atualizar o perfil.", "error");
      },
    });
  }

  function useUpdateUserImage() {
    return useMutation((formData: FormData) => updateUserImage(formData), {
      onSuccess: ({ url }) => {
        toast("Foto de perfil atualizada com sucesso.", "success");
        if (user) {
          setUser({ ...user, image: url });
        }
      },
      onError: () => {
        toast("Ocorreu um erro ao atualizar foto.", "error");
      },
    });
  }

  function useSignOut() {
    setUser(null);
    navigate("/login");
    localStorage.removeItem("@arbo:accessToken");
    queryClient.invalidateQueries();
    queryClient.clear();
  }

  const AuthContextValues: AuthContextData = useMemo(
    () => ({
      user,
      isLoading,
      useSignIn,
      useSignUp,
      useAuthWithGoogle,
      useMe,
      useUpdateUser,
      useSignOut,
      useUpdateUserImage,
    }),
    [
      user,
      isLoading,
      useSignIn,
      useSignUp,
      useAuthWithGoogle,
      useMe,
      useUpdateUser,
      useSignOut,
      useUpdateUserImage,
    ]
  );

  return (
    <AuthContext.Provider value={AuthContextValues}>
      {children}

      <MobileFeaturesModal
        isOpen={isMobileFeatureModalOpen}
        onClose={() => {
          localStorage.setItem("@arbo:showMobileFeatures", "false");
          onMobileFeatureModalClose();
        }}
      />
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
