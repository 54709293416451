import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Modal: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      bg: "blackAlpha.400"
    },
    dialog: {
      mx: [6, 0],
      borderRadius: 20,
      bg: "background"
    },
    header: {
      fontSize: "1.15rem",
      color: "gray.400",
      textAlign: "center",
      mt: 2,
      mx: "auto",
    }
  }
};

export default Modal;