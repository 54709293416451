import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { useMemo } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoMdInformationCircle } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Header } from "components/Layout/Dashboard/Header";
import { endOfMinute, isFuture, isPast } from "date-fns";
import { useEvent, useEventQuestions, useRankingEvent } from "hooks/event";
import { FiAlertCircle, FiUser } from "react-icons/fi";
import { arredondar } from "utils/formatNote";
import { isWithinThePeriod } from "utils/isWithinThePeriod";
import { EmptyContent } from "components/EmptyContent";
import { MdPeople } from "react-icons/md";

export function SeeRanking() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: event } = useEvent(id);
  const { data: questions } = useEventQuestions(id);
  const { data: participantsRanking, isLoading } = useRankingEvent(id);

  const currentStage = useMemo(() => {
    if (event?.isFinished) {
      return "Finalizado";
    }

    if (
      event?.responsePeriodEnd &&
      isFuture(new Date(event?.responsePeriodStart))
    ) {
      return "Inscrição";
    }

    if (
      isWithinThePeriod(event?.responsePeriodStart, event?.responsePeriodEnd)
    ) {
      return "Resposta";
    }

    if (
      isWithinThePeriod(
        event?.correctionPeriodStart,
        event?.correctionPeriodEnd
      )
    ) {
      return "Correção";
    }

    if (isWithinThePeriod(event?.appealPeriodStart, event?.appealPeriodEnd)) {
      return "Recurso";
    }

    return "Em espera";
  }, [event]);

  return (
    <>
      <Header
        title="Gerenciador de Evento"
        goback={`/meus-eventos/${id}/gerenciador`}
      />
      <Flex
        bg="gray.50"
        height="100%"
        borderRadius="20px"
        p="2rem"
        direction="column"
        overflow="auto"
      >
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner color="primary.500" />
          </Flex>
        ) : (
          <>
            <Text fontWeight="700" fontSize="1.5rem" color="black">
              {event?.name} - Ranking
            </Text>
            <Text fontWeight="700" fontSize="1.125rem" color="gray.400">
              Etapa Atual: {currentStage}
            </Text>
            <Flex flex={1} align="flex-start" overflow="auto">
              {participantsRanking?.length === 0 ? (
                <EmptyContent
                  message="Este evento não possui nenhuma correção."
                  icon={MdPeople}
                />
              ) : (
                <Table variant="unstyled">
                  <Thead>
                    <Tr>
                      <Th
                        w={
                          (event && event?.allowPeerCorrection) ||
                            (event && event.moderators.length > 0)
                            ? "auto"
                            : "70%"
                        }
                      >
                        Participantes
                      </Th>

                      {event
                        && !questions?.every((question) => question.type === "MULTIPLE_CHOICE")
                        && (event?.allowPeerCorrection ||
                          event.moderators.length > 0) && (
                          <Th>Corretores</Th>
                        )}

                      {!questions?.every((question) => question.type === "MULTIPLE_CHOICE")
                        && participantsRanking
                        && participantsRanking[0]?.corrections?.map((_, index) => (
                          <Th>
                            <Flex align="center" gap={1}>
                              Resultado {index + 1}{" "}
                              <Icon
                                as={IoMdInformationCircle}
                                w="0.688rem"
                                h="0.688rem"
                                color="gray.200"
                              />
                            </Flex>
                          </Th>
                        ))}
                      <Th>
                        <Flex align="center" gap={1}>
                          Resultado Geral
                          <Icon
                            as={IoMdInformationCircle}
                            w="0.688rem"
                            h="0.688rem"
                            color="gray.200"
                          />
                        </Flex>
                      </Th>
                      <Th>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {participantsRanking?.map((itemRanking) => (
                      <Tr key={itemRanking._id}>
                        <Td>
                          <ParticipantCard
                            name={`${itemRanking.user.firstName} ${itemRanking.user.lastName}`}
                            email={itemRanking.user.email}
                            showEmail
                            user={itemRanking?.user}
                          />
                        </Td>

                        {event
                          && !questions?.every((question) => question.type === "MULTIPLE_CHOICE")
                          && (event?.allowPeerCorrection ||
                            event.moderators.length > 0) && (
                            <Td
                              display="flex"
                              flexDirection="row"
                              position="relative"
                            >
                              {itemRanking?.corrections.map((correction, index) => (
                                <Box
                                  zIndex={index === 1 ? 3 : 0}
                                  position={
                                    index === 1 ? "absolute" : "relative"
                                  }
                                  left={index === 1 ? 8 : 0}
                                  key={correction.corrector.id}
                                >
                                  {correction.corrector.image ? (
                                    <Image
                                      boxSize={10}
                                      objectFit="cover"
                                      src={correction.corrector.image}
                                      alt={`${correction.corrector.firstName} ${correction.corrector.lastName}`}
                                      border="3px solid"
                                      borderColor="background"
                                      borderRadius="full"
                                    />
                                  ) : (
                                    <Flex
                                      bg="gray.200"
                                      align="center"
                                      justify="center"
                                      border="3px solid"
                                      borderColor="background"
                                      borderRadius="full"
                                      w="48px"
                                      h="48px"
                                    >
                                      <Icon
                                        as={FiUser}
                                        w="48px"
                                        color="gray.500"
                                      />
                                    </Flex>
                                  )}
                                </Box>
                              ))}
                            </Td>
                          )}

                        {!questions?.every((question) => question.type === "MULTIPLE_CHOICE")
                          && itemRanking?.corrections.map((el) => (
                            <Td key={el.corrector.id}>
                              {el.grade === null ? (
                                event &&
                                  !event.allowModerators &&
                                  !event.allowPeerCorrection &&
                                  isPast(
                                    endOfMinute(new Date(event.responsePeriodEnd))
                                  ) ? (
                                  <Button
                                    variant="link"
                                    as={Link}
                                    to={`/meus-eventos/${id}/corrigir/${itemRanking?._id}`}
                                  >
                                    Corrigir
                                  </Button>
                                ) : event &&
                                  (event.allowModerators ||
                                    event.allowPeerCorrection) &&
                                  isPast(
                                    endOfMinute(
                                      new Date(event.correctionPeriodEnd)
                                    )
                                  ) ? (
                                  <Button
                                    variant="link"
                                    as={Link}
                                    to={`/meus-eventos/${id}/corrigir/${itemRanking?._id}?submissionCorrectionId=${el._id}`}
                                  >
                                    Corrigir
                                  </Button>
                                ) : (
                                  <Text color="gray.400" fontSize="sm">
                                    Sem correção
                                  </Text>
                                )
                              ) : (
                                arredondar(el.grade)
                              )}
                            </Td>
                          ))}

                        <Td>{arredondar(itemRanking.grade)}</Td>

                        <Td>
                          {itemRanking.isCorrected ? (
                            <Flex>
                              <Tooltip
                                label="Correção efetuada"
                                placement="top"
                              >
                                <Flex>
                                  <Icon
                                    as={BsCheckCircleFill}
                                    w="1.25rem"
                                    h="1.25rem"
                                    color="primary.500"
                                  />
                                </Flex>
                              </Tooltip>
                            </Flex>
                          ) : event &&
                            isPast(
                              endOfMinute(new Date(event.responsePeriodEnd))
                            ) &&
                            !questions?.every((question) => question.type === "MULTIPLE_CHOICE")
                            && !event.allowModerators &&
                            !event.allowPeerCorrection ? (
                            <Button
                              variant="link"
                              as={Link}
                              to={`/meus-eventos/${id}/corrigir/${itemRanking?._id}`}
                            >
                              Corrigir
                            </Button>
                          ) : (
                            <Flex>
                              <Tooltip
                                label="Correção pendente"
                                placement="top"
                              >
                                <Flex>
                                  <Icon
                                    as={FiAlertCircle}
                                    w="1.25rem"
                                    h="1.25rem"
                                    color="secondary.500"
                                  />
                                </Flex>
                              </Tooltip>
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Flex>
            <Flex alignSelf="center">
              <Button variant="link" onClick={() => navigate(-1)}>
                <Text fontWeight="700" color="primary.500">
                  Voltar para o Gerenciador de Evento
                </Text>
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
}
