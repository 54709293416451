import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { useAuth } from "contexts/auth/useAuth";
import { useDeleteAccount, useSendDeleteAccountCode } from "hooks/user";
import { DisclosureProps } from "interfaces/common";
import { DeleteAccountData } from "interfaces/User";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineVpnKey } from "react-icons/md";

export function DeleteAccountModal({ isOpen, onClose }: DisclosureProps) {
  const { useSignOut } = useAuth();

  const { mutate: deleteAccount, isLoading, isSuccess } = useDeleteAccount();
  const { mutate: sendDeleteAccountCode } = useSendDeleteAccountCode();

  const {
    formState: { isDirty, errors },
    register,
    handleSubmit,
    reset,
  } = useForm<DeleteAccountData>({
    defaultValues: {
      token: "",
    },
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleDeleteAccount = ({ token }: DeleteAccountData) => {
    deleteAccount(token);
  };

  useEffect(() => {
    if (isSuccess) {
      useSignOut();
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isOpen) sendDeleteAccountCode();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader maxW={400}>
        Tem certeza que deseja excluir sua conta?
        <Text fontWeight={400} fontSize="sm" color="gray.400">
          Lembre-se de que essa ação é irreversível. Confirme com código no seu
          email para realizar essa ação.
        </Text>
      </ModalHeader>

      <ModalCloseButton />

      <Flex
        as="form"
        onSubmit={handleSubmit(handleDeleteAccount)}
        direction="column"
      >
        <ModalBody display="flex" flexDir="column" gap={4} w="85%" mx="auto">
          <FormControl isInvalid={!!errors.token}>
            <FormLabel>Insira um código</FormLabel>

            <InputGroup>
              <InputLeftElement
                children={<MdOutlineVpnKey />}
                color={errors.token ? "error" : "primary.500"}
              />
              <Input placeholder="••••••••" {...register("token")} />
            </InputGroup>

            <FormErrorMessage>{errors.token?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent="space-between" w="85%" mx="auto">
          <Button onClick={handleClose} variant="link" colorScheme="gray">
            Não!
          </Button>

          <Button
            type="submit"
            colorScheme="secondary"
            px={6}
            isDisabled={!isDirty}
            isLoading={isLoading}
          >
            Sim, excluir
          </Button>
        </ModalFooter>
      </Flex>
    </Modal>
  );
}
