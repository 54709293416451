import { Flex, Image, Text } from "@chakra-ui/react";
import crown from "assets/crown.svg";
import { useAuth } from "contexts/auth/useAuth";
import { useEvent } from "hooks/event";
import { useParams } from "react-router-dom";
import { makeFullName } from "utils/makeFullName";
import { OwnerInfoProps } from "./props";

export function OwnerInfo({ loggedUserIsTheOwner }: OwnerInfoProps) {
  const { id } = useParams();

  const { data: event } = useEvent(id);
  const { user } = useAuth();

  const owner = loggedUserIsTheOwner ? user : event?.owner;

  return (
    <Flex align="center" gap={4}>
      <Image src={crown} w={34} h={34} />

      <Text fontWeight={700} color="gray.300">
        {makeFullName(owner?.firstName, owner?.lastName)}{" "}
        {(user?.id === owner?.id || loggedUserIsTheOwner) && "(você)"}
      </Text>
    </Flex>
  );
}
