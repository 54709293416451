import {
  Button,
  ListItem,
  ModalBody,
  ModalFooter,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { RESPONSE_RECOMMENDATIONS } from "constants/texts";
import { Link } from "react-router-dom";
import { formatDate } from "utils/format";

type ResponseRecommendationsModalProps = {
  responsePeriodEnd?: string;
} & ModalProps;

export function ResponseRecommendationsModal({
  responsePeriodEnd,
  isOpen,
  onClose,
}: ResponseRecommendationsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalBody
        gap={8}
        display="flex"
        flexDirection="column"
        pt={8}
        px={8}
        pb={0}
      >
        <Text
          fontSize="1.125rem"
          maxW={350}
          textAlign="center"
          alignSelf="center"
        >
          Algumas recomendações necessárias, antes de responder a este evento.
        </Text>
        <UnorderedList pl={2}>
          {RESPONSE_RECOMMENDATIONS.map((recomendation, index) => (
            <ListItem key={recomendation}>
              {recomendation}{" "}
              {index === 5 && `${formatDate(responsePeriodEnd)}.`}
            </ListItem>
          ))}
        </UnorderedList>
      </ModalBody>

      <ModalFooter justifyContent="space-between" my={4} mx={4}>
        <Button variant="link" onClick={onClose}>
          Cancelar
        </Button>

        <Link to="quiz">
          <Button colorScheme="primary" onClick={onClose}>
            Prosseguir
          </Button>
        </Link>
      </ModalFooter>
    </Modal>
  );
}
