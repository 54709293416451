import {
  Button,
  Flex,
  Grid,
  IconButton,
  Image,
  Spinner,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { CallsParticipantsCard } from "components/Cards/CallsParticipantsCard";
import { EventStatisticsCard } from "components/Cards/EventStatisticsCard";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { isFuture } from "date-fns";
import { useAppealsEvent, useDashBoardEvent, useEvent } from "hooks/event";
import { Question } from "interfaces/event";
import { useMemo, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { isWithinThePeriod } from "utils/isWithinThePeriod";
import dashboardCalls from "../../assets/calls-dashboard-event.svg";
import { EndEvent } from "./EndEvent";

export function EventManagement() {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isDesktop = useBreakpointValue({ lg: "none" });
  const { data: event, isLoading } = useEvent(id);
  const { data: dashboardEvent } = useDashBoardEvent(id);
  const { data: appeals } = useAppealsEvent(id, page);

  const currentStage = useMemo(() => {
    if (event?.isFinished) {
      return "Finalizado";
    }

    if (
      event?.responsePeriodStart &&
      isFuture(new Date(event?.responsePeriodStart))
    ) {
      return "Inscrição";
    }

    if (
      isWithinThePeriod(event?.responsePeriodStart, event?.responsePeriodEnd)
    ) {
      return "Resposta";
    }

    if (
      isWithinThePeriod(
        event?.correctionPeriodStart,
        event?.correctionPeriodEnd
      )
    ) {
      return "Correção";
    }

    if (isWithinThePeriod(event?.appealPeriodStart, event?.appealPeriodEnd)) {
      return "Recurso";
    }

    return "Em espera";
  }, [event]);

  return (
    <Flex direction="column" gap={4} h="100%">
      <Header title="Gerenciador de Evento" goback={`/meus-eventos/${id}`} />

      <Paper px={8} py={12} gap={4} overflow="auto">
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          <>
            <Flex
              justifyContent="space-between"
              w="100%"
              direction={isDesktop ? "row" : "column"}
            >
              <Text fontSize="1.5rem" fontWeight="800" color="black">
                {event?.name}
              </Text>
              <Flex direction="row" gap={4}>
                {event?.isFinished ? (
                  <Button
                    leftIcon={<MdOutlineCheckCircleOutline />}
                    _hover={{ bg: "primary.500" }}
                    _active={{ bg: "primary.500" }}
                    cursor="default"
                  >
                    Evento finalizado
                  </Button>
                ) : (
                  <Button variant="outlineSecondary" onClick={onOpen}>
                    Finalizar evento
                  </Button>
                )}

                {!event?.isFinished &&
                  event?.responsePeriodStart &&
                  isFuture(new Date(event?.responsePeriodStart)) && (
                    <IconButton
                      aria-label="Ir para a página de edição do evento"
                      icon={<FiEdit />}
                      as={Link}
                      to={`/meus-eventos/${id}/editor`}
                    />
                  )}
              </Flex>
            </Flex>
            <Text fontSize="1.125rem" fontWeight="700" color="gray.400">
              Etapa Atual: {currentStage}
            </Text>
            <Flex
              justifyContent="space-between"
              w="100%"
              direction={isDesktop ? "row" : "column"}
              alignItems="center"
              gap={8}
            >
              {dashboardEvent && (
                <EventStatisticsCard
                  redirect="participantes"
                  nameButton="Ver Participantes"
                  numberParticipants={dashboardEvent.participantsCount}
                  title="Participantes neste evento"
                />
              )}
              {dashboardEvent &&
                event?.responsePeriodStart &&
                event?.responsePeriodEnd && (
                  <EventStatisticsCard
                    enable={
                      currentStage !== "Inscrição"
                        ? true
                        : isWithinThePeriod(
                            event.responsePeriodStart,
                            event.responsePeriodEnd
                          )
                    }
                    redirect="respostas"
                    nameButton="Ver Respostas"
                    numberParticipants={dashboardEvent.submissionsCount}
                    title="Respostas neste evento"
                  />
                )}
              {dashboardEvent &&
                event?.correctionPeriodStart &&
                event?.correctionPeriodEnd && (
                  <EventStatisticsCard
                    enable={
                      currentStage !== "Inscrição"
                        ? true
                        : isWithinThePeriod(
                            event?.correctionPeriodStart,
                            event?.correctionPeriodEnd
                          )
                    }
                    redirect="ranking"
                    nameButton="Ver Ranking"
                    numberParticipants={
                      dashboardEvent.uncorrectedSubmissionsCount
                    }
                    title="Respostas não corrigidas"
                  />
                )}
            </Flex>
            <Flex
              border="1px solid "
              borderColor="secondary.500"
              direction="column"
              w="100%"
              borderRadius="20px"
              px={6}
              py={4}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Text
                  fontSize="1.125em"
                  fontWeight="700"
                  color="gray.500"
                  mb="1rem"
                >
                  Chamados
                </Text>
              </Flex>
              {appeals && appeals.length > 0 ? (
                <Flex direction="column" gap={4} h="100%">
                  <Grid
                    templateColumns={
                      appeals?.length === 0
                        ? "1fr"
                        : "repeat(auto-fill, minmax(500px, 1fr))"
                    }
                    gap={6}
                    justifyItems="center"
                    alignItems={appeals?.length === 0 ? "center" : "flex-start"}
                    h="100%"
                    w="100%"
                  >
                    {appeals?.map((appeal) => (
                      <CallsParticipantsCard
                        redirect={`${appeal._id}`}
                        name={`${appeal.user.firstName} ${appeal.user.lastName}`}
                        numberQuestions={(appeal.question as Question).order}
                        image={appeal.user?.image as string}
                      />
                    ))}
                  </Grid>
                  <Flex w="100%" justifyContent="center">
                    {/* <PaginationBar {...appeals?.meta} setPage={setPage} /> */}
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  h="100%"
                  gap={4}
                >
                  <Image
                    src={dashboardCalls}
                    width={{ base: "150px", lg: "170px" }}
                    height={{ base: "130px", lg: "130px" }}
                  />
                  <Text
                    fontSize="0.875em"
                    color="gray.600"
                    w="29%"
                    textAlign="center"
                  >
                    Não há chamados para serem atendidos Caso tenham chamado,
                    eles irão aparecer aqui
                  </Text>
                </Flex>
              )}
            </Flex>
            <EndEvent isOpen={isOpen} onClose={onClose} />
          </>
        )}
      </Paper>
    </Flex>
  );
}
