import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Radio: ComponentStyleConfig = {
  baseStyle: {
    label:{fontSize:"0.875rem", color:"black"},
    control: {
      borderColor: "primary.500",

      _checked: {
        bg: "background",
        borderColor: "primary.500",

        _hover: {
          borderColor: "primary.500",
          bg:"background",
        },

        _before: {
          content: "\"\"",
          display: "inline-block",
          pos: "relative",
          w: "80%",
          h: "80%",
          borderRadius: "full",
          bg: "primary.500",
        },
      },
      _focus: {
        boxShadow: "none",
      },
    },
  },
};

export default Radio;
