import { Button, Flex } from "@chakra-ui/react";
import { useEvent as useEventContext } from "contexts/event/useEvent";
import { useToast } from "contexts/layout/toast/useToast";
import { isFuture } from "date-fns";
import {
  useCreateEvent,
  useEditEvent, useEventQuestions,
  useUploadEventImage
} from "hooks/event";
import {
  Attachment,
  MultipleChoiceQuestion,
  OpenQuestion
} from "interfaces/event";
import { User } from "interfaces/User";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { uploadQuestionImage } from "services/event";
import { prepareFormData } from "utils/prepareFormData";
import { eventSetupSchema } from "../Settings/schema";
import { Step } from "./Step";

export function LeftSide() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { toast } = useToast();

  const {
    event,
    eventSettingsForm,
    eventQuestionsAndAnswersForm,
    questionsToRemove,
    steps,
  } = useEventContext();

  const { data: questionsFromRequest } = useEventQuestions(id);

  const {
    mutate: createEvent,
    isLoading: isCreateLoading,
    data: eventCreated,
    isSuccess: isCreateSuccess,
  } = useCreateEvent();

  const {
    mutate: editEvent,
    isLoading: isEditLoading,
    isSuccess: isEditSuccess,
  } = useEditEvent();

  const {
    mutate: uploadEventImage,
    isLoading: isUploadEventImageLoading,
    isSuccess: isUploadEventImageSuccess,
  } = useUploadEventImage(id ?? eventCreated?.id);

  useEffect(() => {
    if (
      ((isCreateSuccess || isEditSuccess) &&
        (typeof event.image === "string" || !event.image)) ||
      (event.image instanceof File && isUploadEventImageSuccess)
    ) {
      navigate(`/meus-eventos/${id ?? eventCreated?.id}`);
    }
  }, [isCreateSuccess, isEditSuccess, isUploadEventImageSuccess]);

  useEffect(() => {
    if (
      eventSettingsForm.getValues().image &&
      eventSettingsForm.getValues().image instanceof File &&
      (isCreateSuccess || isEditSuccess) &&
      (event.isDraft || id || eventCreated?.id)
    ) {
      uploadEventImage(eventSettingsForm.getValues().image as File);
    }
  }, [isCreateSuccess, isEditSuccess, id, eventCreated?.id]);

  const handleSaveEvent = async () => {
    const validateEvent = Promise.all([
      eventSetupSchema.validate(eventSettingsForm?.getValues()),
    ]);

    validateEvent
      .then(async (resolvedData) => {
        const [eventResolved] = resolvedData;

        const moderators = (eventResolved.moderators as User[])?.map(
          ({ email }) => email
        );

        const questions = [];

        if (steps.includes("Q&A")) {
          const questionsListWithRemovedQuestions = eventQuestionsAndAnswersForm
            .getValues()
            .questions?.concat(id ? questionsToRemove : []) as Array<
              OpenQuestion | MultipleChoiceQuestion
            >;

          for (const question of questionsListWithRemovedQuestions) {
            let questionId;

            if (id) {
              if (
                (event.questions?.some(({ id }) => id === question?.id) ||
                  questionsToRemove?.some(({ id }) => id === question?.id)) &&
                questionsFromRequest?.some(({ id }) => id === question?.id)
              ) {
                questionId = question?.id;
              }
            }

            if (question.files?.length) {
              const attachements = question.files.reduce(
                (previousFile, currentFile) =>
                  question.title.includes(currentFile.url)
                    ? [...previousFile, currentFile]
                    : previousFile,
                [] as Attachment[]
              );

              for (const { file, url } of attachements) {
                const formData = new FormData();
                formData.append("file", file);

                // eslint-disable-next-line no-await-in-loop
                const { url: imageUrl } = await uploadQuestionImage(formData);

                question.title = question.title.replace(url, imageUrl);
              }
            }

            const questionChoices = [];

            if (question?.type === "MULTIPLE_CHOICE") {
              const { choices } = question;

              for (const choice of choices) {
                let choiceId;

                if (id) {
                  if (
                    (
                      questionsFromRequest?.find(
                        (questionId) => questionId.id === question.id
                      ) as MultipleChoiceQuestion
                    )?.choices?.some(({ id }) => id === choice.id)
                  ) {
                    choiceId = question.id;
                  } else {
                    choiceId = undefined;
                  }
                }

                questionChoices.push({
                  ...choice,
                  id: choiceId as string,
                });
              }
            }

            questions.push({
              ...question,
              choices: questionChoices,
              id: questionId,
              isNullified: undefined,
              updatedAt: undefined,
              createdAt: undefined,
              remove: id ? question.remove : undefined,
              files: undefined,
            });
          }
        }

        const eventBaseRequestData = {
          ...prepareFormData({
            ...eventResolved,
            moderators:
              moderators?.length === 0 ? event.moderators : moderators,
          }),
          questions,
          distributionOccurred: undefined,
          owner: undefined,
          image: undefined,
          currentDate: undefined,
          weightOfQuestions: Number(event?.weightOfQuestions),
        };

        if (id) {
          editEvent({
            ...eventBaseRequestData,
            appealPeriodStart: undefined,
            appealPeriodEnd: undefined,
            code: undefined,
            createdAt: undefined,
            participants: undefined,
            participantsCount: undefined,
            updatedAt: undefined,
            isFinished: undefined,
            eventStartNotified: undefined,
            correctionStartNotified: undefined,
            linkedGroup: undefined,
            appealPeriodNotified: undefined,
          });
        } else {
          createEvent(eventBaseRequestData);
        }
      })
      .catch(async (e) => {
        // console.log(e);
        await eventSettingsForm.trigger([
          "name",
          "responsePeriodStart",
          "responsePeriodEnd",
          "correctionPeriodStart",
          "correctionPeriodEnd",
        ]);

        await eventQuestionsAndAnswersForm.trigger(["questions"]);

        toast(
          "Há campos obrigatórios a ser preenchidos ou inválidos.",
          "warning"
        );
      });
  };

  return (
    <Flex direction="column" justify="space-between" position="sticky" top={0}>
      <Flex direction="column" gap={4}>
        <Step
          index="1"
          title="Configure seu Evento"
          to="configuracoes"
          tag="SETUP"
        />
        <Step
          index="2"
          title="Perguntas e Respostas"
          to="perguntas-e-respostas"
          tag="Q&A"
        />
        <Step
          index="3"
          title="Publique seu Evento"
          to="publicar"
          tag="PUBLISH"
        />

        {event.isDraft && (
          <Button
            variant="link"
            mt={4}
            onClick={handleSaveEvent}
            isLoading={
              isCreateLoading || isEditLoading || isUploadEventImageLoading
            }
            isDisabled={
              !(
                event.responsePeriodStart &&
                isFuture(new Date(event.responsePeriodStart))
              ) && !!id
            }
          >
            Salvar rascunho
          </Button>
        )}
      </Flex>

      <Button
        variant="link"
        colorScheme="secondary"
        mb={4}
        as={Link}
        to="/meus-eventos"
      >
        Cancelar
      </Button>
    </Flex>
  );
}
