import { Badge, Flex, Image, Text, Tooltip } from "@chakra-ui/react";
import circleChecked from "assets/circle-checked.svg";
import circleOutline from "assets/circle-outline.svg";
import crownModerator from "assets/crown-moderator.svg";
import crown from "assets/crown.svg";
import { NoCover } from "components/NoCover";
import { useAuth } from "contexts/auth/useAuth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "utils/format";
import { CardFlag, EventCardProps } from "./props";

export function EventCard({ ...event }: EventCardProps) {
  const { user } = useAuth();

  const [cardFlag, setCardFlag] = useState<CardFlag | undefined>(undefined);

  useEffect(() => {
    if (user) {
      if (event?.moderators?.includes(user?.id as any)) {
        setCardFlag({
          image: crownModerator,
          description: "Você é moderador deste evento.",
        });
      } else if (event.participants?.includes(user.id)) {
        setCardFlag({
          image: circleChecked,
          description: "Você é participante deste evento.",
        });
      } else if (event.owner === user.id) {
        setCardFlag({
          image: crown,
          description: "Você é proprietário deste evento.",
        });
      } else {
        setCardFlag({
          image: circleOutline,
          description: "Você não é participante deste evento.",
        });
      }
    }
  }, [user]);

  return (
    <Flex
      direction="column"
      borderRadius={20}
      bg="gray.50"
      w="100%"
      as={Link}
      to={`/meus-eventos/${event.id}`}
      border="1px solid #EBEBEB"
    >
      <Flex h={108}>
        {event.image ? (
          <Image
            src={event.image}
            objectFit="cover"
            borderTopRadius={20}
            w="100%"
            h="100%"
          />
        ) : (
          <NoCover />
        )}
      </Flex>

      <Flex p={4} justify="space-between">
        <Flex direction="column" gap={2}>
          <Flex direction="column" color="gray.500">
            <Text fontWeight={700} fontSize="sm">
              {event.name}
            </Text>
            <Text fontSize="xs">
              Data de início: {formatDate(event.responsePeriodStart)}
            </Text>
            <Text fontSize="xs">
              Data de conclusão: {formatDate(event.appealPeriodEnd)}
            </Text>
          </Flex>

          <Flex h={18} gap={1}>
            {event.categories?.map((category) => (
              <Badge key={category.name} bg="secondary.500">
                {category.name}
              </Badge>
            ))}
          </Flex>
        </Flex>

        <Flex direction="column" justify="space-between" align="end">
          <Flex>
            {event.isDraft && <Badge variant="draft">Rascunho</Badge>}
          </Flex>

          <Tooltip label={cardFlag?.description} placement="top">
            <Image src={cardFlag?.image} w={9} h={9} />
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
}
