import { Flex, Spinner } from "@chakra-ui/react";
import { EmptyContent } from "components/EmptyContent";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { useAuth } from "contexts/auth/useAuth";
import { GroupProvider } from "contexts/group/useGroup";
import { useGroup } from "hooks/group";
import { useEffect } from "react";
import { MdOutlineManageAccounts } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { MyAccountDetails } from "./MyAccountDetails";

export function MyAccount() {
  const { id } = useParams();
  const { data: group, isLoading } = useGroup("655984c7d1a6afc130bf52df");
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {

    if (!user?.roles.includes("account-manager")) {
      navigate("/");
    }

  }, [user]);

  return (
    <GroupProvider>
      <Header title="Minha conta" />

      <Flex gap={4} overflow="auto" h="100%">

        {isLoading ? (
          <Paper flex={3} p={8} align="center" justify="center">
            <Spinner />
          </Paper>
        ) : (
          <Paper flex={3} p={8} overflow="auto">
            {group ? (
              <MyAccountDetails />
            ) : (
              <EmptyContent
                message="Nenhuma conta encontrada."
                icon={MdOutlineManageAccounts}
              />
            )}
          </Paper>
        )}
      </Flex>
    </GroupProvider>
  );
}
