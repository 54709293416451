import { validateCPF } from "validations-br";
import * as yup from "yup";

const editUserSchema = yup.object().shape({
  fullName: yup.string().required("O nome completo é obrigatório."),
  email: yup
    .string()
    .email("E-mail inválido.")
    .required("O e-mail é obrigatório."),
  username: yup.string().required("O nome de usuário é obrigatório."),
  cpf: yup
    .string()
    .test("validate CPF", "CPF inválido.", (cpf) =>
      cpf !== "" ? validateCPF(String(cpf)) : true
    ),
  dateOfBirth: yup.date().nullable(),
  aboutMe: yup.string().nullable(),
  lattes: yup.string().url("Link inválido.").nullable(),
  linkedIn: yup.string().url("Link inválido.").nullable(),
  educations: yup.array().of(
    yup.object().shape(
      {
        title: yup.string().when(["instituition", "startDate", "endDate"], {
          is: (instituition: string, startDate: string, endDate: string) =>
            instituition?.length || startDate?.length || endDate?.length,
          then: yup
            .string()
            .required("O título da formação acadêmica é obrigatório."),
        }),
        institution: yup.string().when(["title", "startDate", "endDate"], {
          is: (title: string, startDate: string, endDate: string) =>
            title?.length || startDate?.length || endDate?.length,
          then: yup
            .string()
            .required("A instituição de formação é obrigatória."),
        }),
        startDate: yup
          .string()
          .nullable()
          .when(["title", "instituition", "endDate"], {
            is: (title: string, instituition: string, endDate: string) =>
              title?.length || instituition?.length || endDate?.length,
            then: yup
              .string()
              .nullable()
              .required("A data inicial dessa formação é obrigatória."),
          }),
        endDate: yup
          .string()
          .nullable()
          .when(["title", "instituition", "startDate"], {
            is: (title: string, instituition: string, startDate: string) =>
              startDate?.length || instituition?.length || title?.length,
            then: yup
              .string()
              .nullable()
              .required("A data final dessa formação é obrigatória."),
          }),
      },
      [
        ["title", "instituition"],
        ["title", "startDate"],
        ["title", "endDate"],

        ["instituition", "title"],
        ["instituition", "startDate"],
        ["instituition", "endDate"],

        ["startDate", "title"],
        ["startDate", "instituition"],
        ["startDate", "endDate"],

        ["endDate", "title"],
        ["endDate", "instituition"],
        ["endDate", "startDate"],
      ]
    )
  ),
  links: yup.array().of(
    yup.object().shape(
      {
        title: yup.string().when("url", {
          is: (url: string) => url?.length,
          then: yup.string().required("O título do link é obrigatório."),
        }),
        url: yup
          .string()
          .url("Link inválido")
          .when("title", {
            is: (title: string) => title?.length,
            then: yup.string().url("Link inválido").required("A url do link é obrigatória."),
          }),
      },
      [
        ["title", "url"],
        ["url", "title"],
      ]
    )
  ),
});

export default editUserSchema;
