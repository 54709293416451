import { Badge, Box, Button, Flex, Icon, Spinner, useDisclosure } from "@chakra-ui/react";
import {
  useAddGroupParticipant,
  useDeleteGroupParticipant,
  useGroupPartcipants
} from "hooks/group";
import { useEffect, useState } from "react";
import { createColumn, Table } from "react-chakra-pagination";
import { FormProvider, useForm } from "react-hook-form";
import { CiViewList } from "react-icons/ci";
import { MdAddCircle, MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { CreateAccountLicenseModal } from "./CreateAccountLicenseModal";


type User = {
  id: number;
  name: string;
  email: string;
  role: string;
  plan: string;
  status: "active" | "inactive" | "blocked";
};

const userList: User[] = [
  {
    id: 1,
    name: "Alexandre",
    email: "alexandre@gmail.com",
    role: "ADMIN",
    plan: "Business",
    status: "active"
  },
  {
    id: 2,
    name: "Alexandre",
    email: "alexandre@gmail.com",
    role: "USER",
    plan: "Business",
    status: "blocked"
  },
  {
    id: 3,
    name: "Alexandre",
    email: "alexandre@gmail.com",
    role: "USER",
    plan: "Business",
    status: "inactive"
  }
];

export function UserList() {
  const { id } = useParams();
  const methods = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const participant = methods.watch("participant", "");
  const [search, setSearch] = useState("");

  // const { data: users } = useUsers();
  const { data: participants, isLoading } = useGroupPartcipants(id);
  const { mutate: deleteParticipant } = useDeleteGroupParticipant(id as string);
  const {
    mutate: addGroupParticipant,
    isSuccess,
    isLoading: isAddGroupParticipantLoading,
  } = useAddGroupParticipant(id as string);

  const statusMap: Record<string, string> = {
    active: "Ativo",
    inactive: "Inativo",
    blocked: "Bloqueado"
  };
  const statusColorScheme: Record<string, string> = {
    active: "green",
    blocked: "yellow",
    inactive: "red"
  };
  const tableData = userList.map(user => ({
    name: user.name,
    email: user.email,
    plan: user.plan,
    role: user.role,
    status: (
      <Box display="flex" justifyContent="start" textAlign="center" alignItems="center">
        <Badge colorScheme={statusColorScheme[user.status]} width="fit-content">
          {statusMap[user.status]}
        </Badge>
      </Box>

    ),
    action: (
      <Flex direction="row" gap={2} align="right">
        <Button
          colorScheme="gray"
          onClick={() => console.log("edit license")}
          size="sm"
        >
          <Icon as={MdOutlineEdit} fontSize="20" />
        </Button>
        <Button
          colorScheme="red"
          onClick={() => console.log("edit license")}
          size="sm"
        >
          <Icon as={MdOutlineDelete} fontSize="20" />
        </Button>
      </Flex>
    ),
  }));

  const columnHelper = createColumn<(typeof tableData)[0]>();
  const columns = [
    columnHelper.accessor("name", {
      cell: info => info.getValue(),
      header: "Nome",
    }),
    columnHelper.accessor("email", {
      cell: info => info.getValue(),
      header: "E-mail",
    }),
    columnHelper.accessor("plan", {
      cell: info => info.getValue(),
      header: "Plano",
    }),
    columnHelper.accessor("role", {
      cell: info => info.getValue(),
      header: "Papel",
    }),
    columnHelper.accessor("status", {
      cell: info => info.getValue(),
      header: "Status",
    }),
    columnHelper.accessor("action", {
      cell: info => info.getValue(),
      header: "Ações",
    }),
  ];

  useEffect(() => {
    if (isSuccess) {
      setSearch("");
    }
  }, [isSuccess]);

  return (
    <Flex direction="column" gap={0}>
      <Flex direction="row-reverse" align="right">
        <FormProvider {...methods}>
          <Flex gap={6} align="right">
            <Button
              leftIcon={<MdAddCircle />}
              onClick={onOpen}
              height={50}
              isLoading={isAddGroupParticipantLoading}
            >
              Criar novo usuário
            </Button>
          </Flex>
        </FormProvider>
      </Flex>

      <Flex direction="column" align="center">
        {isLoading && <Spinner />}

        <Box sx={{ "& > div": { px: 0 }, width: "100%" }}>
          <Table
            colorScheme="green"
            emptyData={{
              icon: CiViewList,
              text: "Nenhuma licença encontrada.",
            }}
            totalRegisters={5}
            onPageChange={page => console.log(page)}
            columns={columns}
            data={tableData}
          />
        </Box>
      </Flex>
      {/* <CreateAccountLicenseModal isOpen={isOpen} onClose={onClose} /> */}
    </Flex>
  );
}
