import { Button, Flex, Text } from "@chakra-ui/react";
import { FooterDesktopProps } from "./props";

export function FooterDesktop({
  numberQuestions,
  currentQuestion,
  onChangePrevious,
  onChangeNext,
  disabled,
  nameNextButtom,
  type,
}: FooterDesktopProps) {
  return (
    <Flex justifyContent="space-between" mx={4} alignItems="center">
      <Button
        variant="link"
        opacity={currentQuestion === 1 ? 0 : 1}
        disabled={currentQuestion === 1}
        onClick={onChangePrevious}
      >
        Voltar
      </Button>
      <Text>
        {currentQuestion}/{numberQuestions}
      </Text>
      <Button type={type} disabled={disabled} px="25px" onClick={onChangeNext}>
        {nameNextButtom}
      </Button>
    </Flex>
  );
}
