import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Progress,
  Skeleton,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { endOfMinute, isPast } from "date-fns";
import {
  useCorrectPeerCorrection,
  useCorrectWithModeratorsOrOwnerRequest,
  useEvent,
  useSubmission,
} from "hooks/event";
import { CorrectionForm, OpenAnswer, OpenQuestion } from "interfaces/event";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function Submission() {
  const { id: eventId, submissionId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  const {
    data: submission,
    isLoading,
    isSuccess,
  } = useSubmission(submissionId);

  const { data: event } = useEvent(eventId);

  const params = new URLSearchParams(search);
  const correctionId = params.get("correctionId") as string;
  const submissionCorrectionId = params.get("submissionCorrectionId") as string;

  const {
    mutate: correctPeerCorrection,
    isLoading: isCorrectPeerCorrectionLoading,
    isSuccess: isCorrectPeerCorrectionSuccess,
  } = useCorrectPeerCorrection(correctionId, eventId);

  const {
    mutate: correctWithModeratorsOrOwner,
    isLoading: isCorrectWithModeratorsOrOwnerLoading,
    isSuccess: isCorrectWithModeratorsOrOwnerSuccess,
  } = useCorrectWithModeratorsOrOwnerRequest(
    submissionId,
    eventId,
    event?.correctionPeriodEnd &&
      isPast(endOfMinute(new Date(event.correctionPeriodEnd)))
      ? submissionCorrectionId
      : undefined
  );

  const openAnswers = (isSuccess &&
    submission?.answers.filter(
      (answer) => (answer as OpenAnswer).openAnswer
    )) as OpenAnswer[];

  const defaultValues: CorrectionForm = {
    corrections: openAnswers
      ? openAnswers.map((answer) => ({
          answerId: answer.id,
          feedback: "",
          grade: "",
        }))
      : [],
  };

  const methods = useForm<CorrectionForm>({
    defaultValues,
  });

  const {
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const [questionIndex, setQuestionIndex] = useState(0);
  const [showErrors, setShowErrors] = useState(false);

  const corrections = watch("corrections");
  const feedback = watch(`corrections.${questionIndex}.feedback`);
  const grade = watch(`corrections.${questionIndex}.grade`);

  useEffect(() => {
    if (isSuccess) reset(defaultValues);
  }, [isSuccess]);

  const toPreviousQuestion = () => {
    if (questionIndex + 1 >= (openAnswers[0].question as OpenQuestion).order) {
      setQuestionIndex((questionIndex) => questionIndex - 1);
    }
  };

  const toNextQuestion = () => {
    if (questionIndex < openAnswers.length - 1) {
      setQuestionIndex((questionIndex) => questionIndex + 1);
      setShowErrors(false);
    }
  };

  useEffect(() => {
    if (
      isCorrectPeerCorrectionSuccess ||
      isCorrectWithModeratorsOrOwnerSuccess
    ) {
      navigate(`/meus-eventos/${eventId}/corrigir`);
    }
  }, [isCorrectPeerCorrectionSuccess, isCorrectWithModeratorsOrOwnerSuccess]);

  useEffect(() => {
    if (errors && showErrors) {
      if (feedback === "") {
        setError(`corrections.${questionIndex}.feedback`, {
          message: "O feedback é obrigatório.",
        });
      } else {
        clearErrors(`corrections.${questionIndex}.feedback`);
      }

      if (
        grade === "" ||
        Number(grade) < 0 ||
        Number(grade) > 100 ||
        Number.isNaN(Number(grade))
      ) {
        if (grade === "") {
          setError(`corrections.${questionIndex}.grade`, {
            message: "A nota é obrigatória.",
          });
        }

        if (
          Number(grade) < 0 ||
          Number(grade) > 100 ||
          Number.isNaN(Number(grade))
        ) {
          setError(`corrections.${questionIndex}.grade`, {
            message: "A nota precisa ser um número inteiro entre 1 e 100.",
          });
        }
      } else {
        clearErrors(`corrections.${questionIndex}.grade`);
      }
    }
  }, [feedback, grade, showErrors, questionIndex, errors]);

  const handleNextQuestion = () => {
    if (
      feedback !== "" &&
      grade !== "" &&
      !Number.isNaN(Number(grade)) &&
      !(Number(grade) < 0) &&
      !(Number(grade) > 100)
    ) {
      if (questionIndex !== openAnswers.length - 1) {
        toNextQuestion();
      } else {
        const submitCorrectionsBaseData = corrections.map((correction) => ({
          ...correction,
          grade: Number(correction.grade),
        }));

        if (event?.allowPeerCorrection) {
          // verify if event is within correction period
          if (isPast(endOfMinute(new Date(event.correctionPeriodEnd)))) {
            correctWithModeratorsOrOwner(submitCorrectionsBaseData, {
              onSuccess() {
                navigate(`/meus-eventos/${eventId}/gerenciador/ranking`);
              },
            });
          } else {
            correctPeerCorrection(submitCorrectionsBaseData);
          }
        } else {
          correctWithModeratorsOrOwner(submitCorrectionsBaseData);
        }
      }
    } else {
      setShowErrors(true);
    }
  };

  return (
    <Flex direction="column" gap={8} w="100%" as="form">
      {!isLargerThan1080 && openAnswers?.length && (
        <Progress
          value={((questionIndex + 1) * 100) / openAnswers.length}
          borderRadius="full"
        />
      )}

      {isVisible && (
        <Alert
          status="warning"
          fontSize="sm"
          variant="left-accent"
          position="relative"
          borderRightRadius={8}
        >
          <AlertIcon />

          <Box>
            <AlertTitle>Atenção</AlertTitle>
            <AlertDescription>Cada nota vale de 0 a 100.</AlertDescription>
          </Box>

          <CloseButton
            alignSelf="flex-start"
            position="absolute"
            right={8}
            top="50%"
            transform="translate(50%,-50%)"
            onClick={onClose}
          />
        </Alert>
      )}

      <Flex direction="column" gap={5} flex={1}>
        <Flex direction="column" gap={8}>
          <Flex direction="column" gap={2}>
            {isLoading ? (
              <Skeleton w={16} h={4} />
            ) : (
              <Heading fontWeight={400} fontSize="sm" color="gray.500">
                Questão{" "}
                {(openAnswers[questionIndex]?.question as OpenQuestion)?.order}
              </Heading>
            )}

            {isLoading ? (
              <Skeleton w={80} h={4} />
            ) : (
              <Box
                fontWeight={700}
                color="gray.400"
                dangerouslySetInnerHTML={{
                  __html: (openAnswers[questionIndex]?.question as OpenQuestion)
                    ?.title,
                }}
              />
            )}
          </Flex>

          <Flex direction="column" gap={4}>
            <Flex direction="column" gap={2}>
              {isLoading ? (
                <Skeleton w={16} h={4} />
              ) : (
                <Heading fontWeight={400} fontSize="sm" color="gray.500">
                  Padrão de Resposta
                </Heading>
              )}

              {isLoading ? (
                <Skeleton w={80} h={4} />
              ) : (
                <Box
                  fontWeight={700}
                  color="gray.400"
                  dangerouslySetInnerHTML={{
                    __html: (
                      openAnswers[questionIndex]?.question as OpenQuestion
                    )?.answerPattern,
                  }}
                />
              )}
            </Flex>

            <Flex direction="column" gap={2}>
              {isLoading ? (
                <Skeleton w={16} h={4} />
              ) : (
                <Heading fontWeight={400} fontSize="sm" color="gray.500">
                  Barema e critérios
                </Heading>
              )}

              {isLoading ? (
                <Skeleton w={80} h={4} />
              ) : (
                <Box
                  fontWeight={700}
                  color="gray.400"
                  dangerouslySetInnerHTML={{
                    __html: (
                      openAnswers[questionIndex]?.question as OpenQuestion
                    )?.criteria,
                  }}
                />
              )}
            </Flex>

            <Flex direction="column" gap={2}>
              {isLoading ? (
                <Skeleton w={16} h={4} />
              ) : (
                <Heading fontWeight={400} fontSize="sm" color="gray.500">
                  Resposta do Aluno
                </Heading>
              )}

              {isLoading ? (
                <Skeleton w={80} h={4} />
              ) : (
                <Box
                  fontWeight={700}
                  color="gray.400"
                  dangerouslySetInnerHTML={{
                    __html: openAnswers[questionIndex]?.openAnswer,
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex gap={7} direction={isLargerThan1080 ? "row" : "column"}>
          <FormControl
            isInvalid={
              errors &&
              errors.corrections &&
              !!errors.corrections[questionIndex]?.feedback
            }
          >
            <FormLabel>Inserir feedback ao participante</FormLabel>
            <Controller
              control={control}
              name={`corrections.${questionIndex}.feedback`}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} placeholder=" " value={feedback} />
              )}
            />

            <FormErrorMessage>
              {errors &&
                errors.corrections &&
                errors.corrections[questionIndex]?.feedback?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            w={isLargerThan1080 ? "40%" : "100%"}
            isInvalid={
              errors &&
              errors.corrections &&
              !!errors.corrections[questionIndex]?.grade
            }
          >
            <FormLabel>Inserir nota</FormLabel>

            <Controller
              control={control}
              name={`corrections.${questionIndex}.grade`}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder=" "
                  value={grade ? String(grade) : ""}
                />
              )}
            />

            <FormErrorMessage>
              {errors &&
                errors.corrections &&
                errors.corrections[questionIndex]?.grade?.message}
            </FormErrorMessage>

            {/* <FormErrorMessage>
              {errors && errors?.grade?.message}
            </FormErrorMessage> */}
          </FormControl>
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center">
        {isLargerThan1080 ? (
          <Button
            variant="link"
            isDisabled={questionIndex === 0}
            onClick={toPreviousQuestion}
          >
            Voltar
          </Button>
        ) : (
          <IconButton
            variant="outline"
            aria-label="Voltar"
            icon={<MdKeyboardArrowLeft />}
            onClick={toPreviousQuestion}
            isDisabled={questionIndex === 0}
            borderRadius="full"
            w={55}
            minW={55}
            h={55}
            minH={55}
          />
        )}

        {isLoading ? (
          <Skeleton h={4} w={12} />
        ) : (
          <Text>
            {questionIndex + 1} / {openAnswers?.length}
          </Text>
        )}

        {isLargerThan1080 ? (
          <Button
            size="lg"
            boxShadow="none"
            px={12}
            onClick={handleNextQuestion}
            isLoading={
              isLoading ||
              isCorrectPeerCorrectionLoading ||
              isCorrectWithModeratorsOrOwnerLoading
            }
          >
            {questionIndex < openAnswers.length - 1
              ? "Prosseguir"
              : "Finalizar"}
          </Button>
        ) : (
          <IconButton
            aria-label="Prosseguir"
            icon={<MdKeyboardArrowRight />}
            onClick={handleNextQuestion}
            isLoading={
              isLoading ||
              isCorrectPeerCorrectionLoading ||
              isCorrectWithModeratorsOrOwnerLoading
            }
            borderRadius="full"
            w={55}
            minW={55}
            h={55}
            minH={55}
          />
        )}
      </Flex>
    </Flex>
  );
}
