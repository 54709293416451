import {
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { EmptyContent } from "components/EmptyContent";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { differenceInBusinessDays, isFuture } from "date-fns";
import { useEvent, useParticipantsEvent } from "hooks/event";
import { useMemo } from "react";
import { MdPeople } from "react-icons/md";
import { Link, useParams } from "react-router-dom";

import { isWithinThePeriod } from "utils/isWithinThePeriod";

export function SeeParticipants() {
  const { id } = useParams();
  const { data: participants, isLoading } = useParticipantsEvent(id);
  const { data: event } = useEvent(id);

  const currentStage = useMemo(() => {
    if (event?.isFinished) {
      return "Finalizado";
    }
    if (
      event?.responsePeriodEnd &&
      isFuture(new Date(event?.responsePeriodStart))
    ) {
      return "Inscrição";
    }

    if (
      isWithinThePeriod(event?.responsePeriodStart, event?.responsePeriodEnd)
    ) {
      return "Resposta";
    }

    if (
      isWithinThePeriod(
        event?.correctionPeriodStart,
        event?.correctionPeriodEnd
      )
    ) {
      return "Correção";
    }

    if (isWithinThePeriod(event?.appealPeriodStart, event?.appealPeriodEnd)) {
      return "Recurso";
    }

    return "Em espera";
  }, [event]);

  return (
    <>
      <Header
        title="Gerenciador de Evento"
        goback={`/meus-eventos/${id}/gerenciador`}
      />

      <Paper
        bg="gray.50"
        height="100%"
        borderRadius="20px"
        p="2rem"
        direction="column"
        overflow="auto"
      >
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner color="primary.500" />
          </Flex>
        ) : (
          <>
            <Text fontWeight="700" fontSize="1.5rem" color="black">
              {event?.name} - Participantes
            </Text>
            <Text fontWeight="700" fontSize="1.125rem" color="gray.400">
              Etapa Atual: {currentStage}
            </Text>

            <Flex flex={1} align="flex-start" overflow="auto">
              {participants?.length === 0 ? (
                <EmptyContent
                  message="Este evento não possui nenhum participante."
                  icon={MdPeople}
                />
              ) : (
                <Table variant="unstyled">
                  <Thead>
                    <Tr>
                      <Th>participantes </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {participants?.map((user) => (
                      <Tr key={user.id}>
                        <Td>
                          <ParticipantCard isLink showEmail user={user} />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Flex>

            <Flex alignSelf="center">
              <Button
                variant="link"
                as={Link}
                to={`/meus-eventos/${id}/gerenciador`}
              >
                <Text fontWeight="700" color="primary.500">
                  Voltar para o Gerenciador de Evento
                </Text>
              </Button>
            </Flex>
          </>
        )}
      </Paper>
    </>
  );
}
