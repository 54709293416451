import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Header } from "components/Layout/Dashboard/Header";
import { EMAIL, HELP_QA } from "constants/texts";
import email from "../../assets/email.svg";

export function Help() {
  const isDesktop = useBreakpointValue({ lg: "none" });
  return (
    <>
      <Header title="Ajuda" />

      <Flex
        w="100%"
        alignItems={isDesktop ? "center" : "flex-start"}
        justifyContent={isDesktop ? "space-between" : "flex-start"}
        direction="column"
        gap="1.5rem"
        overflow="auto"
      >
        <Flex
          bg="background"
          w={isDesktop ? "50%" : "auto"}
          p="1.5rem"
          direction="column"
          gap="4"
          borderRadius="10px"
        >
          <Text fontWeight="700" color="primary.500">
            Dúvidas mais frequentes
          </Text>

          <Accordion w="100%" allowToggle allowMultiple>
            {HELP_QA.map(({ answer, question }) => (
              <AccordionItem>
                <AccordionButton>
                  <Box color="black" flex="1" textAlign="left">
                    {question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  color="gray.500"
                  fontSize="0.75rem"
                  pb={4}
                  dangerouslySetInnerHTML={{ __html: answer }}
                  sx={{
                    a: {
                      color: "primary.500",
                      fontWeight: 700,
                    },
                  }}
                />
              </AccordionItem>
            ))}
          </Accordion>
        </Flex>
        <Flex
          bg="background"
          w={isDesktop ? "50%" : "auto"}
          p="1.5rem"
          borderRadius="10px"
          direction="column"
          gap={4}
        >
          <Text fontWeight="700" color="primary.500">
            Fale conosco
          </Text>
          <Divider />
          <Flex direction="row" alignItems="center" gap={4}>
            <Image src={email} />
            <Link href={`mailto:${EMAIL}`}>{EMAIL}</Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
