import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useReferFriend } from "hooks/user";
import { ReferFriend } from "interfaces/User";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FiMail } from "react-icons/fi";
import referFriendSchema from "./schema";

export function ReferFriendModal({ isOpen, onClose }: ModalProps) {
  const { mutate: referFriend, isLoading, isSuccess } = useReferFriend();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ReferFriend>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(referFriendSchema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  function handleReferFriend({ email }: ReferFriend) {
    referFriend(email);
  }

  useEffect(() => {
    handleClose();
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="sm">
      <ModalHeader maxW={300}>Coloque o e-mail que deseja indicar!</ModalHeader>

      <ModalCloseButton />

      <Flex
        as="form"
        onSubmit={handleSubmit(handleReferFriend)}
        direction="column"
      >
        <ModalBody>
          <FormControl
            variant="floating"
            id="friend-email"
            isInvalid={!!errors.email}
          >
            <InputGroup>
              <InputLeftElement
                children={<FiMail />}
                color={errors.email ? "error" : "primary.500"}
              />
              <Input placeholder=" " {...register("email")} />
              <FormLabel color={errors.email ? "error" : "primary.500"}>
                Indique um amigo
              </FormLabel>
            </InputGroup>

            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            isFullWidth
            isDisabled={!isDirty}
            isLoading={isLoading}
          >
            Indicar
          </Button>
        </ModalFooter>
      </Flex>
    </Modal>
  );
}
