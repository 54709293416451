import { Flex, Spinner } from "@chakra-ui/react";
import { EmptyContent } from "components/EmptyContent";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { useAuth } from "contexts/auth/useAuth";
import { GroupProvider } from "contexts/group/useGroup";
import { useGroup } from "hooks/group";
import { useEffect } from "react";
import { MdOutlineManageAccounts } from "react-icons/md";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { LeftSide } from "./LeftSide";

export function Accounts() {
  const { id } = useParams();
  const { data: group, isLoading } = useGroup(id);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {

    if (!user?.roles.includes("admin")) {
      navigate("/");
    }

  }, [user]);

  return (
    <GroupProvider>
      <Header title="Contas" />

      <Flex gap={4} overflow="auto" h="100%">
        <LeftSide />

        {isLoading ? (
          <Paper flex={3} p={8} align="center" justify="center">
            <Spinner />
          </Paper>
        ) : (
          <Paper flex={3} p={8} overflow="auto">
            {group ? (
              <Outlet />
            ) : (
              <EmptyContent
                message="Nenhuma conta selecionada."
                icon={MdOutlineManageAccounts}
              />
            )}
          </Paper>
        )}
      </Flex>
    </GroupProvider>
  );
}
