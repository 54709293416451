import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useAuth } from "contexts/auth/useAuth";

export function SignOutModal({ isOpen, onClose }: ModalProps) {
  const { useSignOut } = useAuth();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalHeader maxW={300}>
        Tem certeza que deseja desconectar sua conta?
      </ModalHeader>

      <ModalCloseButton />

      <ModalBody textAlign="center" fontSize="sm" maxW={300} mx="auto">
        Para realizar o acesso novamente, deverá informar e-mail e senha.
      </ModalBody>

      <ModalFooter justifyContent="space-between">
        <Button variant="link" onClick={onClose} colorScheme="gray">
          Não!
        </Button>

        <Button colorScheme="secondary" onClick={useSignOut}>
          Sim, sair
        </Button>
      </ModalFooter>
    </Modal>
  );
}
