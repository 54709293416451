import { Flex, Input, Text } from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { makeFullName } from "utils/makeFullName";
import { AutocompleteProps } from "./props";

export function Autocomplete({
  data,
  name,
  isMulti = false,
  search,
  setSearch,
  ...rest
}: AutocompleteProps) {
  const { setValue, watch } = useFormContext();

  const [isFocus, setIsFocus] = useState(false);
  const [options, setOptions] = useState(data);

  const previousData = watch(name);

  useEffect(() => {
    setOptions(
      data?.filter((item) =>
        makeFullName(item.firstName, item.lastName)
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    );
  }, [search]);

  const handleSelectOption = (data: any) => {
    if (isMulti) {
      setValue(name, [...new Set([...previousData, data])]);
      setSearch("");
    } else {
      setSearch(makeFullName(data.firstName, data.lastName));
      setValue(name, data);
    }

    setIsFocus(false);
  };

  return (
    <Flex direction="column" position="relative" flex={1}>
      <Input
        name={name}
        autoComplete="off"
        borderBottomRadius={isFocus && search ? 0 : 10}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          setTimeout(() => setIsFocus(false), 500);
        }}
        {...rest}
      />

      {search !== "" && isFocus && (
        <Flex
          direction="column"
          maxH="40vh"
          overflow="auto"
          border="1px solid"
          borderColor="primary.500"
          borderBottomRadius={10}
          borderTop={0}
          position="absolute"
          bg="background"
          w="100%"
          top="100%"
          zIndex={2}
          pb={2}
        >
          {options?.map((data) => (
            <Flex
              key={data.id}
              onClick={() => handleSelectOption(data)}
              px={4}
              py={2}
              _hover={{ bg: "backgroundGray" }}
            >
              <ParticipantCard user={data} />
            </Flex>
          ))}

          {options?.length === 0 && <Text>Sem opções</Text>}
        </Flex>
      )}
    </Flex>
  );
}
