import { Grid, Spinner } from "@chakra-ui/react";
import { EmptyContent } from "components/EmptyContent";
import { EventCard } from "components/Events/EventCard";
import { useGroupEvents } from "hooks/group";
import { MdOutlineToday } from "react-icons/md";
import { useParams } from "react-router-dom";

export function Events() {
  const { id } = useParams();
  const { data: events, isLoading } = useGroupEvents(id as string);

  return (
    <Grid
      templateColumns={
        isLoading || events?.length === 0
          ? "1fr"
          : "repeat(auto-fill, minmax(315px, 1fr))"
      }
      gap={6}
      justifyItems="center"
      alignItems={isLoading ? "center" : "flex-start"}
      h="100%"
      w="100%"
    >
      {isLoading && <Spinner />}

      {events?.length ? (
        events?.map((event) => <EventCard key={event.id} {...event} />)
      ) : (
        <EmptyContent
          message="Não há eventos para exibir."
          icon={MdOutlineToday}
        />
      )}
    </Grid>
  );
}
