import type { ComponentStyleConfig } from "@chakra-ui/theme";

const IconButton: ComponentStyleConfig = {
  variants: {
    ghost: {
      bg: "transparent",
      _hover: {
        bg: "transparent"
      },
      _active: {
        bg: "transparent"
      }
    }
  }
};

export default IconButton;
