import { CreateUser, Credentials, ResetPasswordProps } from "interfaces/User";
import api from "./api";

export async function getMe() {
  const { data } = await api.get("/me");
  return data;
}

export async function signIn(credentials: Credentials) {
  const { data } = await api.post("/auth/login", credentials);
  return data;
}

export async function signUp(user: CreateUser) {
  const { data } = await api.post("/users", user);
  return data;
}

export async function authWithGoogle(tokenId: string) {
  const { data } = await api.post("/auth/google", {
    id_token: tokenId,
  });
  return data;
}

export async function sendRecoveryPasswordEmail(email: string) {
  const { data } = await api.post("/password/forgot", {
    email,
    redirectUrl: `${process.env.REACT_APP_BASE_URL}/redefinir-senha`,
  });

  return data;
}

export async function resetPassword({
  token,
  password,
  passwordConfirmation,
}: ResetPasswordProps) {
  const { data } = await api.put("/password/reset", {
    token,
    password,
    passwordConfirmation,
  });

  return data;
}
