import { Flex } from "@chakra-ui/react";
import { PaperProps } from "./props";

export function Paper({ children, ...rest }: PaperProps) {
  return (
    <Flex
      direction="column"
      bg={{ base: "background", lg: "#FBFBFB" }}
      borderRadius={20}
      w="100%"
      h="100%"
      {...rest}
    >
      {children}
    </Flex>
  );
}
