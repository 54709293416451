import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Menu: ComponentStyleConfig = {
  baseStyle: {
    list: {
      borderRadius: 20,
    },
    item: {
      _focus: {
        bg: "gray.50",
      },
      _hover: {
        bg: "gray.50",
      },
      _active: {
        bg: "gray.50",
      },
    }
  }
};

export default Menu;