import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "components/Modal";
import { useUpdatePassword } from "hooks/user";
import { DisclosureProps } from "interfaces/common";
import { UpdatePasswordData } from "interfaces/User";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdArrowBack, MdOutlineLock } from "react-icons/md";
import { updatePasswordSchema } from "./schema";

export function UpdatePasswordModal({ isOpen, onClose }: DisclosureProps) {
  const { mutate: updatePassword, isLoading, isSuccess } = useUpdatePassword();

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<UpdatePasswordData>({
    defaultValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(updatePasswordSchema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleUpdatePassword = ({
    currentPassword,
    password,
    passwordConfirmation,
  }: UpdatePasswordData) => {
    updatePassword({ currentPassword, password, passwordConfirmation });
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="sm">
      <ModalHeader maxW={340}>
        Alteração de senha
        <Text fontWeight={400} fontSize="sm" color="gray.400">
          Insira sua senha atual e uma nova senha{" "}
        </Text>
      </ModalHeader>

      <ModalCloseButton />

      <Flex
        as="form"
        onSubmit={handleSubmit(handleUpdatePassword)}
        direction="column"
      >
        <ModalBody display="flex" flexDir="column" gap={4}>
          <FormControl isInvalid={!!errors.currentPassword}>
            <FormLabel>Senha atual</FormLabel>

            <InputGroup>
              <InputLeftElement
                children={<MdOutlineLock />}
                color={errors.currentPassword ? "error" : "primary.500"}
              />
              <Input placeholder="••••••••" {...register("currentPassword")} />
            </InputGroup>

            <FormErrorMessage>
              {errors.currentPassword?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Nova senha</FormLabel>

            <InputGroup>
              <InputLeftElement
                children={<MdOutlineLock />}
                color={errors.password ? "error" : "primary.500"}
              />
              <Input placeholder="••••••••" {...register("password")} />
            </InputGroup>

            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.passwordConfirmation}>
            <FormLabel>Confirmar nova senha</FormLabel>

            <InputGroup>
              <InputLeftElement
                children={<MdOutlineLock />}
                color={errors.passwordConfirmation ? "error" : "primary.500"}
              />
              <Input
                placeholder="••••••••"
                {...register("passwordConfirmation")}
              />
            </InputGroup>

            <FormErrorMessage>
              {errors.passwordConfirmation?.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <IconButton
            aria-label="voltar"
            icon={<MdArrowBack/>}
            onClick={handleClose}
            variant="link"
            colorScheme="gray"
          />

          <Button
            type="submit"
            px={6}
            isDisabled={!isDirty}
            isLoading={isLoading}
          >
            Alterar senha
          </Button>
        </ModalFooter>
      </Flex>
    </Modal>
  );
}
