import { EventInfo } from "components/Events/EventInfo";
import { Layout } from "components/Layout";
import { AuthLayout } from "components/Layout/AuthLayout";
import { Dashboard } from "components/Layout/Dashboard";
import { Accounts } from "pages/Accounts";
import { AccountDetails } from "pages/Accounts/AccountDetails";
import { ConfirmEmail } from "pages/ConfirmEmail";
import { Correction } from "pages/Correction";
import { Submission } from "pages/Correction/Submission";
import { SubmissionsList } from "pages/Correction/SubmissionsList";
import { EventManagement } from "pages/EventManagement";
import { SeeAnswers } from "pages/EventManagement/SeeAnswers";
import { Answers } from "pages/EventManagement/SeeAnswers/Answers";
import { SeeCalls } from "pages/EventManagement/SeeCalls";
import { SeeParticipants } from "pages/EventManagement/SeeParticipants";
import { SeeRanking } from "pages/EventManagement/SeeRanking";
import { Explore } from "pages/Explore";
import { Groups } from "pages/Groups";
import { GroupDetails } from "pages/Groups/GroupDetails";
import { Help } from "pages/Help";
import { Mural } from "pages/Mural";
import { MyAccount } from "pages/MyAccount";
import { MyAnswer } from "pages/MyAnswers";
import { SeeAnswer } from "pages/MyAnswers/SeeAnswer";
import { MyEvents } from "pages/MyEvents";
import { CreateEvent } from "pages/MyEvents/CreateEvent";
import { QuestionsAndAnswers } from "pages/MyEvents/CreateEvent/Q&A";
import { EventSettings } from "pages/MyEvents/CreateEvent/Settings";
import { EventDetails } from "pages/MyEvents/EventDetails";
import { Profile } from "pages/Profile";
import { Question } from "pages/Question";
import { Settings } from "pages/Settings";
import { Plans } from "pages/Settings/Plans";
import { Preferences } from "pages/Settings/Preferences";
import { UpdateProfile } from "pages/Settings/UpdateProfile";
import { Users } from "pages/Users";
import { Navigate, RouteObject } from "react-router-dom";
import { RequireAuth } from "./RequireAuth";

export const protectedRoutes: RouteObject = {
  element: <Layout />,
  children: [
    {
      element: <Dashboard />,
      children: [
        {
          path: "meus-eventos/:id",
          element: <EventDetails />,
        },
        {
          element: <RequireAuth />,
          children: [
            {
              index: true,
              element: <Navigate to="mural" />,
            },
            {
              path: "mural",
              element: <Mural />,
            },
            {
              path: "meus-eventos",
              children: [
                {
                  index: true,
                  element: <MyEvents />,
                },
                {
                  path: "editor",
                  element: <CreateEvent />,
                  children: [
                    {
                      index: true,
                      element: <Navigate to="configuracoes" />,
                    },
                    {
                      path: "configuracoes",
                      element: <EventSettings />,
                    },
                    {
                      path: "perguntas-e-respostas",
                      element: <QuestionsAndAnswers />,
                    },
                    {
                      path: "publicar",
                      element: <EventInfo />,
                    },
                  ],
                },
                {
                  path: ":id",
                  children: [
                    {
                      path: "editor",
                      element: <CreateEvent />,
                      children: [
                        {
                          index: true,
                          element: <Navigate to="configuracoes" />,
                        },
                        {
                          path: "configuracoes",
                          element: <EventSettings />,
                        },
                        {
                          path: "perguntas-e-respostas",
                          element: <QuestionsAndAnswers />,
                        },
                        {
                          path: "publicar",
                          element: <EventInfo />,
                        },
                      ],
                    },
                    {
                      path: "gerenciador",
                      children: [
                        { index: true, element: <EventManagement /> },
                        { path: "participantes", element: <SeeParticipants /> },
                        { path: "ranking", element: <SeeRanking /> },
                        {
                          path: ":answerId",
                          element: <SeeCalls />,
                        },
                        {
                          path: "respostas",
                          children: [
                            { index: true, element: <SeeAnswers /> },
                            { path: ":answerId", element: <Answers /> },
                          ],
                        },
                      ],
                    },
                    {
                      path: "corrigir",
                      element: <Correction />,
                      children: [
                        { index: true, element: <SubmissionsList /> },
                        { path: ":submissionId", element: <Submission /> },
                      ],
                    },
                    {
                      path: "minhas-respostas",
                      children: [
                        { index: true, element: <MyAnswer /> },
                        { path: ":answerId", element: <SeeAnswer /> },
                      ],
                    },
                    { path: "quiz", element: <Question /> },
                  ],
                },
              ],
            },
            {
              path: "grupos",
              element: <Groups />,
              children: [
                {
                  path: ":id/:tab",
                  element: <GroupDetails />,
                },
              ],
            },
            {
              path: "contas",
              element: <Accounts />,
              children: [
                {
                  path: ":id/:tab",
                  element: <AccountDetails />,
                },
              ],
            },
            {
              path: "usuarios",
              element: <Users />,
            },
            {
              path: "minha-conta",
              element: <MyAccount />,
              children: [
                {
                  path: ":id/:tab",
                  element: <AccountDetails />,
                },
              ],
            },
            {
              path: "explorar",
              element: <Explore />,
            },
            {
              path: "ajuda",
              element: <Help />,
            },
          ],
        },
        {
          path: ":username",
          element: <Profile />,
        },
        {
          path: "configuracoes",
          element: <Settings />,
          children: [
            {
              index: true,
              element: <Navigate to="perfil" />,
            },
            {
              path: "perfil",
              element: <UpdateProfile />,
            },
            {
              path: "preferencias",
              element: <Preferences />,
            },
            {
              path: "planos",
              element: <Plans />,
            },
          ],
        },
      ],
    },
    {
      element: <AuthLayout />,
      children: [
        {
          path: "confirmar-email",
          element: <ConfirmEmail />,
        },
      ],
    },
  ],
};
