import { Material } from "components/Cards/MaterialCard/props";
import { EventType } from "interfaces/event";
import { CreateGroupRequest, Group, RankingProps } from "interfaces/group";
import { User } from "interfaces/User";
import api from "services/api";

export async function getGroupsRequest(name?: string) {
  const { data } = await api.get<Group[]>("/groups", {
    params: {
      name,
    },
  });
  return data;
}

export async function getGroupRequest(id?: string) {
  const { data } = await api.get<Group>(`/groups/${id}`);
  return data;
}

export async function getGroupParticipantsRequest(id: string) {
  const { data } = await api.get<User[]>(`/groups/${id}/participants`);
  return data;
}

export async function createGroupRequest(group: CreateGroupRequest) {
  const { data } = await api.post("/groups", group);
  return data;
}

export async function deleteGroupParticipantRequest(
  groupId: string,
  userId: string
) {
  const { data } = await api.delete(`/groups/${groupId}/users/${userId}`);
  return data;
}

export async function addGroupParticipantRequest(
  groupId: string,
  userId: string
) {
  const { data } = await api.put(`/groups/${groupId}/users/${userId}`);
  return data;
}

export async function addGroupParticipantByEmailRequest(
  id: string,
  email: string
) {
  const { data } = await api.post(`/groups/${id}/invitations`, {
    email,
  });
  return data;
}

export async function getGroupEventsRequest(id: string) {
  const { data } = await api.get<EventType[]>(`/groups/${id}/events`);
  return data;
}

export async function getGroupMaterialRequest(id: string) {
  const { data } = await api.get<Material[]>(`/groups/${id}/attachments`);
  return data;
}

export async function deleteGroupMaterialRequest(
  id: string,
  attachmentId: string
) {
  const { data } = await api.delete(
    `/groups/${id}/attachments/${attachmentId}`
  );
  return data;
}

export async function addGroupMaterialRequest(id: string, file: File) {
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await api.post(`/groups/${id}/attachments`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
}

export async function getGroupRankingRequest(id: string) {
  const { data } = await api.get<RankingProps[]>(`/groups/${id}/ranking`);
  return data;
}

export async function deleteGroupParticipant(groupId: string, userId: string) {
  const res = await api.delete(`/groups/${groupId}/users/${userId}`);
  return res.data;
}
export async function addParticipantGroup(groupId: string, userId: string) {
  const res = await api.put(`/groups/${groupId}/users/${userId}`);
  return res.data;
}
export async function getEventsGroup(groupId: string) {
  const res = await api.get(`/groups/${groupId}/events`);
  return res.data;
}
export async function addFileGroup(file: File, groupId: string) {
  const formData = new FormData();
  formData.append("file", file);
  const res = await api.post(`/groups/${groupId}/attachments`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
}
export async function getMaterialsGroup(groupId: string) {
  const res = await api.get(`/groups/${groupId}/attachments`);
  return res.data;
}
export async function getRankingGroup(groupId: string) {
  const res = await api.get(`/groups/${groupId}/ranking`);
  return res.data;
}
