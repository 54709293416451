import {
  MdGridView,
  MdHelpOutline,
  MdOutlineExplore,
  MdOutlineGroups,
  MdSupervisedUserCircle,
  MdOutlineVerifiedUser,
  MdOutlineManageAccounts,
  MdToday,
} from "react-icons/md";

export const SIDEBAR_ITEMS = [
  {
    title: "Mural",
    path: "/mural",
    icon: MdGridView,
  },
  {
    title: "Explorar",
    path: "/explorar",
    icon: MdOutlineExplore,
  },
  {
    title: "Meus Eventos",
    path: "/meus-eventos",
    icon: MdToday,
  },
  {
    title: "Grupos",
    path: "/grupos",
    icon: MdOutlineGroups,
  },
];

export const SIDEBAR_ADMIN_ITEMS = [
  {
    title: "Contas",
    path: "/contas",
    icon: MdOutlineManageAccounts,
  },
  {
    title: "Usuários",
    path: "/usuarios",
    icon: MdSupervisedUserCircle,
  }
];

export const SIDEBAR_ACCOUNT_MANAGER_ITEMS = [
  {
    title: "Minha conta",
    path: "/minha-conta",
    icon: MdOutlineManageAccounts,
  },
];

export const SIDEBAR_SUPPORT_ITEMS = [
  {
    title: "Central de ajuda",
    path: "/ajuda",
    icon: MdHelpOutline,
  },
];
