import { Flex, Heading, Text } from "@chakra-ui/react";
import { Form } from "./Form";

export function ResetPassword() {
  return (
    <Flex direction="column" h="100%">
      <Flex direction="column" align="center" gap={5} mt={4} mb={10}>
        <Heading fontSize="2xl" color="primary.600">
          Alterar senha
        </Heading>

        <Text fontSize="sm" color="gray.300" maxW={310} textAlign="center">
          Crie aqui sua nova senha para acessar a sua conta
        </Text>
      </Flex>

      <Form />
    </Flex>
  );
}
