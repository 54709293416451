import {
  Button,
  ListItem,
  ModalBody,
  ModalFooter,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { CORRECTION_RECOMMENDATIONS } from "constants/texts";
import { Link } from "react-router-dom";

export function CorrectionRecommendationsModal({
  isOpen,
  onClose,
}: ModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalBody
        gap={8}
        display="flex"
        flexDirection="column"
        pt={8}
        px={8}
        pb={0}
      >
        <Text
          fontSize="1.125rem"
          maxW={350}
          textAlign="center"
          alignSelf="center"
        >
          Algumas recomendações necessárias, antes de corrigir a este evento.
        </Text>
        <UnorderedList pl={2}>
          {CORRECTION_RECOMMENDATIONS.map((recomendation) => (
            <ListItem key={recomendation}>{recomendation} </ListItem>
          ))}
        </UnorderedList>
      </ModalBody>

      <ModalFooter justifyContent="space-between" my={4} mx={4}>
        <Button variant="link" onClick={onClose}>
          Cancelar
        </Button>

        <Link to="corrigir">
          <Button colorScheme="primary" onClick={onClose}>
            Prosseguir
          </Button>
        </Link>
      </ModalFooter>
    </Modal>
  );
}
