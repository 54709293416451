
import { Box, Button, Divider, Flex, List, Text } from "@chakra-ui/react";
import { SIDEBAR_ACCOUNT_MANAGER_ITEMS, SIDEBAR_ADMIN_ITEMS, SIDEBAR_ITEMS, SIDEBAR_SUPPORT_ITEMS } from "constants/sidebar";
import { COMPANY_NAME } from "constants/texts";
import { useAuth } from "contexts/auth/useAuth";
import { getYear } from "date-fns";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import SvgLogo from "build/assets/Logo";

import { SidebarItem } from "../SidebarItem";

export function DesktopSidebarMenu() {
  const { user } = useAuth();
  const navigate = useNavigate();

  function renderSidebarItems() {
    return SIDEBAR_ITEMS.map((item) => (
      <SidebarItem key={item.path} {...item} />
    ));
  }

  function renderSidebarAdminItems() {
    return SIDEBAR_ADMIN_ITEMS.map((item) => (
      <SidebarItem key={item.path} {...item} />
    ));
  }

  function renderSidebarAccountManagerItems() {
    return SIDEBAR_ACCOUNT_MANAGER_ITEMS.map((item) => (
      <SidebarItem key={item.path} {...item} />
    ));
  }

  function renderSidebarSupportItems() {
    return SIDEBAR_SUPPORT_ITEMS.map((item) => (
      <SidebarItem key={item.path} {...item} />
    ));
  }

  return (
    <Flex maxW={60} w="100%" bg="background" borderRadius={20} pt={16} pb={6}>
      <Flex direction="column" w="100%" justify="space-around" gap={14}>
        <Flex justify="center">
          <RouterLink to="/">
            <SvgLogo width="192px" height="40px" />
          </RouterLink>
        </Flex>

        <Flex flex={1}>
          <List w="100%" position="relative" h="fit-content">
            <Box position='relative' padding='0' marginBottom={0}>
              <Box ml='1'>
                <Text fontWeight='bold' color="grayText">
                  Painel
                </Text>
              </Box>
              <Divider variant="dashed" />
            </Box>

            {renderSidebarItems()}

            {/* {(user?.roles.includes("admin") || user?.roles.includes("account-manager")) && (
              <Box position='relative' padding='0' marginBottom={0}>
                <Box ml='1' mt='1'>
                  <Text fontWeight='bold' color="grayText">
                    Configurações da conta
                  </Text>
                </Box>
                <Divider variant="dashed" />
              </Box>
            )} */}

            {/* {user?.roles.includes("admin") && renderSidebarAdminItems()}
            {user?.roles.includes("account-manager") && renderSidebarAccountManagerItems()} */}

            <Box position='relative' padding='0' marginBottom={0}>
              <Box ml='1' mt='1'>
                <Text fontWeight='bold' color="grayText">
                  Ajuda
                </Text>
              </Box>
              <Divider variant="dashed" />
            </Box>
            {renderSidebarSupportItems()}

            {/* <Flex justify="center" align="center" direction="column" mt="20">
              <Text fontSize="sm" color="teal" fontWeight={700} mb="2">
                Quer ter acesso completo?
              </Text>
              <Button w="80%" colorScheme="yellow" color="yellow.400" variant="outline" onClick={() => { navigate("/configuracoes/planos"); }}>
                Melhorar plano
              </Button>
            </Flex> */}

          </List>
        </Flex>

        <Text fontSize="2xs" textAlign="center" color="gray.300">
          {getYear(new Date())}. Todos os direitos reservados. <br />
          {COMPANY_NAME}
        </Text>
      </Flex>
    </Flex>
  );
}
