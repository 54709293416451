import { Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { Paper } from "components/Paper";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <Paper p={8} justify="center" align="center">
      <Flex gap={4} align="center">
        <Heading>404</Heading>
        <Divider orientation="vertical" />
        <Text>Página não encontrada.</Text>
      </Flex>

      <Button mt={6} as={Link} to="/">
        Voltar ao início
      </Button>
    </Paper>
  );
}
