import { NotificationRequest } from "interfaces/notification";
import api from "./api";

export async function getNotifications(pages?: number) {
  const { data } = await api.get(`/notifications?page=${pages}`);
  return {
    data: data.data,
    meta: data.meta,
  };
}

export async function getNotificationsRequest({ pageParam = 1 }) {
  const { data } = await api.get<NotificationRequest>(
    `/notifications?page=${pageParam}`
  );
  return {
    data: data.data,
    meta: data.meta,
  };
}

export async function readNotification(id: string) {
  const { data } = await api.post(`/notifications/${id}/read`);
  return data;
}
