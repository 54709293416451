export function prepareFormData<T>(obj: T): any {
  const data: { [key: string]: any } = {};

  for (const [key, value] of Object.entries(obj as any)) {
    if (value === "") {
      data[key] = null;
    } else {
      /*
      
      if (Array.isArray(value) && value.length !== 0) {
        data[key] = value.map((val) => prepareData(val));
      } else {
        data[key] = value;
      } 
      
      */

      data[key] = value ?? null;
    }
  }

  return data;
}
