import { Flex, FlexProps, Icon } from "@chakra-ui/react";
import { MdImage } from "react-icons/md";

export function NoCover(props: FlexProps) {
  return (
    <Flex
      {...props}
      align="center"
      justify="center"
      borderTopRadius={20}
      w="100%"
      bg="gray.100"
    >
      <Icon as={MdImage} w={12} h={12} color="gray.300" />
    </Flex>
  );
}
