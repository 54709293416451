import { useToast } from "contexts/layout/toast/useToast";
import { CreateGroupRequest } from "interfaces/group";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addGroupMaterialRequest,
  addGroupParticipantRequest,
  createGroupRequest,
  deleteGroupMaterialRequest,
  deleteGroupParticipantRequest,
  getGroupEventsRequest,
  getGroupMaterialRequest,
  getGroupParticipantsRequest,
  getGroupRankingRequest,
  getGroupRequest,
  getGroupsRequest
} from "services/group";

export function useGroups(name?: string) {
  return useQuery(["groups", name], () => getGroupsRequest(name), {
    staleTime: 1000 * 30, // 30 seconds
  });
}

export function useGroup(id?: string) {
  return useQuery(["group", id], () => getGroupRequest(id), {
    enabled: Boolean(id),
    staleTime: 1000 * 60, // 1 minute
  });
}

export function useGroupPartcipants(id?: string) {
  return useQuery(
    ["group-participants", id],
    () => getGroupParticipantsRequest(id as string),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 30, // 30 seconds
    }
  );
}

export function useDeleteGroupParticipant(groupId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (userId: string) => deleteGroupParticipantRequest(groupId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["group-participants", groupId]);
        queryClient.invalidateQueries(["groups"]);
        toast("Participante removido com sucesso.", "success");
      },
    }
  );
}

export function useCreateGroup() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((group: CreateGroupRequest) => createGroupRequest(group), {
    onSuccess: () => {
      queryClient.invalidateQueries("groups");
      toast("Grupo criado com sucesso.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao criar o grupo.", "error");
    },
  });
}

export function useAddGroupParticipant(groupId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (userId: string) => addGroupParticipantRequest(groupId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["group-participants", groupId]);
        queryClient.invalidateQueries("groups");
        toast("Participante adicionado com sucesso.", "success");
      },
      onError: () => {
        toast("Ocorreu um erro ao adicionar o participante ao grupo.", "error");
      },
    }
  );
}

export function useGroupEvents(id: string) {
  return useQuery(["group-events", id], () => getGroupEventsRequest(id), {
    enabled: Boolean(id),
    staleTime: 1000 * 60, // 1 minute
  });
}

export function useGroupMaterials(id: string) {
  return useQuery(["group-materials", id], () => getGroupMaterialRequest(id), {
    enabled: Boolean(id),
    staleTime: 1000 * 60, // 1 minute
  });
}

export function useDeleteGroupMaterial(groupId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation(
    (attachmentId: string) => deleteGroupMaterialRequest(groupId, attachmentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["group-materials", groupId]);
      toast("Material removido com sucesso.", "success");
    },
  }
  );
}

export function useAddGroupMaterial(groupId: string) {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation((file: File) => addGroupMaterialRequest(groupId, file), {
    onSuccess: () => {
      queryClient.invalidateQueries(["group-materials", groupId]);
      toast("Material adicionado com sucesso.", "success");
    },
  });
}

export function useGroupRanking(id: string) {
  return useQuery(["group-ranking", id], () => getGroupRankingRequest(id), {
    enabled: Boolean(id),
    staleTime: 1000 * 60, // 1 minute
  });
}
