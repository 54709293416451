import { Avatar, Flex, ListItem, Text, Tooltip } from "@chakra-ui/react";
import { useGroup } from "contexts/group/useGroup";
import { Link, useParams } from "react-router-dom";
import { formatNamesList } from "utils/format";
import { AccountItemProps } from "./props";

export function AccountItem({ name, participants, period, _id }: AccountItemProps) {
  const { reset } = useGroup();
  const { id: paramsId } = useParams();

  const isSelected = _id === paramsId;

  const groupNameFirstLetter = name.charAt(0).toUpperCase();

  const participantsNames = participants.map(
    (participant) => participant.firstName
  );

  return (
    <ListItem
      as={Link}
      to={`/contas/${_id}/users`}
      onClick={() => reset()}
      display="flex"
      alignItems="center"
      border="1px solid"
      borderLeftWidth={isSelected ? "10px" : 1}
      borderColor={isSelected ? "primary.500" : "gray.200"}
      borderRadius={10}
      gap={3}
      px={2}
      py={2}
      transition="all .2s"
      bg={
        isSelected
          ? "linear-gradient(90deg, rgba(24, 185, 170, 0.2) 0%, rgba(24, 185, 170, 0) 83.4%)"
          : "transparent"
      }
      _hover={!isSelected ? { bg: "backgroundGray" } : {}}
    >
      <Avatar
        name={name}
        bg="rgba(24, 185, 170, 0.1)"
        color="primary.500"
        borderRadius="full"
        boxSize="48px"
        py={4}
        getInitials={() => "C"}
      />

      <Flex direction="column" w="66%">
        <Text color="black" fontSize="sm" fontWeight={600}>
        Colégio Evolutivo
        </Text>
        <Text
          fontSize="xs"
          textOverflow="ellipsis"
          maxW="100%"
          display="block"
          overflow="hidden"
          whiteSpace="nowrap"
          as="i"
        >
          {/* {formatNamesList(participantsNames) ?? "Nenhum participante "} */}
          Diretor Pai d'Égua
        </Text>
      </Flex>
    </ListItem>
  );
}
