import {
  Button,
  Flex,
  FormControl, FormLabel, Input,
  ModalBody,
  ModalFooter,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useAuth } from "contexts/auth/useAuth";
import { useToast } from "contexts/layout/toast/useToast";
import { useCreateGroup } from "hooks/group";
import { useUsers } from "hooks/user";
import { CreateGroup } from "interfaces/group";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { createGroupSchema } from "./InviteByEmailModal/schema";

const defaultValues: CreateGroup = {
  name: "",
  participants: [],
  period: "",
};

export function CreateAccountLicenseModal({ isOpen, onClose }: ModalProps) {
  // const {
  //   isOpen: isInviteModalOpen,
  //   onOpen: onInviteModalOpen,
  //   onClose: onInviteModalClose,
  // } = useDisclosure();

  const methods = useForm<CreateGroup>({
    defaultValues,
    // resolver: yupResolver(createGroupSchema),
  });

  // const {
  //   register,
  //   watch,
  //   reset,
  //   handleSubmit,
  //   setValue,
  //   formState: { errors },
  // } = methods;
  // const participants = watch("participants");

  const { user } = useAuth();
  const { data: users } = useUsers();
  const { toast } = useToast();

  const { mutate: createGroup, isLoading, isSuccess } = useCreateGroup();
  const [search, setSearch] = useState("");

  // useEffect(() => {
  //   if (participants.find(({ id }) => id === user?.id)) {
  //     toast("Você não pode ser participante deste grupo.", "warning");
  //     setValue(
  //       "participants",
  //       participants.filter(({ id }) => id !== user?.id)
  //     );
  //   }
  // }, [participants]);

  const handleClose = () => {
    // reset();
    onClose();
  };

  const handleCreateGroup = (group: CreateGroup) => {
    createGroup({
      name: group.name,
      period: group.period,
      userIds: group.participants.map(({ id }) => id),
    });

    handleClose();
  };

  useEffect(() => {
    if (isSuccess) {
      setSearch("");
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      {/* <FormProvider {...methods}> */}
      <FormProvider {...methods}>
        <Flex
          as="form"
          direction="column"
        // onSubmit={handleSubmit(handleCreateGroup)}
        >
          <ModalBody display="flex" flexDirection="column" gap={8} p={8}>
            <Flex direction="column" gap={4}>
              <Flex gap={6}>
                {/* <FormControl isInvalid={!!errors.name}> */}
                <FormControl>
                  <FormLabel>Data de expiração</FormLabel>
                  <Input
                    placeholder="Selecione a data de expiração"
                    size="md"
                    type="datetime-local"
                    sx={{ height: "40px", borderRadius: "6px" }}
                  />
                  {/* <FormErrorMessage>{errors.name?.message}</FormErrorMessage> */}
                </FormControl>
                <FormControl>
                  <FormLabel>Limite de usuário</FormLabel>
                  <NumberInput step={5} defaultValue={5} min={5}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>

              <Flex gap={6}>
                <FormControl>
                  <FormLabel>Plano</FormLabel>
                  <Select>
                    <option value="basic">Basic</option>
                    <option value="pro">Pro</option>
                    <option value="business">Business</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select disabled>
                    <option value="active">Ativo</option>
                    <option value="inactive">Inativo</option>
                    <option value="blocked">Bloqueado</option>
                  </Select>
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button variant="link" onClick={handleClose} px={8}>
              Cancelar
            </Button>

            <Button
              isLoading={isLoading}
              type="submit"
              onClick={handleClose}
            // isDisabled
            >
              Gerar licença
            </Button>
          </ModalFooter>
        </Flex>
      </FormProvider>
    </Modal>
  );
}
