import {
  Button,
  Flex,
  Icon,
  Image,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useExploreSlides } from "hooks/explore";
import { useState } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";

export function Slider() {
  const isDesktop = useBreakpointValue({ lg: "none" });
  const { data: slides, isLoading } = useExploreSlides();

  const [slideIndex, setSlideIndex] = useState(1);

  function nextSlide() {
    if (slides && slideIndex !== slides.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slides && slideIndex === slides.length) {
      setSlideIndex(1);
    }
  }

  function prevSlide() {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slides && slideIndex === 1) {
      setSlideIndex(slides.length);
    }
  }

  // console.log(slides && slides[slideIndex - 1]);

  return (
    <Flex direction="column" width="90%" gap={6}>
      <Flex alignItems="center" position="relative" justifyContent="center">
        <Button
          w="60px"
          height="60px"
          borderRadius="20px"
          bg={isDesktop ? "primary.700" : "transparent"}
          position="absolute"
          variant={isDesktop ? "solid" : "link"}
          left={isDesktop ? -8 : -10}
          onClick={() => prevSlide()}
        >
          <Icon
            as={isDesktop ? HiArrowLeft : GoChevronLeft}
            w="24px"
            h="21px"
            color={isDesktop ? "white" : "primary.500"}
          />
        </Button>

        {isLoading ? (
          <Spinner />
        ) : (
          slides &&
          slides.map(({ url }, index) => (
            <Image
              display={index + 1 === slideIndex ? "block" : "none"}
              transition="ease-in-out"
              src={url}
              objectFit="cover"
              h={[150, 263]}
              width="100%"
              borderRadius={20}
            />
          ))
        )}

        <Button
          w="60px"
          height="60px"
          borderRadius="20px"
          bg={isDesktop ? "primary.700" : "transparent"}
          position="absolute"
          right={isDesktop ? -8 : -10}
          variant={isDesktop ? "solid" : "link"}
          onClick={() => nextSlide()}
        >
          <Icon
            as={isDesktop ? HiArrowRight : GoChevronRight}
            w="24px"
            h="21px"
            color={isDesktop ? "white" : "primary.500"}
          />
        </Button>
      </Flex>
      <Flex gap={3} justifyContent="center" w="100%" alignItems="center">
        {slides?.map((slide, index) => (
          <Button
            key={slide._id}
            p={0}
            w={slideIndex === index + 1 ? "22px" : "12px"}
            height="12px"
            minWidth="12px"
            onClick={() => setSlideIndex(index + 1)}
            bg={slideIndex === index + 1 ? "primary.500" : "gray.300"}
            borderRadius="50px"
          />
        ))}
      </Flex>
    </Flex>
  );
}
