import {
  Avatar,
  Button,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  SkeletonCircle,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { Paper } from "components/Paper";
import { ShareLink } from "components/ShareLink";
import { useAuth } from "contexts/auth/useAuth";
import { useUser } from "hooks/user";
import { BiEdit } from "react-icons/bi";
import { FiUser, FiUserPlus } from "react-icons/fi";
import { MdOutlineSettings } from "react-icons/md";
import { Link, useParams } from "react-router-dom";

import { makeFullName } from "utils/makeFullName";
import { ProfileProps } from "../props";
import { ReferFriendModal } from "../ReferFriendModal";

export function LeftSide({ isLoggedUserProfile }: ProfileProps) {
  const [isLargerThan1320] = useMediaQuery("(min-width: 1320px)");
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const { username } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user: loggedUser } = useAuth();

  const { data: user, isLoading } = useUser(
    username as string,
    isLoggedUserProfile
  );

  const profile = isLoggedUserProfile ? loggedUser : user;

  const isLoaded = isLoggedUserProfile || !isLoading;

  return (
    <Paper align="center" gap={6} position="relative" flex={1} p={8}>
      {isLoggedUserProfile && (
        <>
          {!isLargerThan1080 && (
            <IconButton
              aria-label=""
              position="absolute"
              left={6}
              top={6}
              w={55}
              height={55}
              borderRadius={20}
              icon={<MdOutlineSettings />}
              as={Link}
              to="/configuracoes/preferencias"
            />
          )}

          <IconButton
            aria-label=""
            position="absolute"
            right={6}
            top={6}
            w={55}
            height={55}
            borderRadius={20}
            icon={<BiEdit />}
            as={Link}
            to="/configuracoes/perfil"
            zIndex={1}
          />
        </>
      )}
      <SkeletonCircle
        boxSize={isLargerThan1320 ? 150 : 120}
        isLoaded={isLoaded}
      >
        <Avatar
          src={profile?.image ?? undefined}
          icon={profile?.image ? undefined : <FiUser size={42} />}
          bg="gray.100"
          color="gray.400"
          boxSize={isLargerThan1320 ? 150 : 120}
        />
      </SkeletonCircle>

      <Flex direction="column" align="center" gap={6} w="100%">
        <Flex direction="column" align="center" gap={2} color="primary.600">
          <Skeleton isLoaded={isLoaded}>
            <Text fontWeight={800} fontSize="2xl">
              {makeFullName(profile?.firstName, profile?.lastName)}
            </Text>
          </Skeleton>

          <Skeleton isLoaded={isLoaded}>
            <Text fontSize="sm">{profile?.email}</Text>
          </Skeleton>
        </Flex>
      </Flex>
      <Divider />
      <Flex justify="space-around" w="100%">
        <Skeleton isLoaded={isLoaded}>
          <Flex direction="column" align="center">
            <Text fontSize="sm" color="gray.300">
              Participou de
            </Text>

            <Text fontSize="4xl" fontWeight={800} color="gray.400">
              {String(profile?.eventsJoinedCount).padStart(2, "0")}
            </Text>

            <Text fontSize="sm" color="gray.300">
              eventos
            </Text>
          </Flex>
        </Skeleton>

        <Skeleton isLoaded={isLoaded}>
          <Flex direction="column" align="center">
            <Text fontSize="sm" color="gray.300">
              Criou
            </Text>

            <Text fontSize="4xl" fontWeight={800} color="gray.400">
              {String(profile?.createdEventsCount).padStart(2, "0")}
            </Text>

            <Text fontSize="sm" color="gray.300">
              eventos
            </Text>
          </Flex>
        </Skeleton>
      </Flex>
      <Divider />
      {isLargerThan1320 && (
        <Flex direction="column" gap={8} w="100%">
          <ShareLink
            title={`Compartilhe ${isLoggedUserProfile ? "seu" : "este"} perfil`}
            link={`${process.env.REACT_APP_BASE_URL}/${profile?.username}`}
          />

          {isLoggedUserProfile && (
            <Button variant="link" leftIcon={<FiUserPlus />} onClick={onOpen}>
              Indique um amigo
            </Button>
          )}
        </Flex>
      )}

      <ReferFriendModal isOpen={isOpen} onClose={onClose} />
    </Paper>
  );
}
