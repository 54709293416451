import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "contexts/auth/useAuth";
import { CreateUser, SignUpData } from "interfaces/User";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { passwordSchema } from "./schema";

export function PasswordStep() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { useSignUp } = useAuth();

  const user = state as Pick<
    CreateUser,
    "firstName" | "lastName" | "email" | "username"
  >;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Pick<SignUpData, "password" | "passwordConfirmation">>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(passwordSchema),
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChangePasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const { mutate: signUp, isLoading, isSuccess } = useSignUp();

  useEffect(() => {
    if (isSuccess) {
      navigate("/confirmar-email", { state: user.email });
    }
  }, [isSuccess]);

  const handleSignUp = (
    passwordData: Pick<SignUpData, "password" | "passwordConfirmation">
  ) => {
    signUp({
      ...user,
      ...passwordData,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/confirmar-email", { state: user.email });
    }
  }, [isSuccess]);

  return (
    <Flex direction="column" gap={8} w="100%" h="100%" px={8} pb={[8, 0]}>
      <Flex
        as="form"
        onSubmit={handleSubmit(handleSignUp)}
        direction="column"
        justify={["flex-start", "flex-start", "space-between"]}
        gap={4}
        w="100%"
        h="100%"
      >
        <Flex direction="column" gap={4}>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Crie uma senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<MdLockOutline size={20} />}
                color={errors.password ? "error" : "primary.500"}
              />
              <Input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="••••••••"
                {...register("password")}
              />

              <InputRightElement
                children={
                  <IconButton
                    variant="icon"
                    aria-label="toggle password visibilty"
                    icon={isPasswordVisible ? <FiEye /> : <FiEyeOff />}
                    fontSize={20}
                    onClick={handleChangePasswordVisible}
                  />
                }
                color={errors.password ? "error" : "gray.200"}
              />
            </InputGroup>

            <FormErrorMessage maxW={300}>
              {errors.password?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.passwordConfirmation}>
            <FormLabel>Confirme sua senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<MdLockOutline size={20} />}
                color={errors.passwordConfirmation ? "error" : "primary.500"}
              />
              <Input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="••••••••"
                {...register("passwordConfirmation")}
              />
              <InputRightElement
                children={
                  <IconButton
                    variant="icon"
                    aria-label="toggle password visibilty"
                    icon={isPasswordVisible ? <FiEye /> : <FiEyeOff />}
                    fontSize={20}
                    onClick={handleChangePasswordVisible}
                  />
                }
                color={errors.passwordConfirmation ? "error" : "gray.200"}
              />
            </InputGroup>

            <FormErrorMessage w="fit-content">
              {errors.passwordConfirmation?.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex direction="column" align="center" gap={4}>
          <Button
            isFullWidth
            type="submit"
            size="lg"
            isLoading={isLoading}
            isDisabled={!isDirty}
          >
            Criar conta
          </Button>

          <Link as={RouterLink} to="/criar-conta/perfil" state={user}>
            Voltar
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
