import { validateRichTextEditorContent } from "utils/validateRichTextEditorContent";
import * as yup from "yup";

export const questionsAndAnswersSchema = yup
  .object()
  .shape({
    questions: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.string().required(),
          title: yup
            .string()
            .test("validate title", "O título é obrigatório.", (title) =>
              validateRichTextEditorContent(title)
            ),
          answerPattern: yup.string().when("type", {
            is: "OPEN",
            then: yup
              .string()
              .test(
                "validate answerPattern",
                "O padrão de resposta é obrigatório.",
                (answerPattern) => validateRichTextEditorContent(answerPattern)
              )
              .required("O padrão de resposta é obrigatório."),
          }),
          criteria: yup.string().when("type", {
            is: "OPEN",
            then: yup
              .string()
              .test(
                "validate criteria",
                "O barema/critério para a questão é obrigatório.",
                (criteria) => validateRichTextEditorContent(criteria)
              )
              .required("O barema/critério para a questão é obrigatório."),
          }),
        })
      )
      .min(1, "É necessário adicionar pelo menos 1 questão."),
  })
  .nullable();
