import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete } from "components/Autocomplete";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { EmptyContent } from "components/EmptyContent";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useAuth } from "contexts/auth/useAuth";
import { useToast } from "contexts/layout/toast/useToast";
import { useCreateGroup } from "hooks/group";
import { useUsers } from "hooks/user";
import { CreateGroup } from "interfaces/group";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiExport } from "react-icons/bi";
import { MdPeople } from "react-icons/md";
import { InviteByEmailModal } from "./InviteByEmailModal";
import { createGroupSchema } from "./InviteByEmailModal/schema";

const defaultValues: CreateGroup = {
  name: "",
  participants: [],
  period: "",
};

export function CreateAccountModal({ isOpen, onClose }: ModalProps) {
  const {
    isOpen: isInviteModalOpen,
    onOpen: onInviteModalOpen,
    onClose: onInviteModalClose,
  } = useDisclosure();

  const methods = useForm<CreateGroup>({
    defaultValues,
    resolver: yupResolver(createGroupSchema),
  });

  const {
    register,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;
  const participants = watch("participants");

  const { user } = useAuth();
  const { data: users } = useUsers();
  const { toast } = useToast();

  const { mutate: createGroup, isLoading, isSuccess } = useCreateGroup();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (participants.find(({ id }) => id === user?.id)) {
      toast("Você não pode ser participante deste grupo.", "warning");
      setValue(
        "participants",
        participants.filter(({ id }) => id !== user?.id)
      );
    }
  }, [participants]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleCreateGroup = (group: CreateGroup) => {
    createGroup({
      name: group.name,
      period: group.period,
      userIds: group.participants.map(({ id }) => id),
    });

    handleClose();
  };

  useEffect(() => {
    if (isSuccess) {
      setSearch("");
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <FormProvider {...methods}>
        <Flex
          as="form"
          direction="column"
          onSubmit={handleSubmit(handleCreateGroup)}
        >
          <ModalBody display="flex" flexDirection="column" gap={8} p={8}>
            <Flex direction="column" gap={4}>
              <Flex gap={6}>
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel>Nome da conta</FormLabel>
                  <Input
                    placeholder="ex.: Colégio Evolutivo"
                    {...register("name")}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex gap={6} align="end">
                <FormControl>
                  <FormLabel>Adicionar responsável</FormLabel>

                  <Autocomplete
                    isMulti
                    search={search}
                    setSearch={setSearch}
                    data={users}
                    name="participants"
                    placeholder="ex.: Alexandre"
                  />
                </FormControl>

                <IconButton
                  aria-label=""
                  height={50}
                  w={50}
                  icon={<BiExport />}
                  onClick={onInviteModalOpen}
                />
              </Flex>
            </Flex>

            <Flex
              direction="column"
              gap={4}
              align="center"
              justify={participants?.length === 0 ? "center" : "start"}
              height={200}
              overflow="auto"
            >
              {participants?.map((user) => (
                <ParticipantCard
                  showBorder
                  showEmail
                  onRemove={() => {
                    setValue(
                      "participants",
                      participants.filter(({ id }) => id !== user.id)
                    );
                  }}
                  key={user.id}
                  user={user}
                />
              ))}

              {participants?.length === 0 && (
                <EmptyContent
                  message="Nenhum responsável selecionado."
                  icon={MdPeople}
                />
              )}
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button variant="link" onClick={handleClose} px={8}>
              Cancelar
            </Button>

            <Button
              isLoading={isLoading}
              type="submit"
              isDisabled={participants.length === 0}
            >
              Criar grupo
            </Button>
          </ModalFooter>
        </Flex>

        <InviteByEmailModal
          isOpen={isInviteModalOpen}
          onClose={onInviteModalClose}
        />
      </FormProvider>
    </Modal>
  );
}
