import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { accountTabs } from "constants/account";
import { useGroup } from "hooks/group";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AccountLicenses } from "../Panels/AccountLicenses";
import { AccountUsers } from "../Panels/AccountUsers";

export function AccountDetails() {
  const { id, tab } = useParams();

  const [tabIndex, setTabIndex] = useState(0);
  const { data: group } = useGroup(id);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setTabIndex(0);
  }, [id]);

  return (
    <Flex
      direction="column"
      gap={8}
      overflow="auto"
      h="100%"
    >
      <Heading fontSize="1.125rem" color="gray.400">
        {/* {group?.name} */}
        Colégio Evolutivo
      </Heading>

      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {/* {accountTabs.map((tab) => (
            <Tab
              key={tab}
              as={Link}
              to={{
                pathname: `/contas/${id}/${tab}`,
              }}
              textTransform="capitalize"
            >
              {tab}
            </Tab>
          ))} */}
          <Tab
            key="users"
            as={Link}
            to={{
              pathname: `/contas/${id}/users`,
            }}
            textTransform="capitalize"
          >
            Usuários
          </Tab>
          <Tab
            key="licenses"
            as={Link}
            to={{
              pathname: `/contas/${id}/licenses`,
            }}
            textTransform="capitalize"
          >
            Licenças
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AccountUsers />
          </TabPanel>

          <TabPanel>
            <AccountLicenses />
          </TabPanel>

        </TabPanels>
      </Tabs>
    </Flex >
  );
}
