import { Button, Flex, FormLabel, Spinner } from "@chakra-ui/react";
import { Autocomplete } from "components/Autocomplete";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import {
  useAddGroupParticipant,
  useDeleteGroupParticipant,
  useGroupPartcipants,
} from "hooks/group";
import { useUsers } from "hooks/user";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdAddCircle } from "react-icons/md";
import { useParams } from "react-router-dom";

export function Participants() {
  const { id } = useParams();
  const methods = useForm();

  const participant = methods.watch("participant", "");
  const [search, setSearch] = useState("");

  const { data: users } = useUsers();
  const { data: participants, isLoading } = useGroupPartcipants(id);
  const { mutate: deleteParticipant } = useDeleteGroupParticipant(id as string);
  const {
    mutate: addGroupParticipant,
    isSuccess,
    isLoading: isAddGroupParticipantLoading,
  } = useAddGroupParticipant(id as string);

  useEffect(() => {
    if (isSuccess) {
      setSearch("");
    }
  }, [isSuccess]);

  return (
    <Flex direction="column" gap={6}>
      <Flex direction="column" gap="0.625rem">
        <FormProvider {...methods}>
          <FormLabel>Pesquisar por usuários</FormLabel>

          <Flex gap={6} align="center">
            <Autocomplete
              name="participant"
              data={users}
              search={search}
              setSearch={setSearch}
              placeholder="ex.: João Guilherme"
              w="100%"
            />

            <Button
              leftIcon={<MdAddCircle />}
              onClick={() => {
                addGroupParticipant(participant.id);
                methods.setValue("name", "");
              }}
              height={50}
              isLoading={isAddGroupParticipantLoading}
              isDisabled={search === ""}
            >
              Adicionar participante
            </Button>
          </Flex>
        </FormProvider>
      </Flex>

      <Flex direction="column" align="center" gap={4}>
        {isLoading && <Spinner />}

        {participants?.map((participant) => (
          <ParticipantCard
            showBorder
            showEmail
            isLink
            key={participant.id}
            user={participant}
            onRemove={() => deleteParticipant(participant.id)}
          />
        ))}
      </Flex>
    </Flex>
  );
}
