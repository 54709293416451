import { Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, InputGroup, InputLeftElement, InputRightElement, Select } from "@chakra-ui/react";
import { useState } from "react";
import { BiSolidCreditCard, BiSolidCreditCardFront, BiSolidIdCard } from "react-icons/bi";


export const PaymentMethodForm = () => {
    const [show, setShow] = useState(false);
    const [hasPaymentMethods, setHasPaymentMethods] = useState(false);
    const [hasCpfFill, setHasCpfFill] = useState(false);
    const handleClick = () => setShow(!show);

    return (
        <>
            <Heading w="100%" textAlign="center" fontSize="2xl" mt="1%" mb="1%" fontWeight="normal">
                Adicionar forma de pagamento
            </Heading>
            <Flex minWidth="" maxWidth="320px">
                <FormControl isRequired>
                    <FormLabel htmlFor="credit-card-name" fontWeight="normal">
                        Nome impresso no cartão
                    </FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement pointerEvents="none">
                            <BiSolidIdCard color="gray.300" fill="gray" />
                        </InputLeftElement>
                        <Input id="credit-card-name" sx={{ borderColor: "#CAD2D2 !important" }} type="text" required />
                    </InputGroup>
                </FormControl>
            </Flex>
            <Flex>
                <FormControl maxWidth="220px" minWidth="220px" mr="5%" isRequired>
                    <FormLabel htmlFor="credit-card-number" fontWeight="normal">
                        Número do cartão
                    </FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement pointerEvents="none">
                            <BiSolidCreditCardFront color="gray.800" fill="gray" />
                        </InputLeftElement>
                        <Input id="credit-card-number" sx={{ borderColor: "#CAD2D2 !important" }} type="text" required />
                    </InputGroup>
                </FormControl>

                <FormControl maxWidth="170px" mr="5%" isRequired>
                    <FormLabel htmlFor="credit-card-due-date" fontWeight="normal">
                        Data de validade
                    </FormLabel>
                    <Input id="credit-card-due-date" sx={{ borderColor: "#CAD2D2 !important" }} type="month" />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel htmlFor="credit-card-ccv" fontWeight="normal">
                        Código de segurança
                    </FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement pointerEvents="none">
                            <BiSolidCreditCard color="gray.800" fill="gray" />
                        </InputLeftElement>
                        <Input id="credit-card-ccv" maxWidth="100px" sx={{ borderColor: "#CAD2D2 !important" }} type="text" required />
                    </InputGroup>
                </FormControl>
            </Flex>

            {/* hasPaymentMethod */}
            {/* <Heading w="100%" textAlign="center" fontSize="2xl" mt="1%" mb="1%" fontWeight="normal">
                Selecione a forma de pagamento
            </Heading>
            <Flex maxWidth="320px" >
                <FormControl isRequired>
                    <FormLabel htmlFor="credit-card-name" fontWeight="normal">
                        Formas de pagamento
                    </FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                        <Select
                            id="country"
                            name="country"
                            autoComplete="country"
                            placeholder="Selecione"
                            focusBorderColor="brand.400"
                            shadow="sm"
                            size="sm"
                            w="full"
                            rounded="md">
                            <option value='cd1'>Cartao - 5XXX.XXXX.XXXX.8888</option>
                            <option value='cd2'>Cartao - 5XXX.XXXX.XXXX.8888</option>
                            <option value='cd3'>Cartao - 5XXX.XXXX.XXXX.8888</option>
                        </Select>
                    </InputGroup>
                </FormControl>
            </Flex> */}
        </>
    );
};

export const PlanSelectionForm = () => {
    const [show, setShow] = useState(false);
    // selected plan
    const [hasPaymentMethods, setHasPaymentMethods] = useState(false);
    const handleClick = () => setShow(!show);

    return (
        <>
            <Heading w="100%" textAlign="center" fontWeight="normal" mb="2%">
                User Registration
            </Heading>
            <Flex>
                <FormControl mr="5%">
                    <FormLabel htmlFor="first-name" fontWeight="normal">
                        First name
                    </FormLabel>
                    <Input id="first-name" placeholder="First name" />
                </FormControl>

                <FormControl>
                    <FormLabel htmlFor="last-name" fontWeight="normal">
                        Last name
                    </FormLabel>
                    <Input id="last-name" placeholder="First name" />
                </FormControl>
            </Flex>
            <FormControl mt="2%">
                <FormLabel htmlFor="email" fontWeight="normal">
                    Email address
                </FormLabel>
                <Input id="email" type="email" />
                <FormHelperText>We&apos;ll never share your email.</FormHelperText>
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="password" fontWeight="normal" mt="2%">
                    Password
                </FormLabel>
                <InputGroup size="md">
                    <Input
                        pr="4.5rem"
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? "Hide" : "Show"}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>
        </>
    );
};