import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Input,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  useMediaQuery
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "components/DatePicker";
import { useAuth } from "contexts/auth/useAuth";
import { UserFormType } from "interfaces/User";
import { ChangeEvent } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { FiUser } from "react-icons/fi";
import { MdAdd, MdClose } from "react-icons/md";
import InputMask from "react-input-mask";
import { useLocation, useNavigate } from "react-router-dom";
import { prepareFormData } from "utils/prepareFormData";
import editUserSchema from "./schema";

export function UpdateProfile() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLargerThan1320] = useMediaQuery("(min-width: 1320px)");

  const { user, useUpdateUser, useUpdateUserImage } = useAuth();

  const { mutate: updateUserImage, isLoading: isUpdatingImage } =
    useUpdateUserImage();
  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser();

  const defaultValues = {
    fullName: `${user?.firstName} ${user?.lastName}` ?? "",
    cpf: user?.cpf ?? "",
    email: user?.email ?? "",
    aboutMe: user?.aboutMe ?? "",
    dateOfBirth: user?.dateOfBirth ?? undefined,
    image: undefined,
    links: user?.links ?? [],
    username: user?.username ?? "",
    linkedIn: user?.linkedIn ?? "",
    lattes: user?.lattes ?? "",
    educations:
      user?.educations.length !== 0
        ? user?.educations
        : [
          {
            title: "",
            institution: "",
          },
        ],
  };

  //

  const methods = useForm<UserFormType>({
    resolver: yupResolver(editUserSchema),
    defaultValues,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  //

  const { fields, append, remove } = useFieldArray({
    control,
    name: "educations",
  });

  const {
    fields: linkFields,
    append: linkAppend,
    remove: linkRemove,
  } = useFieldArray({
    control,
    name: "links",
  });

  const appendAcademicFields = () => {
    append({ title: "", institution: "", startDate: null, endDate: null });
  };

  const appendLinkFields = () => {
    linkAppend({ title: "", url: "" });
  };

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.item(0)) {
      const formData = new FormData();
      formData.append("avatar", event.target.files.item(0) as File);

      updateUserImage(formData);
    }
  };

  function renderDateOfBirth() {
    return (
      <FormControl isInvalid={!!errors.dateOfBirth}>
        <FormLabel>Data de nascimento</FormLabel>
        <DatePicker name="dateOfBirth" placeholder="18/05/2022" />
        <FormErrorMessage>
          {errors.dateOfBirth && errors.dateOfBirth.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderAcademicEducationFields() {
    return fields.map((field, index) => (
      <Flex key={field.id} direction="column" gap={4}>
        <FormControl
          isInvalid={!!(errors.educations && errors.educations[index]?.title)}
        >
          <Flex justify="space-between">
            <FormLabel>Formação Acadêmica {index !== 0 && index + 1}</FormLabel>

            <IconButton
              variant="unstyled"
              aria-label="remove-education"
              icon={<MdClose />}
              color="primary.500"
              size="icon"
              _hover={{ color: "primary.700" }}
              onClick={() => remove(index)}
            />
          </Flex>
          <Input
            {...register(`educations.${index}.title`)}
            placeholder="Bacharelado em Engenharia Mecânica"
          />

          <FormErrorMessage>
            {errors.educations &&
              errors.educations[index]?.title &&
              errors.educations[index]?.title?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={
            !!(errors.educations && errors.educations[index]?.institution)
          }
        >
          <FormLabel>Instituição de Ensino</FormLabel>
          <Input
            {...register(`educations.${index}.institution`)}
            placeholder="URCA - Universidade Regional do Cariri"
          />

          <FormErrorMessage>
            {errors.educations &&
              errors.educations[index]?.institution &&
              errors.educations[index]?.institution?.message}
          </FormErrorMessage>
        </FormControl>

        <Flex gap={4}>
          <FormControl
            isInvalid={
              !!(errors.educations && errors.educations[index]?.startDate)
            }
          >
            <FormLabel>Início</FormLabel>

            <DatePicker
              isModal
              name={`educations.${index}.startDate`}
              placeholder="18/05/2022"
            />
            <FormErrorMessage>
              {errors.educations &&
                errors.educations[index]?.startDate &&
                errors.educations[index]?.startDate?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!(errors.educations && errors.educations[index]?.endDate)
            }
          >
            <FormLabel>Fim</FormLabel>

            <DatePicker
              isModal
              name={`educations.${index}.endDate`}
              placeholder="18/05/2022"
            />

            <FormErrorMessage>
              {errors.educations &&
                errors.educations[index]?.endDate &&
                errors.educations[index]?.endDate?.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>

        {(fields.length > 1 || !isLargerThan1320) && <Divider />}
      </Flex>
    ));
  }

  function renderLinkFields() {
    return linkFields.map((field, index) => (
      <Flex key={field.id} direction="column" gap={4}>
        <FormControl isInvalid={!!(errors.links && errors.links[index]?.title)}>
          <Flex justify="space-between">
            <FormLabel>Titulo {index !== 0 && index + 1}</FormLabel>

            <IconButton
              variant="unstyled"
              aria-label="remove-link"
              icon={<MdClose />}
              color="primary.500"
              size="icon"
              _hover={{ color: "primary.700" }}
              onClick={() => linkRemove(index)}
            />
          </Flex>
          <Input {...register(`links.${index}.title`)} placeholder="Meu site" />
          <FormErrorMessage>
            {errors.links &&
              errors.links[index]?.title &&
              errors.links[index]?.title?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!(errors.links && errors.links[index]?.url)}>
          <FormLabel>Url {index !== 0 && index + 1}</FormLabel>
          <Input
            {...register(`links.${index}.url`)}
            placeholder="meusite.com"
          />
          <FormErrorMessage>
            {errors.links &&
              errors.links[index]?.url &&
              errors.links[index]?.url?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
    ));
  }

  function renderLeftSide() {
    return (
      <Flex direction="column" gap={4}>
        <FormControl isInvalid={!!errors.fullName}>
          <FormLabel>Nome completo</FormLabel>
          <Input {...register("fullName")} placeholder="Alexandre Carvalho" />
          <FormErrorMessage>
            {errors.fullName && errors.fullName.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.username}>
          <FormLabel>Nome de usuário</FormLabel>
          <Input {...register("username")} placeholder="alexandre" />
          <FormErrorMessage>
            {errors.username && errors.username.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.email}>
          <FormLabel>E-mail</FormLabel>
          <Input {...register("email")} placeholder="alexandre@arbo.com" />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.cpf}>
          <FormLabel>CPF</FormLabel>
          <Input
            as={InputMask}
            mask="999.999.999-99"
            {...register("cpf")}
            placeholder="000.000.000-00"
          />
          <FormErrorMessage>
            {errors.cpf && errors.cpf.message}
          </FormErrorMessage>
        </FormControl>

        {!isLargerThan1320 && renderDateOfBirth()}

        <FormControl isInvalid={!!errors.aboutMe}>
          <FormLabel>Sobre Mim</FormLabel>
          <Textarea
            {...register("aboutMe")}
            placeholder="Ex: sou uma pessoa muito..."
            resize="vertical"
          />
          <FormErrorMessage>
            {errors.aboutMe && errors.aboutMe.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
    );
  }

  function renderRightSide() {
    return (
      <Flex direction="column" gap={4} w="100%">
        {isLargerThan1320 && renderDateOfBirth()}

        <FormControl isInvalid={!!errors.linkedIn}>
          <FormLabel>LinkedIn</FormLabel>
          <Input
            {...register("linkedIn")}
            placeholder="https://www.linkedin.com/in/alexandre"
          />
          <FormErrorMessage>
            {errors.linkedIn && errors.linkedIn.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.lattes}>
          <FormLabel>Lattes</FormLabel>
          <Input
            {...register("lattes")}
            placeholder="https://www.lattes.com/in/alexandre"
          />
          <FormErrorMessage>
            {errors.lattes && errors.lattes.message}
          </FormErrorMessage>
        </FormControl>

        {linkFields.length > 1 && !isLargerThan1320 && <Divider />}

        {renderLinkFields()}
        <Flex direction="column" align="center" gap={2}>
          <IconButton
            aria-label="add education link"
            icon={<MdAdd size="30px" />}
            height="40px"
            isRound
            onClick={appendLinkFields}
          />

          <Button
            variant="link"
            color="primary.500"
            fontWeight={700}
            onClick={appendLinkFields}
          >
            Adicionar mais um link
          </Button>
        </Flex>
        {fields.length > 1 && !isLargerThan1320 && <Divider />}

        {renderAcademicEducationFields()}

        <Flex direction="column" align="center" gap={2}>
          <IconButton
            aria-label="add education field"
            icon={<MdAdd size="30px" />}
            height="40px"
            isRound
            onClick={appendAcademicFields}
          />

          <Button
            variant="link"
            color="primary.500"
            fontWeight={700}
            onClick={appendAcademicFields}
          >
            Adicionar mais uma formação
          </Button>
        </Flex>
      </Flex>
    );
  }

  function handleUpdateUser(userData: UserFormType) {
    if (userData) {
      const [firstName, ...lastName] = userData.fullName
        .split(" ")
        .filter(Boolean);

      const educations = userData.educations.filter(
        ({ title, institution, startDate, endDate }) =>
          title && institution && startDate && endDate
      );
      const links = userData.links.filter(({ title, url }) => title && url);

      const userPreparedData = {
        ...prepareFormData(userData),
        firstName,
        lastName: lastName.join(" "),
        fullName: undefined,
        linkedIn: userData.linkedIn === "" ? null : userData.linkedIn,
        lattes: userData.lattes === "" ? null : userData.lattes,
        educations,
        links,
        image: undefined,
      };

      updateUser(userPreparedData);
    }
  }

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        onSubmit={handleSubmit(handleUpdateUser)}
        direction="column"
        gap={4}
      >
        <Flex
          gap={isLargerThan1320 ? 16 : 4}
          direction={isLargerThan1320 ? "row" : "column"}
        >
          <Flex direction="column" gap={6} w="100%">
            <Flex
              direction={isLargerThan1320 ? "row" : "column"}
              align="center"
              gap={8}
            >
              {isUpdatingImage ? (
                <Spinner />
              ) : user?.image ? (
                <Image
                  src={user?.image}
                  objectPosition="top"
                  objectFit="cover"
                  borderRadius="full"
                  boxSize={isLargerThan1320 ? 150 : 120}
                />
              ) : (
                <Flex
                  bg="gray.100"
                  align="center"
                  justify="center"
                  borderRadius="50%"
                  w={isLargerThan1320 ? 150 : 120}
                  h={isLargerThan1320 ? 150 : 120}
                >
                  <Icon as={FiUser} w={50} h={50} color="gray.400" />
                </Flex>
              )}

              <Flex>
                <Input
                  type="file"
                  id="update-profile-picture"
                  hidden
                  onChange={handleChangeImage}
                />

                <Button
                  as="label"
                  htmlFor="update-profile-picture"
                  variant="link"
                  cursor="pointer"
                >
                  Alterar foto
                </Button>
              </Flex>
            </Flex>

            {!isLargerThan1320 && <Divider mb={4} />}

            {isLargerThan1320 && renderLeftSide()}
          </Flex>

          {isLargerThan1320 && renderRightSide()}

          {!isLargerThan1320 && (
            <Tabs variant="soft-rounded" isFitted>
              <TabList>
                <Tab>Pessoais</Tab>
                <Tab>Acadêmicas</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{renderLeftSide()}</TabPanel>
                <TabPanel>{renderRightSide()}</TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Flex>

        <Flex
          direction={isLargerThan1320 ? "row" : "column-reverse"}
          justify="flex-end"
          gap={isLargerThan1320 ? 14 : 4}
          px={isLargerThan1320 ? 0 : 3}
        >
          <Button
            size={isLargerThan1320 ? "md" : "lg"}
            w={isLargerThan1320 ? "fit-content" : "100%"}
            variant="link"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            px={10}
            size={isLargerThan1320 ? "md" : "lg"}
            w={isLargerThan1320 ? "fit-content" : "100%"}
            isLoading={isUpdatingUser}
            isDisabled={!isDirty}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  );
}
