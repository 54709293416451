import { Button, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useEventUnsubscribe } from "hooks/event";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function UnsubscribeModal({ isOpen, onClose }: ModalProps) {
  const { id } = useParams();
  const navigate = useNavigate();

  const { mutate: unsubscribe, isSuccess } = useEventUnsubscribe();

  useEffect(() => {
    if (isSuccess) {
      navigate("/meus-eventos");
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalHeader pb="0">
        Tem certeza que deseja cancelar a participação neste evento?
      </ModalHeader>

      <ModalBody
        justifyContent="center"
        textAlign="center"
        fontSize="sm"
        maxW={300}
        mx="auto"
      >
        Você NÃO poderá retornar e continuar de onde parou!
      </ModalBody>

      <ModalFooter justifyContent="space-between">
        <Button
          variant="link"
          onClick={() => unsubscribe(id as string)}
          color="gray"
        >
          Confirmar
        </Button>

        <Button colorScheme="secondary" onClick={onClose}>
          Voltar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
