import { useToast } from "contexts/layout/toast/useToast";
import { Preferences, UpdatePasswordData } from "interfaces/User";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  confirmEmailRequest,
  deleteAccount,
  getUser,
  getUsersRequest,
  referFriend,
  resendConfirmEmailRequest,
  sendDeleteAccountCode,
  updatePassword,
  updatePreferencesRequest,
  verifyEmailRequest,
  verifyUsernameRequest,
} from "services/user";

export function useUsers(name?: string) {
  return useQuery(["users", name], () => getUsersRequest(name), {
    staleTime: 1000 * 30,
  });
}

export function useUser(username: string, isLoggedUserProfile?: boolean) {
  return useQuery(["user", username], () => getUser(username), {
    staleTime: 1000 * 30,
    enabled: Boolean(!isLoggedUserProfile && username),
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useVerifyUserEmail() {
  const { toast } = useToast();

  return useMutation((email: string) => verifyEmailRequest(email), {
    onError: () => {
      toast("Ocorreu um erro ao buscar o usuário.", "error");
    },
  });
}

export function useVerifyUsername(username: string) {
  const { toast } = useToast();

  return useQuery(
    ["username", username],
    () => verifyUsernameRequest(username),
    {
      enabled: Boolean(username),
      onError: () => {
        toast("Ocorreu um erro ao buscar os dados deste usuário.", "error");
      },
    }
  );
}

export function useConfirmEmail(code: string) {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  return useQuery("confirm-email", () => confirmEmailRequest(code), {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("me");
      toast("E-mail confirmado com sucesso.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao enviar o código de confirmação.", "error");
    },
  });
}

export function useResendConfirmationEmail() {
  const { toast } = useToast();

  return useMutation(() => resendConfirmEmailRequest(), {
    onError: () => {
      toast("Ocorreu um erro ao enviar o e-mail de confirmação.", "error");
    },
  });
}

export function useUpdatePassword() {
  const { toast } = useToast();

  return useMutation(
    ({ currentPassword, password, passwordConfirmation }: UpdatePasswordData) =>
      updatePassword({ currentPassword, password, passwordConfirmation }),
    {
      onSuccess: () => {
        toast("Senha atualizada com sucesso.", "success");
      },
      onError: () => {
        toast("Ocorreu um erro ao atualizar a senha.", "error");
      },
    }
  );
}

export function useUpdatePreference() {
  const { toast } = useToast();

  return useMutation(
    (props: { allowDataCollect: boolean; notificationOpts: Preferences }) =>
      updatePreferencesRequest(props),
    {
      onError: () => {
        toast("Ocorreu um erro ao atualizar as preferências.", "error");
      },
    }
  );
}

export function useSendDeleteAccountCode() {
  const { toast } = useToast();

  return useMutation(() => sendDeleteAccountCode(), {
    onSuccess: () => {
      toast("O código de exclusão foi enviado para o seu e-mail.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao enviar código de exclusão de conta.", "error");
    },
  });
}

export function useDeleteAccount() {
  const { toast } = useToast();

  return useMutation((token: string) => deleteAccount(token), {
    onSuccess: () => {
      toast("Conta excluída com sucesso.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao excluir conta.", "error");
    },
  });
}

export function useReferFriend() {
  const { toast } = useToast();

  return useMutation((email: string) => referFriend(email), {
    onSuccess: () => {
      toast("Convite enviado com sucesso.", "success");
    },
    onError: () => {
      toast("Ocorreu um erro ao enviar o convite.", "error");
    },
  });
}
