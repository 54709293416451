import { Option } from "interfaces/common";
import { MultipleChoiceQuestion } from "interfaces/event";
import {
  CreateEventSteps,
  EventUserLevel,
  ModeratorEmail,
} from "interfaces/EventOLD";
import { v4 as uuidv4 } from "uuid";

export const CREATE_EVENT_STEPS: {
  [key in CreateEventSteps]: CreateEventSteps;
} = {
  POST: "POST",
  QUIZ: "QUIZ",
  SETUP: "SETUP",
};

export const questionTypeOptions = [
  {
    label: "Múltipla escolha",
    value: "MULTIPLE_CHOICE",
  },
  {
    label: "Questão aberta",
    value: "OPEN",
  },
];

export const eventUserLevelOptions = [
  {
    label: "Participante",
    value: EventUserLevel.PARTICIPANT,
  },
  {
    label: "Organizador",
    value: EventUserLevel.ORGANIZER,
  },
];

export const defaultModeratorEmailField: ModeratorEmail = { email: "" };

export const defaultMultipleChoiceQuestion: MultipleChoiceQuestion = {
  id: uuidv4(),
  order: 1,
  title: "<p></p>",
  type: "MULTIPLE_CHOICE",
  choices: [
    { id: uuidv4(), text: "Opção 1", isCorrect: true },
    { id: uuidv4(), text: "Opção 2", isCorrect: false },
  ],
  remove: false,
  files: [],
};

export const weightOfQuestionsOptions: Option[] = [
  { label: "10%", value: "10" },
  { label: "20%", value: "20" },
  { label: "30%", value: "30" },
  { label: "40%", value: "40" },
  { label: "50%", value: "50" },
  { label: "60%", value: "60" },
  { label: "70%", value: "70" },
  { label: "80%", value: "80" },
  { label: "90%", value: "90" },
];

export const eventUserTypeOptions: Option[] = [
  { label: "Participante", value: "participant" },
  { label: "Organizador", value: "organizer" },
  { label: "Todos", value: "all" },
];

export const eventTimeTypeList: Option[] = [
  { label: "Todos", value: "all" },
  { label: "Futuros", value: "future" },
  { label: "Em Andamento", value: "in_progress" },
  { label: "Finalizados", value: "finished" },
];
