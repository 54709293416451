import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Editable: ComponentStyleConfig = {
  baseStyle: {
    input: {
      _focus: {
        px: 1,
      }
    },
    preview: {
      px: 1,
    }
  },
};

export default Editable;