import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSendRecoveryPasswordEmail } from "hooks/auth";
import { SendMailProps } from "interfaces/User";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FiMail } from "react-icons/fi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { sendMailSchema } from "./schema";

export function SendMail() {
  const navigate = useNavigate();

  const {
    mutate: sendRecoveryPasswordEmail,
    isLoading,
    isSuccess,
  } = useSendRecoveryPasswordEmail();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<SendMailProps>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(sendMailSchema),
  });

  const handleSendEmail = ({ email }: SendMailProps) => {
    sendRecoveryPasswordEmail(email);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("verificar-email");
    }
  }, [isSuccess]);

  return (
    <Flex
      direction="column"
      align="center"
      gap={8}
      w="100%"
      h="100%"
      px={8}
      pb={[8, 0]}
    >
      <Text maxW={310} fontSize="sm" color="gray.300" textAlign="center">
        Digite o e-mail utilizado em sua conta e enviaremos um link para criar
        uma nova senha:
      </Text>

      <Flex
        as="form"
        onSubmit={handleSubmit(handleSendEmail)}
        direction="column"
        justify="space-between"
        gap={4}
        w="100%"
        h="100%"
      >
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <InputGroup>
            <InputLeftElement
              children={<FiMail size={20} />}
              color={errors.email ? "error" : "primary.500"}
            />
            <Input
              placeholder="ex.:alexandre@arbo.com"
              {...register("email")}
            />
          </InputGroup>

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Flex direction="column" align="center" gap={4}>
          <Button
            isFullWidth
            type="submit"
            size="lg"
            isLoading={isLoading}
            isDisabled={!isDirty}
          >
            Enviar e-mail de recuperação
          </Button>

          <Button
            isFullWidth
            as={RouterLink}
            to="/login"
            size="lg"
            variant="outline"
          >
            Cancelar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
