import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Spinner: ComponentStyleConfig = {
  baseStyle: {
    color: "primary.500",
  },
  defaultProps: {
    size: "lg"
  }
};

export default Spinner;