import {
  Button,
  Divider,
  ListItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UnorderedList,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";

export function MobileFeaturesModal({ isOpen, onClose }: ModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalHeader maxW={300} fontSize="sm" textAlign="left">
        A Eduka Hub em sua versão responsiva, tem algumas limitações de
        funcionalidades, veja abaixo
      </ModalHeader>

      <Divider w="90%" mx="auto" />

      <ModalBody fontSize="sm" maxW={300} mx="auto">
        <UnorderedList fontSize="sm" fontWeight={300}>
          <ListItem>Não é possivel criar eventos;</ListItem>
          <ListItem>Não é possivel resolver chamados (de correção).</ListItem>
        </UnorderedList>
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="secondary" onClick={onClose}>
          Concordar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
