import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  IconButton,
  Radio,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { MdClose, MdDragIndicator } from "react-icons/md";
import { ChoiceOptionProps } from "./props";

export function ChoiceOption({
  choice,
  setChoice,
  index,
  order,
  onRemove,
  disableRemove,
}: ChoiceOptionProps) {
  const { id, isCorrect, text } = choice;

  const inputRef = useRef<HTMLDivElement | null>(null);

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  return (
    <Draggable
      key={id}
      draggableId={JSON.stringify({
        choiceId: id,
        order,
      })}
      index={index}
    >
      {(provided) => (
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          _hover={{
            ".show-only-on-hover": {
              transition: "all .3s ease",
              display: "flex",
              visibility: "visible",
            },
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          key={id}
        >
          <Flex justify="space-between" align="center" w="100%">
            <Flex align="center" gap={4}>
              <IconButton
                aria-label=""
                icon={<MdDragIndicator />}
                variant="link"
                minW="auto"
                height="20px"
                visibility="hidden"
                className="show-only-on-hover"
                {...provided.dragHandleProps}
              />

              <Flex as={FormControl} align="center" gap={2} w="100%">
                <Radio value={choice.id} />
                <Editable
                  color="black"
                  fontSize="sm"
                  fontWeight={isCorrect ? 700 : 400}
                  onClick={handleFocus}
                  value={text}
                  onChange={(value) => setChoice({ ...choice, text: value })}
                >
                  <EditablePreview ref={inputRef} />
                  <EditableInput />
                </Editable>
              </Flex>
            </Flex>

            <Flex align="center" gap={2}>
              {isCorrect && (
                <Text fontSize="xs" color="gray.300">
                  Resposta correta
                </Text>
              )}

              <IconButton
                display="none"
                className="show-only-on-hover"
                aria-label="remove option"
                icon={<MdClose />}
                variant="icon"
                minW="fit-content"
                height="20px"
                color="primary.500"
                onClick={() => onRemove(index)}
                isDisabled={disableRemove}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Draggable>
  );
}
