import { Box, Flex, Spinner, useMediaQuery } from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { Outlet } from "react-router-dom";

export function Layout() {
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { isLoading } = useAuth();

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="center"
        h="100vh"
        bg={{ base: "background", md: "backgroundGray" }}
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box
      maxW={1720}
      mx="auto"
      minH="100vh"
      bg={isLargerThan1080 ? "backgroundGray" : "background"}
    >
      <Outlet />
    </Box>
  );
}
