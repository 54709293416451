import type { ComponentStyleConfig } from "@chakra-ui/theme";

const activeLabelStyles = {
  transform: "scale(0.85)",
  left: 0,
  top: "-.65rem",
  padding: "0 .5rem",
  my: 0,
};

const FloatingLabel: ComponentStyleConfig = {
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
          {
            ...activeLabelStyles,
          },
        label: {
          top: ".85rem",
          left: 0,
          zIndex: 1,
          position: "absolute",
          bg: "background",
          color: "#13223EB2",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          mb: 1,
          transformOrigin: "left top",
          transition: "all .2s ease",
          fontSize: "xs",
          fontWeight: 400,
        },
        "> div": {
          label: {
            left: "2rem",
          },
        },
      },
    },
  },
};

export default FloatingLabel;
