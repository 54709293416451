import {
  Avatar,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { FiUser } from "react-icons/fi";
import {
  MdArrowDropDown,
  MdLogout,
  MdNotifications,
  MdOutlineAccountCircle,
  MdSettings,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { SignOutModal } from "../../SignOutModal";
import { HeaderRightSideProps } from "./props";

export function HeaderRightSide({ onNotificationsOpen }: HeaderRightSideProps) {
  const { user } = useAuth();

  const {
    isOpen: isSignOutModalOpen,
    onOpen: onSignOutModalOpen,
    onClose: onSignOutModalClose,
  } = useDisclosure();

  return (
    <Flex align="center" gap={7}>
      <IconButton
        variant="link"
        aria-label="toggle notifications"
        icon={<MdNotifications color="gray" />}
        onClick={onNotificationsOpen}
      />

      <Flex>
        <Link to={`/${user?.username}`}>
          {user?.image ? (
            <Image
              src={user.image}
              boxSize={16}
              borderRadius="full"
              objectFit="cover"
            />
          ) : (
            <Avatar
              icon={<FiUser size={24} />}
              boxSize={16}
              borderRadius="full"
            />
          )}
        </Link>

        <Menu autoSelect={false}>
          {({ isOpen: isMenuOpen }) => (
            <>
              <MenuButton
                alignItems="center"
                as={IconButton}
                variant="link"
                aria-label="toggle menu"
                icon={<MdArrowDropDown color="gray" />}
                transform={isMenuOpen ? "rotate(180deg)" : "rotate(0)"}
              />
              <MenuList
                border="none"
                borderRadius={20}
                p={5}
                zIndex={99999}
                color="gray.300"
              >
                <MenuItem
                  as={Link}
                  to={`/${user?.username}`}
                  icon={<Icon as={MdOutlineAccountCircle} size="1.15rem" />}
                  fontWeight={700}
                >
                  Meu Perfil
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/configuracoes"
                  icon={<Icon as={MdSettings} size="1.15rem" />}
                  fontWeight={700}
                >
                  Configurações
                </MenuItem>

                <MenuItem
                  onClick={onSignOutModalOpen}
                  icon={<MdLogout size="1.15rem" />}
                  fontWeight={700}
                >
                  Sair
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>

      <SignOutModal isOpen={isSignOutModalOpen} onClose={onSignOutModalClose} />
    </Flex>
  );
}
