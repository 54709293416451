import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  IconButton,
  RadioGroup,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Editor } from "components/Editor";
import { SelectComponent } from "components/Select";
import { questionTypeOptions } from "constants/event";
import { useEvent } from "contexts/event/useEvent";
import { OpenQuestion } from "interfaces/event";
import { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { IoMdTrash } from "react-icons/io";
import {
  MdDragIndicator,
  MdOutlineCircle,
  MdOutlineContentCopy,
  MdOutlineSettings,
  MdRadioButtonChecked,
  MdTextFields,
} from "react-icons/md";
import { isObjectEmpty } from "utils/isObjectEmpty";
import { v4 as uuidv4 } from "uuid";
import { ChoiceOption } from "../ChoiceOption";
import { OpenQuestionSettingsModal } from "../OpenQuestionSettingsModal";
import { AppendChoiceProps, QuestionEditorProps } from "./props";

export function QuestionEditor({
  order,
  title,
  id,
  onDuplicate,
  onRemove,
}: QuestionEditorProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { eventQuestionsAndAnswersForm } = useEvent();

  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = eventQuestionsAndAnswersForm;

  const {
    fields: choicesOptions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `questions.${order}.choices`,
  });

  const type = watch(`questions.${order}.type`);
  const question = watch(`questions.${order}`);
  const choices = watch(`questions.${order}.choices`);
  const files = watch(`questions.${order}.files`);

  const [hasOpenQuestionErrors, setHasOpenQuestionErrors] = useState(false);

  const appendChoice = ({ isOtherChoice }: AppendChoiceProps) => {
    append({
      id: uuidv4(),
      text: isOtherChoice ? "Outro" : "Nova opção",
      isCorrect: false,
    });
  };

  useEffect(() => {
    if (
      errors.questions &&
      errors.questions[order] &&
      question.type === "OPEN"
    ) {
      if (!isObjectEmpty(errors) && !errors.questions[order].title) {
        setHasOpenQuestionErrors(true);
        onOpen();
      }
    } else {
      setHasOpenQuestionErrors(false);
    }
  }, [errors.questions]);

  return (
    <Draggable draggableId={id} index={order}>
      {(provided) => (
        <FormProvider {...eventQuestionsAndAnswersForm}>
          <Flex
            direction="column"
            justify="center"
            gap={4}
            p={6}
            bg="white"
            border="1px solid"
            borderColor="gray.100"
            borderRadius={15}
            cursor="default"
            ref={provided.innerRef}
            {...provided.draggableProps}
            key={order}
          >
            <Controller
              control={control}
              name={`questions.${order}.type`}
              render={({ field: { ref, ...props } }) => (
                <SelectComponent
                  options={questionTypeOptions}
                  icon={
                    type === "MULTIPLE_CHOICE" ? (
                      <MdRadioButtonChecked />
                    ) : (
                      <MdTextFields />
                    )
                  }
                  inputRef={ref}
                  {...props}
                />
              )}
            />

            <FormControl
              isInvalid={!!(errors.questions && errors.questions[order]?.title)}
            >
              <Controller
                control={control}
                name={`questions.${order}.title`}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Editor
                    defaultValue={title}
                    placeholder=""
                    setAttachment={(file) =>
                      setValue(`questions.${order}.files`, [...files, file])
                    }
                    error={error}
                    onChange={onChange}
                  />
                )}
              />

              <FormErrorMessage>
                {errors.questions &&
                  errors.questions[order]?.title &&
                  errors.questions[order].title?.message}
              </FormErrorMessage>
            </FormControl>

            {type === "MULTIPLE_CHOICE" && (
              <Flex direction="column" gap={1} mb={4}>
                <RadioGroup
                  w="100%"
                  value={choices.find((choice) => choice.isCorrect)?.id}
                  onChange={(id) =>
                    setValue(
                      `questions.${order}.choices`,
                      choices.map((choice) => ({
                        ...choice,
                        isCorrect: id === choice.id,
                      }))
                    )
                  }
                >
                  <Droppable droppableId={`question-${order}`} type="choices">
                    {(provided) => (
                      <Flex direction="column" ref={provided.innerRef}>
                        {choicesOptions.map((choice, choiceIndex) => (
                          <Flex key={choice.id}>
                            <Controller
                              control={control}
                              name={`questions.${order}.choices.${choiceIndex}`}
                              render={({ field: { onChange, value } }) => (
                                <ChoiceOption
                                  choice={value}
                                  setChoice={onChange}
                                  index={choiceIndex}
                                  order={order}
                                  onRemove={() => remove(choiceIndex)}
                                  disableRemove={choicesOptions.length === 2}
                                />
                              )}
                            />
                          </Flex>
                        ))}
                        {provided.placeholder}
                      </Flex>
                    )}
                  </Droppable>
                </RadioGroup>

                {choicesOptions.length < 5 && (
                  <Flex align="center" gap={1} ml="2.15rem">
                    <Icon
                      as={MdOutlineCircle}
                      color="primary.500"
                      opacity={0.35}
                    />
                    <Button
                      variant="link"
                      color="black"
                      fontSize="sm"
                      onClick={() => appendChoice({ isOtherChoice: false })}
                    >
                      Adicionar opção
                    </Button>

                    <Text fontSize="sm" color="black">
                      ou
                    </Text>

                    <Button
                      variant="link"
                      fontSize="sm"
                      fontWeight={600}
                      onClick={() => appendChoice({ isOtherChoice: true })}
                    >
                      adicionar "Outro"
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}

            <Flex direction="column" gap={2}>
              <Divider />

              <Flex justify="space-between">
                <Flex gap={4}>
                  <IconButton
                    aria-label="drag question"
                    icon={<MdDragIndicator />}
                    variant="icon"
                    color="primary.500"
                    minW="auto"
                    {...provided.dragHandleProps}
                  />

                  {type === "OPEN" && (
                    <Button
                      leftIcon={<MdOutlineSettings />}
                      onClick={onOpen}
                      colorScheme={
                        hasOpenQuestionErrors ? "secondary" : "primary"
                      }
                    >
                      Configuração de resposta
                    </Button>
                  )}
                </Flex>

                <Flex gap={4}>
                  <IconButton
                    aria-label="copy question"
                    icon={<MdOutlineContentCopy />}
                    variant="icon"
                    color="primary.500"
                    colorScheme="secondary"
                    minW="auto"
                    onClick={() => onDuplicate(order, question)}
                  />

                  <IconButton
                    aria-label="delete question"
                    icon={<IoMdTrash />}
                    variant="icon"
                    color="primary.500"
                    minW="auto"
                    onClick={() => onRemove(order + 1)}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <OpenQuestionSettingsModal
            isOpen={isOpen}
            onClose={onClose}
            question={question as OpenQuestion}
            index={order}
          />
        </FormProvider>
      )}
    </Draggable>
  );
}
