import { Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { Form } from "./Form";

export function SignIn() {
  const isDesktop = useBreakpointValue({ md: "none" });

  return (
    <Flex direction="column">
      {isDesktop && (
        <Flex direction="column" align="center" mt={4} mb={10}>
          <Text fontSize="xs" color="gray.300">
            Seja bem-vindo(a),
          </Text>
          <Heading fontSize="2xl" color="primary.600">
            Acesse sua conta!
          </Heading>
        </Flex>
      )}

      <Form />
    </Flex>
  );
}
