import { Flex, List } from "@chakra-ui/react";
import { useGroups } from "hooks/group";
import { AccountItem } from "./AccountItem";
import { GroupListProps } from "./props";

export function AccountList({ name }: GroupListProps) {
  const { data: groups } = useGroups(name);

  return (
    <Flex
      as={List}
      direction="column"
      w="100%"
      gap={2}
    >
      {groups?.map((group) => <AccountItem key={group._id} {...group} />)}
    </Flex>
  );
}
