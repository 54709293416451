import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useResetPassword } from "hooks/auth";
import { ResetPasswordProps } from "interfaces/User";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { createNewPasswordSchema } from "./schema";

export function Form() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const { mutate: resetPassword, isLoading, isSuccess } = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordProps>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(createNewPasswordSchema),
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChangePasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleResetPassword = ({
    password,
    passwordConfirmation,
  }: ResetPasswordProps) => {
    if (token) {
      resetPassword({ password, passwordConfirmation, token });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
    }
  }, [isSuccess]);

  return (
    <Flex direction="column" gap={8} w="100%" h="100%" px={8} pb={[8, 0]}>
      <Flex
        as="form"
        onSubmit={handleSubmit(handleResetPassword)}
        direction="column"
        justify="space-between"
        gap={4}
        w="100%"
        h="100%"
      >
        <Flex direction="column" gap={4}>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Nova senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<MdLockOutline size={20} />}
                color={errors.password ? "error" : "primary.500"}
              />
              <Input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="••••••••"
                {...register("password")}
              />

              <InputRightElement
                children={
                  <IconButton
                    variant="icon"
                    aria-label="toggle password visibilty"
                    icon={isPasswordVisible ? <FiEye /> : <FiEyeOff />}
                    fontSize={20}
                    onClick={handleChangePasswordVisible}
                  />
                }
                color={errors.password ? "error" : "gray.200"}
              />
            </InputGroup>

            <FormErrorMessage maxW={300}>
              {errors.password?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.passwordConfirmation}>
            <FormLabel>Repetir nova senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<MdLockOutline size={20} />}
                color={errors.passwordConfirmation ? "error" : "primary.500"}
              />
              <Input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="••••••••"
                {...register("passwordConfirmation")}
              />
              <InputRightElement
                children={
                  <IconButton
                    variant="icon"
                    aria-label="toggle password visibilty"
                    icon={isPasswordVisible ? <FiEye /> : <FiEyeOff />}
                    fontSize={20}
                    onClick={handleChangePasswordVisible}
                  />
                }
                color={errors.passwordConfirmation ? "error" : "gray.200"}
              />
            </InputGroup>

            <FormErrorMessage w="fit-content">
              {errors.passwordConfirmation?.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex direction="column" align="center" gap={4}>
          <Button isFullWidth size="lg" type="submit" isLoading={isLoading}>
            Criar nova senha
          </Button>

          <Button
            isFullWidth
            as={RouterLink}
            to="/login"
            size="lg"
            variant="outline"
          >
            Cancelar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
