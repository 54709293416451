import { Flex, Grid, Spinner, Text } from "@chakra-ui/react";
import { CallsParticipantsCard } from "components/Cards/CallsParticipantsCard";
import { EmptyContent } from "components/EmptyContent";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { useAuth } from "contexts/auth/useAuth";
import {
  useEvent,
  useQuestionsAnswerEvent,
  useSubmissionRequest,
} from "hooks/event";
import { MdPeople } from "react-icons/md";
import { useParams } from "react-router-dom";

export function MyAnswer() {
  const { id } = useParams();
  const { user } = useAuth();

  const { data: detailsEvent } = useEvent(id);
  const { data: detailsAnswerEvent } = useQuestionsAnswerEvent(id);
  const { data: answerCorrected, isLoading: isLoadingAnswerCorrected } =
    useSubmissionRequest(detailsAnswerEvent?._id);

  const answerCorrectedByOrder =
    answerCorrected?.sort((a, b) => a.question.order - b.question.order) ?? [];

  return (
    <>
      <Header
        title={`${detailsEvent?.name ? detailsEvent.name : ""}`}
        goback={`/meus-eventos/${id}`}
      />

      <Paper px={8} py={10} h="100%" overflow="auto">
        <Text fontSize="1.5rem" fontWeight="800" color="black">
          Minhas respostas
        </Text>
        <Text fontSize="1.125rem" fontWeight="700" color="gray.400">
          Etapa Atual: {detailsEvent?.isFinished ? "Finalizado" : "Recurso"}
        </Text>
        {isLoadingAnswerCorrected ? (
          <Flex w="100%" h="100vh" justify="center" align="center">
            <Spinner />
          </Flex>
        ) : (
          <Flex
            direction="column"
            justifyContent="space-between"
            mt={8}
            flex={1}
          >
            {answerCorrectedByOrder?.length === 0 ? (
              <Flex h="100%" w="100%" align="center" justify="center">
                <EmptyContent
                  message="Nenhuma submissão disponível."
                  icon={MdPeople}
                />
              </Flex>
            ) : (
              <Grid
                templateColumns={
                  answerCorrected?.length === 0
                    ? "1fr"
                    : "repeat(auto-fill, minmax(500px, 1fr))"
                }
                templateRows="auto"
                alignItems={
                  answerCorrected?.length === 0 ? "center" : "flex-start"
                }
                w="100%"
              >
                {answerCorrected &&
                  answerCorrectedByOrder?.map((el, index) => (
                    <Flex
                      key={el._id}
                      borderBottomWidth={{
                        base:
                          index === answerCorrected.length - 1 ? "0px" : "1px",
                        lg:
                          answerCorrected.length % 2 === 0 &&
                          (index === answerCorrected.length - 1 ||
                            index === answerCorrected.length - 2)
                            ? "0px"
                            : answerCorrected.length % 2 === 1 &&
                              index === answerCorrected.length - 1
                            ? "0px"
                            : "1px",
                      }}
                      borderColor="gray.100"
                      mb={4}
                      pr={12}
                    >
                      <CallsParticipantsCard
                        isNullify={el.appeal && el.appeal.feedback === null}
                        redirect={`${el._id}`}
                        image={user?.image as string}
                        name={`${user?.firstName} ${user?.lastName}`}
                        isFeedBack={el?.appeal && el?.appeal.feedback !== null}
                        isBorderButtom={false}
                        numberQuestions={el.question.order}
                      />
                    </Flex>
                  ))}
              </Grid>
            )}
          </Flex>
        )}
      </Paper>
    </>
  );
}
