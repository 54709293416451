import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import report from "assets/report.svg";
import checked from "assets/check-circle.svg";
import { CallsParticipantsProps } from "./props";

export function CallsParticipantsCard({
  numberQuestions,
  image,
  name,
  isBorderButtom = true,
  redirect,
  isFeedBack,
  isNullify,
}: CallsParticipantsProps) {
  return (
    <Flex
      w="100%"
      borderBottom={isBorderButtom ? "1px solid" : "0px"}
      alignItems="center"
      justifyContent="space-between"
      pb="1rem"
    >
      <Flex direction="row" alignItems="center" gap={1.5}>
        {image ? (
          <Image
            boxSize={12}
            borderRadius="24px"
            objectFit="cover"
            src={image}
            alt="Dan Abramov"
            flexShrink={0}
          />
        ) : (
          <Flex
            bg="gray.200"
            align="center"
            justify="center"
            borderRadius="24px"
            w="48px"
            h="48px"
          >
            <Icon as={FiUser} w="48px" color="gray.500" />
          </Flex>
        )}
        <Flex flexDirection="column">
          <Flex>
            <Text fontSize="0.813rem" color="backgroundDark" fontWeight="400">
              {name}
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="0.875rem" color="gray.300">
              Questão {numberQuestions}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems="center" gap={4}>
        {isFeedBack && (
          <Flex
            bg="primary.500"
            borderRadius={5}
            alignItems="center"
            justifyContent="center"
            p="6px"
          >
            <Image src={checked} w="20px" h="20px" />
          </Flex>
        )}
        {isNullify && (
          <Flex
            bg="secondary.500"
            borderRadius={5}
            alignItems="center"
            justifyContent="center"
            p="6px"
          >
            <Image src={report} w="20px" h="20px" />
          </Flex>
        )}
        <Link to={redirect}>
          <Text fontSize="0.813rem" color="secondary.500">
            Visualizar
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
}
