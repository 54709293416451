import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Button: ComponentStyleConfig = {
  baseStyle: {
    color: "background",
    fontSize: "normal",
    fontWeight: 700,
    _focus: {
      boxShadow: "none",
    },
  },
  sizes: {
    icon: {
      height: "fit-content",
      width: "fit-content",
    },
    md: {
      borderRadius: 10,
      height: 42,
      fontSize: "normal",
    },
    lg: {
      borderRadius: 20,
      height: "60px",
      boxShadow: "6px 6px 12px 0px #18B9AA59",
      fontSize: "normal",
    },
  },
  variants: {
    icon: {
      bg: "transparent",
      color: "inherit",
      minW: "auto",
    },
    link: {
      boxShadow: "none",
      _hover: {
        textDecoration: "none",
      },
    },
    outline: {
      bg: "background",
      color: "primary.500",
    },
    outlineSecondary: {
      border: "1px solid",
      borderColor: "secondary.500",
      bg: "background",
      color: "secondary.500",
      _hover: {
        bg: "white",
      },
    },
  },
  defaultProps: {
    size: "md",
    colorScheme: "primary",
  },
};

export default Button;
