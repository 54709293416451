import {
  Avatar,
  Flex,
  IconButton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useReadNotification } from "hooks/notifications";
import { Notification } from "interfaces/notification";
import { useEffect } from "react";
import { MdKeyboardArrowRight, MdNotifications } from "react-icons/md";
import { Link } from "react-router-dom";
import { getNotificationTime } from "utils/getNotificationTime";

export function NotificationItem({
  id,
  isRead,
  message,
  sender: { image },
  createdAt,
  event,
  onClose,
}: Notification & { onClose(): void }) {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  const { mutate: readNotification, isSuccess } = useReadNotification(id);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess]);

  return (
    <Flex
      as={Link}
      to={`/meus-eventos/${event}`}
      w="100%"
      gap={4}
      onClick={() => readNotification()}
    >
      <Avatar
        src={image ?? undefined}
        icon={image ? <MdNotifications /> : undefined}
      />

      <Flex
        flex={1}
        direction={isLargerThan600 ? "row" : "column"}
        justify={isLargerThan600 ? "flex-start" : "space-between"}
        gap={isLargerThan600 ? 6 : 0}
      >
        <Text fontWeight={isRead ? 500 : 800} fontSize="xs" flex={1}>
          {message}
        </Text>
        <Text fontSize="xs" whiteSpace="nowrap">
          {getNotificationTime(new Date(createdAt), isLargerThan600)}
        </Text>
      </Flex>

      {!isLargerThan600 && (
        <IconButton
          aria-label=""
          icon={<MdKeyboardArrowRight />}
          variant="link"
        />
      )}
    </Flex>
  );
}
