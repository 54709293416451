import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Skeleton,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { useEvent as useEventContext } from "contexts/event/useEvent";
import { endOfMinute, isPast } from "date-fns";
import {
  useEvent,
  useEventLookup,
  useEventQuestions,
  useEventQuestionsRestricted,
  usePendingCorrectionsCount,
} from "hooks/event";
import { EventStatusLookupEnum, EventType } from "interfaces/event";
import { User } from "interfaces/User";
import { Link as RouterLink, useParams } from "react-router-dom";
import { formatDate } from "utils/format";

import { isWithinThePeriod } from "utils/isWithinThePeriod";
import { CorrectionRecommendationsModal } from "../CorrectionRecommendationsModal";

export function Stages() {
  const { id } = useParams();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const {
    isOpen: isCorrectionRecomendationsModalOpen,
    onOpen: onCorrectionRecomendationsModalOpen,
    onClose: onCorrectionRecomendationsModalClose,
  } = useDisclosure();

  const { user } = useAuth();

  const { data: eventFromRequest, isLoading: isEventLoading } = useEvent(id);
  const { data: questions } = useEventQuestions(id);
  const { data: questionsFromRequest } = useEventQuestionsRestricted(id, {
    enabled: !!user,
  });
  const { data: lookup } = useEventLookup(id, {
    enabled: !!user,
  });

  const { event: eventFromContext } = useEventContext();

  const event =
    id && !eventFromContext
      ? {
        ...eventFromRequest,
        categories: eventFromRequest?.categories?.map(({ name }) => name),
        questions: questionsFromRequest?.map((question) => ({
          ...question,
          remove: false,
        })),
      }
      : eventFromContext;

  const { data: count } = usePendingCorrectionsCount(
    {
      ...(event as EventType),
      id,
    },
    {
      enabled: !!user,
    }
  );

  const loggedUserIsAModerator = (event?.moderators as User[])?.some(
    (moderator) => moderator.id === user?.id
  );
  const loggedUserIsAParticipant = (event as EventType).participants?.some(
    (participantId) => participantId === user?.id
  );

  return (
    <Flex w="100%" direction="column" gap={isLargerThan1080 ? 8 : 3}>
      {!isLargerThan1080 && (
        <Heading fontSize="lg" color="gray.400">
          Histórico do Evento
        </Heading>
      )}

      <Flex
        align="center"
        gap={2}
        opacity={
          (event?.responsePeriodEnd &&
            isPast(endOfMinute(new Date(event.responsePeriodEnd)))) ||
            event?.isFinished
            ? 0.5
            : 1
        }
      >
        <Box
          h="95%"
          w="5px"
          bg={
            isWithinThePeriod(
              event?.responsePeriodStart,
              event?.responsePeriodEnd
            ) && !event?.isFinished
              ? "primary.500"
              : "#FBFBFB"
          }
        />

        <Flex direction="column" gap={2} flex={1} w="100%">
          <Skeleton isLoaded={!isEventLoading}>
            <Text fontWeight={700} fontSize="lg" color="gray.400">
              Período de Resposta do Evento
            </Text>
          </Skeleton>

          <Skeleton isLoaded={!isEventLoading}>
            <Text color="gray.300">
              De {formatDate(event?.responsePeriodStart as string)}
            </Text>
            <Text color="gray.300">
              Até {formatDate(event?.responsePeriodEnd as string)}
            </Text>
          </Skeleton>
        </Flex>
      </Flex>

      <Flex
        align="center"
        gap={2}
        opacity={
          (event?.correctionPeriodEnd &&
            isPast(endOfMinute(new Date(event.correctionPeriodEnd)))) ||
            event?.isFinished
            ? 0.5
            : 1
        }
      >
        <Box
          h="95%"
          w="5px"
          bg={
            isWithinThePeriod(
              event?.correctionPeriodStart,
              event?.correctionPeriodEnd
            ) && !event?.isFinished
              ? "primary.500"
              : "#FBFBFB"
          }
        />
        <Flex direction="column" gap={2} flex={1} w="100%">
          <Skeleton isLoaded={!isEventLoading}>
            <Text fontWeight={700} fontSize="lg" color="gray.400">
              Período de Correção do Evento
            </Text>
          </Skeleton>

          <Skeleton isLoaded={!isEventLoading}>
            <Text color="gray.300">
              De {formatDate(event?.correctionPeriodStart as string)}
            </Text>
            <Text color="gray.300">
              Até {formatDate(event?.correctionPeriodEnd as string)}
            </Text>
          </Skeleton>

          {isWithinThePeriod(
            event?.correctionPeriodStart,
            event?.correctionPeriodEnd
          ) &&
            !event?.isFinished &&
            !event.isDraft &&
            questions?.some((question) => question.type === "OPEN") &&
            (loggedUserIsAModerator ||
              (loggedUserIsAParticipant && event.allowPeerCorrection && event.distributionOccurred) ||
              (!event.allowModerators && !event.allowPeerCorrection)) && (
              <Skeleton isLoaded={!isEventLoading}>
                <Flex direction="column" gap={2}>
                  {event?.allowPeerCorrection && count !== 0 && (
                    <Text maxW={269} color="gray.300">
                      Você tem questões de {count} participantes para corrigir.
                    </Text>
                  )}

                  <Button
                    variant="link"
                    onClick={onCorrectionRecomendationsModalOpen}
                    w="fit-content"
                    isDisabled={event?.allowPeerCorrection && count === 0}
                  >
                    Corrigir agora
                  </Button>
                </Flex>
              </Skeleton>
            )}
        </Flex>
      </Flex>

      {id &&
        (event as EventType).appealPeriodStart &&
        (event as EventType).appealPeriodStart && (
          <>
            <Flex
              align="center"
              gap={2}
              opacity={
                ((event as EventType)?.appealPeriodEnd &&
                  isPast(
                    endOfMinute(new Date((event as EventType).appealPeriodEnd))
                  )) ||
                  event?.isFinished
                  ? 0.5
                  : 1
              }
            >
              <Box
                h="95%"
                w="5px"
                bg={
                  isWithinThePeriod(
                    (event as EventType)?.appealPeriodStart,
                    (event as EventType)?.appealPeriodEnd
                  ) && !event?.isFinished
                    ? "primary.500"
                    : "#FBFBFB"
                }
              />
              <Flex direction="column" gap={2} flex={1} w="100%">
                <Skeleton isLoaded={!isEventLoading}>
                  <Text fontWeight={700} fontSize="lg" color="gray.400">
                    Período de Recurso do Evento
                  </Text>
                </Skeleton>

                <Skeleton isLoaded={!isEventLoading}>
                  <Text color="gray.300">
                    De {formatDate((event as EventType)?.appealPeriodStart)}
                  </Text>
                  <Text color="gray.300">
                    Até {formatDate((event as EventType)?.appealPeriodEnd)}
                  </Text>
                </Skeleton>

                {(lookup === EventStatusLookupEnum.CORRECTED_ANSWERS ||
                  lookup === EventStatusLookupEnum.ANSWERED) &&
                  isWithinThePeriod(
                    (event as EventType)?.appealPeriodStart,
                    (event as EventType)?.appealPeriodEnd
                  ) &&
                  !event?.isFinished && (
                    <Skeleton isLoaded={!isEventLoading}>
                      <Link as={RouterLink} to="minhas-respostas">
                        Ver minhas respostas corrigidas
                      </Link>
                    </Skeleton>
                  )}
              </Flex>
            </Flex>

            {event?.isFinished && (
              <Flex align="center" gap={2}>
                <Box h="95%" w="5px" bg="primary.500" />
                <Flex direction="column" gap={2} flex={1} w="100%">
                  <Skeleton isLoaded={!isEventLoading}>
                    <Text fontWeight={700} fontSize="lg" color="gray.400">
                      Evento Finalizado
                    </Text>
                  </Skeleton>

                  {lookup === EventStatusLookupEnum.CORRECTED_ANSWERS && (
                    <Skeleton isLoaded={!isEventLoading}>
                      <Link as={RouterLink} to="minhas-respostas">
                        Seu resultado final!
                      </Link>
                    </Skeleton>
                  )}
                </Flex>
              </Flex>
            )}
          </>
        )}

      <CorrectionRecommendationsModal
        isOpen={isCorrectionRecomendationsModalOpen}
        onClose={onCorrectionRecomendationsModalClose}
      />
    </Flex>
  );
}
