import { extendTheme, FormErrorMessage } from "@chakra-ui/react";
import Accordion from "./components/Accordion";
import { Avatar } from "./components/Avatar";
import Badge from "./components/Badge";
import CloseButton from "./components/CloseButton";
import Button from "./components/Forms/Button";
import Editable from "./components/Forms/Editable";
import FloatingLabel from "./components/Forms/FloatingLabel";
import FormLabel from "./components/Forms/FormLabel";
import Input from "./components/Forms/Input";
import Radio from "./components/Forms/Radio";
import Switch from "./components/Forms/Switch";
import Textarea from "./components/Forms/Textarea";
import Heading from "./components/Heading";
import IconButton from "./components/IconButton";
import Link from "./components/Link";
import Menu from "./components/Menu";
import Modal from "./components/Modal";
import Progress from "./components/Progress";
import Skeleton from "./components/Skeleton";
import Spinner from "./components/Spinner";
import Table from "./components/Table";
import Tabs from "./components/Tabs";
import Text from "./components/Text";
import UnorderedList from "./components/UnorderedList";

export const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        bg: "backgroundGray",
      },
    },
  },
  fonts: {
    body: "Nunito, sans-serif",
    heading: "Nunito, sans-serif",
  },
  fontSizes: {
    "2xs": "0.625rem",
  },
  colors: {
    white: "#F3F9F8",
    grayText: "#A0AAA9",
    black: "#0C0F0F",
    gray: {
      50: "#FBFBFB",
      100: "#DEE7E6",
      200: "#CAD2D2",
      300: "#7A8483",
      400: "#5C6160",
    },
    primary: {
      50: "#EBFFFF",
      100: "#D4FFFB",
      500: "#18B9AA",
      600: "#287C74",
      700: "#1C5B55",
    },
    secondary: {
      500: "#FF6584",
      600: "#7B2335",
      700: "#FF6584",
    },
    background: "#FFFFFF",
    backgroundGray: "#F3F3F3",
    backgroundDark: "#000000",
    error: "#CB1D1D",
    success: "#71D40D",
    warning: "#F7B230",
  },
  components: {
    Button,
    Input,
    FormLabel,
    Textarea,
    Tabs,
    Table,
    Switch,
    Editable,
    Form: {
      ...FloatingLabel,
    },
    FormErrorMessage,
    Text,
    Heading,
    Link,
    Menu,
    Radio,
    Modal,
    Spinner,
    CloseButton,
    UnorderedList,
    IconButton,
    Accordion,
    Badge,
    Skeleton,
    Progress,
    Avatar,
  },
});
