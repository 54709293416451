import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import alphabeticalOrder from "assets/alphabetical-order.svg";
import numericalOrder from "assets/numerical-order.svg";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { useGroupEvents, useGroupRanking } from "hooks/group";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Headers } from "react-csv/components/CommonPropTypes";
import { HiOutlineDownload } from "react-icons/hi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useParams } from "react-router-dom";
import { formatDecimal } from "utils/format";
import { makeFullName } from "utils/makeFullName";

const LIMIT = 2;

export function Ranking() {
  const { id } = useParams();

  const { data: ranking } = useGroupRanking(id as string);
  const { data: events, isLoading } = useGroupEvents(id as string);

  const [page, setPage] = useState(1);
  const indexOfLastEvent = page * LIMIT;
  const indexOfFirstEvent = indexOfLastEvent - LIMIT;
  const currentEvents = events?.slice(indexOfFirstEvent, indexOfLastEvent);

  const headers = [
    { label: "participante", key: "participant" },
    ...(events?.map((event) => ({ label: event.name, key: event.id })) ?? ""),
    { label: "resultado", key: "result" },
  ] as Headers;
  const data = ranking?.map(({ submissions, totalGrade, user }) => ({
    participant: makeFullName(user.firstName, user.lastName),
    ...submissions.reduce(
      (fields, submission) => ({
        ...fields,
        [submission.event._id]: formatDecimal(submission.grade) ?? 0,
      }),
      {}
    ),
    result: formatDecimal(totalGrade),
  }));

  const toPreviousPage = () => {
    if (page > 1) {
      setPage((page) => page - 1);
    }
  };

  const toNextPage = () => {
    if (events && page * LIMIT <= events?.length) {
      setPage((page) => page + 1);
    }
  };

  return (
    <Flex direction="column" gap={8}>
      <Flex justify="space-between" w="full" align="center">
        <Button
          leftIcon={<HiOutlineDownload />}
          isDisabled={ranking?.length === 0}
        >
          <CSVLink data={data ?? []} headers={headers || undefined}>
            {" "}
            Exportar Notas (.csv)
          </CSVLink>
        </Button>

        <ButtonGroup size="sm" isAttached>
          <Button>
            <Image src={alphabeticalOrder} />
          </Button>

          <Button>
            <Image src={numericalOrder} />
          </Button>
        </ButtonGroup>
      </Flex>

      {isLoading ? (
        <Spinner />
      ) : ranking?.length === 0 ? (
        <Text fontSize="sm">Nenhum resultado disponível.</Text>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Participantes</Th>
              <Th textAlign="right">
                <IconButton
                  aria-label=""
                  variant="link"
                  minW="fit-content"
                  icon={<MdKeyboardArrowLeft />}
                  onClick={toPreviousPage}
                  disabled={!(page > 1)}
                  colorScheme="black"
                />
              </Th>

              {currentEvents && (
                <>
                  <Th textAlign="center">{currentEvents[0]?.name ?? ""}</Th>

                  <Th textAlign="center">{currentEvents[1]?.name ?? ""}</Th>
                </>
              )}
              <Th textAlign="left">
                <IconButton
                  aria-label=""
                  variant="link"
                  minW="fit-content"
                  icon={<MdKeyboardArrowRight />}
                  onClick={toNextPage}
                  disabled={events && !(page * LIMIT <= events?.length)}
                  colorScheme="black"
                />
              </Th>
              <Th textAlign="center">Resultado</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ranking?.map(({ submissions, totalGrade, user }) => (
              <Tr key={totalGrade}>
                <Td>
                  <ParticipantCard showEmail user={user} />
                </Td>

                <Td />

                {currentEvents && (
                  <>
                    <Td textAlign="center">
                      {currentEvents[0] &&
                        (formatDecimal(
                          submissions?.slice(
                            indexOfFirstEvent,
                            indexOfLastEvent
                          )[0]?.grade
                        ) ??
                          0)}
                    </Td>

                    <Td textAlign="center">
                      {currentEvents[1] &&
                        (formatDecimal(
                          submissions?.slice(
                            indexOfFirstEvent,
                            indexOfLastEvent
                          )[1]?.grade
                        ) ??
                          0)}
                    </Td>
                  </>
                )}

                <Td />

                <Td textAlign="center">{formatDecimal(totalGrade)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Flex>
  );
}
