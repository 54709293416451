import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInWeeks,
  format,
} from "date-fns";
import { formatDate } from "./format";

export function getNotificationTime(time: Date, isDesktop: boolean) {
  const today = new Date();

  if (differenceInMinutes(today, time) > 0) {
    if (differenceInHours(today, time) > 0) {
      if (differenceInDays(today, time) > 0) {
        if (differenceInWeeks(today, time) > 0) {
          return !isDesktop ? formatDate(time)  : format(time, "HH:mm");
        }

        return `Há ${differenceInDays(today, time)}d`;
      }

      return isDesktop ? `Há ${differenceInHours(today, time)}h` : format(time, "HH:mm");
    }

    return isDesktop && `Há ${differenceInMinutes(today, time)} min`;
  }

  return isDesktop && `Há ${differenceInSeconds(today, time)}s`;
}
