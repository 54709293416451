import { Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export function SignUp() {
  const isDesktop = useBreakpointValue({ md: "none" });

  return (
    <Flex direction="column" h="100%">
      {isDesktop && (
        <Flex direction="column" align="center" mt={4} mb={10}>
          <Text fontSize="xs" color="gray.300">
            Seja bem-vindo(a),
          </Text>
          <Heading fontSize="2xl" color="primary.600">
            Crie sua conta
          </Heading>
        </Flex>
      )}

      <Outlet />
    </Flex>
  );
}
