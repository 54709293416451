import { EventInfo } from "components/Events/EventInfo";
import { Header } from "components/Layout/Dashboard/Header";
import { useEvent } from "hooks/event";
import { NotFound } from "pages/NotFound";
import { useParams } from "react-router-dom";

export function EventDetails() {
  const { id } = useParams();
  const { isError } = useEvent(id as string);

  return (
    <>
      <Header title="Detalhes do Evento" goback="/meus-eventos" />

      {isError ? <NotFound /> : <EventInfo />}
    </>
  );
}
