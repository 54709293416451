import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Link: ComponentStyleConfig = {
  baseStyle: {
    color: "primary.500",
    fontWeight: 700,
    fontSize: "md",
    _focus: {
      boxShadow: "none",
      textDecoration: "none",
    },
    _active: {
      textDecoration: "none",
    }
  }
};

export default Link;