export const MURAL_WELCOME_MESSAGE =
  "Seja bem vindo à nossa comunidade de aprendizado. Nossa plataforma oferece a criação de eventos acadêmicos com a possibilidade de correção em pares (peer assessment). Estamos na etapa de testes, e por isso seu feedback é muito importante. Dúvidas podem ser dirimidas na seção ‘ajuda’, no menu principal, ou através do nosso email:";

export const RESPONSE_RECOMMENDATIONS = [
  "Bem vindo a etapa de respostas do nosso evento.",
  "Antes de responder a cada questão, revise o enunciado.",
  "As respostas são individuais.",
  "Textos de autoria de terceiros (retirados da web) devem ter a indicação da fonte.",
  "Caso o evento tenha correção em pares, lembre-se de na próxima etapa realizar a correção das atividades dos seus colegas. Nesta etapa, será disponibilizado o padrão de resposta que servirá como parâmetro para a correção.",
  "Atente para a data limite de resposta",
  "Boa atividade!",
];

export const CORRECTION_RECOMMENDATIONS = [
  "Corrija as questões com a máxima dedicação. Lembre que um colega irá corrigir o seu trabalho, e você espera a mesma dedicação dele.",
  "Faça a correção com base no padrão de resposta disponibilizado pelo organizador do evento, e em função dos critérios para correção, os quais permitem definir o valor da nota em uma escala de 0 a 100 pontos.",
  "O feedback para o participante é obrigatório, e em um texto breve, você deve expor os motivos que justificam a nota atribuída.",
  "Fique a vontade para elogiar, mas se for criticar, o faça de forma construtiva e educada.",
  "Boa correção!",
];

const CNPJ = "49.224.059/0001-52";
export const EMAIL = "contato@edukahub.com.br";

export const COMPANY_NAME = "Arbo Tecnologia Educacional LTDA";

export const HELP_QA = [
  {
    question: "O que é a Eduka Hub?",
    answer:
      "É uma plataforma acadêmica que busca melhorar a forma de aprendizado, congregando alunos, professores e organizações. Nossa premissa básica é: acreditamos que a tecnologia é parceira do ensino, e ao proporcionar maior autonomia para os alunos, aumenta o engajamento e a retenção de conhecimento.",
  },
  {
    question: "O que é correção em pares?",
    answer:
      "Correção em pares compõe uma das metodologias ativas, as quais colocam o aluno no centro do processo de aprendizado. Em específico, correção em pares (peer assessment) possibilita que cada aluno (participante do evento) possa corrigir o trabalho de outro participante, no lugar do professor. Tal técnica já é utilizada há bastante tempo, de forma presencial, e apresenta resultados consistentes em melhoria de retenção de conhecimento e engajamento dos discentes. <br/> <br/> A literatura acadêmica já evidencia os ganhos de aprendizagem mediada por pares, principalmente com a utilização de recursos computacionais e com padrões de correções adequadamente estabelecidos. Seguem alguns exemplos de meta-análises sobre o tema: <br/><br/> <a target='_blank' href='https://www.tandfonline.com/doi/full/10.1080/02602938.2019.1600186'>Full article: An empirical review of anonymity effects in peer assessment, peer feedback, peer review, peer evaluation and peer grading</a> <br/> <br/><a target='_blank' href='https://www.tandfonline.com/doi/abs/10.1080/02602938.2017.1339775'>Academics’ perceptions of the benefits and challenges of self and peer assessment in higher education </a> <br/><br/> <a target='_blank' href='https://oaj.fupress.net/index.php/formare/article/view/8339'>The effectiveness of peer assessment on students’ performance in higher education. Evidence of an overview of meta-analyses | Form@re </a> ",
  },
  {
    question: "Como funciona a correção em pares na Eduka Hub?",
    answer:
      "Na Eduka Hub, a correção em pares se dá com uso de tecnologia, a plataforma em si, o que permite aplicação virtual, e com a vantagem da correção de forma anônima. Ou seja, o participante não sabe de quem são os trabalhos que avaliou, bem como não sabe quem avaliou a sua atividade.",
  },
  {
    question: "Como a Eduka Hub funciona? E a etapa de recurso?",
    answer:
      "A partir de um cadastro simples, o usuário da plataforma pode organizar eventos acadêmicos e compartilhar estes eventos com os demais usuários. Quem cria o evento é chamado de organizador (eg. professor), e pode incluir nesse evento questões de múltipla escolha ou abertas. Para as questões de múltipla escolha, a correção é automática pelo sistema, já que o professor ao cadastrar as questões, indica o item correto. Para as questões abertas, o organizador terá duas opções de correção: 1) de forma tradicional; e 2) com a utilização da correção em pares. <br/> <br/> Na opção com correção em pares, o organizador informará no cadastro da questão, o ‘padrão de resposta’ esperado para a questão aberta, bem como os critérios para determinar a pontuação da questão. Tais informações serão disponibilizadas na etapa de correção, bem como o sistema irá atribuir a resposta (de forma anônima) de dois colegas para correção. Vencida a etapa de correção, os participantes terão acesso às suas notas atribuídas pelos colegas.  <br/><br/> Caso o participante se sinta prejudicado, poderá abrir um chamado durante a etapa de recurso, e expor ao professor, à luz da nota e feedbacks obtidos, e em confronto ao  padrão de resposta definido, pleitear a revisão de sua nota. O professor poderá acatar, ou não, o pleito, e em acatando, atribuirá nova nota.",
  },
  {
    question: "O que são moderadores?",
    answer:
      "Moderadores são participantes adicionais convidados pelo organizador do evento, os quais têm a prerrogativa de corrigir os trabalhos dos demais participantes. Tal opção é possível apenas em eventos que NÃO tenham a correção em pares ativada.",
  },
  {
    question: "Como funcionam os grupos?",
    answer:
      "Os organizadores podem criar grupos com os usuários que já estejam cadastrados na plataforma, e assim disponibilizar materiais (arquivos e links) para um público previamente definido. Também será possível associar um evento a um determinado grupo, e nesse caso, a inscrição dos membros do grupo será automática. <br/> A ideia dos grupos também é facilitar a exportação das notas na forma de uma planilha.",
  },
  {
    question: "Quais os resultados da validação do método?",
    answer:
      "Nas avaliações já realizadas da metodologia da Eduka Hub, os resultados indicam um engajamento maior para 62,5% dos participantes, além de razoável capacidade para avaliar o trabalho do colega. <br/> Para 81% dos entrevistados, as notas recebidas foram justas, e para 75% destes, o aprendizado decorrente das atividades foi maior, se comparado ao método tradicional. Mesmo percentual de 75% se disse satisfeito com as atividades realizadas, e tem interesse em repetir a experiência. </br> Adicionalmente, em função do percentual de alunos que participaram da etapa de correção, e do número de recursos abertos, o tempo dedicado à correção pelo docente diminuiu em 94%.",
  },
  {
    question: "Como elaborar questões com base no ENADE?",
    answer:
      "Do ponto de vista pedagógico, nossa sugestão é que as questões dos eventos sejam elaboradas no modelo ‘ENADE’. Ou seja, em formato que valorize a aplicação dos conceitos, em detrimento de memorizações rasas. As questões também devem ser inéditas, ou adaptadas, de modo a evitar o uso de respostas prontas disponíveis na web. </br> Maiores informações com relação ao formato das questões podem ser encontradas em: <br/><br/> <a href='http://www.adventista.edu.br/_imagens/area_academica/files/Como%20elaborar%20provas%20estilo%20enade(1).pdf' target='_blank'>Orientação para elaboração de provas de acordo com o ENADE</a> <br/> (Obs: material de terceiros, disponibilizado de forma pública)",
  },
  {
    question: "O uso da plataforma é gratuito?",
    answer:
      "SIM, o uso da plataforma é gratuito para os participantes dos eventos, e durante nossa fase de testes, também será gratuito para os organizadores. <br/> NÃO, o uso da plataforma é pago, mediante assinatura, para os organizadores de eventos, vencida a etapa de testes. </br> CONTUDO, nosso modelo de negócio prevê uma categoria de conta gratuita para organizadores, com limitação na quantidade de eventos simultâneos e no acesso aos recursos adicionais.",
  },
  {
    question: "Meus dados estão protegidos?",
    answer:
      "SIM, seus dados estão protegidos em conformidade a LGPD (lei 13.709/2018) e Marco Civil da internet (Lei 12.965/2014), e dessa forma, não serão disponibilizados, vendidos ou permutados à terceiros, salvo por determinação judicial.",
  },
  {
    question: "Quais as informações legais da empresa?",
    answer: `Arbo Tecnologia Educacional (EdTech) <br/> CNPJ: ${CNPJ} </br> Av. Dom Luís, 880 - sala 506 - Aldeota Fortaleza - Ce <br/> Contato: <a href='mailto:${EMAIL}'>${EMAIL}</a>`,
  },
];
