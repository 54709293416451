import { Flex, List } from "@chakra-ui/react";
import { useGroups } from "hooks/group";
import { GroupItem } from "./GroupItem";
import { GroupListProps } from "./props";

export function GroupList({ name }: GroupListProps) {
  const { data: groups } = useGroups(name);

  return (
    <Flex
      as={List}
      direction="column"
      w="100%"
      gap={2}
    >
      {groups?.map((group) => <GroupItem key={group._id} {...group} />)}
    </Flex>
  );
}
