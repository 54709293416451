import { Box, Flex, Icon, ListItem, Text } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";

import { SidebarItemProps } from "./props";

export function SidebarItem({ icon, path, title }: SidebarItemProps) {
  const { pathname } = useLocation();
  const match = pathname.includes(path);

  return (
    <ListItem as={NavLink} to={path} key={path} transition="all .2s">
      <Flex
        gap={6}
        color={match ? "primary.500" : "grayText"}
        bgGradient={
          match
            ? "linear(to-r, rgba(24, 185, 170, 0.2) 0%, rgba(24, 185, 170, 0) 83.4%)"
            : "background"
        }
        _hover={{
          color: "primary.500"
        }}
      >
        <Box bg={match ? "primary.500" : "background"} w={2} height={10} />

        <Flex gap={4} alignItems="center">
          <Icon as={icon} />
          <Text fontWeight={500}>{title}</Text>
        </Flex>
      </Flex>
    </ListItem>
  );
}
