import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Tab,
  TabList,
  Tabs,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { DatePicker } from "components/DatePicker";
import { EmptyContent } from "components/EmptyContent";
import { EventCard } from "components/Events/EventCard";
import { EventFilterModal } from "components/Events/EventFilterModal";
import { Header } from "components/Layout/Dashboard/Header";
import { PaginationBar } from "components/PaginationBar";
import { Paper } from "components/Paper";
import { SelectComponent } from "components/Select";
import { eventTimeTypeList, eventUserTypeOptions } from "constants/event";
import { parseISO, startOfDay } from "date-fns";
import { useEvents, useMyEvents } from "hooks/event";
import { ListResponseOptionsType } from "interfaces/common";
import {
  EventListFilters,
  EventStatusTime,
  EventUserType,
} from "interfaces/event";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { MdClose, MdOutlineToday, MdSearch } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function MyEvents() {
  const { search } = useLocation();
  const navigate = useNavigate();

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [page, setPage] = useState(1);

  const params = new URLSearchParams(search);
  const date = params.get("date");

  const methods = useForm<Omit<EventListFilters, "page" | "size">>({
    defaultValues: {
      name: "",
      type: "all",
      time: "all",
      startDate: undefined,
    },
  });

  const { control, register, watch, setValue, reset } = methods;

  const name = watch("name");
  const type = watch("type");
  const time = watch("time");
  const startDate = watch("startDate");

  useEffect(() => {
    setValue("startDate", date ? parseISO(date) : undefined);
  }, [date]);

  const onTabChange = (index: number) => {
    if (isLargerThan1080) {
      setValue("time", eventTimeTypeList[index].value as EventStatusTime);
    } else {
      setValue("type", eventUserTypeOptions[index].value as EventUserType);
    }

    setPage(1);
  };

  const { data: eventsRequest, isLoading } = useMyEvents({
    name: name === "" ? undefined : name,
    type: type === "all" ? undefined : type,
    size: 9,
    page,
    time: startDate || name !== "" || time === "all" ? undefined : time,
    startDate: startDate ? startOfDay(new Date(startDate)) : undefined,
  });

  return (
    <>
      <Header hasFilter title="Meus Eventos" onFilterOpen={onOpen} />

      <Paper p={8} h="100%" overflow="auto">
        {isLargerThan1080 && (
          <Flex align="center">
            <Flex align="center" justify="space-between" w="100%">
              <Flex direction="column">
                <Heading fontSize="lg" color="gray.400">
                  Eventos
                </Heading>

                {(startDate || name !== "") && (
                  <Button
                    variant="link"
                    size="xs"
                    onClick={() => {
                      reset();
                      navigate("/meus-eventos");
                    }}
                  >
                    Limpar filtros
                  </Button>
                )}
              </Flex>

              <FormProvider {...methods}>
                <Flex as="form" gap={4}>
                  <FormControl>
                    <InputGroup>
                      <Input placeholder="Pesquisar" {...register("name")} />
                      <InputRightElement
                        children={name === "" ? <MdSearch /> : <MdClose />}
                        color="primary.500"
                        cursor="pointer"
                        onClick={() => {
                          if (name !== "") reset();
                        }}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl>
                    <DatePicker name="startDate" placeholder="Data" />
                  </FormControl>

                  <FormControl>
                    <Controller
                      control={control}
                      name="type"
                      render={({ field: { ref, ...props } }) => (
                        <SelectComponent
                          isClearable={false}
                          options={eventUserTypeOptions}
                          inputRef={ref}
                          {...props}
                        />
                      )}
                    />
                  </FormControl>
                </Flex>
              </FormProvider>
            </Flex>
          </Flex>
        )}

        {isLargerThan1080 ? (
          <Tabs onChange={onTabChange}>
            {!startDate && name === "" && (
              <TabList>
                <Tab>Todos</Tab>
                <Tab>Futuros</Tab>
                <Tab>Em andamento</Tab>
                <Tab>Finalizados</Tab>
              </TabList>
            )}
          </Tabs>
        ) : (
          <Tabs variant="soft-rounded" w="100%" onChange={onTabChange}>
            <TabList>
              {eventUserTypeOptions
                .filter(({ value }) => value !== "all")
                .map((typeOption) => (
                  <Tab key={typeOption.value}>{typeOption.label}</Tab>
                ))}
            </TabList>
          </Tabs>
        )}

        <Flex direction="column" gap={8} flex={1}>
          <Flex
            direction="column"
            align="center"
            justify="center"
            w="100%"
            h="100%"
            gap={4}
            mt={8}
            overflow="auto"
          >
            {isLoading ? (
              <Flex w="100%" h="100%" flex={1} align="center" justify="center">
                <Spinner />
              </Flex>
            ) : (
              <Grid
                templateColumns={
                  eventsRequest?.data?.length === 0
                    ? "1fr"
                    : "repeat(auto-fill, minmax(315px, 1fr))"
                }
                gap={6}
                justifyItems="center"
                alignItems={
                  eventsRequest?.data.length === 0 ? "center" : "flex-start"
                }
                h="100%"
                w="100%"
              >
                {eventsRequest?.data?.length === 0 ? (
                  <EmptyContent
                    message={
                      type === "all"
                        ? "Não há eventos para exibir."
                        : `Você não está ${
                            type === "organizer"
                              ? "organizando"
                              : type === "participant"
                              ? "participando de"
                              : ""
                          } nenhum evento.`
                    }
                    icon={MdOutlineToday}
                  />
                ) : (
                  eventsRequest?.data?.map((event: any) => (
                    <EventCard key={event.id} {...event} />
                  ))
                )}
              </Grid>
            )}
          </Flex>

          <Flex justify="center">
            {isLargerThan1080 && <Box w="100%" />}

            <PaginationBar
              {...(eventsRequest?.meta as ListResponseOptionsType)}
              setPage={setPage}
            />

            {isLargerThan1080 && (
              <Flex w="100%" justify="flex-end">
                <Button as={Link} to="editor" size="lg" px={16}>
                  Criar Evento
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Paper>

      <EventFilterModal
        enableTime
        isOpen={isOpen}
        onClose={onClose}
        selectedItem={time as string}
        setSelectedItem={(value) => setValue("time", value as EventStatusTime)}
        selectedDate={startDate}
        setSelectedDate={(value) => setValue("startDate", value as Date)}
      />
    </>
  );
}
