import { createContext, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { GroupContextData, GroupFilter, GroupProviderProps } from "./props";

export const GroupContext = createContext({} as GroupContextData);

export function GroupProvider({ children }: GroupProviderProps) {
  const { register, watch, reset } = useForm<GroupFilter>();
  const name = watch("name", "");

  const contextValues = useMemo(() => ({
    register,
    name,
    reset,
  }), [
    register,
    name,
    reset
  ]);

  return (
    <GroupContext.Provider value={contextValues}>
      {children}
    </GroupContext.Provider>
  );
}

export function useGroup() {
  return useContext(GroupContext);
}
