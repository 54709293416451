import { Grid, useMediaQuery, Text } from "@chakra-ui/react";
import { Header } from "components/Layout/Dashboard/Header";
import { useAuth } from "contexts/auth/useAuth";
import { useUser } from "hooks/user";
import { NotFound } from "pages/NotFound";
import { useParams } from "react-router-dom";
import { LeftSide } from "./LeftSide";
import { RightSide } from "./RightSide";

export function Profile() {
  const { username } = useParams();
  const [isLargerThan1320] = useMediaQuery("(min-width: 1320px)");

  const { user } = useAuth();

  const isLoggedUserProfile = user?.username === username;

  const { isError } = useUser(username as string, isLoggedUserProfile);

  return (
    <>
      <Header title={`${isLoggedUserProfile ? "Meu Perfil" : ""}`} />

      {isError ? (
        <NotFound />
      ) : (
        <Grid
          w="100%"
          flex={1}
          h="100%"
          overflow="auto"
          gridTemplateColumns={isLargerThan1320 && !isError ? "1fr 2fr" : "1fr"}
          gap={isLargerThan1320 ? 8 : 0}
        >
          {!isError && <LeftSide isLoggedUserProfile={isLoggedUserProfile} />}
          <RightSide isLoggedUserProfile={isLoggedUserProfile} />
        </Grid>
      )}
    </>
  );
}
