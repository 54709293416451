import { Flex, Icon, Text } from "@chakra-ui/react";
import { EmptyContentProps } from "./props";

export function EmptyContent({ message, icon }: EmptyContentProps) {
  return (
    <Flex direction="column" w="100%" h="100%" align="center" justify="center">
      <Icon as={icon} boxSize="4rem" color="gray.100" />
      <Text fontSize="sm" color="gray.300">
        {message}
      </Text>
    </Flex>
  );
}
