import {
  Badge,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Skeleton,
  Spinner,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { NoCover } from "components/NoCover";
import { useAuth } from "contexts/auth/useAuth";
import { useEvent as useEventContext } from "contexts/event/useEvent";
import { useToast } from "contexts/layout/toast/useToast";
import { endOfMinute, isFuture } from "date-fns";
import {
  useCreateEvent,
  useEditEvent,
  useEvent,
  useEventLookup,
  useEventQuestionsRestricted,
  useEventSubscribe,
  useUploadEventImage,
} from "hooks/event";
import {
  Attachment,
  EventStatusLookupEnum,
  MultipleChoiceQuestion,
  OpenQuestion,
} from "interfaces/event";
import { User } from "interfaces/User";
import { questionsAndAnswersSchema } from "pages/MyEvents/CreateEvent/Q&A/schema";
import { eventSetupSchema } from "pages/MyEvents/CreateEvent/Settings/schema";
import { useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { uploadQuestionImage } from "services/event";
import { isObjectEmpty } from "utils/isObjectEmpty";
import { isWithinThePeriod } from "utils/isWithinThePeriod";
import { prepareFormData } from "utils/prepareFormData";
import { CoverButtonProps } from "../props";
import { ResponseRecommendationsModal } from "../ResponseRecommendationsModal";

export function Banner() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const {
    isOpen: isRecomendationsModalOpen,
    onOpen: onRecomendationsModalOpen,
    onClose: onRecomendationsModalClose,
  } = useDisclosure();

  const { toast } = useToast();
  const { user } = useAuth();

  const { data: eventFromRequest, isLoading } = useEvent(id);
  const { data: questionsFromRequest } = useEventQuestionsRestricted(id, {
    enabled: !!user,
  });
  const { data: lookup } = useEventLookup(id, {
    enabled: !!user,
  });

  const {
    mutate: createEvent,
    data: eventCreated,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
  } = useCreateEvent();

  const {
    mutate: editEvent,
    isLoading: isEditLoading,
    isSuccess: isEditSuccess,
  } = useEditEvent();

  const { mutate: subscribe, isLoading: isSubscribeLoading } =
    useEventSubscribe(id as string);

  const {
    event: eventFromContext,
    questionsToRemove,
    eventSettingsForm,
    steps,
  } = useEventContext();

  useEffect(() => {
    if (steps) {
      if (!steps.includes("PUBLISH")) {
        navigate("/meus-eventos/editor");
      }
    }
  }, [steps]);

  const event =
    id && !eventFromContext
      ? {
          ...eventFromRequest,
          categories: eventFromRequest?.categories?.map(({ name }) => name),
          questions: questionsFromRequest?.map((question) => ({
            ...question,
            remove: false,
          })),
        }
      : eventFromContext;

  const {
    mutate: uploadEventImage,
    isLoading: isUploadEventImageLoading,
    isSuccess: isUploadEventImageSuccess,
  } = useUploadEventImage(id ?? eventCreated?.id);

  const loggedUserIsTheOwner = event?.owner?.id === user?.id;
  const loggedUserIsAModerator = (event?.moderators as User[])?.some(
    (moderator) => moderator.id === user?.id
  );

  useEffect(() => {
    if (
      ((isCreateSuccess || isEditSuccess) &&
        (typeof event.image === "string" || !event.image)) ||
      (event.image instanceof File && isUploadEventImageSuccess)
    ) {
      navigate(`/meus-eventos/${id ?? eventCreated?.id}`);
    }
  }, [isCreateSuccess, isEditSuccess, isUploadEventImageSuccess]);

  useEffect(() => {
    if (
      eventSettingsForm?.getValues().image &&
      eventSettingsForm?.getValues().image instanceof File &&
      (isCreateSuccess || isEditSuccess) &&
      (id || eventCreated?.id)
    ) {
      uploadEventImage(eventSettingsForm?.getValues().image as File);
    }
  }, [isCreateSuccess, isEditSuccess, id, eventCreated?.id]);

  async function handleSaveEvent() {
    const validateEvent = Promise.all([
      eventSetupSchema.validate(event),
      questionsAndAnswersSchema.validate(event),
    ]);

    validateEvent
      .then(async (resolvedData) => {
        const [eventResolved, questionsResolved] = resolvedData;

        const moderators = (eventResolved.moderators as User[])?.map(
          ({ email }) => email
        );

        const questionsListWithRemovedQuestions =
          questionsResolved.questions.concat(
            id ? questionsToRemove : []
          ) as Array<OpenQuestion | MultipleChoiceQuestion>;

        const questions = [];

        for (const question of questionsListWithRemovedQuestions) {
          let questionId;

          if (id) {
            if (
              (event.questions?.some(({ id }) => id === question?.id) ||
                questionsToRemove?.some(({ id }) => id === question?.id)) &&
              questionsFromRequest?.some(({ id }) => id === question?.id)
            ) {
              questionId = question?.id;
            }
          }

          if ((question as OpenQuestion)?.files?.length) {
            const attachements = question.files.reduce(
              (previousFile, currentFile) =>
                question.title.includes(currentFile.url)
                  ? [...previousFile, currentFile]
                  : previousFile,
              [] as Attachment[]
            );

            for (const { file, url } of attachements) {
              const formData = new FormData();
              formData.append("file", file);

              // eslint-disable-next-line no-await-in-loop
              const { url: imageUrl } = await uploadQuestionImage(formData);

              question.title = question.title.replace(url, imageUrl);
            }
          }

          const questionChoices = [];

          if (question?.type === "MULTIPLE_CHOICE") {
            const { choices } = question;

            for (const choice of choices) {
              let choiceId;

              if (id) {
                if (
                  (
                    questionsFromRequest?.find(
                      (questionId) => questionId.id === question.id
                    ) as MultipleChoiceQuestion
                  )?.choices?.some(({ id }) => id === choice.id)
                ) {
                  choiceId = choice.id;
                } else {
                  choiceId = undefined;
                }
              }

              questionChoices.push({
                ...choice,
                id: choiceId as string,
              });
            }
          }

          if (question) {
            questions.push({
              ...question,
              choices: questionChoices,
              id: questionId,
              isNullified: undefined,
              updatedAt: undefined,
              createdAt: undefined,
              remove: id ? question.remove : undefined,
              files: undefined,
            });
          }
        }

        const eventBaseRequestData = {
          ...prepareFormData({
            ...eventResolved,
            questions,
            moderators:
              moderators?.length === 0 ? event.moderators : moderators,
          }),
          distributionOccurred: undefined,
          owner: undefined,
          image: undefined,
          currentDate: undefined,
          isDraft: false,
          weightOfQuestions: Number(event?.weightOfQuestions),
        };

        if (id) {
          editEvent({
            ...eventBaseRequestData,
            linkedGroup: undefined,
            linkedGroupId: eventResolved.linkedGroup,
            appealPeriodStart: undefined,
            appealPeriodEnd: undefined,
            code: undefined,
            createdAt: undefined,
            participants: undefined,
            participantsCount: undefined,
            updatedAt: undefined,
            isFinished: undefined,
            eventStartNotified: undefined,
            correctionStartNotified: undefined,
            appealPeriodNotified: undefined,
          });
        } else {
          createEvent(eventBaseRequestData);
        }
      })
      .catch((e) => {
        navigate(id ? `/meus-eventos/${id}/editor` : "/meus-eventos/editor");
        // console.log(e);
        toast(
          "Há campos obrigatórios a ser preenchidos ou inválidos.",
          "warning"
        );
      });
  }

  function getDesktopButtonProps(): CoverButtonProps {
    if (!user) {
      return {
        text: "Fazer login",
        isLink: true,
        props: {
          to: "/login",
          onClick: () => {
            sessionStorage.setItem("@arbo:redirectToEvent", pathname);
          },
        },
      };
    }

    if (loggedUserIsTheOwner) {
      if (!event?.isFinished) {
        if (event.isDraft) {
          return {
            text: "Publicar Evento",
            props: {
              onClick: handleSaveEvent,
              isLoading: isCreateLoading || isEditLoading,
            },
          };
        }

        if (eventFromContext) {
          return {
            text: "Salvar Alterações",
            props: {
              onClick: handleSaveEvent,
              isLoading: isEditLoading,
            },
          };
        }
      }

      return {
        text: "Acompanhar evento",
        isLink: true,
        props: {
          to: "gerenciador",
          variant: "outline",
        },
      };
    }

    if (!event?.isFinished) {
      if (
        lookup === EventStatusLookupEnum.JOINED &&
        isWithinThePeriod(event?.responsePeriodStart, event?.responsePeriodEnd)
      ) {
        return {
          text: "Quero Responder!",
          props: {
            variant: "outline",
            onClick: onRecomendationsModalOpen,
          },
        };
      }

      if (
        lookup === EventStatusLookupEnum.NOT_JOINED &&
        event?.responsePeriodEnd &&
        isFuture(endOfMinute(new Date(event.responsePeriodEnd))) &&
        !loggedUserIsAModerator
      ) {
        return {
          text: "Participar do Evento!",
          props: {
            onClick: () => subscribe(id as string),
            isLoading: isSubscribeLoading,
          },
        };
      }
    }

    return {};
  }

  return (
    <>
      <Flex h={[188, 233]} w="100%" position="relative">
        {isLoading || isUploadEventImageLoading ? (
          <Flex align="center" justify="center" w="100%" h={[188, 233]}>
            <Spinner />
          </Flex>
        ) : event?.image ? (
          <Flex h={[188, 233]} w="100%">
            <Image
              src={
                typeof event.image === "string"
                  ? event.image
                  : URL.createObjectURL(event.image)
              }
              w="100%"
              objectFit="cover"
              borderTopRadius={20}
            />
          </Flex>
        ) : (
          <Flex h={[188, 233]} w="100%">
            <NoCover />
          </Flex>
        )}

        <Flex
          w="100%"
          bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 20.82%, #000000 100%)"
          position="absolute"
          align="end"
          h={[188, 233]}
          pb={5}
          px={[8, 12]}
        >
          <Flex direction="column" gap={[4, 7]}>
            <Skeleton isLoaded={!isLoading}>
              <Flex align="center" gap={2}>
                <Heading fontSize="2xl" color="white">
                  {event?.name}
                </Heading>

                {id && event?.isDraft && (
                  <Badge variant="draft">Rascunho</Badge>
                )}
              </Flex>
            </Skeleton>

            <Skeleton isLoaded={!isLoading}>
              <Flex gap={2}>
                {event?.categories?.map((category) => (
                  <Badge
                    key={
                      typeof category === "string" ? category : category.name
                    }
                    colorScheme="primary"
                  >
                    {typeof category === "string" ? category : category.name}
                  </Badge>
                ))}
              </Flex>
            </Skeleton>
          </Flex>

          {isLargerThan1080 &&
            loggedUserIsTheOwner &&
            !event?.isFinished &&
            ((event?.responsePeriodStart &&
              isFuture(new Date(event?.responsePeriodStart)) &&
              !pathname.includes("editor")) ||
              (event.isDraft && !pathname.includes("editor"))) && (
              <IconButton
                as={Link}
                to="editor"
                size="lg"
                aria-label="Editar evento"
                icon={<FiEdit />}
                position="absolute"
                right="1.875rem"
                top="1rem"
                w={55}
                maxH={55}
              />
            )}

          {isLargerThan1080 && !isObjectEmpty(getDesktopButtonProps()) && (
            <Flex
              position="absolute"
              right={12}
              bottom={-7}
              w="18.5rem"
              align="center"
              justify="center"
            >
              <Button
                size="lg"
                isFullWidth
                as={getDesktopButtonProps().isLink ? Link : undefined}
                to={getDesktopButtonProps().props?.to ?? "#"}
                isLoading={
                  getDesktopButtonProps().props?.isLoading ||
                  isUploadEventImageLoading
                }
                {...getDesktopButtonProps().props}
              >
                {getDesktopButtonProps()?.text}
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>

      <ResponseRecommendationsModal
        responsePeriodEnd={event?.responsePeriodEnd}
        isOpen={isRecomendationsModalOpen}
        onClose={onRecomendationsModalClose}
      />
    </>
  );
}
