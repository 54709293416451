import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Badge: ComponentStyleConfig = {
  baseStyle: {
    px: "0.563rem",
    py: "0.125rem",
    fontSize: "0.625rem",
    display: "flex",
    alignItems: "center",
    h: "fit-content",
    lineHeight: "normal"
  },
  variants: {
    solid: {
      borderRadius: "full",
      textTransform: "normal",
      fontWeight: 400,
      color: "background"
    },
    draft: {
      bg: "gray.300",
      color: "background",
      fontWeight: 700,
      borderRadius: 5,
    }
  },
  defaultProps: {
    variant: "solid",
  }
};

export default Badge;