import { Flex, useMediaQuery } from "@chakra-ui/react";
import NotificationProvider from "contexts/notifications/useNotifications";
import { Outlet } from "react-router-dom";
import { DesktopSidebarMenu } from "./DesktopSidebarMenu";

export function Dashboard() {
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  return (
    <NotificationProvider>
      <Flex
        h="100vh"
        px={isLargerThan1080 ? 12 : 0}
        py={isLargerThan1080 ? 8 : 0}
        gap={8}
      >
        {isLargerThan1080 && <DesktopSidebarMenu />}

        <Flex flex="1" direction="column">
          <Outlet />
        </Flex>
      </Flex>
    </NotificationProvider>
  );
}
