import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Tabs: ComponentStyleConfig = {
  baseStyle: {
    tabpanel: {
      px: 0,
    }
  },
  variants: {
    line: {
      tab: {
        color: "black",
        fontWeight: 600,
        _selected: {
          color: "black",
          borderColor: "primary.500",
          fontWeight: 800
        },
        _focus: {
          boxShadow: "none"
        },
        _active: {
          bg: "gray.50"
        },
        _hover: {
          borderColor: "primary.600"
        }
      }
    },
    "soft-rounded": {
      tablist: {
        gap: 6
      },
      tab: {
        border: "1px solid",
        borderColor: "primary.500",
        borderRadius: 15,
        h: "60px",
        w: "100%",
        color: "primary.500",
        _selected: {
          bg: "primary.500",
          color: "background",
          boxShadow: "6px 6px 12px rgba(24, 185, 170, 0.35)"
        }
      }
    }
  }
};

export default Tabs;
