import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
} from "@chakra-ui/react";
import { useVerifyUsername } from "hooks/user";
import { CreateUser, SignUpData } from "interfaces/User";
import { useForm } from "react-hook-form";
import { FiAtSign, FiMail, FiUser } from "react-icons/fi";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { makeFullName } from "utils/makeFullName";

export function ProfileStep() {
  const navigate = useNavigate();
  const { search, state } = useLocation();

  const params = new URLSearchParams(search);
  const email = params.get("email") as string;

  const user = state as Pick<
    CreateUser,
    "firstName" | "lastName" | "email" | "username"
  >;

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Pick<SignUpData, "fullName" | "email" | "username">>({
    // resolver: yupResolver(signInSchema),
    defaultValues: {
      fullName: user ? makeFullName(user?.firstName, user?.lastName) : "",
      username: user?.username ?? "",
      email: email ?? user?.email,
    },
  });

  const username = watch("username");

  const { data: verifyUserName, isSuccess } = useVerifyUsername(username);

  const handleCreateProfile = (
    user: Pick<SignUpData, "fullName" | "username" | "email">
  ) => {
    const [firstName, ...lastName] = user.fullName.split(" ").filter(Boolean);

    if (
      (!lastName.length && lastName.length !== 1) ||
      (isSuccess && verifyUserName?.isTaken)
    ) {
      if (isSuccess && verifyUserName?.isTaken) {
        setError("username", {
          message: "Este nome de usuário já está em uso.",
        });
      }

      if (!lastName.length || lastName.length !== 1) {
        setError("fullName", {
          message:
            "O seu nome deve estar no formato nome e sobrenome. Ex.: Juliano Soares",
        });
      }
    } else {
      navigate("/criar-conta/senha", {
        state: {
          firstName,
          lastName: lastName.join(","),
          username: user.username,
          email: user.email,
        } as Pick<CreateUser, "firstName" | "lastName" | "email" | "username">,
      });
    }
  };

  return (
    <Flex direction="column" gap={8} w="100%" h="100%" px={8} pb={[8, 0]}>
      <Flex
        as="form"
        onSubmit={handleSubmit(handleCreateProfile)}
        direction="column"
        justify={["flex-start", "flex-start", "space-between"]}
        gap={4}
        w="100%"
        h="100%"
      >
        <Flex direction="column" gap={4}>
          <FormControl isInvalid={!!errors.fullName}>
            <FormLabel>Seu nome</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<FiUser size={20} />}
                color={errors.fullName ? "error" : "primary.500"}
              />
              <Input
                placeholder="ex.:alexandre@arbo.com"
                {...register("fullName")}
              />
            </InputGroup>

            <FormErrorMessage maxW={300}>
              {errors.fullName?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.username}>
            <FormLabel>Seu nome de usuário</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<FiAtSign size={20} />}
                color={errors.username ? "error" : "primary.500"}
              />
              <Input
                placeholder="@alexandresoares12"
                {...register("username")}
              />
            </InputGroup>

            <FormErrorMessage w="fit-content">
              {errors.username?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<FiMail size={20} />}
                color={errors.email ? "error" : "primary.500"}
              />
              <Input
                readOnly
                placeholder="ex.:alexandre@arbo.com"
                {...register("email")}
              />
            </InputGroup>

            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex direction="column" align="center" gap={4}>
          <Button isFullWidth type="submit" size="lg">
            Próximo
          </Button>

          <Link as={RouterLink} to="/login">
            Cancelar
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
