import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  List,
  ListItem,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import {
  useAcceptAppeals,
  useAppealDetailsEvent,
  useDetailsAnswerRequest,
  useNullifyQuestion,
  useRejectAppeals,
} from "hooks/event";
import { MultipleChoiceQuestion, OpenQuestion } from "interfaces/event";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getChoiceLetter } from "utils/getChoiceLetter";
import profileOpacity from "../../../assets/profile-opacity.svg";
import { callsFormSchema } from "./schema";

type CallForm = {
  type: "CORRECTION_APPEAL" | "QUESTION_APPEAL";
  feedback: string;
  grade: string;
};

const defaultValues: CallForm = {
  type: "QUESTION_APPEAL",
  feedback: "",
  grade: "",
};

export function SeeCalls() {
  const { id, answerId } = useParams();
  const navigate = useNavigate();

  const [optionRequest, setOptionRequest] = useState<"ACEPT" | "REJECT">(
    "REJECT"
  );
  const [showFields, setShowFields] = useState(false);

  const { data: appeal, isLoading } = useAppealDetailsEvent(answerId);
  const { data: answer } = useDetailsAnswerRequest(appeal?.answer?._id);

  const {
    mutate: rejectAppeals,
    isSuccess: isRejectSuccess,
    isLoading: isRejectLoading,
  } = useRejectAppeals(answerId as string, id as string);

  const {
    mutate: acceptAppeals,
    isSuccess: isAcceptSuccess,
    isLoading: isAcceptLoading,
  } = useAcceptAppeals(answerId as string, id as string);

  const {
    mutate: nullifyQuestion,
    isSuccess: isNullifyQuestionSuccess,
    isLoading: isNullifyQuestionLoading,
  } = useNullifyQuestion(id as string);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm<CallForm>({
    defaultValues,
    resolver: yupResolver(callsFormSchema),
  });

  useEffect(() => {
    if (appeal?.answer) {
      setValue("type", "CORRECTION_APPEAL");
    } else {
      setValue("type", "QUESTION_APPEAL");
    }
  }, [appeal]);

  function onSubmit(call: CallForm) {
    if (id) {
      if (optionRequest === "REJECT") {
        rejectAppeals({
          eventId: id,
          feedback: call.feedback,
        }, {
          onSuccess: () => {
            navigate(-1);
          }
        });
      } else {
        acceptAppeals({
          eventId: id,
          feedback: call.feedback,
          grade: Number(call.grade),
          submissionId: answer?.submission._id as string,
        }, {
          onSuccess: () => {
            navigate(-1);
          }
        });
      }

    }
  }

  const handleNullifyQuestion = () => {
    if (appeal) {
      nullifyQuestion({
        questionId: appeal?.question.id,
        appealId: appeal?._id,
      });
    }
  };

  useEffect(() => {
    if (isRejectSuccess || isAcceptSuccess || isNullifyQuestionSuccess) {
      navigate(`/meus-eventos/${id}/gerenciador`);
    }
  }, [isAcceptSuccess, isRejectSuccess, isNullifyQuestionSuccess]);

  return (
    <>
      <Header
        title="Gerenciador de Evento"
        goback={`/meus-eventos/${id}/gerenciador`}
      />

      <Paper
        p="2rem"
        gap="2.813rem"
        overflow="auto"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {isLoading ? (
          <Flex w="100%" h="100vh" justify="center" align="center">
            <Spinner />
          </Flex>
        ) : (
          <>
            <Flex justifyContent="space-between">
              <Flex direction="column">
                <Text fontWeight="700" fontSize="1.5rem" color="black">
                  Resolução de Chamado
                </Text>
                <Text fontWeight="700" fontSize="1.125rem" color="gray.400">
                  Etapa Atual: Recurso
                </Text>
              </Flex>

              <Flex alignItems="center" gap={4}>
                {answer?.submission?.user?.image && (
                  <Image
                    boxSize={12}
                    borderRadius="24px"
                    objectFit="cover"
                    src={answer?.submission?.user?.image}
                    flexShrink={0}
                  />
                )}

                <Text>
                  {answer?.submission?.user?.firstName}{" "}
                  {answer?.submission?.user?.lastName}
                </Text>
              </Flex>
            </Flex>

            <Flex direction="column" gap={8} w="100%">
              <Flex justifyContent="space-between" gap={12}>
                <Flex direction="column" gap={4} w="85%">
                  <Text fontSize="0.875rem">
                    Questão {answer?.question.order ?? appeal?.question?.order}
                  </Text>
                  {appeal?.question && (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: appeal.question.title,
                      }}
                      fontSize="1rem"
                      color="gray.400"
                      fontWeight="700"
                    />
                  )}
                  {appeal?.question?.type && appeal.question.type === "OPEN" ? (
                    <>
                      <Text fontSize="0.875rem">Padrão de resposta</Text>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: (appeal?.question as OpenQuestion)
                            .answerPattern,
                        }}
                        color="gray.600"
                      />
                    </>
                  ) : (
                    <>
                      <Text fontSize="0.875rem" color="gray.500">
                        Alternativas da questão
                      </Text>
                      <List color="gray.500">
                        {appeal &&
                          (
                            appeal.question as MultipleChoiceQuestion
                          ).choices.map((choice) => (
                            <ListItem key={choice.id}>
                              {getChoiceLetter(
                                (
                                  appeal.question as MultipleChoiceQuestion
                                ).choices.indexOf(choice)
                              )}
                              {") "}
                              {choice.text}
                            </ListItem>
                          ))}
                      </List>
                    </>
                  )}
                </Flex>

                {showFields && (
                  <Flex direction="column" gap={4}>
                    <FormControl isInvalid={!!errors.feedback}>
                      <FormLabel>
                        Escreva um feedback para o participante, justificando
                        a sua decisão.
                      </FormLabel>
                      <Textarea
                        rows={6}
                        placeholder="ex.: Recusamos sua solicitação, pois..."
                        {...register("feedback")}
                      />
                      <FormErrorMessage>
                        {errors?.feedback?.message}
                      </FormErrorMessage>
                    </FormControl>

                    {optionRequest === "ACEPT" && (
                      <FormControl isInvalid={!!errors.feedback}>
                        <FormLabel
                          fontSize="0.75rem"
                          fontWeight="700"
                          color="gray.500"
                        >
                          Nova Nota
                        </FormLabel>
                        <Input
                          width="296px"
                          height={10}
                          isInvalid={!!errors.grade}
                          {...register("grade")}
                        />
                      </FormControl>
                    )}
                  </Flex>
                )}
              </Flex>

              {appeal?.type === "CORRECTION_APPEAL" && (
                <>
                  <Box>
                    <Text fontSize="0.875rem">Resposta do Participante</Text>
                    {appeal && appeal.question.type === "OPEN" ? (
                      <Text color="gray.600">{appeal.answer.openAnswer}</Text>
                    ) : (
                      <Text color="gray.600">
                        {getChoiceLetter(
                          (
                            appeal?.question as MultipleChoiceQuestion
                          ).choices.indexOf(
                            (
                              appeal?.question as MultipleChoiceQuestion
                            ).choices.find(
                              (el) =>
                                el.id === appeal?.answer?.multipleChoiceAnswer
                            )!
                          )
                        )}

                        {") "}
                        {(appeal?.question as MultipleChoiceQuestion).choices &&
                          (
                            appeal?.question as MultipleChoiceQuestion
                          ).choices.find(
                            (el) =>
                              el.id === appeal?.answer?.multipleChoiceAnswer
                          )?.text}
                      </Text>
                    )}
                  </Box>

                  <Flex
                    justifyContent="space-between"
                    width={{ base: "100%", lg: "50%" }}
                  >
                    <Flex direction="column" gap="0.75rem">
                      <Text fontSize="0.875rem">
                        Nota dos Corretores (
                        {appeal && appeal.answer?.corrections.length})
                      </Text>
                      <Flex gap="1rem">
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          gap="0.75rem"
                        >
                          {appeal?.answer?.corrections.map((correction) => (
                            <Flex
                              alignItems="center"
                              gap="0.75rem"
                              key={correction._id}
                            >
                              <Image src={profileOpacity} />
                              <Text fontSize="0.875rem">
                                {correction?.grade}
                              </Text>
                            </Flex>
                          ))}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex direction="column" gap="0.75rem">
                      <Text fontSize="0.875rem">Nota final</Text>
                      <Text
                        pt="10px"
                        color="primary.500"
                        fontWeight="700"
                        fontSize="0.875rem"
                      >
                        {appeal?.answer?.corrections &&
                          appeal.answer.corrections
                            .map((correction) => correction.grade ?? 0)
                            .reduce(
                              (total, currentElement) => total + currentElement,
                              0
                            ) / appeal.answer.corrections.length}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}

              <Box>
                <Text fontSize="0.875rem">Justificativa do Participante</Text>
                <Text>{appeal?.justification}</Text>
              </Box>
            </Flex>
            <Flex justifyContent="flex-end" gap="1.313rem">
              <Flex gap={4}>
                {showFields ? (
                  <>
                    <Button
                      variant='link'
                      onClick={() => setShowFields(false)}
                    >
                      Cancelar
                    </Button>

                    {optionRequest === "ACEPT" ? (
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={isAcceptLoading}
                        isDisabled={!watch("feedback") && !watch("grade")}
                      >
                        Finalizar chamado
                      </Button>
                    ) : (
                      <Button
                        colorScheme="secondary"
                        type="submit"
                        isLoading={isRejectLoading}
                        isDisabled={!watch("feedback")}
                      >
                        Finalizar chamado
                      </Button>

                    )}

                  </>
                ) : (
                  <>
                    <Button
                      colorScheme="secondary"
                      onClick={() => {
                        setOptionRequest("REJECT");
                        setShowFields(true);
                      }}
                    >
                      Recusar
                    </Button>

                    {appeal?.type === "CORRECTION_APPEAL" && (
                      <Button
                        type="button"
                        onClick={() => {
                          setOptionRequest("ACEPT");
                          setShowFields(true);
                        }}
                      >
                        Aceitar
                      </Button>
                    )}

                    {appeal?.type === "QUESTION_APPEAL" && (
                      <Button
                        type="button"
                        onClick={handleNullifyQuestion}
                        isLoading={isNullifyQuestionLoading}
                      >
                        Anular questão
                      </Button>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Paper>
    </>
  );
}
