import {
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";
import loginHero from "assets/login-image.png";
import SvgLogo from "build/assets/Logo";
import { COMPANY_NAME } from "constants/texts";
import { getYear } from "date-fns";
import { Outlet } from "react-router-dom";

export function AuthLayout() {
  const [isLargerThan848] = useMediaQuery("(min-width: 848px)");
  const isDesktop = useBreakpointValue({ md: "none" });

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-around"
      minH="100vh"
      overflow="auto"
      gap={12}
      p={[0, 12]}
    >
      <SvgLogo width="392px" height="70px"/>
      <Grid
        templateColumns={isLargerThan848 ? "repeat(2, 1fr)" : "1fr"}
        flex={1}
        justifyItems="center"
        bg="background"
        borderRadius={20}
        overflow="hidden"
        w={["100%", "auto"]}
      >
        {isLargerThan848 && (
          <GridItem overflow="hidden" borderLeftRadius={20}>
            <Image src={loginHero} objectFit="cover" h="100%" />
          </GridItem>
        )}

        <GridItem p={[0, 8]} w="100%">
          <Outlet />
        </GridItem>
      </Grid>

      {isDesktop && (
        <Text fontSize="xs" color="grayText">
          { `2023 - ${getYear(new Date())}`}. Todos os direitos reservados. {COMPANY_NAME}.
        </Text>
      )}
    </Flex>
  );
}
