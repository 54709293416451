import {
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  List,
  ListItem,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { Paper } from "components/Paper";
import { ShareLink } from "components/ShareLink";
import { useAuth } from "contexts/auth/useAuth";
import { format, parseISO } from "date-fns";
import { useUser } from "hooks/user";
import { BsFillSquareFill } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";
import { ImLinkedin } from "react-icons/im";
import { useParams } from "react-router-dom";
import { ProfileProps } from "../props";
import { ReferFriendModal } from "../ReferFriendModal";

export function RightSide({ isLoggedUserProfile }: ProfileProps) {
  const { username } = useParams();
  const [isLargerThan1320] = useMediaQuery("(min-width: 1320px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user: loggedUser } = useAuth();

  const { data: user, isLoading } = useUser(
    username as string,
    isLoggedUserProfile
  );

  const profile = isLoggedUserProfile ? loggedUser : user;

  const isLoaded = isLoggedUserProfile || !isLoading;

  return (
    <Paper
      flex={3}
      gap={10}
      direction={isLargerThan1320 ? "column" : "column-reverse"}
      justify={isLargerThan1320 ? "start" : "end"}
      p={8}
      pt={isLargerThan1320 ? 8 : 0}
      h="100%"
    >
      {isLoaded ? (
        <>
          {!isLargerThan1320 && (
            <Flex direction="column" gap={8} w="100%">
              <ShareLink
                title="Compartilhe seu perfil"
                link={`${process.env.REACT_APP_BASE_URL}/${profile?.username}`}
              />

              <Button variant="link" leftIcon={<FiUserPlus />} onClick={onOpen}>
                Indique um amigo
              </Button>
            </Flex>
          )}

          <Flex direction="column" gap={2}>
            <Heading fontSize="lg" color="gray.400">
              Sobre mim
            </Heading>
            <Text color="gray.300">{profile?.aboutMe}</Text>
          </Flex>

          {profile?.educations.length !== 0 && (
            <Flex direction="column" gap={2}>
              <Heading fontSize="lg" color="gray.400">
                Formação Acadêmica
              </Heading>

              <Flex direction="column" gap={4}>
                {profile?.educations.map(
                  ({ institution, endDate, startDate, title }) => (
                    <Flex key={title} direction="column" color="gray.300">
                      <Text>
                        {startDate && format(parseISO(startDate), "yyyy")}-
                        {endDate && format(parseISO(endDate), "yyyy")}
                      </Text>
                      <Text fontWeight={700}>{title}</Text>
                      <Text>{institution}</Text>
                    </Flex>
                  )
                )}
              </Flex>
            </Flex>
          )}

          {profile?.links.length !== 0 && (
            <Flex direction="column" gap={2}>
              <Heading fontSize="lg" color="gray.400">
                Links
              </Heading>

              <List gap={4}>
                {profile?.links.map(({ title, url }) => (
                  <ListItem key={url} as={Link} href={url} isExternal>
                    <Flex gap={4} alignItems="center" fontWeight={700}>
                      <Text>{title}</Text>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </Flex>
          )}

          <Flex
            direction={isLargerThan1320 ? "column" : "row"}
            align={isLargerThan1320 ? "start" : "center"}
            justify={isLargerThan1320 ? "start" : "center"}
            gap={4}
          >
            {profile?.linkedIn && (
              <Link href={profile?.linkedIn} isExternal>
                <Flex gap={4} alignItems="center" fontWeight={700}>
                  <Icon as={ImLinkedin} w={8} h={8} />
                  <Text display={{ base: "none", md: "flex" }}>
                    Visite meu perfil no LinkedIn
                  </Text>
                </Flex>
              </Link>
            )}

            {profile?.lattes && (
              <Link href={profile?.lattes} isExternal>
                <Flex gap={4} alignItems="center">
                  <Icon as={BsFillSquareFill} w={8} h={8} />
                  <Text display={{ base: "none", md: "flex" }}>
                    Visite meu perfil no Lattes
                  </Text>
                </Flex>
              </Link>
            )}
          </Flex>
        </>
      ) : (
        <Flex w="100%" h="100%" align="center" justify="center">
          <Spinner />
        </Flex>
      )}

      <ReferFriendModal isOpen={isOpen} onClose={onClose} />
    </Paper>
  );
}
