import * as yup from "yup";

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail inválido.")
    .required("O e-mail é obrigatório."),
  password: yup
    .string()
    .min(8, "A senha deve ter pelo menos 8 caracteres.")
    .required("A senha é obrigatória."),
});
