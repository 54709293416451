import { Button, Flex, IconButton } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { PaginationBarProps } from "./props";

const visiblePagesCount = 4;

export function PaginationBar(props: PaginationBarProps) {
  const { hasNextPage, hasPreviousPage, pagesCount, page, setPage } = props;

  const initialPage = 1;
  const lastPage = pagesCount;

  const pages = Array.from(Array(pagesCount), (_, index) => index + 1);

  pages.shift();
  pages.pop();

  const [availablePages, setAvailablePages] = useState<number[]>(
    pages.slice(initialPage - 1, initialPage - 1 + visiblePagesCount)
  );

  useEffect(() => {
    if (page) {
      if (page === initialPage) {
        setAvailablePages(
          pages.slice(initialPage - 1, initialPage - 1 + visiblePagesCount)
        );
      } else if (page === lastPage) {
        setAvailablePages(pages.slice(page - 2 - visiblePagesCount, lastPage));
      } else if (!availablePages.includes(page)) {
        setAvailablePages(pages.slice(page - 2, page - 2 + visiblePagesCount));
      }
    }
  }, [page, pagesCount]);

  if (pagesCount <= 1) {
    return null;
  }

  return (
    <Flex align="center" mt={4}>
      <IconButton
        aria-label="Página anterior"
        icon={<MdKeyboardArrowLeft />}
        variant="link"
        borderRadius="full"
        minW={42}
        isDisabled={!hasPreviousPage}
        onClick={() => setPage(page - 1)}
      />

      <Button
        borderRadius="full"
        fontWeight={400}
        fontSize={10}
        height={5}
        minW={5}
        w={5}
        px={0}
        mx={1}
        colorScheme={page && page === initialPage ? "primary" : "gray"}
        onClick={() => setPage(initialPage)}
        mr={4}
      >
        {initialPage}
      </Button>

      {availablePages.map((index) => (
        <Button
          key={index}
          borderRadius="full"
          fontWeight={400}
          fontSize={10}
          height={5}
          minW={5}
          w={5}
          px={0}
          mx={1}
          colorScheme={page === index ? "primary" : "gray"}
          onClick={() => setPage(index)}
        >
          {index}
        </Button>
      ))}

      {pagesCount > 1 && (
        <Button
          borderRadius="full"
          fontWeight={400}
          fontSize={10}
          height={5}
          minW={5}
          w={5}
          px={0}
          mx={1}
          colorScheme={page && page === lastPage ? "primary" : "gray"}
          onClick={() => setPage(lastPage)}
          ml={4}
        >
          {lastPage}
        </Button>
      )}

      <IconButton
        aria-label="Próxima página"
        icon={<MdKeyboardArrowRight />}
        variant="link"
        borderRadius="full"
        minW={42}
        isDisabled={!hasNextPage}
        onClick={() => setPage(page + 1)}
      />
    </Flex>
  );
}
