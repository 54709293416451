import * as yup from "yup";

export const createNewPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "A senha deve ter pelo menos 8 caracteres.")
    .required("A senha é obrigatória."),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas não coincidem."),
});
