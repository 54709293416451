import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { EventStatisticsProps } from "./props";

export function EventStatisticsCard({
  enable = true,
  nameButton,
  numberParticipants,
  redirect,
  title,
}: EventStatisticsProps) {
  return (
    <Box
      border="1px solid"
      borderRadius="20px"
      alignItems="center"
      borderColor="primary.500"
      w="100%"
      opacity={enable ? 1 : 0.5}
      py={4}
    >
      <Flex direction="row" gap={4} justifyContent="center" alignItems="center">
        <Text fontSize="3rem" fontWeight="800" color="gray.400">
          {numberParticipants}
        </Text>
        <Text fontSize="0.875rem" fontWeight="700" color="gray.300" w={100}>
          {title}
        </Text>
      </Flex>
      <Flex direction="row" justifyContent="center">
        <Button
          variant="link"
          as={Link}
          disabled={!enable}
          to={enable ? redirect : ""}
          alignSelf="center"
          colorScheme="primary"
        >
          {nameButton}
        </Button>
      </Flex>
    </Box>
  );
}
