import type { ComponentStyleConfig } from "@chakra-ui/theme";

const UnorderedList: ComponentStyleConfig = {
  baseStyle: {
    marginInlineStart: 0,
    listStyleType: "none"
  }
};

export default UnorderedList;
