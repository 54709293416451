import { QueryOptions } from "interfaces/common";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import {
  getNotificationsRequest,
  readNotification,
} from "services/notification";

export function useNotifications(options?: QueryOptions) {
  return useInfiniteQuery("notifications", getNotificationsRequest, {
    ...options,
    keepPreviousData: true,
    staleTime: 1000 * 30, // 30 segundos
    getNextPageParam: (lastPage) =>
      lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined,
  });
}

export function useReadNotification(id: string) {
  const queryClient = useQueryClient();

  return useMutation(["notification", id], () => readNotification(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });
}
