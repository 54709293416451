import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { COUNTDOWN_INITIAL_TIME_IN_SECONDS } from "constants/email";
import { useAuth } from "contexts/auth/useAuth";
import { useConfirmEmail, useResendConfirmationEmail } from "hooks/user";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export function Form() {
  const navigate = useNavigate();

  const { handleSubmit, watch, setValue } = useForm<{ code: string }>({
    defaultValues: {
      code: "",
    },
  });

  const code = watch("code");

  const [enableResendMail, setEnableResendMail] = useState(false);
  const [secondsAmount, setSecondsAmount] = useState(0);

  const minutes = Math.floor(secondsAmount / 60);
  const seconds = secondsAmount % 60;

  useEffect(() => {
    if (secondsAmount === 0) {
      setEnableResendMail(true);
      return;
    }

    setTimeout(() => {
      setSecondsAmount((secondsAmount) => secondsAmount - 1);
    }, 1000);
  }, [secondsAmount]);

  const { user } = useAuth();

  const {
    mutate: resendConfirmationEmail,
    isLoading: isResendConfirmationEmailLoading,
  } = useResendConfirmationEmail();

  const {
    refetch,
    isSuccess,
    isLoading: isConfirmEmailLoading,
  } = useConfirmEmail(code);

  const handleResendMail = () => {
    resendConfirmationEmail();

    setEnableResendMail(false);
    setSecondsAmount(COUNTDOWN_INITIAL_TIME_IN_SECONDS);
  };

  const handleConfirmEmail = () => {
    if (code.length === 4) {
      refetch();
    }
  };

  useEffect(() => {
    if (isSuccess && user?.confirmedAt) {
      navigate("/");
    }
  }, [isSuccess, user]);

  return (
    <Flex direction="column" gap={8} w="100%" h="100%" px={8} pb={[8, 0]}>
      <Flex
        as="form"
        direction="column"
        justify="space-between"
        gap={8}
        w="100%"
        h="100%"
        onSubmit={handleSubmit(handleConfirmEmail)}
      >
        <FormControl
          display="flex"
          flexDirection="column"
          alignContent="center"
          gap={16}
          maxW={80}
        >
          <FormLabel textAlign="center" fontSize="sm">
            Enviamos um código de confirmação para seu e-mail confirme abaixo
            este código
          </FormLabel>

          <HStack justify="center">
            <PinInput
              placeholder=""
              value={code}
              onChange={(input) => {
                setValue("code", input);
              }}
            >
              <PinInputField
                boxShadow="0px 5px 10px 0px #074EE826"
                borderColor="primary.500"
                color="primary.500"
                fontWeight={700}
                minW={16}
                h={54}
                _focus={{
                  boxShadow: "0px 5px 10px 0px #074EE826",
                }}
                _hover={{
                  borderColor: "primary.600",
                }}
              />
              <PinInputField
                boxShadow="0px 5px 10px 0px #074EE826"
                borderColor="primary.500"
                color="primary.500"
                fontWeight={700}
                minW={16}
                h={54}
                _focus={{
                  boxShadow: "0px 5px 10px 0px #074EE826",
                }}
                _hover={{
                  borderColor: "primary.600",
                }}
              />
              <PinInputField
                boxShadow="0px 5px 10px 0px #074EE826"
                borderColor="primary.500"
                color="primary.500"
                fontWeight={700}
                minW={16}
                h={54}
                _focus={{
                  boxShadow: "0px 5px 10px 0px #074EE826",
                }}
                _hover={{
                  borderColor: "primary.600",
                }}
              />
              <PinInputField
                boxShadow="0px 5px 10px 0px #074EE826"
                borderColor="primary.500"
                color="primary.500"
                fontWeight={700}
                minW={16}
                h={54}
                _focus={{
                  boxShadow: "0px 5px 10px 0px #074EE826",
                }}
                _hover={{
                  borderColor: "primary.600",
                }}
              />
            </PinInput>
          </HStack>
        </FormControl>

        <Flex direction="column" align="center" gap={4}>
          <Text fontWeight={700} fontSize="sm" color="gray.400">
            Não recebeu o código?
          </Text>

          <Button
            isFullWidth
            type="submit"
            size="lg"
            variant="outline"
            isLoading={isResendConfirmationEmailLoading}
            isDisabled={!enableResendMail}
            onClick={handleResendMail}
          >
            {enableResendMail
              ? "Reenviar código"
              : `${minutes}:${String(seconds).padStart(
                  2,
                  "0"
                )} para reenviar código`}
          </Button>

          <Button
            isFullWidth
            type="submit"
            size="lg"
            isDisabled={code.length < 4}
            isLoading={isConfirmEmailLoading}
            mt={2}
          >
            Confirmar e-mail
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
