export function groupBy(array: any[], key: string) {
  const reduce = array.reduce(
    (acc, item) => ({
      ...acc,
      [item[key]]: [...(acc[item[key]] ?? []), item],
    }),
    {}
  );
  return Object.entries(reduce);
}
