import { Layout } from "components/Layout";
import { NotFound } from "pages/NotFound";
import { RouteObject } from "react-router-dom";
import { authRoutes } from "./auth.routes";
import { protectedRoutes } from "./protected.routes";

export const commonRoutes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: "*",
        element: <NotFound />,
      },
      protectedRoutes,
      authRoutes,
    ],
  },
];
