import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      color: "primary.500",
    },
  },
  variants: {
    outline: {
      field: {
        height: 50,
        fontSize: "sm",
        borderRadius: 10,
        borderColor: "gray.200",
        _placeholder: {
          color: "gray.200",
        },
        _hover: {
          borderColor: "primary.500",
        },
        _focus: {
          boxShadow: "none",
          borderColor: "primary.500",
        },
        _invalid: {
          boxShadow: "none",
          _placeholder: {
            color: "red.300",
          },
          "&:not(:placeholder-shown), &:placeholder-shown": {
            borderColor: "error",
            color: "error",
          },
        },
        _disabled: {
          opacity: .5,
          _hover: {
            borderColor: "inherit",
          },
          "&:not(:placeholder-shown), &:placeholder-shown": {
            borderColor: "inherit",
            color: "inherit",
          },
        },
        "&:not(:placeholder-shown)": {
          borderColor: "primary.500",
          color: "primary.500"
        },
      },
    },
  },
};

export default Input;