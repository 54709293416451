import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    color: "gray.400",
    fontWeight: 700,
    fontSize: "xs",
    marginBottom: 1,
  },
};

export default FormLabel;
