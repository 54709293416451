import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  ListItem,
  ModalBody,
  ModalFooter,
  Text,
  Textarea,
  List,
} from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { useAuth } from "contexts/auth/useAuth";
import { useReportQuestion } from "hooks/event";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getChoiceLetter } from "utils/getChoiceLetter";
import { Modal } from "../../Modal";
import { ReportModalProps } from "./props";

export function ReportModal({
  isOpen,
  onClose,
  title,
  questionId,
  numberQuestion,
  choices,
}: ReportModalProps) {
  const { id: eventId } = useParams();

  const { mutate: reportQuestion, isLoading, isSuccess } = useReportQuestion();

  const { user } = useAuth();

  const [justification, setJustification] = useState("");

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isOpen) setJustification("");
  }, [isOpen]);

  const handleReportQuestion = () => {
    reportQuestion({
      eventId: eventId as string,
      justification,
      questionId,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalBody
        gap={4}
        display="flex"
        flexDirection="column"
        pt={8}
        px={8}
        pb={0}
      >
        <Text fontSize="sm">Questão {numberQuestion}</Text>

        <Box
          dangerouslySetInnerHTML={{ __html: title }}
          fontSize="1.125rem"
          color="gray.500"
        />

        <List pl={2}>
          {choices?.map(({ text, id }, index) => (
            <ListItem key={id}>
              {getChoiceLetter(index)}
              {") "}
              {text}
            </ListItem>
          ))}
        </List>

        <Divider />

        <FormControl isRequired>
          <FormLabel>
            Escreva um feedback para o proprietário do evento, justificando o
            problema.
          </FormLabel>
          <Textarea
            value={justification}
            onChange={(event) => setJustification(event?.target.value)}
            placeholder="ex.: Recusamos sua solicitação, pois..."
          />
        </FormControl>
      </ModalBody>

      <ModalFooter gap={10} justifyContent="space-between">
        <ParticipantCard user={user} />

        <Flex justifyContent="space-between" gap={4}>
          <Button variant="link" onClick={onClose}>
            Cancelar
          </Button>

          <Button
            colorScheme="secondary"
            px="1.188rem"
            onClick={handleReportQuestion}
            isLoading={isLoading}
            isDisabled={justification === ""}
          >
            Reportar
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
}
