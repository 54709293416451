import {
  Divider,
  Flex,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ParticipantCard } from "components/Cards/ParticipantsCard";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { FooterDesktop } from "components/Quiz/FooterDesktop";
import { FooterMobile } from "components/Quiz/FooterMobile";
import { SeeAnswer } from "components/SeeAnswer";
import { Event, QuestionType } from "interfaces/EventOLD";
import { User } from "interfaces/User";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAnswersSubmissions, getEvent } from "services/event";
import { formatDate, formatHour } from "utils/format";

interface ChoicesProps {
  isCorrect: boolean;
  text: string;
  id: string;
}
export interface QuestionsProps {
  title: string;
  type: QuestionType;
  choices: ChoicesProps[];
  criteria: string;
}
interface AnswerProps {
  multipleChoiceAnswer: string;
  openAnswer: string;
  question: QuestionsProps;
}

interface AnswerSubmission {
  answers: AnswerProps[];
  user: User;
}
export function Answers() {
  const navigate = useNavigate();
  const { id, answerId } = useParams();
  const isDesktop = useBreakpointValue({ lg: "none" });

  const [detailsEvent, setDetailsEvent] = useState<Event>({} as Event);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [answerSubmission, setAnswerSubmission] = useState<AnswerSubmission>(
    {} as AnswerSubmission
  );

  async function EventDetails() {
    setIsLoading(true);
    try {
      if (id) {
        const res = await getEvent(id);
        setDetailsEvent(res);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function Answers() {
    setIsLoading(true);
    try {
      if (answerId) {
        const res = await getAnswersSubmissions(answerId);
        setAnswerSubmission(res);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(true);
    }
  }
  useEffect(() => {
    EventDetails();
    Answers();
  }, []);

  return (
    <>
      {detailsEvent.name && (
        <Header
          title={detailsEvent?.name}
          goback={`/meus-eventos/${id}/gerenciador`}
        />
      )}

      <Paper p={8} gap={8} overflow="auto">
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner color="primary.500" />
          </Flex>
        ) : (
          <>
            <Flex justifyContent="space-between" w="100%">
              <Flex direction="column">
                <Text fontWeight="700" fontSize="1.125rem" color="black">
                  Respostas
                </Text>
                <Text fontSize="0.875rem" color="gray.300">
                  Data limite {formatDate(detailsEvent.responsePeriodEnd)} -{" "}
                  {formatHour(detailsEvent.responsePeriodEnd)}
                </Text>
              </Flex>

              <ParticipantCard
                showEmail={false}
                user={answerSubmission?.user}
                w="fit"
              />
            </Flex>
            <Divider />
            <Flex>
              {answerSubmission?.answers && (
                <SeeAnswer
                  numberQuestion={currentQuestion}
                  {...answerSubmission.answers[currentQuestion]}
                />
              )}
            </Flex>
            <Flex
              w="100%"
              h="100%"
              direction="column"
              justifyContent="flex-end"
            >
              {isDesktop ? (
                <FooterDesktop
                  disabled={false}
                  currentQuestion={currentQuestion + 1}
                  numberQuestions={answerSubmission?.answers?.length}
                  onChangeNext={() => {
                    if (
                      currentQuestion + 1 ===
                      answerSubmission?.answers?.length
                    ) {
                      navigate(-1);
                    }
                    setCurrentQuestion(currentQuestion + 1);
                  }}
                  onChangePrevious={() =>
                    setCurrentQuestion(currentQuestion - 1)
                  }
                  nameNextButtom={
                    answerSubmission?.answers?.length === 1 ||
                    currentQuestion + 1 === answerSubmission?.answers?.length
                      ? "Sair"
                      : "Prosseguir"
                  }
                />
              ) : (
                <FooterMobile
                  disabled={false}
                  currentQuestion={answerSubmission?.answers?.length}
                  numberQuestions={5}
                  onChangeNext={() => {
                    if (
                      currentQuestion + 1 ===
                      answerSubmission?.answers?.length
                    ) {
                      navigate(-1);
                    }
                    setCurrentQuestion(currentQuestion + 1);
                  }}
                  onChangePrevious={() =>
                    setCurrentQuestion(currentQuestion - 1)
                  }
                />
              )}
            </Flex>
          </>
        )}
      </Paper>
    </>
  );
}
