import { Slide } from "interfaces/explore";
import api from "./api";

export async function getExploreSlidesRequest() {
  const { data } = await api.get<Slide[]>("explore-slide");
  return data;
}

export async function uploadExploreSlideRequest(file: File) {
  const formData = new FormData();

  formData.append("file", file);

  const { data } = await api.post("explore-slide", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
}

export async function deleteExploreSlideRequest(id: string) {
  const { data } = await api.delete(`/explore-slide/${id}`);
  return data;
}
