import {
  Flex,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import csv from "assets/csv.svg";
import docx from "assets/docx.svg";
import jpeg from "assets/jpeg.svg";
import mp4 from "assets/mp4.svg";
import pdf from "assets/pdf.svg";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MaterialCardProps } from "./props";

export function MaterialCard({
  filename,
  byteSize,
  createdAt,
  url,
  onRemove,
}: MaterialCardProps) {
  const renderIconTypeCard = () => {
    switch (filename.split(".")[1]) {
      case "csv":
        return csv;
      case "txt":
        return docx;
      case "docx":
        return docx;
      case "png":
        return jpeg;
      case "jpeg":
        return jpeg;
      case "jpg":
        return jpeg;
      case "mp4":
        return mp4;
      default:
        return pdf;
    }
  };

  return (
    <Flex
      w="100%"
      align="center"
      justify="space-between"
      boxShadow="0px 2px 4px rgba(139, 149, 164, 0.24)"
      borderRadius={8}
      py={3}
      px={2}
      cursor="pointer"
    >
      <Flex gap={2} align="center">
        <Image
          boxSize="42px"
          borderRadius="24px"
          objectFit="cover"
          src={renderIconTypeCard()}
          alt={renderIconTypeCard()}
          flexShrink={0}
        />

        <Flex direction="column" w="100%">
          <Tooltip label={filename}>
            <Text
              color="black"
              fontSize="sm"
              fontWeight={500}
              whiteSpace="nowrap"
              maxW="260px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {filename}
            </Text>
          </Tooltip>
          <Text fontSize="xs" color="gray.500">
            {format(new Date(createdAt), "dd 'de' LLLL 'de' yyyy", {
              locale: ptBR,
            })}
          </Text>
        </Flex>
      </Flex>

      <Flex direction="column" justify="end" align="end" gap={3}>
        <Menu placement="top-end">
          <MenuButton
            as={IconButton}
            aria-label=""
            variant="link"
            icon={<BiDotsVerticalRounded />}
            minW="auto"
          />

          <MenuList
            border="none"
            boxShadow="4px 4px 4px rgba(0, 0, 0, 0.25)"
            borderRadius={10}
            minW={160}
          >
            <MenuItem
              color="primary.500"
              justifyContent="center"
              fontSize="sm"
              as={Link}
              href={url}
            >
              Baixar arquivo
            </MenuItem>
            <MenuItem
              color="secondary.500"
              justifyContent="center"
              fontSize="sm"
              onClick={onRemove}
            >
              Deletar arquivo
            </MenuItem>
          </MenuList>
        </Menu>

        <Text fontSize="xs" color="gray.600">
          {(byteSize / 1000).toFixed(2)}Kb
        </Text>
      </Flex>
    </Flex>
  );
}
