import { Flex, Spinner } from "@chakra-ui/react";
import { EmptyContent } from "components/EmptyContent";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { GroupProvider } from "contexts/group/useGroup";
import { useGroup } from "hooks/group";
import { MdPeople } from "react-icons/md";
import { Outlet, useParams } from "react-router-dom";
import { LeftSide } from "./LeftSide";

export function Groups() {
  const { id } = useParams();
  const { data: group, isLoading } = useGroup(id);

  return (
    <GroupProvider>
      <Header title="Grupos" />

      <Flex gap={4} overflow="auto" h="100%">
        <LeftSide />

        {isLoading ? (
          <Paper flex={3} p={8} align="center" justify="center">
            <Spinner />
          </Paper>
        ) : (
          <Paper flex={3} p={8} overflow="auto">
            {group ? (
              <Outlet />
            ) : (
              <EmptyContent
                message="Nenhum grupo selecionado."
                icon={MdPeople}
              />
            )}
          </Paper>
        )}
      </Flex>
    </GroupProvider>
  );
}
