import {
  Avatar,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton, List,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import SvgLogo from "build/assets/Logo";
import { SIDEBAR_ITEMS } from "constants/sidebar";
import { COMPANY_NAME } from "constants/texts";
import { useAuth } from "contexts/auth/useAuth";
import { getYear } from "date-fns";
import { DisclosureProps } from "interfaces/common";
import {
  MdAddPhotoAlternate,
  MdKeyboardArrowRight,
  MdLogout
} from "react-icons/md";
import { Link } from "react-router-dom";
import { makeFullName } from "utils/makeFullName";
import { SidebarItem } from "../SidebarItem";
import { SignOutModal } from "../SignOutModal";

export function MobileSidebarMenu({ isOpen, onClose }: DisclosureProps) {
  const { user } = useAuth();

  const {
    isOpen: isSignOutModalOpen,
    onOpen: onSignOutModalOpen,
    onClose: onSignOutModalClose,
  } = useDisclosure();

  function renderSidebarItems() {
    return SIDEBAR_ITEMS.filter((item) => item.path !== "/grupos").map(
      (item) => <SidebarItem key={item.path} {...item} />
    );
  }

  return (
    <Drawer placement="left" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />

      <DrawerContent bg="background" py={8}>
        <DrawerHeader
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={8}
          mb={8}
        >
          <Link to="/">
            <SvgLogo width="92px" height="20px" />
          </Link>

          <Flex
            as={Link}
            to={`/${user?.username}`}
            align="center"
            w="100%"
            gap={4}
          >
            <Avatar
              src={user?.image ?? undefined}
              icon={!user?.image ? <MdAddPhotoAlternate /> : undefined}
              bg="transparent"
              color="primary.500"
              border="3px solid"
              borderColor="primary.500"
              boxSize={16}
            />

            <Text color="primary.500" fontWeight={400} flex={1}>
              {makeFullName(user?.firstName, user?.lastName)}
            </Text>

            <IconButton
              variant="link"
              aria-label="link to profile"
              icon={<MdKeyboardArrowRight />}
            />
          </Flex>
        </DrawerHeader>

        <DrawerBody p={0}>
          <List w="100%">{renderSidebarItems()}</List>
        </DrawerBody>

        <DrawerFooter flexDirection="column" alignItems="flex-start" gap={6}>
          <Button
            variant="link"
            color="grayText"
            leftIcon={<MdLogout />}
            p={0}
            onClick={onSignOutModalOpen}
          >
            Sair
          </Button>

          <Divider />

          <Text
            fontSize="2xs"
            color="gray.200"
            maxW={40}
            textAlign="center"
            mx="auto"
          >
            {getYear(new Date())}. Todos os direitos reservados. {COMPANY_NAME}.
          </Text>
        </DrawerFooter>
      </DrawerContent>

      <SignOutModal isOpen={isSignOutModalOpen} onClose={onSignOutModalClose} />
    </Drawer>
  );
}
