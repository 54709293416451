import {
  Button,
  Flex,
  Grid,
  Icon,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { MaterialCard } from "components/Cards/MaterialCard";
import {
  useAddGroupMaterial,
  useDeleteGroupMaterial,
  useGroupMaterials,
} from "hooks/group";
import { ChangeEvent } from "react";
import { IoMdCloudUpload } from "react-icons/io";
import { MdAddCircle } from "react-icons/md";
import { useParams } from "react-router-dom";

export function Materials() {
  const { id } = useParams();

  const { data: materials, isLoading } = useGroupMaterials(id as string);
  const { mutate: deleteGroupMaterial } = useDeleteGroupMaterial(id as string);
  const { mutate: addGroupMaterial, isLoading: addMaterialLoading } =
    useAddGroupMaterial(id as string);

  const handleAddFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.item(0)) {
      addGroupMaterial(event.target.files.item(0) as File);
    }
  };

  return (
    <Flex direction="column" h="100%" gap={8}>
      <Grid
        templateColumns={
          isLoading ? "1fr" : "repeat(auto-fill, minmax(315px, 1fr))"
        }
        gap={6}
        justifyItems="center"
        alignItems={isLoading ? "center" : "flex-start"}
        h="100%"
        w="100%"
      >
        {isLoading && <Spinner />}

        {materials?.map((material) => (
          <MaterialCard
            onRemove={() => deleteGroupMaterial(material._id)}
            {...material}
          />
        ))}
      </Grid>

      <Flex flex={1} h="100%" direction="column" align="center">
        <Input
          type="file"
          id="update-profile-picture"
          hidden
          onChange={handleAddFile}
        />

        <Button
          as="label"
          htmlFor="update-profile-picture"
          cursor="pointer"
          mt="5px"
          size="md"
          height={12}
          fontSize={16}
          leftIcon={<Icon as={MdAddCircle} w={5} h={5} color="white" />}
          w="fit-content"
          isLoading={addMaterialLoading}
        >
          Adicionar materiais
        </Button>

        {materials?.length === 0 && (
          <>
            <Flex direction="row" alignSelf="center" mt="15%">
              <Icon
                as={IoMdCloudUpload}
                w={16}
                h={10}
                color="#18B9AA"
                opacity={0.3}
              />
            </Flex>
            <Flex direction="row" mt="15px">
              <Text color="#A7A7A7" fontSize="1rem">
                Nenhum arquivo adicionado.
              </Text>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}
