import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Tab,
  TabList,
  Tabs,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { EmptyContent } from "components/EmptyContent";
import { EventCard } from "components/Events/EventCard";
import { EventFilterModal } from "components/Events/EventFilterModal";
import { Header } from "components/Layout/Dashboard/Header";
import { PaginationBar } from "components/PaginationBar";
import { Paper } from "components/Paper";
import { Slider } from "components/Slider";
import { eventTimeTypeList } from "constants/event";
import { useEvents } from "hooks/event";
import { EventListFilters, EventStatusTime } from "interfaces/event";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdClose, MdOutlineToday, MdSearch } from "react-icons/md";

export function Explore() {
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [page, setPage] = useState(1);

  const methods = useForm<Omit<EventListFilters, "page" | "size">>({
    defaultValues: {
      name: "",
      time: "future",
    },
  });

  const { register, watch, setValue, reset } = methods;

  const name = watch("name");
  const time = watch("time");

  const onTabChange = (index: number) => {
    setValue("time", eventTimeTypeList[index].value as EventStatusTime);

    setPage(1);
  };

  const { data: eventsRequest, isLoading } = useEvents({
    size: 9,
    page,
    time: name !== "" ? undefined : time,
    name: name === "" ? undefined : name,
  });

  return (
    <>
      <Header hasFilter title="Explorar" onFilterOpen={onOpen} />

      <Paper p={8} h="100vh" overflow="auto">
        <Flex align="center">
          <Flex
            align="center"
            justify={isLargerThan1080 ? "flex-end" : "center"}
            w="100%"
            gap={4}
          >
            {isLargerThan1080 && name !== "" && (
              <Button variant="link" size="xs" onClick={() => reset()}>
                Limpar filtros
              </Button>
            )}

            <Flex as="form" gap={4} w={isLargerThan1080 ? "auto" : "100%"}>
              <FormControl>
                {!isLargerThan1080 && <FormLabel>Pesquisar</FormLabel>}

                <InputGroup>
                  {isLargerThan1080 ? (
                    <InputRightElement
                      children={name === "" ? <MdSearch /> : <MdClose />}
                      color="primary.500"
                      cursor="pointer"
                      onClick={() => {
                        if (name !== "") reset({ name: "" });
                      }}
                    />
                  ) : (
                    <InputLeftElement
                      children={<MdSearch />}
                      color="primary.500"
                      cursor="pointer"
                      onClick={() => {
                        if (name !== "") reset({ name: "" });
                      }}
                    />
                  )}
                  <Input placeholder="Pesquisar" {...register("name")} />

                  {!isLargerThan1080 && name !== "" && (
                    <InputRightElement
                      children={<MdClose />}
                      color="primary.500"
                      cursor="pointer"
                      onClick={() => {
                        if (name !== "") reset({ name: "" });
                      }}
                    />
                  )}
                </InputGroup>
              </FormControl>
            </Flex>
          </Flex>
        </Flex>

        {isLargerThan1080 && (
          <Tabs onChange={onTabChange}>
            {name === "" && (
              <TabList>
                <Tab>Futuros</Tab>
                <Tab>Em andamento</Tab>
                <Tab>Finalizados</Tab>
              </TabList>
            )}
          </Tabs>
        )}

        <Flex
          w="100%"
          justify="center"
          align="center"
          mt={isLargerThan1080 ? 10 : 6}
          mb={8}
        >
          <Slider />
        </Flex>

        <Flex
          direction="column"
          align="center"
          justify="center"
          w="100%"
          gap={4}
          mt={8}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <Grid
              templateColumns={
                eventsRequest?.data?.length === 0
                  ? "1fr"
                  : "repeat(auto-fill, minmax(315px, 1fr))"
              }
              gap={6}
              justifyItems="center"
              alignItems={eventsRequest?.length === 0 ? "center" : "flex-start"}
              h="100%"
              w="100%"
            >
              {eventsRequest?.data?.length === 0 ? (
                <EmptyContent
                  message="Não há eventos para exibir."
                  icon={MdOutlineToday}
                />
              ) : (
                eventsRequest?.data?.map((event: any) => (
                  <EventCard key={event.id} {...event} />
                ))
              )}
            </Grid>
          )}
        </Flex>

        <Flex justify="center">
          <PaginationBar {...eventsRequest?.meta} setPage={setPage} />
        </Flex>
      </Paper>

      <EventFilterModal
        enableTime={false}
        isOpen={isOpen}
        onClose={onClose}
        selectedItem={time as string}
        setSelectedItem={(value) => setValue("time", value as EventStatusTime)}
        setSelectedDate={(value) => setValue("startDate", value as Date)}
      />
    </>
  );
}
