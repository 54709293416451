import { Attachment } from "interfaces/event";
import BlotFormatter from "quill-blot-formatter";
import { useCallback, useRef } from "react";
import { FieldError } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "./styles.css";

Quill.register("modules/blotFormatter", BlotFormatter);

type EditorProps = {
  setAttachment?: (attachment: Attachment) => void;
  error?: FieldError | undefined;
  onChange(value: string): void;
  placeholder?: string;
  defaultValue?: string;
};

export function Editor({
  setAttachment,
  error,
  onChange,
  placeholder,
  defaultValue,
}: EditorProps) {
  const quillRef = useRef<ReactQuill | null>(null);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getImage = useCallback(async () => {
    const editor = quillRef.current?.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input.files?.length) {
        const file = input.files[0];

        const currentUrl = (await toBase64(file)) as string;

        if (setAttachment) {
          setAttachment({
            file,
            url: currentUrl,
          });
        }

        const range = editor?.getSelection()?.index;
        editor?.insertEmbed(range ?? 0, "image", currentUrl, "user");
      }
    };
  }, []);

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["blockquote", "link", "image"],
      ],
      handlers: {
        image: getImage,
      },
    },
    blotFormatter: {},
  };

  return (
    <ReactQuill
      ref={quillRef}
      placeholder={placeholder}
      theme="bubble"
      modules={modules}
      onChange={onChange}
      defaultValue={defaultValue}
      className={error && "error"}
    />
  );
}
