import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { groupTabs } from "constants/group";
import { useGroup } from "hooks/group";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Events } from "../Panels/Events";
import { Materials } from "../Panels/Materials";
import { Participants } from "../Panels/Participants";
import { Ranking } from "../Panels/Ranking";

export function GroupDetails() {
  const { id, tab } = useParams();

  const [tabIndex, setTabIndex] = useState(0);
  const { data: group } = useGroup(id);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setTabIndex(groupTabs.indexOf(tab ?? "participantes"));
  }, [id]);

  return (
    <Flex
      direction="column"
      gap={8}
      overflow="auto"
      h="100%"
    >
      <Heading fontSize="1.125rem" color="gray.400">
        {group?.name}
      </Heading>

      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {groupTabs.map((tab) => (
            <Tab
              key={tab}
              as={Link}
              to={{
                pathname: `/grupos/${id}/${tab}`,
              }}
              textTransform="capitalize"
            >
              {tab}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Participants />
          </TabPanel>

          <TabPanel>
            <Events />
          </TabPanel>

          <TabPanel>
            <Materials />
          </TabPanel>

          <TabPanel>
            <Ranking />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex >
  );
}
