import { Flex, Spinner } from "@chakra-ui/react";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { EventProvider } from "contexts/event/useEvent";
import { useEventQuestions } from "hooks/event";
import { Outlet, useParams } from "react-router-dom";
import { LeftSide } from "./LeftSide";

export function CreateEvent() {
  const { id } = useParams();
  const { isLoading } = useEventQuestions(id);

  return (
    <EventProvider>
      <Header title={id ? "Editando evento" : "Criando evento"} />

      {isLoading ? (
        <Flex
          gap={12}
          overflowY="auto"
          h="100%"
          align="center"
          justify="center"
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex gap={12} overflow="auto" position="relative" h="100%">
          <LeftSide />

          <Paper>
            <Outlet />
          </Paper>
        </Flex>
      )}
    </EventProvider>
  );
}
