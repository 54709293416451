import { Flex, Text, Icon } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { TagProps } from "./props";

export function Tag(props: TagProps) {
  const { name, onClose, ...rest } = props;

  return (
    <Flex
      {...rest}
      borderRadius="full"
      w="fit-content"
      py="2px"
      px="9px"
      color="background"
      align="center"
      gap={1}
    >
      <Text fontSize="xs">
        {name}
      </Text>
      {
        onClose && (
          <Icon as={MdClose} w={3} h={3} cursor="pointer" onClick={onClose} />
        )
      }
    </Flex >
  );
}
