import { ReactNode, useMemo } from "react";
import { IconContext } from "react-icons";

interface ConfigIconProps {
  children: ReactNode;
}

export function IconProvider({ children }: ConfigIconProps) {
  const value: IconContext = useMemo(
    () => ({
      size: "20px",
      style: {
        fontSize: "20px",
      },
    }),
    []
  );

  return <IconContext.Provider value={value}>{children}</IconContext.Provider>;
}
