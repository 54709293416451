import { Flex, Icon } from "@chakra-ui/react";
import { Option } from "interfaces/common";
import { MdArrowDropDown } from "react-icons/md";
import Select, {
  components,
  ControlProps,
  DropdownIndicatorProps,
  StylesConfig,
} from "react-select";
import { SelectComponentProps, SelectOption } from "./props";

function Control({ children, ...props }: ControlProps<SelectOption, false>) {
  const {
    // @ts-ignore
    selectProps: { icon },
  } = props;
  return (
    <components.Control {...props}>
      {icon && <Flex pl=".5rem">{icon}</Flex>}
      {children}
    </components.Control>
  );
}

function DropdownIndicator(props: DropdownIndicatorProps<SelectOption, false>) {
  const {
    // @ts-ignore
    selectProps: { showDropdownIcon },
  } = props;
  return showDropdownIcon ? (
    <components.DropdownIndicator {...props}>
      <Icon as={MdArrowDropDown} color="primary.500" w={6} h={6} />
    </components.DropdownIndicator>
  ) : null;
}

const styles: StylesConfig<SelectOption, false> = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, { menuIsOpen, hasValue, isDisabled }) => ({
    ...provided,
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    boxShadow: "none",
    fontSize: 14,
    height: 50,
    borderRadius: menuIsOpen ? "10px 10px 0 0" : 10,
    borderBottom: menuIsOpen ? "none" : "1px solid inherit",
    borderColor: hasValue ? "var(--chakra-colors-primary-500)" : "inherit",
    color: "var(--chakra-colors-primary-500)",
    cursor: "pointer",
    "&.select__control--is-disabled": {
      opacity: 0.5,
      background: "transparent",
    },
    "&:hover": {
      borderColor: "var(--chakra-colors-primary-500)",
    },
    "&.select__control--is-focused": {
      borderColor: "var(--chakra-colors-primary-500)",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "var(--chakra-colors-primary-primary-500)",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "var(--chakra-colors-background)",
    margin: 0,
    padding: 0,
    paddingBottom: ".65rem",
    borderRadius: "0 0 10px 10px",
    border: "1px solid var(--chakra-colors-primary-500)",
    boxShadow: "none",
    transition: "all .3s",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    paddingLeft: 0,
    marginLeft: 0,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    height: 35,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    color: "var(--chakra-colors-primary-500)",
    background: isFocused ? "rgba(24, 185, 170, 0.1)" : "transparent",
    "&:active": {
      background: "var(--chakra-colors-primary-100)",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--chakra-colors-gray-200)",
  }),
};

export function SelectComponent({
  isClearable,
  isDisabled,
  error,
  options,
  placeholder,
  noOptionsMessage = "Sem opções",
  onChange,
  value,
  ...props
}: SelectComponentProps) {
  return (
    <Select
      isDisabled={isDisabled}
      isClearable={isClearable}
      styles={styles}
      options={options}
      placeholder={placeholder}
      noOptionsMessage={() => noOptionsMessage}
      classNamePrefix="arbo_select"
      components={{
        IndicatorSeparator: () => null,
        Control,
      }}
      onChange={(option) => {
        if (Array.isArray(option)) {
          onChange((option as Option[]).map(({ value }) => String(value)));
        } else {
          onChange(option ? String((option as Option).value) : "");
        }
      }}
      value={
        typeof value === "string"
          ? options.find((option) => option.value === value)
          : options?.filter((option) => value?.includes(String(option.value)))
      }
      {...props}
    />
  );
}
