import {
  Badge,
  Box, Button, Heading, HStack, Icon, List, ListIcon, ListItem, Stack, Text, useColorModeValue, useDisclosure, VStack
} from "@chakra-ui/react";
import { useAuth } from "contexts/auth/useAuth";
import { useToast } from "contexts/layout/toast/useToast";
import { createColumn, Table } from "react-chakra-pagination/dist/index";
import { CiViewList } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlinePreview } from "react-icons/md";
import { PlanSelectionModal } from "./PlanSelectionModal";


type SubscriptionPaymentHistory = {
  id: number;
  userEmail: string;
  plan: string;
  planPeriod: "monthly" | "annual";
  paidDate: string;
  status: "pending" | "paid" | "due";
};

const subscriptionPaymentHistories: SubscriptionPaymentHistory[] = [
  {
    id: 1,
    userEmail: "fyitzovicze@cnet.com",
    plan: "Pro",
    planPeriod: "monthly",
    paidDate: "22.11.2023",
    status: "paid"
  },
  {
    id: 2,
    userEmail: "fyitzovicze@cnet.com",
    plan: "Pro",
    planPeriod: "annual",
    paidDate: "22.11.2023",
    status: "due"
  },
  {
    id: 3,
    userEmail: "fyitzovicze@cnet.com",
    plan: "Pro",
    planPeriod: "monthly",
    paidDate: "22.11.2023",
    status: "pending"
  }
];


export function Plans() {
  const { user } = useAuth();
  const { toast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const statusMap: Record<string, string> = {
    paid: "Pago",
    pending: "Pendente",
    due: "Vencido"
  };
  const planPeriodMap: Record<string, string> = {
    monthly: "Mensal",
    annual: "Anual"
  };
  const statusColorScheme: Record<string, string> = {
    paid: "green",
    pending: "yellow",
    due: "red"
  };

  const tableData = subscriptionPaymentHistories.map(subHs => ({
    userEmail: subHs.userEmail,
    plan: subHs.plan,
    planPeriod: planPeriodMap[subHs.planPeriod],
    paidDate: subHs.paidDate,
    status: (
      <Box display="flex" justifyContent="start" textAlign="center" alignItems="center">
        <Badge colorScheme={statusColorScheme[subHs.status]} width="fit-content">
          {statusMap[subHs.status]}
        </Badge>
      </Box>

    ),
    action: (
      <Button
        colorScheme="gray"
        onClick={() => console.log("show invoice")}
        size="sm"
      >
        <Icon as={MdOutlinePreview} fontSize="20" />
      </Button>
    ),
  }));

  const columnHelper = createColumn<(typeof tableData)[0]>();
  const columns = [
    columnHelper.accessor("userEmail", {
      cell: info => info.getValue(),
      header: "E-mail",
    }),
    columnHelper.accessor("plan", {
      cell: info => info.getValue(),
      header: "Plano",
    }),
    columnHelper.accessor("planPeriod", {
      cell: info => info.getValue(),
      header: "Período",
    }),
    columnHelper.accessor("paidDate", {
      cell: info => info.getValue(),
      header: "Data do pagamento",
    }),
    columnHelper.accessor("status", {
      cell: info => info.getValue(),
      header: "Status",
    }),
    columnHelper.accessor("action", {
      cell: info => info.getValue(),
      header: "",
    }),
  ];


  return (
    <Box>
      <VStack alignItems="start">
        <Heading fontSize="2xl">Planos</Heading>
        <Text fontSize="sm" color="gray.500" marginTop="0 !important">Selecione o melhor plano para você</Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 4 }}
        py={10}>

        <Box
          mb={4}
          shadow="base"
          borderWidth="1px"
          alignSelf={{ base: "center", lg: "flex-start" }}
          borderColor="green.500" // SELECTED
          maxWidth="285px"
          minWidth="285px"
          minHeight="450px"
          maxHeight="450px"
          borderRadius="xl">
          <Box py={4} px={8}>
            <Text fontWeight="500" fontSize="2xl">
              Free
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                R$
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                0,00
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /mês
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            minHeight="230px"
            borderBottomRadius="xl">
            <List spacing={3} textAlign="start" px={6}>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                01(um) evento por mês
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                50 alunos/participantes
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                100MB de armazenamento
              </ListItem>
            </List>
          </VStack>
          <Box display="flex" px={6}>
            <Button w="full" colorScheme="green" variant="outline" disabled> {/* SELECTED */}
              Plano atual
            </Button>
          </Box>

        </Box>

        <Box
          mb={4}
          shadow="base"
          borderWidth="1px"
          alignSelf={{ base: "center", lg: "flex-start" }}
          borderColor={useColorModeValue("gray.200", "gray.500")}
          maxWidth="285px"
          minWidth="285px"
          minHeight="450px"
          maxHeight="450px"
          borderRadius="xl">
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: "translate(-50%)" }}>
              <Text
                textTransform="uppercase"
                bg="yellow.400"
                px={3}
                py={1}
                color="white"
                // color={useColorModeValue("gray.900", "gray.300")}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Mais popular
              </Text>
            </Box>
            <Box py={4} px={8}>
              <Text fontWeight="500" fontSize="2xl">
                Basic
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  R$
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  29,90
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /mês
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              minHeight="230px"
              borderBottomRadius="xl">
              <List spacing={3} textAlign="start" px={6}>
                <ListItem fontSize="sm">
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  50 eventos por mês
                </ListItem>
                <ListItem fontSize="sm">
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  250 alunos/participantes
                </ListItem>
                <ListItem fontSize="sm">
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  500MB de armazenamento
                </ListItem>
                <ListItem fontSize="sm">
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Recursos de grupos e mediadores
                </ListItem>
              </List>
            </VStack>
            <Box display="flex" px={6}>
              <Button w="full" colorScheme="green" variant="outline" onClick={onOpen}>
                {/* <Text color="white"> */}
                  Contratar
                {/* </Text> */}
              </Button>
            </Box>
          </Box>
        </Box>


        <Box
          mb={4}
          shadow="base"
          borderWidth="1px"
          alignSelf={{ base: "center", lg: "flex-start" }}
          borderColor={useColorModeValue("gray.200", "gray.500")}
          maxWidth="285px"
          minWidth="285px"
          minHeight="450px"
          maxHeight="450px"
          borderRadius="xl">
          <Box py={4} px={8}>
            <Text fontWeight="500" fontSize="2xl">
              Pro
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                R$
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                39,90
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /mês
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            minHeight="230px"
            borderBottomRadius="xl">
            <List spacing={3} textAlign="start" px={6}>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                100 eventos por mês
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                250 alunos/participantes
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                2GB de armazenamento
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                Recursos de grupos e mediadores
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="green.500" />
                Integração de plataformas (em breve)
              </ListItem>
            </List>
          </VStack>
          <Box display="flex" px={6}>
            <Button w="full" colorScheme="green" variant="outline" onClick={onOpen}>
              Contratar
            </Button>
          </Box>
        </Box>

      </Stack>

      <Heading size="sm" as="h3" fontWeight="bold" color="gray.400">
        Histórico da assinatura
      </Heading>
      {/* <Box mt="2" > */}
      <Box mt="2" sx={{ "& > div": { px: 0 } }}>
        <Table
          colorScheme="green"
          emptyData={{
            icon: CiViewList,
            text: "Sem históricos de assinatura",
          }}
          totalRegisters={5}
          onPageChange={page => console.log(page)}
          columns={columns}
          data={tableData}
        />
      </Box>

      <PlanSelectionModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}
