import {
  Button,
  Flex,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { Link } from "react-router-dom";
import { ExitQuizProps } from "./props";

export function ExitQuiz({
  isOpen,
  onClose,
  saveAnswer,
}: ExitQuizProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalHeader pb="0">
        Tem certeza que deseja sair das respostas do evento?
      </ModalHeader>

      <ModalBody
        justifyContent="center"
        textAlign="center"
        fontSize="sm"
        maxW={300}
        mx="auto"
      >
        Você poderá retornar e continuar de onde parou.
      </ModalBody>

      <ModalFooter justifyContent="space-between">
        <Button p={4} variant="link" onClick={onClose} color="gray">
          Voltar
        </Button>

        <Button
          as={Link}
          to="/meus-eventos"
          onClick={saveAnswer}
          colorScheme="secondary"
        >
          Salvar e sair
        </Button>
      </ModalFooter>
    </Modal>
  );
}
