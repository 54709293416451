import { Button, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { ModalProps } from "components/Modal/props";
import { useFinishEvent } from "hooks/event";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function EndEvent({ isOpen, onClose }: ModalProps) {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    mutate: finishEvent,
    isLoading,
    isSuccess,
  } = useFinishEvent(id as string);

  const handleEndEvent = () => {
    finishEvent();
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
      navigate("/meus-eventos");
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalHeader pb="0">
        Tem certeza que deseja finalizar este evento?
      </ModalHeader>

      <ModalBody
        justifyContent="center"
        textAlign="center"
        fontSize="sm"
        maxW={300}
        mx="auto"
      >
        Você NÃO poderá retornar e continuar de onde parou!
      </ModalBody>

      <ModalFooter justifyContent="space-between">
        <Button p={4} variant="link" onClick={onClose} color="gray">
          Voltar
        </Button>

        <Button
          colorScheme="secondary"
          onClick={handleEndEvent}
          isLoading={isLoading}
        >
          Finalizar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
