import { Flex, Icon, useBreakpointValue, useToast as useChakraToast } from "@chakra-ui/react";
import { createContext, useContext, useMemo, } from "react";
import { MdCheckCircle, MdInfo, MdWarning } from "react-icons/md";
import { ToastContextData, ToastProviderProps } from "./props";

export const ToastContext = createContext({} as ToastContextData);

export function ToastProvider(props: ToastProviderProps) {
  const { children } = props;
  const renderToast = useChakraToast();
  const isDesktop = useBreakpointValue({ lg: "none" });

  function toast(
    title: string,
    status: "info" | "warning" | "success" | "error" | undefined
  ) {
    const icon = () => {
      switch (status) {
        case "info" || "error":
          return MdInfo;
        case "success":
          return MdCheckCircle;
        case "warning":
          return MdWarning;
        default:
          return MdInfo;
      }
    };

    renderToast({
      position: isDesktop ? "top-right" : "bottom",
      render: () => (
        <Flex
          align="center"
          gap={2}
          bg={status}
          color="background"
          borderRadius={10}
          py={2}
          px={4}
          minH={42}
          minW={isDesktop ? 390 : "100%"}
        >
          <Icon as={icon()} w="20px" h="20px" />
          {title}
        </Flex>
      )
    });
  }

  const ToastContextValues: ToastContextData = useMemo(() => ({
    toast
  }), [
    toast
  ]);

  return (
    <ToastContext.Provider value={ToastContextValues}>
      {children}
    </ToastContext.Provider>
  );
};

export function useToast() {
  return useContext(ToastContext);
}