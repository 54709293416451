import { AuthLayout } from "components/Layout/AuthLayout";
import { RecoverPassword } from "pages/RecoverPassword";
import { CheckMail } from "pages/RecoverPassword/CheckMail";
import { ResetPassword } from "pages/RecoverPassword/ResetPassword";
import { SendMail } from "pages/RecoverPassword/SendMail";
import { SignIn } from "pages/SignIn";
import { SignUp } from "pages/SignUp";
import { PasswordStep } from "pages/SignUp/Form/PasswordStep";
import { ProfileStep } from "pages/SignUp/Form/ProfileStep";
import { VerifyEmail } from "pages/SignUp/VerifyEmail";
import { Navigate, RouteObject } from "react-router-dom";
import { PublicRoutes } from "./PublicRoutes";

export const authRoutes: RouteObject = {
  element: <AuthLayout />,
  children: [
    {
      element: <PublicRoutes />,
      children: [
        {
          index: true,
          element: <Navigate to="login" />,
        },
        {
          path: "login",
          element: <SignIn />,
        },
        {
          path: "criar-conta",
          element: <SignUp />,
          children: [
            {
              index: true,
              element: <Navigate to="verificar-email" />,
            },
            {
              path: "verificar-email",
              element: <VerifyEmail />,
            },
            {
              path: "perfil",
              element: <ProfileStep />,
            },
            {
              path: "senha",
              element: <PasswordStep />,
            },
          ],
        },
        {
          path: "recuperar-senha",
          element: <RecoverPassword />,
          children: [
            {
              index: true,
              element: <SendMail />,
            },
            {
              path: "verificar-email",
              element: <CheckMail />,
            },
          ],
        },
        {
          path: "redefinir-senha",
          element: <ResetPassword />,
        },
      ],
    },
  ],
};
