import styled from "@emotion/styled";
import WidgetDatePicker from "react-widgets/DatePicker";

export const DatePicker = styled(WidgetDatePicker)`
  &.rw-state-focus {
    .rw-widget-picker {
      box-shadow: none;
      border-color: var(--chakra-colors-primary-500)
    }
  }  

  :hover {
    .rw-widget-picker {
      box-shadow: none;
      border-color: var(--chakra-colors-primary-500)
    }
  }

  &.picker-error {
    .rw-widget-picker {
      box-shadow: none;
      border-color: var(--chakra-colors-error);

      .rw-widget-input {
        color: var(--chakra-colors-error);
      
        ::placeholder {
          color: var(--chakra-colors-red-300);
        }
      }

      .rw-picker-btn {
        color: var(--chakra-colors-error);
      }
    }
  }

  &.picker-filled {
    .rw-widget-picker {
      box-shadow: none;
      border-color: var(--chakra-colors-primary-500);
    }
  }
  
  .rw-widget-picker {
    transition: all .2s;
    height: 50px;
    border-radius: 10px;
    grid-template: 1fr/1fr 3em;
  }

  .rw-widget-input {
    box-shadow: none;
    color: var(--chakra-colors-primary-500);
    font-size: .875rem;

    ::placeholder {
      color: var(--chakra-colors-gray-200);
    }
  }

  .rw-input-addon, .rw-input-addon.rw-picker-btn {
    border-left: none;
  }

  .rw-picker-btn {
    color: var(--chakra-colors-primary-500);
    transition: background .2s ease;

    &:hover{
      background: var(--chakra-colors-white);
    }
  }

  .rw-popup {
   border-radius: 10px;
   border-color: var(--chakra-colors-gray-200);
   margin-bottom: .5rem;
  }

  .rw-state-selected {
    background: var(--chakra-colors-primary-500);
    border: none
  }

  .rw-time-input-clear {
    display: none
  }
`;
