import { Notification } from "interfaces/notification";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import {
  getNotifications as getNotificationsRequest,
  readNotification as readNotificationRequest,
} from "services/notification";

interface NotificationProviderProps {
  children: ReactNode;
}

interface NotificationContextData {
  isLoading: boolean;
  notifications: Notification[];
  getNotifications(page?: number): Promise<void>;
  readNotification(id: string): Promise<void>;
}

export const NotificationContext = createContext({} as NotificationContextData);

export default function NotificationProvider({
  children,
}: NotificationProviderProps) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getNotifications(pages?: number) {
    setIsLoading(true);
    const res = await getNotificationsRequest(pages);
    if (pages === 1 && res.data.length < 10) {
      setNotifications(notifications);
    } else {
      setNotifications([...res.data, ...notifications]);
    }
    setIsLoading(false);
  }

  async function readNotification(id: string) {
    await readNotificationRequest(id).then(() => {
      getNotifications();
    });
  }

  const value: NotificationContextData = useMemo(
    () => ({
      notifications,
      isLoading,
      getNotifications,
      readNotification,
    }),
    [notifications, isLoading, getNotifications, readNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return useContext(NotificationContext);
}
