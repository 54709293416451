import { Flex, useMediaQuery } from "@chakra-ui/react";
import { Header } from "components/Layout/Dashboard/Header";
import { Paper } from "components/Paper";
import { Outlet } from "react-router-dom";
import Nav from "./Nav";

export function Settings() {
  const [isLargerThan1320] = useMediaQuery("(min-width: 1320px)");

  return (
    <>
      <Header title="Configurações" />

      <Flex gap={10} overflow="auto">
        {isLargerThan1320 && <Nav />}

        <Paper flex={3} p={8} height="fit-content">
          <Outlet />
        </Paper>
      </Flex>
    </>
  );
}
