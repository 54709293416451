import { Badge, Box, Button, Flex, Icon, Spinner, useDisclosure } from "@chakra-ui/react";
import {
  useAddGroupParticipant,
  useDeleteGroupParticipant,
  useGroupPartcipants
} from "hooks/group";
import { useUsers } from "hooks/user";
import { useEffect, useState } from "react";
import { createColumn, Table } from "react-chakra-pagination";
import { useForm } from "react-hook-form";
import { CiViewList } from "react-icons/ci";
import { MdOutlineEdit } from "react-icons/md";
import { useParams } from "react-router-dom";


type AccountLicense = {
  id: number;
  owner: string;
  plan: string;
  userLimit: number;
  expiryDate: string;
  status: "active" | "inactive" | "blocked";
};

const accountLicenses: AccountLicense[] = [
  {
    id: 1,
    userLimit: 100,
    owner: "Alexandre",
    plan: "Business",
    expiryDate: "22.11.2023",
    status: "active"
  },
  {
    id: 2,
    userLimit: 100,
    owner: "Alexandre",
    plan: "Basic",
    expiryDate: "22.11.2023",
    status: "blocked"
  },
  {
    id: 3,
    userLimit: 100,
    owner: "Alexandre",
    plan: "Pro",
    expiryDate: "22.11.2023",
    status: "inactive"
  }
];

export function MyAccountLicenses() {
  const { id } = useParams();
  const methods = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const participant = methods.watch("participant", "");
  const [search, setSearch] = useState("");

  const { data: users } = useUsers();
  const { data: participants, isLoading } = useGroupPartcipants(id);
  const { mutate: deleteParticipant } = useDeleteGroupParticipant(id as string);
  const {
    mutate: addGroupParticipant,
    isSuccess,
    isLoading: isAddGroupParticipantLoading,
  } = useAddGroupParticipant(id as string);

  const statusMap: Record<string, string> = {
    active: "Ativo",
    inactive: "Inativo",
    blocked: "Bloqueado"
  };
  const statusColorScheme: Record<string, string> = {
    active: "green",
    blocked: "yellow",
    inactive: "red"
  };
  const tableData = accountLicenses.map(accountLicense => ({
    owner: accountLicense.owner,
    userLimit: accountLicense.userLimit,
    plan: accountLicense.plan,
    expiryDate: accountLicense.expiryDate,
    status: (
      <Box display="flex" justifyContent="start" textAlign="center" alignItems="center">
        <Badge colorScheme={statusColorScheme[accountLicense.status]} width="fit-content">
          {statusMap[accountLicense.status]}
        </Badge>
      </Box>

    )
  }));

  const columnHelper = createColumn<(typeof tableData)[0]>();
  const columns = [
    columnHelper.accessor("plan", {
      cell: info => info.getValue(),
      header: "Plano",
    }),
    columnHelper.accessor("userLimit", {
      cell: info => info.getValue(),
      header: "Limite de usuário",
    }),
    columnHelper.accessor("expiryDate", {
      cell: info => info.getValue(),
      header: "Data de expiracao",
    }),
    columnHelper.accessor("status", {
      cell: info => info.getValue(),
      header: "Status",
    })
  ];

  useEffect(() => {
    if (isSuccess) {
      setSearch("");
    }
  }, [isSuccess]);

  return (
    <Flex direction="column" gap={0}>
      <Flex direction="column" align="center">
        {isLoading && <Spinner />}

        <Box sx={{ "& > div": { px: 0 }, width: "100%" }}>
          <Table
            colorScheme="green"
            emptyData={{
              icon: CiViewList,
              text: "Nenhuma licença encontrada.",
            }}
            totalRegisters={5}
            onPageChange={page => console.log(page)}
            columns={columns}
            data={tableData}
          />
        </Box>
      </Flex>
    </Flex>
  );
}
