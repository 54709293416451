import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { Modal } from "components/Modal";
import { useEventSubscribe } from "hooks/event";
import { DisclosureProps } from "interfaces/common";
import { EventType } from "interfaces/event";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BsKeyFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

export function EventSubscribeModal({ isOpen, onClose }: DisclosureProps) {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm<Pick<EventType, "code">>({
    defaultValues: {
      code: "",
    },
  });

  const code = watch("code");

  const {
    mutate: eventSubscribe,
    isLoading,
    isSuccess,
  } = useEventSubscribe(id as string);

  const handleEventSubscribe = ({ code }: Pick<EventType, "code">) => {
    eventSubscribe(code);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/meus-eventos/${code}`);
    }
  }, [isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalHeader maxW={300}>
        Insira o código do evento para participar
      </ModalHeader>

      <ModalCloseButton />

      <Flex
        as="form"
        direction="column"
        onSubmit={handleSubmit(handleEventSubscribe)}
      >
        <ModalBody>
          <FormControl>
            <FormLabel>Código de Evento</FormLabel>
            <InputGroup>
              <InputLeftElement children={<BsKeyFill />} color="primary.500" />
              <Input placeholder="abc-def-ghi" {...register("code")} />
            </InputGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isFullWidth
            type="submit"
            isLoading={isLoading}
            isDisabled={!isDirty}
          >
            Participar
          </Button>
        </ModalFooter>
      </Flex>
    </Modal>
  );
}
