import { Flex, Icon, Image, Input, Spinner, Text, Tooltip } from "@chakra-ui/react";
import { ChangeEvent, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MdImage } from "react-icons/md";
import { UploadImageFieldProps } from "./props";

export const UploadImageField = ({ name, ...rest }: UploadImageFieldProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { control, watch } = useFormContext();

  const image = watch(name);

  const onUploadImage = (event: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    setIsLoading(true);

    const fileList = event.target.files;

    if (!fileList || !fileList.length) return;

    onChange(fileList[0]);

    setIsLoading(false);
  };

  const renderContent = useMemo(() => {
    if (isLoading) return <Spinner />;

    if (image) {
      return (
        <Tooltip label="Alterar imagem">
          <Image
            src={image instanceof File ? URL.createObjectURL(image) : image}
            w="100%"
            h="100%"
            objectFit="cover"
            borderRadius={20}
            transition="all .3s"
            _hover={image && {
              opacity: .75,
            }}
          />
        </Tooltip>
      );
    }

    return (
      <>
        <Icon
          as={MdImage}
          w="30px"
          h="30px"
          color="primary.500"
        />
        <Text
          fontWeight={700}
          color="gray.300"
          fontSize="xs">
          Adicionar imagem
        </Text>
      </>
    );
  }, [image]);

  return (
    <Flex
      {...rest}
      direction="column"
      align="center"
      gap={2}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => (
          <Input
            type="file"
            accept=".jpg,.jpeg,.png"
            id={name}
            onChange={(event) => onUploadImage(event, onChange)}
            hidden
          />
        )}
      />

      <Flex
        {...rest}
        as="label"
        htmlFor={name}
        bg="#18B9AA1A"
        border="2px dashed"
        borderColor="primary.500"
        borderRadius={20}
        w="100%"
        direction="column"
        align="center"
        justify="center"
        cursor="pointer"
      >
        {renderContent}
      </Flex>
    </Flex>
  );
};
