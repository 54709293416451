import { Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { Paper } from "components/Paper";
import { defaultMultipleChoiceQuestion } from "constants/event";
import { useEvent } from "contexts/event/useEvent";
import { useToast } from "contexts/layout/toast/useToast";
import { useCreateEvent, useEditEvent, useEventQuestions } from "hooks/event";
import {
  Attachment,
  CreateEventType,
  MultipleChoiceQuestion,
  OpenQuestion
} from "interfaces/event";
import { User } from "interfaces/User";
import { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { FormProvider, useFieldArray } from "react-hook-form";
import { MdAddCircle } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { uploadQuestionImage } from "services/event";
import { prepareFormData } from "utils/prepareFormData";

import { reorder } from "utils/reorder";
import { v4 as uuidv4 } from "uuid";
import { eventSetupSchema } from "../Settings/schema";
import { QuestionEditor } from "./QuestionEditor";

export function QuestionsAndAnswers() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { event, setEvent, setSteps, setQuestionsToRemove, steps } = useEvent();

  const { eventQuestionsAndAnswersForm, eventSettingsForm, questionsToRemove } = useEvent();

  const { data: questionsFromRequest } = useEventQuestions(id);

  const {
    mutate: createEvent,
    isLoading: isCreateLoading,
    data: eventCreated,
    isSuccess: isCreateSuccess,
  } = useCreateEvent();

  const {
    mutate: editEvent,
    isLoading: isEditLoading,
    isSuccess: isEditSuccess,
  } = useEditEvent();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = eventQuestionsAndAnswersForm;

  const questions = watch("questions");

  useEffect(() => {
    if (!steps.includes("Q&A")) {
      navigate("/meus-eventos/editor");
    }
  }, [steps]);

  const {
    fields: questionsList,
    append,
    remove,
    insert,
  } = useFieldArray({
    control,
    name: "questions",
  });

  const appendQuestion = (order: number) => {
    append({ ...defaultMultipleChoiceQuestion, order, id: uuidv4() });
  };

  const duplicateQuestion = (
    order: number,
    question: OpenQuestion | MultipleChoiceQuestion
  ) => {
    insert(order, { ...question, id: uuidv4() });
  };

  const onRemoveQuestion = (order: number) => {
    const questionToRemove = questions.find(
      (question) => question.order === order
    );

    if (questionToRemove) {
      setQuestionsToRemove((questionsToRemove) => [
        ...questionsToRemove,
        { ...questionToRemove, remove: true },
      ]);
    }

    remove(order - 1);
  };

  const handleSendQuestions = (data: Pick<CreateEventType, "questions">) => {
    setEvent({
      ...event,
      questions: data.questions.map((question, index) => ({
        ...question,
        order: index + 1,
      })),
    });

    setSteps((step) => [...step, "PUBLISH"]);
    navigate(
      event.id
        ? `/meus-eventos/${event.id}/editor/publicar`
        : "/meus-eventos/editor/publicar"
    );
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    if (result.type === "questions") {
      setValue(
        "questions",
        reorder(questions, result.source.index, result.destination.index)
      );
    } else if (result.type === "choices") {
      const { order } = JSON.parse(result.draggableId);

      setValue(
        `questions.${order}.choices`,
        reorder(
          (questions[order] as MultipleChoiceQuestion).choices,
          result.source.index,
          result.destination.index
        )
      );
    }
  };

  const handleSaveEvent = async () => {
    const validateEvent = Promise.all([
      eventSetupSchema.validate(eventSettingsForm?.getValues()),
    ]);

    validateEvent
      .then(async (resolvedData) => {
        const [eventResolved] = resolvedData;

        const moderators = (eventResolved.moderators as User[])?.map(
          ({ email }) => email
        );

        const questions = [];

        if (steps.includes("Q&A")) {
          const questionsListWithRemovedQuestions = eventQuestionsAndAnswersForm
            .getValues()
            .questions?.concat(id ? questionsToRemove : []) as Array<
              OpenQuestion | MultipleChoiceQuestion
            >;

          for (const question of questionsListWithRemovedQuestions) {
            let questionId;

            if (id) {
              if (
                (event.questions?.some(({ id }) => id === question?.id) ||
                  questionsToRemove?.some(({ id }) => id === question?.id)) &&
                questionsFromRequest?.some(({ id }) => id === question?.id)
              ) {
                questionId = question?.id;
              }
            }

            if (question.files?.length) {
              const attachements = question.files.reduce(
                (previousFile, currentFile) =>
                  question.title.includes(currentFile.url)
                    ? [...previousFile, currentFile]
                    : previousFile,
                [] as Attachment[]
              );

              for (const { file, url } of attachements) {
                const formData = new FormData();
                formData.append("file", file);

                // eslint-disable-next-line no-await-in-loop
                const { url: imageUrl } = await uploadQuestionImage(formData);

                question.title = question.title.replace(url, imageUrl);
              }
            }

            const questionChoices = [];

            if (question?.type === "MULTIPLE_CHOICE") {
              const { choices } = question;

              for (const choice of choices) {
                let choiceId;

                if (id) {
                  if (
                    (
                      questionsFromRequest?.find(
                        (questionId) => questionId.id === question.id
                      ) as MultipleChoiceQuestion
                    )?.choices?.some(({ id }) => id === choice.id)
                  ) {
                    choiceId = question.id;
                  } else {
                    choiceId = undefined;
                  }
                }

                questionChoices.push({
                  ...choice,
                  id: choiceId as string,
                });
              }
            }

            questions.push({
              ...question,
              choices: questionChoices,
              id: questionId,
              isNullified: undefined,
              updatedAt: undefined,
              createdAt: undefined,
              remove: id ? question.remove : undefined,
              files: undefined,
            });
          }
        }

        const eventBaseRequestData = {
          ...prepareFormData({
            ...eventResolved,
            moderators:
              moderators?.length === 0 ? event.moderators : moderators,
          }),
          questions,
          distributionOccurred: undefined,
          owner: undefined,
          image: undefined,
          currentDate: undefined,
          weightOfQuestions: Number(event?.weightOfQuestions),
        };

        if (id) {
          editEvent({
            ...eventBaseRequestData,
            appealPeriodStart: undefined,
            appealPeriodEnd: undefined,
            code: undefined,
            createdAt: undefined,
            participants: undefined,
            participantsCount: undefined,
            updatedAt: undefined,
            isFinished: undefined,
            eventStartNotified: undefined,
            correctionStartNotified: undefined,
            linkedGroup: undefined,
            appealPeriodNotified: undefined,
          });
        } else {
          createEvent(eventBaseRequestData);
        }
      })
      .catch(async (e) => {
        // console.log(e);
        await eventSettingsForm.trigger([
          "name",
          "responsePeriodStart",
          "responsePeriodEnd",
          "correctionPeriodStart",
          "correctionPeriodEnd",
        ]);

        await eventQuestionsAndAnswersForm.trigger(["questions"]);

        toast(
          "Há campos obrigatórios a ser preenchidos ou inválidos.",
          "warning"
        );
      });
  };


  useEffect(() => {
    if ((errors.questions as any)?.message) {
      toast((errors.questions as any)?.message, "error");
    }
    if (isEditSuccess) {
      navigate(`/meus-eventos/${id}`);
    }
    if (isCreateSuccess) {
      navigate(`/meus-eventos/${id}`);
    }
  }, [errors, isCreateSuccess, isEditSuccess]);

  return (
    <Flex flex={1}>
      <FormProvider {...eventQuestionsAndAnswersForm}>
        <Paper p={8}>
          <Flex
            direction="column"
            justify="space-between"
            gap={18}
            as="form"
            onSubmit={handleSubmit(handleSendQuestions)}
          >
            <Flex direction="column" flex={1}>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="questions" type="questions">
                  {(provided) => (
                    <Flex ref={provided.innerRef} direction="column" gap={18}>
                      {questionsList.map((question, order) => (
                        <Flex direction="column" key={question.id} gap={18}>
                          <QuestionEditor
                            {...question}
                            order={order}
                            onRemove={onRemoveQuestion}
                            onDuplicate={duplicateQuestion}
                          />
                        </Flex>
                      ))}
                      {provided.placeholder}
                    </Flex>
                  )}
                </Droppable>
              </DragDropContext>

              <Flex direction="column" align="center" gap={2} mt={6}>
                <IconButton
                  aria-label="delete question"
                  icon={<MdAddCircle size="24px" />}
                  variant="icon"
                  color="primary.500"
                  minW="auto"
                  height="auto"
                  onClick={() => appendQuestion(questionsList.length + 1)}
                />

                <Text color="black" fontSize="sm">
                  Adicionar questão
                </Text>
              </Flex>
            </Flex>

            <Flex gap={12} justify="end">
              <Button
                variant="link"
                size="lg"
                as={Link}
                to={
                  event.id
                    ? `/meus-eventos/${event.id}/editor/configuracoes`
                    : "/meus-eventos/editor/configuracoes"
                }
              >
                Voltar
              </Button>

              <Button type="submit" size="lg" w="13.375rem">
                Avançar
              </Button>
              {/* <Button
                size="lg"
                w="13.375rem"
                onClick={handleSaveEvent}
                isLoading={
                  isCreateLoading || isEditLoading
                }>
                Salvar e sair
              </Button> */}
            </Flex>
          </Flex>
        </Paper>
      </FormProvider>
    </Flex>
  );
}
